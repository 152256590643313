import React, { useEffect } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import 'moment/locale/se';
import 'moment/locale/en-gb';
import { Button, CssBaseline, makeStyles, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { IAppState } from '../redux/store';
import { NavBar } from '../components/navBar';
import Translation from '../services/translation';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SetLoggedOnUser } from '../redux/actions';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import TransText from '../resource/transText';
import { SelectCustomerDialog } from '../components/selectCustomerDialog';
import { LoggedOnUser } from '../model/userModel';
import { CustomerDTO } from '../model/customerModel';

const useStyles = makeStyles((theme) => ({
	layoutLanguage: {
		flexGrow: 1,
		margin: 20,
	},
	title: {
		margin: 20,
	},
	wrapper: {
		position: 'relative',
	},
	inputLable: {
		minWidth: '200px',
		fontSize: '0.9em',
	},
	inputBox: {
		height: 10,
		padding: 8,
		fontSize: '1em',
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	disableDiv: {
		opacity: 0.5,
		pointerEvents: 'none',
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
}));

export const Login = (props: any) => {
	const { baseUrl, lang } = useSelector((state: IAppState) => state.app);
	const dispatch = useDispatch();
	const classes = useStyles();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	const [account, setAccount] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [spinner, setSpinner] = React.useState(false);
	const [openSelectCustomer, setOpenSelectCustomer] = React.useState(false);
	const [loggedOnUser, setLoggedOnUser] = React.useState<LoggedOnUser>();

	const { t } = Translation;

	useEffect(() => {
		if (props.location.state && props.location.state.redirect) {
			enqueueSnackbar(
				t(TransText.login.pageProtectedLogin, null, lang, [
					props.location.state.redirect,
				]),
				{
					variant: 'warning',
				}
			);
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props]);

	const handleAccountChange = (event: any) => {
		setAccount(event.target.value);
	};

	const handlePasswordChange = (event: any) => {
		setPassword(event.target.value);
	};

	const handleFormSubmit = () => {
		setSpinner(true);

		let data = JSON.stringify({
			Username: account,
			Password: password,
			AppCode: process.env.REACT_APP_APPCODE,
		});

		axios
			.post(baseUrl + 'Authenticate/Login', data, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((res) => {
				dispatch(SetLoggedOnUser(res.data));
				setLoggedOnUser(res.data);

				let superRoles = res.data.roles.filter((role: string) => {
					if (role === 'SUPER' || role === 'SUPPORT') return true;
					else return false;
				});
				if (superRoles.length > 0) {
					setOpenSelectCustomer(true);
				} else {
					dispatch(SetLoggedOnUser(res.data));

					if (props.location.state && props.location.state.redirect) {
						history.push({
							pathname: props.location.state.redirect,
							state: { from: 'login' },
						});
					} else history.push('/');

					enqueueSnackbar(t(TransText.app.loggedIn, null, lang, []), {
						variant: 'success',
					});
				}
			})
			.catch((error) => {
				let errorMessage: string = t(TransText.error.general, null, lang, []);
				if (error.message.includes('401'))
					errorMessage = t(TransText.error.failedLogin, null, lang, []);
				else if (error.message === 'Network Error')
					errorMessage = t(TransText.error.network, null, lang, []);
				enqueueSnackbar(errorMessage, {
					variant: 'error',
				});
			})
			.finally(() => {
				setSpinner(false);
			});
	};

	const onCancelCustomerSelection = () => {
		setOpenSelectCustomer(false);
		dispatch(SetLoggedOnUser(null));
		enqueueSnackbar(t(TransText.app.loggedOut, null, lang, []), {
			variant: 'warning',
		});
	};

	const onConfirmCustomerSelection = (customer: CustomerDTO) => {
		setOpenSelectCustomer(false);
		let updated: LoggedOnUser = {
			...loggedOnUser!,
			tenant: customer.id.toLowerCase(),
			tenantName: customer.name,
		};
		dispatch(SetLoggedOnUser(updated));

		enqueueSnackbar(
			t(TransText.app.loggedInWithCustomer, null, lang, [customer.name]),
			{
				variant: 'success',
			}
		);
	};

	return (
		<React.Fragment>
			<CssBaseline />
			<NavBar />
			<ValidatorForm onSubmit={handleFormSubmit}>
				<Grid container justify="center">
					<Grid item xs={12} sm={6} lg={4}>
						<Paper>
							<Box m={4} p={4}>
								<Box display="flex" justifyContent="center" mt={2} mb={2}>
									<Typography variant="h5" noWrap>
										{Translation.t(TransText.login.headTitle, null, lang, [])}
									</Typography>
								</Box>

								<Box p={4}>
									<Box
										display="flex"
										justifyContent="center"
										flexDirection="column"
									>
										<TextValidator
											className={classes.textField}
											margin="dense"
											label="Konto"
											name="konot"
											onChange={handleAccountChange}
											value={account}
											validators={['required']}
											errorMessages={['this field is required']}
											fullWidth
										/>
										<TextValidator
											className={classes.textField}
											margin="dense"
											label="Lösenord"
											name="konot"
											type="password"
											onChange={handlePasswordChange}
											value={password}
											validators={['required']}
											errorMessages={['this field is required']}
											fullWidth
										/>
									</Box>

									<Box display="flex" justifyContent="flex-start" mt={2}>
										<Box flexGrow={1} mr={2}>
											<Button variant="contained" size="small">
												{Translation.t(
													TransText.login.forgottpassword,
													null,
													lang,
													[]
												)}
											</Button>
										</Box>
										<div className={classes.wrapper}>
											<Button
												variant="contained"
												size="small"
												color="primary"
												disabled={spinner}
												type="submit"
											>
												{Translation.t(TransText.app.login, null, lang, [])}
											</Button>
											{spinner && (
												<CircularProgress
													size={24}
													className={classes.buttonProgress}
												/>
											)}
										</div>
									</Box>
								</Box>
							</Box>
						</Paper>
					</Grid>
				</Grid>
			</ValidatorForm>
			{openSelectCustomer ? (
				<SelectCustomerDialog
					open={openSelectCustomer}
					onCancel={onCancelCustomerSelection}
					onConfirm={onConfirmCustomerSelection}
				/>
			) : (
				''
			)}
		</React.Fragment>
	);
};
