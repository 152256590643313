import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { SerieSetting } from '../../../model/dashModel';

interface IProps {
	serie: SerieSetting;
	onEdit: (serie: SerieSetting) => any;
}

export const SerieFuncParameterItem = React.forwardRef(
	(props: IProps, ref: any) => {
		return (
			<React.Fragment>
				<div ref={ref}>
					<Box display="flex" flexDirection="column">
						<Box display="flex" alignItems="center">
							<Box
								m={3}
								onTouchStart={(e) => {
									e.stopPropagation();
									props.onEdit(props.serie);
								}}
								onClick={(e) => {
									e.stopPropagation();
									props.onEdit(props.serie);
								}}
							>
								{true ? <FontAwesomeIcon icon={faEdit} /> : ''}
							</Box>

							<Typography variant="h6">
								<Box fontSize="16px">{props.serie.name}</Box>
							</Typography>
						</Box>

						<Typography variant="h6">
							<Box ml={3} fontSize="14px">
								{props.serie.pointDTO?.pointName +
									',' +
									props.serie.pointDTO?.pointDesc}
							</Box>
						</Typography>
					</Box>
				</div>
			</React.Fragment>
		);
	}
);
