class Translation {
	static t = (
		tranObj: any,
		transItem: string | null,
		lang: string,
		params: string[]
	): string => {
		let transText: string = '';

		if (tranObj === undefined) transText = 'No Translation';
		else {
			if (transItem !== null) {
				transText = tranObj[transItem][lang];
				if (!transText) {
					transText = tranObj[transItem]['enUS'];
					if (!transText) {
						transText = 'No Translation:' + tranObj.toString();
						return transText;
					}
				}
			} else {
				transText = tranObj[lang];
				if (!transText) {
					transText = tranObj['enUS'];

					if (!transText) {
						transText = 'No Translation:' + tranObj.toString();
						return transText;
					}
				}
			}
		}

		if (params !== undefined && params.length !== 0) {
			// Pares the params
			for (let c: number = 0; c < params.length; c++) {
				transText = transText.replace('{' + c + '}', params[c]);
			}
		}
		return transText;
	};
}

export default Translation;
