import {
	Box,
	createStyles,
	CssBaseline,
	Fab,
	makeStyles,
	Paper,
	Theme,
	Typography,
} from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from '../../redux/store';
import Translation from '../../services/translation';
import TransText from '../../resource/transText';

import { NavBar } from '../../components/navBar';

import { useHistory } from 'react-router';

import ArrowBackwardIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexFlow: 'column',
			height: '100vh',
		},
		paper: {
			flex: '1 1 auto',
			margin: '12px',
		},
		textField: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			width: '25ch',
		},
		smallSize: {
			fontSize: 13,
			fontWeight: 400,
		},
		wrapperRel: {
			position: 'relative',
		},
		buttonProgress: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
		},
	})
);

export interface INotFoundProps {
	pcType: string;
	nanoId: string;
}

export const NotFound: React.FC<INotFoundProps> = (props) => {
	const { lang } = useSelector((state: IAppState) => state.app);
	const { t } = Translation;
	const classes = useStyles();
	const history = useHistory();

	const GoBackClick = () => {
		history.push('/nano/' + props.nanoId);
	};

	return (
		<React.Fragment>
			<CssBaseline />
			<div className={classes.root}>
				<NavBar />

				<Paper className={classes.paper}>
					{/* Title */}
					<Box display="flex" justifyContent="space-between" m={2}>
						<Box m={2}>
							<Fab
								size="small"
								color="primary"
								aria-label="add"
								onClick={GoBackClick}
							>
								<ArrowBackwardIcon />
							</Fab>
						</Box>

						<Box>
							<Typography variant="h5" noWrap>
								{t(TransText.app.notFound, null, lang, [props.pcType])}
							</Typography>
						</Box>
						<Box width="40px"></Box>
					</Box>
				</Paper>
			</div>
		</React.Fragment>
	);
};
