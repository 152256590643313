import React from 'react';
import { Box, TextField, makeStyles, Divider, Button } from '@material-ui/core';
import TransText from '../../../resource/transText';
import Translation from '../../../services/translation';
import { IAppState } from '../../../redux/store';
import { SerieFuncParameterItem } from './serieFuncParameterItem';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { SerieSetting } from '../../../model/dashModel';
import { SerieSettingDialog } from './serieSettingDialog';
import { useSnackbar } from 'notistack';

export interface FuncObj {
	funcParams: SerieSetting[];
	funcExpression: string;
}

interface IProps {
	funcObj: FuncObj;
	onUpdate: (funcObj: FuncObj) => any;
}

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
			width: '25ch',
		},
	},
	appBarDialog: {
		position: 'relative',
	},
	titleDialog: {
		marginLeft: theme.spacing(2),
		flexGrow: 1,
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		//width: '25ch',
	},
	smallSize: {
		fontSize: 13,
		fontWeight: 400,
	},
	paper: {
		width: '100%',
		height: '100%',

		backgroundColor: 'white',
	},
	margin: {
		margin: theme.spacing(3),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 90,
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative',
	},
	progress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}));

export const SerieFunction: React.FC<IProps> = (props) => {
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const { lang, loggedOnUser } = useSelector((state: IAppState) => state.app);
	const { t } = Translation;

	const [editPointParam, setEditPointParam] = React.useState<SerieSetting>();
	const serieRefArray = React.useRef(new Map<number, any>());

	const AddPointParam = () => {
		let date = new Date();
		let newSerie: SerieSetting = {
			id: date.getTime(),
			name: '',
			pointDTO: undefined,
			valueCalcExpresion: '',
			valueCalcParams: [],
			valuePathExpression: '',
			graphicalType: '',

			color: '',

			colorLevels: [],

			top: 0,
			left: 0,
			width: 0,
			height: 0,

			value: '',

			controlRef: 0,
		};

		setEditPointParam(newSerie);
	};

	return (
		<React.Fragment>
			<Box display="flex" flexDirection="column">
				{/* Func point parameters */}
				<Box
					display="flex"
					justifyContent="flex-start"
					alignItems="center"
					flexWrap="wrap"
					m={2}
				>
					{t(TransText.serieFunc.addParam, null, lang, [])}
					<Box
						m={6}
						onTouchStart={(e) => {
							e.stopPropagation();
							AddPointParam();
						}}
						onClick={(e) => {
							e.stopPropagation();
							AddPointParam();
						}}
					>
						<FontAwesomeIcon icon={faPlus} />
					</Box>
				</Box>
				<Box
					style={{ backgroundColor: 'blue' }}
					p={2}
					maxHeight={'300px'}
					overflow={'auto'}
				>
					{props.funcObj.funcParams !== undefined ? (
						<Box>
							{props.funcObj.funcParams.map(
								(serie: SerieSetting, index: number) => {
									return (
										<Box
											style={
												editPointParam !== undefined &&
												editPointParam.id === serie.id
													? { backgroundColor: 'yellow' }
													: { backgroundColor: 'white' }
											}
										>
											<SerieFuncParameterItem
												ref={(ref) => {
													serieRefArray.current.set(index, ref);
												}}
												key={serie.id}
												serie={serie}
												onEdit={(serie: SerieSetting) => {
													setEditPointParam(serie);
												}}
											/>
											<Divider />
										</Box>
									);
								}
							)}
						</Box>
					) : (
						''
					)}
				</Box>

				{/* FuncExpresion */}
				<Box mt={2}>
					<TextField
						fullWidth
						multiline
						rows={8}
						className={classes.textField}
						InputProps={{
							classes: {
								//								input: classes.smallSize,
							},
						}}
						InputLabelProps={{
							shrink: true,
						}}
						label={t(TransText.serieFunc.functionExpression, null, lang, [])}
						value={
							props.funcObj.funcExpression !== undefined
								? props.funcObj.funcExpression
								: ''
						}
						onChange={(e) => {
							let fo = { ...props.funcObj };
							fo.funcExpression = e.target.value;

							props.onUpdate(fo);
						}}
					/>
				</Box>
			</Box>

			{editPointParam ? (
				<SerieSettingDialog
					title={t(TransText.serieFunc.SelectParameter, null, lang, [])}
					open={true}
					serie={editPointParam}
					onOk={function (serie: SerieSetting) {
						// Check if update
						let fo = { ...props.funcObj };

						// Handle older widgets that did n ot have func config
						if (fo.funcParams === undefined) fo.funcParams = [];
						if (fo.funcExpression === undefined) fo.funcExpression = '';

						let index = fo.funcParams.findIndex((x) => {
							return x.id === serie.id;
						});

						if (index < 0) {
							// New Func param
							// Filter duplicates
							let found = fo.funcParams.find((x) => {
								return x.name === serie.name;
							});
							if (found === undefined) {
								fo.funcParams.push(serie);
							} else {
								enqueueSnackbar(
									t(TransText.serieFunc.duplicatedParamName, null, lang, [
										serie.name,
									]),
									{
										variant: 'error',
									}
								);
								return;
							}
						} else {
							// Update
							fo.funcParams[index] = serie;
						}

						setEditPointParam(undefined);
						props.onUpdate(fo);
					}}
					onRemove={(serie: SerieSetting) => {
						let fo = { ...props.funcObj };
						let index = fo.funcParams.findIndex((x) => {
							return x.id === serie.id;
						});
						if (index > -1) {
							fo.funcParams.splice(index, 1);
							props.onUpdate(fo);
							setEditPointParam(undefined);
						}
					}}
					onCancel={() => {
						setEditPointParam(undefined);
					}}
					showGraphicalType={false}
					showColorLevels={false}
					showColor={false}
					showFunction={false}
				/>
			) : (
				''
			)}
		</React.Fragment>
	);
};
