import React, { Component } from 'react';
import { CssBaseline, Typography } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from '../redux/store';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Translation from '../services/translation';
import { NavBar } from '../components/navBar';
import TransText from '../resource/transText';

const style = {
	root: {
		background: 'linear-gradient(90deg, #FE6B8B 30%, #FF8E00 90%)',
		borderRadius: 5,
		border: 0,
		color: 'white',
		height: 48,
		padding: '0 30px',
		boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
	},
	paper: {
		margin: 10,
		padding: 10,
	},
};

interface IProps extends WithStyles<typeof style> {
	lang: string;
}

class Home extends Component<IProps> {
	render() {
		const { classes, lang } = this.props;
		const { t } = Translation;

		return (
			<React.Fragment>
				<CssBaseline />
				<NavBar />
				<Grid
					container
					direction="row"
					justify="center"
					alignItems="center"
				></Grid>

				<Grid container justify="center">
					<Grid item xs={12} sm={8} lg={6}>
						<Paper className={classes.paper}>
							<Grid container justify="center">
								<Typography variant="h4">
									{t(TransText.home.headTitle, null, lang, [])}
								</Typography>
							</Grid>
							<Box
								display="flex"
								flexDirection="column"
								justifyContent="center"
								m={4}
							>
								<Typography variant="subtitle1">
									{t(TransText.home.bodyTitle, null, lang, [])}
								</Typography>
								<Typography variant="body1">
									{t(TransText.home.body, null, lang, [])}
								</Typography>
							</Box>
						</Paper>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: IAppState) => {
	return {
		lang: state.app.lang,
	};
};

export default withStyles(style)(compose(connect(mapStateToProps))(Home));
