import React, { CSSProperties, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { IAppState } from '../../../redux/store';
import { PointDTO } from '../../../model/pointModel';
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import Translation from '../../../services/translation';
import TransText from '../../../resource/transText';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { PointAPI } from '../../../services/pointAPI';

import { SerieSetting, GraphicalTypes } from '../../../model/dashModel';
import { ColorPicker, ColorPickerParam } from '../common/colorPicker';

import { SerieFunction, FuncObj } from './serieFunction';
import { SerieFunctionTest } from './serieFunctionTest';
import { ComfirmDialog } from '../../confirmDialog';

const useStyles = makeStyles((theme) => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '25ch',
	},
	smallSize: {
		fontSize: 13,
		fontWeight: 400,
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative',
	},
	progress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 90,
		width: '100%',
	},
}));

interface ISeriesProps {
	open: boolean;
	title: string;
	serie: SerieSetting;
	onOk: (serie: SerieSetting) => any;
	onCancel: () => any;
	onRemove: (serie: SerieSetting) => any;

	showGraphicalType: boolean;
	showColorLevels: boolean;
	showColor: boolean;
	showFunction: boolean;
}

export const SerieSettingDialog = (props: ISeriesProps) => {
	const classes = useStyles();
	const { lang, loggedOnUser } = useSelector((state: IAppState) => state.app);
	const { t } = Translation;

	const [serie, setSerie] = React.useState<SerieSetting>(props.serie);
	const [removeSerie, setRemoveSerie] = React.useState<SerieSetting>();

	const [colorParam, setColorParam] = React.useState<ColorPickerParam>();

	const [points, setPoints] = React.useState<PointDTO[]>([]);
	const [openScriptTest, setOpenScriptTest] = React.useState<boolean>(false);

	const [reloadSpinner, setReloadSpinner] = React.useState<boolean>();

	useEffect(() => {
		let isMounted = true;

		if (isMounted) {
			if (points.length === 0) {
				let pointAPi = new PointAPI();

				pointAPi
					.GetPoints(loggedOnUser?.tenant!, '', 50)
					.then((resp) => {
						if (resp.status >= 200 && resp.status < 300) {
						}
						setPoints(resp.data.pointDTOs);
					})
					.catch((e) => {
						console.log(e.message);
					});
			}
			setReloadSpinner(false);
		}
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getColorButtonStyle = (color: string): CSSProperties => {
		return { backgroundColor: color, minHeight: '30px' };
	};

	return (
		<React.Fragment>
			<Dialog fullWidth open={props.open}>
				<DialogTitle>{props.title}</DialogTitle>
				<DialogContent dividers>
					<Box display="flex" flexDirection="column">
						{/* Name, Color */}
						<Box display="flex" flexDirection="row" mt={2}>
							<TextField
								className={classes.textField}
								InputProps={{
									classes: {
										input: classes.smallSize,
									},
								}}
								InputLabelProps={{
									shrink: true,
								}}
								margin="dense"
								size="small"
								label={t(TransText.app.name, null, lang, [])}
								value={serie.name}
								onChange={(e) => {
									setSerie({ ...serie, name: e.target.value });
								}}
							/>

							{/* Color */}
							{props.showColor ? (
								<Box m={2}>
									<Button
										style={getColorButtonStyle(serie.color)}
										variant="contained"
										onClick={() => {
											setColorParam({
												colorId: -1,
												color: serie.color,
											});
										}}
									></Button>
								</Box>
							) : (
								''
							)}
						</Box>

						{/* Select point */}
						<Box>
							<Autocomplete
								size="small"
								options={points}
								getOptionLabel={(option) =>
									option.pointName + ' , ' + option.pointDesc
								}
								fullWidth
								renderInput={(params) => (
									<TextField
										{...params}
										label={t(TransText.widgetEdit.selectPoint, null, lang, [])}
									/>
								)}
								value={serie.pointDTO}
								onChange={(e, value) => {
									if (value !== null) {
										if (serie.name === '')
											setSerie({
												...serie,
												pointDTO: value!,
												name: value!.pointName,
											});
										else setSerie({ ...serie, pointDTO: value! });
									} else setSerie({ ...serie, pointDTO: undefined });
								}}
							/>
						</Box>

						{/* Function */}
						{props.showFunction ? (
							<Box>
								<Box mt={2}>
									<SerieFunction
										funcObj={{
											funcExpression: serie.valueCalcExpresion,
											funcParams: serie.valueCalcParams,
										}}
										onUpdate={(funcObj: FuncObj) => {
											// Func param
											let s = { ...serie };
											s.valueCalcExpresion = funcObj.funcExpression;
											s.valueCalcParams = funcObj.funcParams;

											setSerie(s);
										}}
									/>
								</Box>
								{/* Func test */}
								<Box mt={2}>
									<Button
										disabled={
											(serie.valueCalcExpresion !== undefined &&
												serie.valueCalcExpresion.length === 0) ||
											serie.pointDTO === undefined
										}
										color="primary"
										size="small"
										variant="contained"
										onClick={() => {
											setOpenScriptTest(true);
										}}
									>
										{t(TransText.serieFunc.testScript, null, lang, [])}
									</Button>
								</Box>
							</Box>
						) : (
							''
						)}

						{/* Type */}
						<Box mt={2}>
							{props.showGraphicalType ? (
								<FormControl className={classes.formControl}>
									<InputLabel id="selectType">
										{t(TransText.series.graphicalShape, null, lang, [])}
									</InputLabel>
									<Select
										fullWidth
										labelId="selectType"
										id="selectType"
										value={serie !== undefined ? serie.graphicalType : ''}
										onChange={(e) => {
											let s = { ...serie };
											s.graphicalType =
												typeof e.target.value === 'string'
													? e.target.value
													: GraphicalTypes.Text;

											setSerie(s);
										}}
									>
										<MenuItem value={GraphicalTypes.Text}>
											{GraphicalTypes.Text}
										</MenuItem>
										<MenuItem value={GraphicalTypes.Rectangle}>
											{GraphicalTypes.Rectangle}
										</MenuItem>
									</Select>
								</FormControl>
							) : (
								''
							)}
						</Box>

						{/* Levels */}
						{props.showColorLevels ? (
							<Box display="flex" flexDirection="column" mt={6}>
								{/* Level 1 */}
								<Box display="flex" flexDirection="row" mb={2}>
									{/* Level */}
									<TextField
										className={classes.textField}
										InputProps={{
											classes: {
												input: classes.smallSize,
											},
										}}
										InputLabelProps={{
											shrink: true,
										}}
										type="number"
										label={t(TransText.series.levelStart, null, lang, ['1'])}
										value={
											serie.colorLevels === undefined ||
											serie.colorLevels.length == 0
												? 0
												: serie.colorLevels[0].level
										}
										onChange={(e) => {
											let ser = { ...serie };
											ser.colorLevels[0].level = Number.parseInt(
												e.target.value
											);

											setSerie(ser);
										}}
									/>

									{/* Color */}
									<Box m={2}>
										<Button
											style={getColorButtonStyle(serie.colorLevels[0].color)}
											variant="contained"
											onClick={() => {
												setColorParam({
													colorId: 0,
													color: serie.colorLevels[0].color,
												});
											}}
										></Button>
									</Box>
								</Box>

								{/* Level 2 */}
								<Box display="flex" flexDirection="row" mb={2}>
									{/* Level */}
									<TextField
										className={classes.textField}
										InputProps={{
											classes: {
												input: classes.smallSize,
											},
										}}
										InputLabelProps={{
											shrink: true,
										}}
										type="number"
										label={t(TransText.series.levelStart, null, lang, ['2'])}
										value={
											serie.colorLevels === undefined
												? 0
												: serie.colorLevels[1].level
										}
										onChange={(e) => {
											let ser = { ...serie };
											ser.colorLevels[1].level = Number.parseInt(
												e.target.value
											);

											setSerie(ser);
										}}
									/>
									{/* Color */}
									<Box m={2}>
										<Button
											style={getColorButtonStyle(serie.colorLevels[1].color)}
											variant="contained"
											onClick={() => {
												setColorParam({
													colorId: 1,
													color: serie.colorLevels[1].color,
												});
											}}
										></Button>
									</Box>
								</Box>
								{/* Level 3 */}
								<Box display="flex" flexDirection="row" mb={2}>
									{/* Level */}
									<TextField
										className={classes.textField}
										InputProps={{
											classes: {
												input: classes.smallSize,
											},
										}}
										InputLabelProps={{
											shrink: true,
										}}
										type="number"
										label={t(TransText.series.levelStart, null, lang, ['3'])}
										value={
											serie.colorLevels === undefined
												? 0
												: serie.colorLevels[2].level
										}
										onChange={(e) => {
											let ser = { ...serie };
											ser.colorLevels[2].level = Number.parseInt(
												e.target.value
											);

											setSerie(ser);
										}}
									/>
									{/* Color */}
									<Box m={2}>
										<Button
											style={getColorButtonStyle(serie.colorLevels[2].color)}
											variant="contained"
											onClick={() => {
												setColorParam({
													colorId: 2,
													color: serie.colorLevels[2].color,
												});
											}}
										></Button>
									</Box>
								</Box>
								{/* Level 4 */}
								<Box display="flex" flexDirection="row" mb={2}>
									{/* Level */}
									<TextField
										className={classes.textField}
										InputProps={{
											classes: {
												input: classes.smallSize,
											},
										}}
										InputLabelProps={{
											shrink: true,
										}}
										type="number"
										label={t(TransText.series.levelStart, null, lang, ['4'])}
										value={
											serie.colorLevels === undefined
												? 0
												: serie.colorLevels[3].level
										}
										onChange={(e) => {
											let ser = { ...serie };
											ser.colorLevels[3].level = Number.parseInt(
												e.target.value
											);

											setSerie(ser);
										}}
									/>
									{/* Color */}
									<Box m={2}>
										<Button
											style={getColorButtonStyle(serie.colorLevels[3].color)}
											variant="contained"
											onClick={() => {
												setColorParam({
													colorId: 3,
													color: serie.colorLevels[3].color,
												});
											}}
										></Button>
									</Box>
								</Box>
							</Box>
						) : (
							''
						)}
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						size="small"
						variant="contained"
						disabled={serie.pointDTO === undefined}
						onClick={() => {
							setRemoveSerie(serie);
						}}
					>
						{t(TransText.app.remove, null, lang, [])}
					</Button>
					<Button
						size="small"
						variant="contained"
						onClick={() => props.onCancel()}
					>
						{t(TransText.app.cancel, null, lang, [])}
					</Button>
					<div className={classes.wrapper}>
						<Button
							disabled={
								serie.name === undefined ||
								serie.name.length === 0 ||
								serie.pointDTO === undefined ||
								reloadSpinner
							}
							color="primary"
							size="small"
							variant="contained"
							onClick={() => {
								props.onOk(serie);
							}}
						>
							{t(TransText.app.ok, null, lang, [])}
						</Button>
						{reloadSpinner && (
							<CircularProgress size={24} className={classes.progress} />
						)}
					</div>
				</DialogActions>
			</Dialog>

			<ComfirmDialog
				showCancelButton={true}
				open={removeSerie !== undefined}
				titleText={t(TransText.series.removeSerie, null, lang, [
					removeSerie?.name!,
				])}
				contentText={''}
				cancelButtonText={t(TransText.app.no, null, lang, [])}
				confirmButtonText={t(TransText.app.yes, null, lang, [])}
				onCancel={() => {
					setRemoveSerie(undefined);
				}}
				onConfirm={() => {
					props.onRemove(removeSerie!);
					setRemoveSerie(undefined);
				}}
			/>
			{colorParam ? (
				<Dialog open={true}>
					<DialogContent>
						<Box m={4}>
							<ColorPicker
								param={colorParam}
								onSelectedColor={(colorParam: ColorPickerParam) => {
									setColorParam(undefined);
									let s = { ...serie };
									if (colorParam.colorId === -1) {
										s.color = colorParam.color;
									} else
										s.colorLevels[colorParam.colorId].color = colorParam.color;
									setSerie(s);
								}}
							/>
						</Box>
					</DialogContent>
				</Dialog>
			) : (
				''
			)}
			<Dialog open={openScriptTest}>
				<DialogContent>
					<Box m={4}>
						<SerieFunctionTest
							serie={serie}
							onUpdate={(serie: SerieSetting) => {
								// Func param
								let s = { ...serie };
								s.valueCalcExpresion = serie.valueCalcExpresion;
								s.valueCalcParams = serie.valueCalcParams;

								setSerie(s);
							}}
							onCancel={() => {
								setOpenScriptTest(false);
							}}
						/>
					</Box>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
};
