import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import { Provider } from 'react-redux';

import 'typeface-roboto';
import './index.css';

import App from './app';
import { store } from './redux/store';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

declare global {
	interface Window {
		Config: any;
	}
}

let baseUrl = './appsettings.json?timestamp=';
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
	baseUrl = './appsettings.json?timestamp=';
}

axios.get(baseUrl + new Date().getTime()).then((res) => {
	ReactDOM.render(
		<Provider store={store}>
			<DndProvider backend={HTML5Backend}>
				<App buildNr={res.data.BuildNumber} baseUrl={res.data.API} />
			</DndProvider>
		</Provider>,

		document.getElementById('root')
	);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
