import { Box } from '@material-ui/core';

import { DataGridPro } from '@mui/x-data-grid-pro';
import moment from 'moment';
import React, {
	CSSProperties,
	Ref,
	useEffect,
	useImperativeHandle,
} from 'react';
import { useSelector } from 'react-redux';
import {
	enPointState,
	PointDataDTO,
	PointDTO,
	PointHistoryResponseDTO,
	RefObj,
	WidgetInfo,
} from '../../../../model/pointModel';
import { IAppState } from '../../../../redux/store';
import { PointAPI } from '../../../../services/pointAPI';
import { IWidgetProps } from '../../widgetTypes';

export enum EnColumnType {
	String = 0,
	Number,
	Date,
}
export interface IColumns {
	id: string;
	label: string;
	type: EnColumnType;
}

export const WidgetDefaultOptions = {
	height: '120px',
	columns: [],
	rows: [],
};

export const WidgetTable1 = React.forwardRef(
	(props: IWidgetProps, ref: Ref<RefObj>) => {
		const { loggedOnUser } = useSelector((state: IAppState) => state.app);

		// First render props.widgetInfo sets widgetINfo state
		const [widgetInfo, setWidgetInfo] = React.useState<WidgetInfo>();

		useImperativeHandle(ref, () => ({ CleanWidgetTypeConfigFromPvs }));

		// Called by the dash when saving the widgets info
		// This fuinction removes any pointdata from the structure so
		// it will not end up in the database.
		const CleanWidgetTypeConfigFromPvs = (): any => {
			let copyJson = JSON.stringify(props.widgetInfo);
			let copyObj: WidgetInfo = JSON.parse(copyJson);
			copyObj.widgetTypeConfig.rows = [];
			return copyObj;
		};

		useEffect(() => {
			let isMounted = true;
			let timer: any;
			let getHistoryDone = true;

			// At first render the widget updates widgetInfo.widgetTypeConfig
			// with default configuration, so we get a first unconfigured render
			if (props.widgetInfo.widgetTypeConfig === undefined) {
				let wi = { ...props.widgetInfo };
				wi.widgetTypeConfig = JSON.parse(JSON.stringify(WidgetDefaultOptions));
				props.widgetUpdateDefaultConfigUpdate(wi);
				return;
			}

			// Read all points data direct on props.widgets update and start a
			// refresh timer
			if (isMounted && !props.widgetInfo.refresh?.subscription) {
				getPointsHistory(props.widgetInfo.points[0], true);

				if (timer == null) {
					timer = setInterval(
						() => {
							if (isMounted) {
								if (getHistoryDone) {
									getHistoryDone = false;
									getPointsHistory(props.widgetInfo.points[0], true)?.then(
										() => {
											getHistoryDone = true;
										}
									);
								}
							}
						},
						props.widgetInfo.refresh === undefined
							? 30000
							: props.widgetInfo.refresh.refresh * 1000
					);
				}
			}

			return () => {
				isMounted = false;
				clearInterval(timer);
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [props.widgetInfo]);

		const getPointsHistory = (pointDTO: PointDTO, rebuild: boolean) => {
			if (!pointDTO) return;

			let pointAPI: PointAPI = new PointAPI();
			return pointAPI
				.GetPointsHistory({
					customerId: loggedOnUser?.tenant == null ? '' : loggedOnUser.tenant,
					pointids: [pointDTO.pointID],
					fromDateTime: moment().subtract(4, 'day').toISOString(),
					toDateTime: moment().toISOString(),
					groupByIntervalUnit: '',
					groupByInterval: 0,
					maxRowsToReturn: 3600,
					onlyReturnCount: false,
					latestValue: false,
				})
				.then((resp) => {
					if (resp.status >= 200 && resp.status < 300) {
						let pointHistoryResp: PointHistoryResponseDTO;
						pointHistoryResp = resp.data;

						if (
							pointHistoryResp !== null &&
							pointHistoryResp.pointHistory !== null &&
							pointHistoryResp.pointHistory.length > 0 &&
							pointHistoryResp.pointHistory[0].points.length > 0 &&
							(props.widgetInfo.widgetTypeConfig.columns === undefined ||
								rebuild)
						) {
							let wi = { ...props.widgetInfo };

							// Filter out any faulted states
							let points: PointDataDTO[] =
								pointHistoryResp.pointHistory[0].points.filter(
									(x: PointDataDTO) => {
										return x.state === enPointState.OK;
									}
								);

							// Get the column names
							if (wi.widgetTypeConfig.columns === []) {
								// Get the column names
								let rowData = JSON.parse(points[0].value);
								rowData.forEach((column: any) => {
									wi.widgetTypeConfig.columns = [
										...wi.widgetTypeConfig.columns,
										{
											field: column.Key,
											headerName: column.Key,
											width: 200,
										},
									];
								});

								if (
									wi.widgetTypeConfig.columns.find(
										(x: any) => x.field.toLowerCase() === 'id'
									) === undefined
								) {
									wi.widgetTypeConfig.columns = [
										...wi.widgetTypeConfig.columns,
										{
											field: 'id',
											headerName: 'id',
											width: 20,
											hide: true,
										},
									];
								}
							}

							// Get the column data
							let tableData: any[] = [];

							let id: number = 0;
							points.forEach((row) => {
								try {
									let rowData = JSON.parse(row.value);
									let tableRow: any = {};

									tableRow['id'] = id;
									id += 1;

									rowData.forEach((col: any) => {
										tableRow[col.name] = col.value;
									});

									tableData.push(tableRow);
								} catch (e) {}
							});

							wi.widgetTypeConfig.rows = tableData;
							wi.points = [pointDTO];

							setWidgetInfo(wi);
						}
					} else {
						// Error
					}
				})
				.catch((e) => {
					console.log(e);
				})
				.finally(() => {
					// setSpinner(false);
				});
		};

		const getDivStyle = (): CSSProperties => {
			return {
				width: '100%',
				height:
					props.widgetInfo.widgetTypeConfig.height === undefined
						? '120px'
						: props.widgetInfo.widgetTypeConfig.height + 'px',
			};
		};

		return (
			<Box style={{ overflow: 'hidden' }} m={2}>
				{widgetInfo ? (
					<div style={getDivStyle()}>
						<DataGridPro
							hideFooter={true}
							rows={
								widgetInfo.widgetTypeConfig.rows === undefined
									? []
									: widgetInfo.widgetTypeConfig.rows
							}
							columns={
								widgetInfo.widgetTypeConfig.columns === undefined
									? []
									: widgetInfo.widgetTypeConfig.columns
							}
							density="compact"
							sortModel={[
								{
									field: 'Date',
									sort: 'desc',
								},
							]}
						/>
					</div>
				) : (
					''
				)}
			</Box>
		);
	}
);
