export enum enTimeUnitType {
	Second,
	Minute,
	Hour,
	Day,
	Week,
	Month,
}

export enum enPointValueType {
	no_type = 0,
	long_type = 1,
	double_type = 2,
	bool_type = 3,
	string_type = 4,
	json_type = 5,
}

export enum enPointState {
	UNINITIALIZED = 0,
	OK = 11,
	SIGNAL_ADRESS_UNKNOWN = 15,
	COM_SIGNALFAULT = 20,
	COM_FAIL = 21,
	CONNECTION_FAIL = 22,
	VALUE_CONVERSION_FAIL = 23,
	COM_TIMEOUT = 24,
	COM_SIGNALNOTCONNECTED = 25,
	NULL = 26,
	APP_FAULT = 50,
}

export enum enRequestType {
	PC_REQUEST = 0,
	NANO_REQUEST = 1,
}

export enum enPcRequestType {
	WRITE_POINT = 0,
	READ_POINT = 1,
	CALL_POINT = 2,
	CALL_FUNCTION = 3,
}

export enum enErrorCodes {
	UNAUTHORIZED = 0,
	STREAM_ENDED = 1,
	NOT_FOUND = 2,
	NO_TENANT = 3,
	INTERNAL_ERROR = 4,
	TIMEOUT = 5,
}
export enum enPcStateCodes {
	PC_UN_INITIALIZED = 0,
	PC_NOT_ACTIVE = 1,
	PC_CONFIGURED = 2,
	PC_CONNECTED = 3,
	PC_RUNNING = 4,
	PC_FAILED = 5,
	PC_DISCONNECTED = 6,
}

export enum enNanoStateCodes {
	NANO_UN_INITIALIZED = 0,
	NANO_NOT_ACTIVE = 1,
	NANO_CONFIGURED = 2,
	NANO_BOOTING_PC = 3,
	NANO_OPEN_SERVERREQUEST = 4,
	NANO_RUNNING = 5,
	NANO_FAILED = 6,
}
export interface RefObj {
	CleanWidgetTypeConfigFromPvs: () => WidgetInfo;
}

export interface WidgetInfo {
	i: string;
	x: number;
	y: number;
	w: number;
	h: number;
	type: string;
	title: string;
	points: PointDTO[];
	refresh: DataRefresh | undefined;
	widgetTypeConfig: any;
	widgetRefIndex: number;
	error: string | undefined;
}

export interface DataRefresh {
	subscription: boolean;

	start: number;
	startUnit: keyof typeof enTimeUnitType;

	last: number;
	lastUnit: keyof typeof enTimeUnitType;

	group: number;
	groupUnit: keyof typeof enTimeUnitType;

	refresh: number;
	refreshUnit: keyof typeof enTimeUnitType;
}

export interface PointDTO {
	pointID: string;
	pointName: string;
	valueType: enPointValueType;
	customerID: string;
	nanoID: string;
	nanoName: string;
	siteID: string;
	siteName: string;
	pointDesc: string;
}

export interface PointHistoryRequestDTO {
	customerId: string;
	pointids: string[];
	fromDateTime: string;
	toDateTime: string;
	groupByIntervalUnit: string;
	groupByInterval: number;
	onlyReturnCount: boolean;
	latestValue: boolean;
	maxRowsToReturn: number;
}

export interface PointHistoryResponseDTO {
	result: string;
	pointHistory: PointHistoryDTO[];
}

export interface PointHistoryDTO {
	pointId: string;
	points: PointDataDTO[];
}

export interface PointDataDTO {
	pointId: string;
	time: string;
	state: enPointState;
	value: string;
	maxValue: string;
	minValue: string;
}

export interface NanoRequestDTO {
	customerId: string;
	requestType: enRequestType;
	pcRequestType: enPcRequestType;
	pointDataDTOs: PointDataDTO[];
	jsonCallParameters: string;
}

export interface NanoResponseDTO {
	pointDataDTOs: PointDataDTO[];
	jsonResult: string;
	result: string;
}

export interface CustomerSiteNanoPcResponse {
	result: string;
	nanoConfigs: NanoConfig[];
}

export interface NanoConfig {
	id: string;
	name: string;
	description: string;
	nanoVersion: string;
	active: boolean;
	nanoConfigJson: string;
	siteID: string;
	siteName: string;
	nanoState: enNanoStateCodes;
	pcConfigs: PcConfig[];
	nanoAppConfigs: NanoAppConfig[];
	[key: string]: any;
}

export interface PcConfig {
	id: string;
	name: string;
	description: string;
	pcType: string;
	pcTypeVersion: string;
	active: boolean;
	pcConfigCustomJson: string;
	runMode: string;
	serviceAccount: string;
	servicePassword: string;
	serviceDomain: string;
	nanoId: string;
	nanoName: string;
	pointconfigs: PointConfig[];
	pcState: enPcStateCodes;
	[key: string]: any;
}

export interface NanoAppConfig {
	nanoAppID: string;
	name: string;
	description: string;
	nanoAppType: string;
	nanoAppVersion: string;
	active: boolean;
	inputPoints: PointConfig[];
	outputPoints: PointConfig[];
	nanoAppPoints: PointConfig[];
	nanoAppCustomJson: string;
	scriptCode: string;
}

export interface PointConfig {
	id: string;
	valueType: string;
	scanRateMS: number;
	deadBand: number;
	pointConfigCustomJson: string;
	valueIsArray: boolean;
	name: string;
	pointAdress: string;

	read: boolean;
	write: boolean;
	subscribed: boolean;
	function: boolean;
	call: boolean;
	description: string;
	importState: string;
	importComment: string;
	upUser: string;
	upTime: Date;
	//[key: string]: any;
}

export interface CustomerPcUpdateReq {
	CustomerId: string;
	PcId: string;
	pcConfig: PcConfig;
}

export interface CustomerPcPointsWTO {
	customerId: string;
	pcId: string;
	pointConfigs: PointConfig[];
}
