import { AppEvents } from './events';
import { IAction } from './actions';
import { EnumConstant, LoggedOnUser } from '../model/userModel';

export interface IState {
	lang: string;
	buildNr: string;
	baseUrl: string;
	loggedOnUser: LoggedOnUser | null;
}

const initState: IState = {
	lang: 'enUS',
	buildNr: '',
	baseUrl: '',
	loggedOnUser: null,
};

export const reducer = (state: IState = initState, action: IAction): IState => {
	switch (action.type) {
		case AppEvents.SET_LANGUAGE:
			return { ...state, lang: action.payload };
		case AppEvents.SET_BUILDNR:
			return { ...state, buildNr: action.payload };
		case AppEvents.SET_BASEURL:
			return { ...state, baseUrl: action.payload };
		case AppEvents.SET_LOGGEDONUSER: {
			if (action.payload)
				sessionStorage.setItem(
					EnumConstant.LOGGEDONUSER,
					JSON.stringify(action.payload)
				);
			else sessionStorage.removeItem(EnumConstant.LOGGEDONUSER);

			return { ...state, loggedOnUser: action.payload };
		}
		default:
			return state;
	}
};
