import {
	AppBar,
	CircularProgress,
	Box,
	Button,
	Checkbox,
	CssBaseline,
	Dialog,
	DialogContent,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	InputLabel,
	makeStyles,
	MenuItem,
	Paper,
	Select,
	TextField,
	Toolbar,
	Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../redux/store';
import TransText from '../../../../resource/transText';
import Translation from '../../../../services/translation';
import { OrangeButton } from '../../../../styles/buttons';
import {
	enPointState,
	enPointValueType,
	enTimeUnitType,
	PointDTO,
	PointHistoryResponseDTO,
	WidgetInfo,
} from '../../../../model/pointModel';
import { PointAPI } from '../../../../services/pointAPI';
import { AdminAPI } from '../../../../services/adminAPI';
import { useSnackbar } from 'notistack';
import { TranslationErrorText } from '../../../../model/generelModel';
import {
	ArtifactResponseWTO,
	UpdateArtifactResponse,
} from '../../../../model/customerModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {
	ControlSerie,
	DragItemTypes,
	ValueControlTypes,
} from './ValueControls/controlTypes';
import { SerieItemControl } from './ValueControls/serieItemControl';
import { ValueControl } from './ValueControls/valueControl';
import { ComfirmDialog } from '../../../confirmDialog';
import { SerieControlDialog } from './ValueControls/serieControlDialog';
import { useDrop, XYCoord } from 'react-dnd';

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
			width: '25ch',
		},
	},
	appBarDialog: {
		position: 'relative',
	},
	titleDialog: {
		marginLeft: theme.spacing(2),
		flexGrow: 1,
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		//width: '25ch',
	},
	smallSize: {
		fontSize: 13,
		fontWeight: 400,
	},
	paper: {
		width: '100%',
		height: '100%',

		backgroundColor: 'white',
	},
	margin: {
		margin: theme.spacing(3),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 90,
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative',
	},
	progress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}));

interface IProps {
	selectedWidgetInfo: WidgetInfo;
	open: boolean;
	remove: (id: string) => any;
	save: (widgetInfo: WidgetInfo) => any;
	cancel: () => any;
}

export const WidgetEditImage1: React.FC<IProps> = ({
	selectedWidgetInfo,
	open,
	save,
	remove,
	cancel,
}) => {
	const classes = useStyles();
	const { t } = Translation;
	const { lang, loggedOnUser } = useSelector((state: IAppState) => state.app);
	const [widgetInfo, setWidgetInfo] =
		React.useState<WidgetInfo>(selectedWidgetInfo);

	const [reloadSpinner, setReloadSpinner] = React.useState<boolean>();
	const [points, setPoints] = React.useState<PointDTO[]>([]);

	const [selectedFile, setSelectedFile] = React.useState<FileList>();
	const [image, setImage] = React.useState<string>();
	const [lastImage, setLastImage] = React.useState<string>();

	const [editSerie, setEditSerie] = React.useState<ControlSerie>();
	const [removeSerie, setRemoveSerie] = React.useState<ControlSerie>();
	const [selectedSerie, setSelectedSerie] = React.useState<ControlSerie>();
	const { enqueueSnackbar } = useSnackbar();

	const imageRef = useRef<HTMLImageElement>(null);
	const serieRefArray = React.useRef(new Map<number, any>());

	useEffect(() => {
		let isMounted = true;

		if (points.length === 0) {
			let pointAPi = new PointAPI();

			pointAPi
				.GetPoints(loggedOnUser?.tenant!, '', 50)
				.then((resp) => {
					if (isMounted) {
						if (resp.status >= 200 && resp.status < 300) {
							setPoints(
								resp.data.pointDTOs.filter((x: any) => {
									return (
										x.valueType === enPointValueType.double_type ||
										x.valueType === enPointValueType.long_type ||
										x.valueType === enPointValueType.bool_type ||
										x.valueType === enPointValueType.json_type
									);
								})
							);
						}
					}
				})
				.catch((e) => {
					console.log(e.message);
				})
				.finally(() => {
					//setPointLoadSpinner(false);
				});
		}

		if (
			isMounted &&
			selectedWidgetInfo.widgetTypeConfig.imageArtifactId !== undefined &&
			selectedWidgetInfo.widgetTypeConfig.imageArtifactId.length > 0 &&
			image === undefined
		) {
			GetImageArtifact(widgetInfo.widgetTypeConfig.imageArtifactId);
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSaveWidget = (widgetInfo: WidgetInfo) => {
		if (selectedFile !== undefined)
			UploadFile(selectedFile[0], selectedFile[0].name)
				.then((resp: any) => {
					if (resp.status >= 200 && resp.status < 300) {
						var updateArtifactResponse: UpdateArtifactResponse = resp.data;

						widgetInfo.widgetTypeConfig.imageArtifactId =
							updateArtifactResponse.id;
						widgetInfo.widgetTypeConfig.imageArtifactUptime =
							updateArtifactResponse.upTime;

						save(widgetInfo);
					}
				})
				.catch((e: any) => {
					let translationErrorText: TranslationErrorText = {
						ErrorCode: 'SERVER_ERROR',
						Parameters: [],
					};

					if (
						e.response.data !== undefined &&
						typeof e.response.data !== 'string'
					) {
						translationErrorText = e.response.data;
					}
					enqueueSnackbar(
						t(
							TransText.error[
								translationErrorText.ErrorCode as keyof typeof TransText.error
							],
							null,
							lang,
							translationErrorText.Parameters
						),
						{
							variant: 'error',
						}
					);
				});
		else save(widgetInfo);
	};

	const OnClickReloadSeries = () => {
		let points: Array<PointDTO> = [];

		if (widgetInfo.widgetTypeConfig.series !== undefined) {
			for (let c = 0; c < widgetInfo.widgetTypeConfig.series.length; c++) {
				points.push(widgetInfo.widgetTypeConfig.series[c].pointDTO);
			}
		}
		getPointsHistory(points);
	};

	const getPointsHistory = (pointDTOs: PointDTO[]) => {
		if (!pointDTOs) return;

		setReloadSpinner(true);
		let pointAPI: PointAPI = new PointAPI();
		pointAPI
			.GetPointsHistory({
				customerId: loggedOnUser?.tenant == null ? '' : loggedOnUser.tenant,
				pointids: pointDTOs.map((x) => x.pointID),
				fromDateTime: '',
				toDateTime: '',
				groupByIntervalUnit: '',
				groupByInterval: 0,
				maxRowsToReturn: 0,
				onlyReturnCount: false,
				latestValue: true,
			})
			.then((resp) => {
				if (resp.status >= 200 && resp.status < 300) {
					let pointHistoryResp: PointHistoryResponseDTO;

					pointHistoryResp = resp.data;

					if (
						pointHistoryResp.pointHistory &&
						pointHistoryResp.pointHistory.length > 0
					) {
						let wi = { ...widgetInfo };

						for (let c = 0; c < pointHistoryResp.pointHistory.length; c++) {
							// Each points

							let series: ControlSerie[] = wi.widgetTypeConfig.series.filter(
								(x: ControlSerie) => {
									return (
										x.pointDTO?.pointID ===
										pointHistoryResp.pointHistory[c].pointId
									);
								}
							);

							series.map((serie) => {
								let samp = pointHistoryResp.pointHistory[c].points[0];

								// Convert to correct type
								if (serie.type === ValueControlTypes.Rectangle) {
									let value: number | null = null;
									value = parseInt(samp.value);

									if (
										samp.state !== enPointState.NULL &&
										samp.state === enPointState.OK
									) {
										serie.value = value;
									} else {
										// TODO: Handle not Ok state
									}
								} else if (serie.type === ValueControlTypes.Text) {
									if (
										samp.state !== enPointState.NULL &&
										samp.state === enPointState.OK
									) {
										serie.value = samp.value;
									} else {
										// TODO: Handle not Ok state
										serie.value = '';
									}
								}
							});

							setWidgetInfo(wi);
						}
					}
				}
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				setReloadSpinner(false);
			});
	};

	const HandleImageOnload = () => {
		if (image !== undefined && lastImage !== image.substring(0, 500)) {
			setLastImage(image.substring(0, 500));

			let wi = { ...widgetInfo };
			wi.widgetTypeConfig.imageHeight = imageRef.current?.height;
			wi.widgetTypeConfig.imageWidth = imageRef.current?.width;
			setWidgetInfo(wi);
		}
	};

	const HandleSelectedFile = (fileList: FileList) => {
		setSelectedFile(fileList);

		let wi = { ...widgetInfo };
		wi.widgetTypeConfig.imageHeight = undefined;
		wi.widgetTypeConfig.imageWidth = undefined;
		setWidgetInfo(wi);

		// Preview
		if (fileList !== null && fileList.length > 0)
			setImage(URL.createObjectURL(fileList[0]));
	};

	const UploadFile = (file: any, fileName: string) => {
		let adminAPI: AdminAPI = new AdminAPI();
		return adminAPI.UploadArtifactFile(
			loggedOnUser?.tenant ? loggedOnUser?.tenant : '',
			widgetInfo.widgetTypeConfig.imageArtifactId,
			selectedWidgetInfo.i + '_image',
			'IMAGE',
			file,
			fileName
		);
	};

	const GetImageArtifact = (artifcatId: string) => {
		let adminAPI: AdminAPI = new AdminAPI();
		adminAPI
			.GetCustomerArtifact(
				loggedOnUser?.tenant ? loggedOnUser?.tenant : '',
				artifcatId
			)
			.then((resp) => {
				if (resp.status >= 200 && resp.status < 300) {
					var artifactResponse: ArtifactResponseWTO = resp.data;

					setImage(artifactResponse.artifactWTOs[0].imreBase64Data);
				}
			})
			.catch((e) => {
				let translationErrorText: TranslationErrorText = {
					ErrorCode: 'SERVER_ERROR',
					Parameters: [],
				};

				if (
					e.response.data !== undefined &&
					typeof e.response.data !== 'string'
				) {
					translationErrorText = e.response.data;
				}
				enqueueSnackbar(
					t(
						TransText.error[
							translationErrorText.ErrorCode as keyof typeof TransText.error
						],
						null,
						lang,
						translationErrorText.Parameters
					),
					{
						variant: 'error',
					}
				);
			});
	};

	const OnEditSerie = (controlSerie: ControlSerie) => {
		controlSerie.newWidget = false;
		setEditSerie(controlSerie);
		setSelectedSerie(controlSerie);
	};

	const AddSerie = () => {
		let date = new Date();
		let serie: ControlSerie = {
			id: date.getTime(),
			name: '',
			pointDTO: undefined,
			colorLevels: [
				{ level: 0, color: '#0d233a' },
				{ level: 25, color: '#8bbc21' },
				{ level: 50, color: '#910000' },
				{ level: 90, color: '#f28f43' },
			],
			valueCalcExpresion: '',
			valueCalcParamPointDTOs: [],
			newWidget: true,
			type: 'Quadrat',
			top: 10,
			left: 10,
			width: 36,
			height: 16,
			value: undefined,
			controlRef: 0,
		};

		setEditSerie(serie);
	};

	const onSaveEditSerie = (controlSerie: ControlSerie) => {
		setSelectedSerie(controlSerie);

		onUpdateEditSerie(controlSerie);
	};

	const onUpdateEditSerie = (controlSerie: ControlSerie) => {
		let wi = { ...widgetInfo };
		let serieIndex = getEditSerieIndex(controlSerie);

		if (serieIndex > -1) {
			wi.widgetTypeConfig.series[serieIndex] = controlSerie;

			wi.points[serieIndex] = controlSerie.pointDTO!;
		} else {
			wi.widgetTypeConfig.series.push(controlSerie);
			wi.points.push(controlSerie.pointDTO!);
		}

		setWidgetInfo(wi);

		setEditSerie(undefined);
	};

	const onCancelEditSerie = () => {
		setEditSerie(undefined);
	};

	const onDeleteEditSerie = () => {
		setEditSerie(undefined);
		setRemoveSerie(editSerie);
	};

	const getEditSerieIndex = (controlSerie: ControlSerie) => {
		let serieIndex = -1;

		if (
			widgetInfo.widgetTypeConfig.series === undefined ||
			widgetInfo.widgetTypeConfig.series.lengt === 0
		)
			return serieIndex;

		let dSerie = editSerie === undefined ? controlSerie : editSerie;

		for (
			let c: number = 0;
			c < widgetInfo.widgetTypeConfig.series.length;
			c++
		) {
			if (widgetInfo.widgetTypeConfig.series[c].id === dSerie!.id) {
				serieIndex = c;
				break;
			}
		}

		return serieIndex;
	};

	const onCancelRemoveSerie = () => {
		setRemoveSerie(undefined);
	};

	const onConfirmRemoveSerie = () => {
		let wi = { ...widgetInfo };
		let serieIndex = getEditSerieIndex(removeSerie!);

		if (serieIndex > -1) {
			wi.points.splice(serieIndex, 1);
			wi.widgetTypeConfig.series.splice(serieIndex, 1);
		}

		setWidgetInfo(wi);

		setRemoveSerie(undefined);
	};

	const HandleSelectSerie = (serie: ControlSerie) => {
		setSelectedSerie(serie);
	};

	const moveValueControl = useCallback(
		(draggedSerie: ControlSerie, left: number, top: number) => {
			let wi = { ...widgetInfo };

			let selSerie: ControlSerie = wi.widgetTypeConfig.series.find(
				(serie: ControlSerie) => {
					return serie.id === draggedSerie.id;
				}
			);
			if (selSerie !== undefined) {
				selSerie.top = top;
				selSerie.left = left;

				setWidgetInfo(wi);
			}
		},
		[widgetInfo, setWidgetInfo]
	);

	const [, drop] = useDrop(
		() => ({
			accept: DragItemTypes.VALUE_CONTROL,
			drop(item: ControlSerie, monitor) {
				const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
				const left = Math.round(item.left + delta.x);
				const top = Math.round(item.top + delta.y);
				moveValueControl(item, left, top);
			},
		}),
		[moveValueControl]
	);

	const ControlSelected = (id: number) => {
		let serie: ControlSerie = widgetInfo.widgetTypeConfig.series.find(
			(serie: ControlSerie) => {
				return serie.id === id;
			}
		);

		if (serie !== undefined && serie !== selectedSerie) {
			setSelectedSerie(serie);
			let index = widgetInfo.widgetTypeConfig.series.findIndex(
				(item: ControlSerie) => item.id === id
			);
			serieRefArray.current.get(index).scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
				inline: 'start',
			});
		}
	};

	return (
		<React.Fragment>
			<Dialog fullScreen open={open}>
				<AppBar className={classes.appBarDialog}>
					<Toolbar>
						<Typography variant="h6" className={classes.titleDialog}>
							{widgetInfo !== undefined ? widgetInfo.title : ''}
						</Typography>

						<Button
							autoFocus
							color="inherit"
							onClick={() => {
								remove(widgetInfo === undefined ? '' : widgetInfo.i);
							}}
						>
							Delete
						</Button>

						<Button
							autoFocus
							color="inherit"
							onClick={() => {
								onSaveWidget(widgetInfo);
							}}
						>
							Save
						</Button>
						<OrangeButton
							autoFocus
							color="inherit"
							onClick={() => {
								cancel();
							}}
						>
							{t(TransText.app.cancel, null, lang, [])}
						</OrangeButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<CssBaseline />
					<Grid container spacing={2}>
						{/* Widget */}
						<Grid item sm={8}>
							<Paper elevation={4}>
								<Box display="flex" flexDirection="column" m={4} p={4}>
									<Box display="flex" justifyContent="space-between">
										<Box>{''}</Box>
										<Box>
											<Typography variant="h6">
												<Box fontSize="16px">
													{widgetInfo !== undefined ? widgetInfo.title : ''}
												</Box>
											</Typography>
										</Box>
										<Box>{''}</Box>
									</Box>

									<Box display="flex" justifyContent="center">
										<div
											ref={drop}
											style={{ position: 'relative', overflow: 'hidden' }}
										>
											<img
												alt={'imageInfo'}
												ref={imageRef}
												src={image}
												width={widgetInfo.widgetTypeConfig.imageWidth}
												height={widgetInfo.widgetTypeConfig.imageHeight}
												style={{ zIndex: -1 }}
												onLoad={HandleImageOnload}
											/>
											{widgetInfo.widgetTypeConfig.series.map(
												(serie: ControlSerie) => (
													<ValueControl
														key={serie.id}
														serie={serie}
														value={serie.value}
														selected={
															selectedSerie !== undefined &&
															selectedSerie.id === serie.id
																? true
																: false
														}
														valueUpdate={() => {}}
														controlSelected={ControlSelected}
													/>
												)
											)}
										</div>
									</Box>
								</Box>
							</Paper>
						</Grid>

						{/* Widget settings */}
						<Grid item sm={4}>
							<Paper elevation={4}>
								<Box m={4} p={4}>
									{/* Title */}
									<Box m={2} mb={2}>
										<TextField
											fullWidth
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											label={t(
												TransText.widgetEdit.widgetTitle,
												null,
												lang,
												[]
											)}
											value={widgetInfo !== undefined ? widgetInfo.title : ''}
											onChange={(e) => {
												setWidgetInfo({
													...widgetInfo,
													title: e.target.value,
												});
											}}
										/>
									</Box>

									{/* Select file to upload */}
									<Box m={2} paddingTop={4}>
										<Button
											variant="contained"
											component="label"
											fullWidth
											color="primary"
										>
											{t(TransText.widgetEdit.selectImage, null, lang, [])}
											<input
												type="file"
												hidden
												onChange={(e) => {
													if (e.target.files !== null)
														HandleSelectedFile(e.target.files);
												}}
												accept={'image/*'}
											/>
										</Button>
									</Box>

									{/* Image size */}
									<Box
										display="flex"
										justifyContent="flex-start"
										flexWrap="wrap"
										m={2}
									>
										<Box mr={2}>
											<TextField
												InputLabelProps={{ shrink: true }}
												type="number"
												margin="dense"
												size="small"
												label={t(TransText.app.height, null, lang, [])}
												value={widgetInfo.widgetTypeConfig.imageHeight}
												onChange={(e) => {
													let wi = { ...widgetInfo };
													if (parseInt(e.target.value) > 1400)
														wi.widgetTypeConfig.imageHeight = '1400';
													else wi.widgetTypeConfig.imageHeight = e.target.value;
													setWidgetInfo(wi);
												}}
											/>
										</Box>
										<TextField
											InputLabelProps={{ shrink: true }}
											type="number"
											margin="dense"
											size="small"
											label={t(TransText.app.width, null, lang, [])}
											value={widgetInfo.widgetTypeConfig.imageWidth}
											onChange={(e) => {
												let wi = { ...widgetInfo };
												if (parseInt(e.target.value) > 1400)
													wi.widgetTypeConfig.imageWidth = '1400';
												else wi.widgetTypeConfig.imageWidth = e.target.value;
												setWidgetInfo(wi);
											}}
										/>
									</Box>

									{/* Series */}
									<Box
										display="flex"
										justifyContent="flex-start"
										alignItems="center"
										flexWrap="wrap"
										m={2}
									>
										{t(TransText.widgetEdit.series, null, lang, [])}
										<Box
											m={6}
											onTouchStart={(e) => {
												e.stopPropagation();
												AddSerie();
											}}
											onClick={(e) => {
												e.stopPropagation();
												AddSerie();
											}}
										>
											<FontAwesomeIcon icon={faPlus} />
										</Box>
									</Box>

									<Box flexDirection="column" display="flex" m={2}>
										<Box
											style={{ backgroundColor: 'blue' }}
											p={2}
											maxHeight={'300px'}
											overflow={'auto'}
										>
											{widgetInfo.widgetTypeConfig.series.map(
												(serie: ControlSerie, index: number) => {
													return (
														<Box
															style={
																selectedSerie !== undefined &&
																selectedSerie.id === serie.id
																	? { backgroundColor: 'yellow' }
																	: { backgroundColor: 'white' }
															}
															onClick={() => {
																HandleSelectSerie(serie);
															}}
														>
															<SerieItemControl
																ref={(ref) => {
																	serieRefArray.current.set(index, ref);
																}}
																key={serie.id}
																controlSerie={serie}
																onEdit={OnEditSerie}
															/>
														</Box>
													);
												}
											)}
										</Box>
									</Box>

									{/* Time range Refresh */}
									<Box display="flex" flexWrap="wrap" m={2} paddingTop={4}>
										<FormGroup row>
											<FormControlLabel
												control={
													<Checkbox
														color="primary"
														checked={
															widgetInfo !== undefined
																? widgetInfo.refresh?.subscription
																: false
														}
														onChange={(e) => {
															let wi = { ...widgetInfo };
															wi.refresh!.subscription = e.target.checked;

															setWidgetInfo(wi);
														}}
													/>
												}
												label={t(
													TransText.widgetEdit.timeRangeSubscription,
													null,
													lang,
													[]
												)}
											/>
										</FormGroup>
										<TextField
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											type="number"
											margin="dense"
											size="small"
											disabled={widgetInfo.refresh?.subscription}
											label={t(
												TransText.widgetEdit.timeRangeRefresh,
												null,
												lang,
												[]
											)}
											value={
												widgetInfo !== undefined
													? widgetInfo.refresh?.refresh
													: 0
											}
											onChange={(e) => {
												let wi = { ...widgetInfo };
												wi.refresh!.refresh = Number.parseInt(e.target.value);

												setWidgetInfo(wi);
											}}
										/>
										<Box ml={2}>
											<FormControl className={classes.formControl}>
												<InputLabel id="demo-simple-select-label">
													{t(
														TransText.widgetEdit.timeRangeRefreshUnit,
														null,
														lang,
														[]
													)}
												</InputLabel>

												<Select
													disabled={widgetInfo.refresh?.subscription}
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={
														widgetInfo !== undefined
															? widgetInfo.refresh?.refreshUnit.toString()
															: ''
													}
													onChange={(e) => {
														let wi = { ...widgetInfo };
														wi.refresh!.refreshUnit = e.target
															.value as keyof typeof enTimeUnitType;

														setWidgetInfo(wi);
													}}
												>
													<MenuItem value={'Second'}>sec</MenuItem>
													<MenuItem value={'Minute'}>min</MenuItem>
													<MenuItem value={'Hour'}>hour</MenuItem>
													<MenuItem value={'Day'}>day</MenuItem>
													<MenuItem value={'Week'}>week</MenuItem>
													<MenuItem value={'Month'}>month</MenuItem>
												</Select>
											</FormControl>
										</Box>
									</Box>
									<Box m={2}>
										<div className={classes.wrapper}>
											<Button
												onClick={OnClickReloadSeries}
												fullWidth
												variant="contained"
												color="primary"
												disabled={reloadSpinner}
											>
												{t(TransText.widgetEdit.loadAllSeries, null, lang, [])}
											</Button>
											{reloadSpinner && (
												<CircularProgress
													size={24}
													className={classes.progress}
												/>
											)}
										</div>
									</Box>
								</Box>
							</Paper>
						</Grid>
					</Grid>

					{editSerie != null ? (
						<SerieControlDialog
							open={editSerie != null ? true : false}
							controlSerie={editSerie!}
							onCancel={onCancelEditSerie}
							onUpdate={onSaveEditSerie}
							onDelete={onDeleteEditSerie}
							showType={true}
							showLevels={true}
						/>
					) : (
						''
					)}

					{removeSerie != null ? (
						<ComfirmDialog
							open={removeSerie != null ? true : false}
							titleText={t(
								TransText.widgetEdit.removeSerieConfirmTitle,
								null,
								lang,
								[removeSerie.name]
							)}
							contentText={t(
								TransText.widgetEdit.removeSerieConfirmContent,
								null,
								lang,
								[removeSerie.name]
							)}
							cancelButtonText={t(TransText.app.no, null, lang, [])}
							showCancelButton={true}
							confirmButtonText={t(TransText.app.yes, null, lang, [])}
							onCancel={onCancelRemoveSerie}
							onConfirm={onConfirmRemoveSerie}
						/>
					) : (
						''
					)}
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
};
