import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { Ref, useEffect, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { SerieSetting } from '../../../../model/dashModel';
import {
	enPointState,
	PointDTO,
	PointHistoryResponseDTO,
	RefObj,
	WidgetInfo,
} from '../../../../model/pointModel';
import { IAppState } from '../../../../redux/store';
import { PointAPI } from '../../../../services/pointAPI';
import { IWidgetProps } from '../../widgetTypes';

export const WidgetDefaultOptions = {
	text: 'Any text kan be set here use {serieName} to include point values',

	fontColor: '#000000',
	fontSize: '12',
	fontWeight: '300',

	series: [],
};
// Serie items
// id
// name
// ColorLevels
// valueCalcExpression
// valueCalcpointParams

export const WidgetTextVar2 = React.forwardRef(
	(props: IWidgetProps, ref: Ref<RefObj>) => {
		const { loggedOnUser } = useSelector((state: IAppState) => state.app);

		const [widgetInfo, setWidgetInfo] = React.useState<WidgetInfo>();

		useEffect(() => {
			let isMounted = true;
			let timer: any = null;
			let getHistoryDone = true;

			// At first render the widget updates widgetInfo.widgetTypeConfig
			// with default configuration, so we get a first unconfigured render
			if (props.widgetInfo.widgetTypeConfig === undefined) {
				let wi = { ...props.widgetInfo };
				wi.widgetTypeConfig = JSON.parse(JSON.stringify(WidgetDefaultOptions));
				props.widgetUpdateDefaultConfigUpdate(wi);
				return;
			}

			// Read all points data direct on props.widgets update and start a
			// refresh timer
			if (isMounted && !props.widgetInfo.refresh?.subscription) {
				getPointsLatestValue(props.widgetInfo.points);

				if (timer == null) {
					timer = setInterval(
						() => {
							if (isMounted) {
								if (getHistoryDone) {
									getHistoryDone = false;
									getPointsLatestValue(props.widgetInfo.points)?.then(() => {
										getHistoryDone = true;
									});
								}
							}
						},
						props.widgetInfo.refresh === undefined
							? 30000
							: props.widgetInfo.refresh.refresh * 1000
					);
				}
			}

			return () => {
				clearInterval(timer);
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [props.widgetInfo]);

		// Links the function CleanWidgetTypeConfigFromPvs to the parent
		useImperativeHandle(ref, () => ({ CleanWidgetTypeConfigFromPvs }));

		// Called by the dash when saving the widgets info
		// This fuinction removes any pointdata from the structure so
		// it will not end up in the database.
		const CleanWidgetTypeConfigFromPvs = (): any => {
			let wi = { ...props.widgetInfo };

			if (
				wi.widgetTypeConfig.series === undefined ||
				wi.widgetTypeConfig.series.length === 0
			)
				return wi;

			wi.widgetTypeConfig.series.map((x: any) => {
				x.data = [];
				return x;
			});

			return wi;
		};

		const getPointsLatestValue = (pointDTOs: PointDTO[]) => {
			let pointAPI: PointAPI = new PointAPI();

			let pointIds: string[] = [];
			for (let pointC = 0; pointC < pointDTOs.length; pointC++)
				pointIds.push(pointDTOs[pointC].pointID);

			return (
				pointAPI
					.GetPointsHistory({
						customerId: loggedOnUser?.tenant == null ? '' : loggedOnUser.tenant,
						pointids: pointIds,
						fromDateTime: '',
						toDateTime: '',
						groupByIntervalUnit: '',
						groupByInterval: 0,
						maxRowsToReturn: 0,
						onlyReturnCount: false,
						latestValue: true,
					})
					.then((resp) => {
						if (resp.status >= 200 && resp.status < 300) {
							let pointHistoryResp: PointHistoryResponseDTO;
							pointHistoryResp = resp.data;

							if (
								pointHistoryResp.pointHistory &&
								pointHistoryResp.pointHistory.length > 0
							) {
								let wi = { ...props.widgetInfo };

								for (
									let cPointId = 0;
									cPointId < pointHistoryResp.pointHistory.length;
									cPointId++
								) {
									let pointIndex = wi.points.findIndex((x) => {
										return (
											x.pointID ===
											pointHistoryResp.pointHistory[cPointId].pointId
										);
									});

									let serie = wi.widgetTypeConfig.series[pointIndex];
									if (serie === undefined) continue;

									serie.data = [];

									// Each pointID
									for (
										let cPoint = 0;
										cPoint <
										pointHistoryResp.pointHistory[cPointId].points.length;
										cPoint++
									) {
										// parse all PVS for point
										let samp =
											pointHistoryResp.pointHistory[cPointId].points[cPoint];

										let utc = moment(samp.time);
										let t = utc.add(utc.utcOffset(), 'minutes').toDate();

										let value: number | null = null;
										if (
											samp.state !== enPointState.NULL &&
											samp.state === enPointState.OK
										)
											value = parseFloat(samp.value);

										serie.value = value;
									}
								}

								setWidgetInfo(wi);
							}
						}
					})
					// .then((resp) => {
					// 	if (resp.status >= 200 && resp.status < 300) {
					// 		let pointHistoryResp: PointHistoryResponseDTO;
					// 		pointHistoryResp = resp.data;

					// 		if (
					// 			pointHistoryResp.pointHistory &&
					// 			pointHistoryResp.pointHistory.length > 0
					// 		) {
					// 			let wi = { ...props.widgetInfo };

					// 			for (
					// 				let cPointId = 0;
					// 				cPointId < pointHistoryResp.pointHistory.length;
					// 				cPointId++
					// 			) {
					// 				let pointIndex = wi.points.findIndex((x) => {
					// 					return (
					// 						x.pointID ===
					// 						pointHistoryResp.pointHistory[cPointId].pointId
					// 					);
					// 				});

					// 				let serie = wi.widgetTypeConfig.series[pointIndex];
					// 				if (serie === undefined) continue;

					// 				serie.data = [];

					// 				// Each pointID
					// 				for (
					// 					let cPoint = 0;
					// 					cPoint <
					// 					pointHistoryResp.pointHistory[cPointId].points.length;
					// 					cPoint++
					// 				) {
					// 					// Convert all PVS points to serie.data point
					// 					// all series has its own time serie
					// 					let samp =
					// 						pointHistoryResp.pointHistory[cPointId].points[cPoint];

					// 					let utc = moment(samp.time);
					// 					let t = utc.add(utc.utcOffset(), 'minutes').toDate();

					// 					let value: number | null = null;
					// 					if (samp.state !== enPointState.NULL)
					// 						value = parseFloat(samp.value);

					// 					serie.data.push({
					// 						x: t,
					// 						y: value,
					// 					});
					// 				}
					// 			}

					// 			setWidgetInfo(wi);
					// 		}
					// 	}
					// })
					.catch((e) => {
						console.log(e);
					})
					.finally(() => {})
			);
		};

		const MergeTextAndVar = () => {
			if (widgetInfo!.widgetTypeConfig.text === undefined) return 'Nan';

			// Splice out all sub parts of the string
			let subParts = [];
			let tempStr: string = widgetInfo!.widgetTypeConfig.text;
			let pos = 0;
			while (true) {
				let paramPos = tempStr.indexOf('{', pos);
				if (paramPos > -1) {
					subParts.push({
						type: 'TEXT',
						data: tempStr.substring(pos, paramPos),
					});

					pos = paramPos;
					paramPos = tempStr.indexOf('}', pos);
					if (paramPos > -1) {
						subParts.push({
							type: 'VAR',
							data: tempStr
								.substring(pos, paramPos + 1)
								.replace('{', '')
								.replace('}', ''),
						});
						pos = paramPos + 1;
					} else {
						// Unclosed parameter
						break;
					}
				} else {
					// Cut of the last
					if (pos >= tempStr.length) {
						break;
					} else {
						subParts.push({
							type: 'TEXT',
							data: tempStr.substring(pos),
						});
						break;
					}
				}
			}

			subParts.map((part: any) => {
				if (part.type === 'VAR') {
					// Find point
					let serie = widgetInfo!.widgetTypeConfig.series.find(
						(x: SerieSetting) => {
							return x.name === part.data;
						}
					);

					if (serie === undefined) part.data = 'undefined';
					else {
						part.serie = serie;
						part.data = serie.value !== null ? serie.value : 'No data';
					}
				}
			});

			// Loop all subparts of the string and style all variabels accordingly
			return (
				<React.Fragment>
					<span
						style={{
							fontSize: widgetInfo!.widgetTypeConfig.fontSize + 'px',
							fontWeight: widgetInfo!.widgetTypeConfig.fontWeight,
						}}
					>
						{subParts.map((x: any) => {
							if (x.type === 'VAR') {
								return (
									<span
										style={{
											color:
												x.serie !== undefined
													? getLevelColor(x.serie, x.data)
													: '#000000',
										}}
									>
										{x.data}
									</span>
								);
							} else return x.data;
						})}
					</span>
				</React.Fragment>
			);
		};

		const getLevelColor = (serie: any, value: number): string => {
			let textColor: string = '#000000';

			for (let c = 0; c < serie.colorLevels.length; c++) {
				if (value >= serie.colorLevels[c].level)
					textColor = serie.colorLevels[c].color;
			}

			return textColor;
		};

		return (
			<React.Fragment>
				{widgetInfo ? (
					<Box display="flex" justifyContent="center" ml={4}>
						<div key={1} id="textvar_2">
							<Typography variant="h5">{MergeTextAndVar()}</Typography>
						</div>
					</Box>
				) : (
					''
				)}
			</React.Fragment>
		);
	}
);
