import React from 'react';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { Box, DialogTitle, Typography } from '@material-ui/core';

interface IProps {
	onCancel: () => any;
	onConfirm: () => any;

	open: boolean;
	titleText: string;
	contentText: string;
	cancelButtonText: string;
	showCancelButton: boolean;
	confirmButtonText: string;
}

export const ComfirmDialog: React.FC<IProps> = (props) => {
	return (
		<Dialog open={props.open}>
			<DialogTitle>
				<Box m={1}>
					<Typography variant="body1">{props.titleText}</Typography>
				</Box>
			</DialogTitle>
			<DialogContent dividers>
				<Box m={1}>
					<Typography variant="body2">{props.contentText}</Typography>
				</Box>
			</DialogContent>
			<DialogActions>
				{props.showCancelButton ? (
					<Button
						size="small"
						variant="contained"
						onClick={() => props.onCancel()}
					>
						{props.cancelButtonText}
					</Button>
				) : (
					''
				)}
				<Button
					color="primary"
					size="small"
					variant="contained"
					onClick={() => props.onConfirm()}
				>
					{props.confirmButtonText}
				</Button>{' '}
			</DialogActions>
		</Dialog>
	);
};
