import React from 'react';
import { useHistory } from 'react-router';
import {
	fade,
	makeStyles,
	Theme,
	createStyles,
} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { SetLanguage, SetLoggedOnUser } from '../redux/actions';
import { IAppState } from '../redux/store';
import Box from '@material-ui/core/Box';
import Translation from '../services/translation';
import TransText from '../resource/transText';
import SE_flag from '../resource/img/sweden.png';
import UK_flag from '../resource/img/unitedkingdom.png';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChartLine,
	faHome,
	faLock,
	faUser,
	faUsers,
	faNetworkWired,
} from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
		},
		menuButton: {
			marginRight: theme.spacing(2),
		},
		offset: theme.mixins.toolbar,
		appBar: {
			transition: theme.transitions.create(['margin', 'width'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
		},
		grow: {
			flexGrow: 1,
		},
		search: {
			position: 'relative',
			borderRadius: theme.shape.borderRadius,
			backgroundColor: fade(theme.palette.common.white, 0.15),
			'&:hover': {
				backgroundColor: fade(theme.palette.common.white, 0.25),
			},
			marginRight: theme.spacing(2),
			marginLeft: 0,
			width: '100%',
			[theme.breakpoints.up('sm')]: {
				marginLeft: theme.spacing(3),
				width: 'auto',
			},
		},
		title: {
			display: 'block',
			[theme.breakpoints.up('sm')]: {
				display: 'block',
			},
		},
		searchIcon: {
			width: theme.spacing(7),
			height: '100%',
			position: 'absolute',
			pointerEvents: 'none',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		inputRoot: {
			color: 'inherit',
		},
		inputInput: {
			padding: theme.spacing(1, 1, 1, 7),
			transition: theme.transitions.create('width'),
			width: '100%',
			[theme.breakpoints.up('md')]: {
				width: 200,
			},
		},
		list: {
			width: 250,
		},
		fullList: {
			width: 'auto',
		},
		sectionDesktop: {
			display: 'none',
			[theme.breakpoints.up('md')]: {
				display: 'flex',
			},
		},
		sectionMobile: {
			display: 'flex',
			[theme.breakpoints.up('md')]: {
				display: 'none',
			},
		},
		flag: {
			width: '28px',
			height: '28px',
		},
	})
);

export const NavBar = (props: any) => {
	const { t } = Translation;
	const history = useHistory();
	const { lang, loggedOnUser, buildNr } = useSelector(
		(state: IAppState) => state.app
	);
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const isMenuOpen = Boolean(anchorEl);
	const [langAnchorEl, setLangAnchorEl] = React.useState(null);
	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});
	type DrawerSide = 'top' | 'left' | 'bottom' | 'right';

	const selectSensor = (name: string) => {
		if (name === 'Home') history.push('/');
		else if (name === 'Login') history.push('/login');
		else if (name === 'Customers') history.push('/customers');
		else if (name === 'Customer') history.push('/customer');
		else if (name === 'Dash') history.push('/dash');
		else if (name === 'Nanos') history.push('/nanos');
		console.log(name);
	};

	const sideList = (side: DrawerSide, buildNr: string) => (
		<div
			className={classes.list}
			role="presentation"
			onClick={toggleDrawer(side, false)}
			onKeyDown={toggleDrawer(side, false)}
		>
			<List>
				<ListItem button key="Home" onClick={() => selectSensor('Home')}>
					<ListItemIcon>
						<FontAwesomeIcon icon={faHome} />
					</ListItemIcon>
					<ListItemText primary="Home" />
				</ListItem>
				<ListItem button key="Login" onClick={() => selectSensor('Login')}>
					<ListItemIcon>
						<FontAwesomeIcon icon={faLock} />
					</ListItemIcon>
					<ListItemText primary="Login" />
				</ListItem>

				{loggedOnUser &&
				(loggedOnUser.roles.includes('SUPER') ||
					loggedOnUser.roles.includes('SUPPORT')) ? (
					<ListItem
						button
						key="Customers"
						onClick={() => selectSensor('Customers')}
					>
						<ListItemIcon>
							<FontAwesomeIcon icon={faUsers} />
						</ListItemIcon>
						<ListItemText primary="Customers" />
					</ListItem>
				) : null}

				{loggedOnUser && loggedOnUser.roles.includes('CUST_ADMIN') ? (
					<ListItem
						button
						key="Customer"
						onClick={() => selectSensor('Customer')}
					>
						<ListItemIcon>
							<FontAwesomeIcon icon={faUser} />
						</ListItemIcon>
						<ListItemText primary="Profile" />
					</ListItem>
				) : null}

				{loggedOnUser &&
				(loggedOnUser.roles.includes('SUPER') ||
					loggedOnUser.roles.includes('SUPPORT') ||
					loggedOnUser.roles.includes('CUST_ADMIN')) ? (
					<ListItem button key="Nanos" onClick={() => selectSensor('Nanos')}>
						<ListItemIcon>
							<FontAwesomeIcon icon={faNetworkWired} />
						</ListItemIcon>
						<ListItemText primary="Nanos" />
					</ListItem>
				) : null}

				{loggedOnUser &&
				(loggedOnUser.roles.includes('SUPER') ||
					loggedOnUser.roles.includes('SUPPORT') ||
					loggedOnUser.roles.includes('CUST_ADMIN') ||
          loggedOnUser.roles.includes('CUST_VIEW')) ? (
					<ListItem button key="Dash" onClick={() => selectSensor('Dash')}>
						<ListItemIcon>
							<FontAwesomeIcon icon={faChartLine} />
						</ListItemIcon>
						<ListItemText primary="Dash" />
					</ListItem>
				) : null}

				<Divider />
				<ListItem button key="BuildNr">
					<Box justifyItems="center">
						<ListItemText primary={buildNr} />
					</Box>
				</ListItem>
			</List>
		</div>
	);

	const toggleDrawer =
		(side: DrawerSide, open: boolean) =>
		(event: React.KeyboardEvent | React.MouseEvent) => {
			if (
				event.type === 'keydown' &&
				((event as React.KeyboardEvent).key === 'Tab' ||
					(event as React.KeyboardEvent).key === 'Shift')
			) {
				return;
			}
			setState({ ...state, [side]: open });
		};

	const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		setAnchorEl(null);
		dispatch(SetLoggedOnUser(null));
		enqueueSnackbar(t(TransText.app.loggedOut, null, lang, []), {
			variant: 'warning',
		});
	};

	const handleMyProfile = () => {
		setAnchorEl(null);
		history.push('/myProfile');
	};

	const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleLangMenuOpen = (event: any) => {
		setLangAnchorEl(event.currentTarget);
	};

	const handleSelectLanguage = (selLang: string) => {
		dispatch(SetLanguage(selLang));

		setLangAnchorEl(null);
	};

	const isLangMenuOpen = Boolean(langAnchorEl);
	const selectlangIcon = () => {
		if (lang === 'svSE')
			return <img src={SE_flag} className={classes.flag} alt=""></img>;
		else if (lang === 'enUS')
			return <img src={UK_flag} className={classes.flag} alt=""></img>;
	};
	const menuId = 'primary-search-account-menu';
	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={menuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			<ListItem button key="BuildNr">
				<Box justifyItems="center">
					<ListItemText primary={loggedOnUser?.user.userName} />
				</Box>
			</ListItem>

			<MenuItem onClick={handleMyProfile}>
				{t(TransText.navBarTop.myProfile, null, lang, [])}
			</MenuItem>

			<Divider></Divider>
			<MenuItem onClick={handleLogout}>
				{t(TransText.navBarTop.logout, null, lang, [])}
			</MenuItem>
		</Menu>
	);
	const renderLangMenu = (
		<Menu
			anchorEl={langAnchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={menuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isLangMenuOpen}
			onClose={() => {
				setLangAnchorEl(null);
			}}
		>
			<MenuItem onClick={() => handleSelectLanguage('svSE')}>
				<IconButton color="inherit">
					<img src={SE_flag} width={40} height={40} alt=""></img>
				</IconButton>
				{Translation.t(TransText.navBarTop.selectLanguage, null, 'svSE', [])}
			</MenuItem>
			<MenuItem onClick={() => handleSelectLanguage('enUS')}>
				<IconButton color="inherit">
					<img src={UK_flag} width={40} height={40} alt=""></img>
				</IconButton>
				{Translation.t(TransText.navBarTop.selectLanguage, null, 'enUS', [])}
			</MenuItem>
		</Menu>
	);
	const mobileMenuId = 'primary-search-account-menu-mobile';

	return (
		<React.Fragment>
			<AppBar style={{ background: '#FFFFFF', color: '#000000' }}>
				<Toolbar>
					<IconButton
						edge="start"
						className={classes.menuButton}
						color="inherit"
						aria-label="open drawer"
						onClick={toggleDrawer('left', true)}
					>
						<MenuIcon />
					</IconButton>
					<div className={classes.sectionDesktop}>
						<Typography className={classes.title} variant="h6" noWrap>
							{t(TransText.navBarTop.titleLong, null, lang, [])}{' '}
							{loggedOnUser == null ? '' : ' ' + loggedOnUser.tenantName}
						</Typography>
					</div>
					<div className={classes.sectionMobile}>
						<Typography className={classes.title} variant="h6" noWrap>
							{t(TransText.navBarTop.title, null, lang, [])}{' '}
							{loggedOnUser == null ? '' : ' ' + loggedOnUser.tenantName}
						</Typography>
					</div>

					<div className={classes.grow} />
					<IconButton color="inherit" onClick={handleLangMenuOpen}>
						{selectlangIcon()}
					</IconButton>

					{loggedOnUser ? (
						<div>
							<div className={classes.sectionDesktop}>
								<IconButton
									edge="end"
									aria-label="account of current user"
									aria-controls={menuId}
									aria-haspopup="true"
									onClick={handleProfileMenuOpen}
									color="inherit"
								>
									<AccountCircle />
								</IconButton>
							</div>
							<div className={classes.sectionMobile}>
								<IconButton
									aria-label="show more"
									aria-controls={mobileMenuId}
									aria-haspopup="true"
									onClick={handleMobileMenuOpen}
									color="inherit"
								>
									<MoreIcon />
								</IconButton>
							</div>
						</div>
					) : null}
				</Toolbar>
			</AppBar>
			<div className={classes.offset} />

			{/* {renderMobileMenu} */}

			{renderMenu}
			{renderLangMenu}

			<Drawer open={state.left} onClose={toggleDrawer('left', false)}>
				{sideList('left', 'ver: ' + buildNr)}
			</Drawer>
		</React.Fragment>
	);
};
