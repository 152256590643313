import {
	Box,
	Button,
	CircularProgress,
	createStyles,
	CssBaseline,
	Fab,
	IconButton,
	makeStyles,
	Paper,
	TextField,
	Theme,
	Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from '../redux/store';
import Translation from '../services/translation';
import TransText from '../resource/transText';

import { NavBar } from '../components/navBar';
import { AdminAPI } from '../services/adminAPI';
import { useHistory, useParams } from 'react-router';
import RouteLeavingGuard from '../components/routeLeavingGuard';
import ArrowBackwardIcon from '@material-ui/icons/ArrowBack';

import { useSnackbar } from 'notistack';
import { enNanoStateCodes, NanoConfig } from '../model/pointModel';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faDownload,
	faPen,
	faPlus,
	faSync,
	faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { EnumRoleTypes } from '../model/userModel';
import { CustomerDTO } from '../model/customerModel';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexFlow: 'column',
			height: '100vh',
		},
		paper: {
			flex: '1 1 auto',
			margin: '12px',
		},
		textField: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			width: '25ch',
		},
		smallSize: {
			fontSize: 13,
			fontWeight: 400,
		},
		wrapperRel: {
			position: 'relative',
		},
		buttonProgress: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
		},
	})
);

export const Customer: React.FC = (props: any) => {
	const { lang, loggedOnUser } = useSelector((state: IAppState) => state.app);
	const { t } = Translation;
	const classes = useStyles();
	const history = useHistory();
	const params: any = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const [entity, setEntity] = React.useState<CustomerDTO>({
		result: '',
		id: '',
		name: '',
		organizationNr: '',
		adress: '',
		zipCode: '',
		city: '',
		country: '',
		contactName: '',
		contactEmail: '',
		contactPhone: '',
		sites: [],
		accounts: [],
	});
	const [orgEntity, setOrgEntity] = React.useState<CustomerDTO>({
		result: '',
		id: '',
		name: '',
		organizationNr: '',
		adress: '',
		zipCode: '',
		city: '',
		country: '',
		contactName: '',
		contactEmail: '',
		contactPhone: '',
		sites: [],
		accounts: [],
	});
	const [dirty, setDirty] = React.useState<boolean>(false);
	const [progress, setProgress] = React.useState<boolean>(false);

	const adminAPI = new AdminAPI();

	const columns: any[] = [
		{
			field: '',
			headerName: '',
			sortable: false,
			width: 30,
			disableClickEventBubbling: true,
			renderCell: (params: any) => {
				const onClick = () => {
					let convPcType: string = params.row.pcType;
					convPcType = convPcType.replaceAll('.', '_');

					history.push({
						pathname:
							'/customerAccount/' +
							convPcType +
							'/' +
							params.id +
							'/' +
							params.row.nanoId,
					});
				};
				return (
					<IconButton size="small" color="primary" onClick={onClick}>
						<FontAwesomeIcon icon={faPen} />
					</IconButton>
				);
			},
		},
		{
			field: 'userId',
			headerName: 'Id',
			width: 50,
			hide: true,
		},
		{
			field: 'name',
			headerName: t(TransText.app.name, null, lang, []),
			width: 180,
		},
		{
			field: 'email',
			headerName: t(TransText.app.active, null, lang, []),
			width: 120,
		},
	];

	useEffect(() => {
		const handleUnload = (e: any) => {
			if (dirty) {
				e.preventDefault();
				e.returnValue = 'Stop';
			}
		};

		window.onbeforeunload = handleUnload;

		return () => {
			window.onbeforeunload = null;
		};
	}, [dirty]);

	useEffect(() => {
		loadEntity();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let isDirty = false;
		for (let key in orgEntity) {
			if (orgEntity.hasOwnProperty(key)) {
				if (key !== 'pcConfigs' && key !== 'nanoAppConfigs') {
					if (entity[key] !== orgEntity[key]) {
						isDirty = true;
						break;
					}
				}
			}
		}

		setDirty(isDirty);

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entity, orgEntity]);

	const onSave = () => {
		setProgress(true);
		adminAPI
			.UpdateCustomer(entity)
			.then(() => {
				setDirty(false);
				enqueueSnackbar(
					t(TransText.nanoDetail.saveSuccessfully, null, lang, []),
					{
						variant: 'success',
					}
				);
			})
			.catch(() => {
				enqueueSnackbar(t(TransText.nanoDetail.saveFailed, null, lang, []), {
					variant: 'error',
				});
			})
			.finally(() => {
				setProgress(false);
			});
	};

	const loadEntity = () => {
		let tenantId: string = '';

		if (params.tenantId === undefined) {
			if (loggedOnUser === null) {
				return;
			} else tenantId = loggedOnUser.tenant;
		} else tenantId = params.tenantId;

		setProgress(true);
		return adminAPI
			.GetCustomer(tenantId)
			.then((response) => {
				if (response.status >= 200 && response.status < 300) {
					setEntity(response.data);
					setOrgEntity(response.data);
					setDirty(false);
					console.log('Reset dirty');
				} else {
					enqueueSnackbar(
						t(TransText.error, response.data.result + '_CUSTOMER', lang, []),
						{
							variant: 'error',
						}
					);
				}
			})
			.catch((ex) => {
				enqueueSnackbar(ex.message, {
					variant: 'error',
				});
			})
			.finally(() => {
				setProgress(false);
			});
	};

	const onCancel = () => {
		setEntity(orgEntity);
		setDirty(false);
	};

	const GoBackClick = () => {
		history.push('/nanos');
	};

	const IsUserInRole = (inRole: EnumRoleTypes) => {
		return loggedOnUser !== null && loggedOnUser.roles.includes(inRole);
	};

	const HandleReload = () => {
		loadEntity();
	};

	const HandleAddPc = () => {};

	return (
		<React.Fragment>
			<RouteLeavingGuard
				when={dirty}
				navigate={(path) => history.push(path)}
				shouldBlockNavigation={(location) => {
					return dirty;
				}}
			/>
			<CssBaseline />

			<div className={classes.root}>
				<NavBar />
				<Paper className={classes.paper}>
					{/* Title */}
					<Box display="flex" justifyContent="space-between" m={2}>
						<Box m={2} display="flex" flexDirection="row">
							{IsUserInRole(EnumRoleTypes.SUPER) ||
							IsUserInRole(EnumRoleTypes.SUPPORT) ? (
								<Box m={2}>
									<Fab
										size="small"
										color="primary"
										aria-label="add"
										onClick={GoBackClick}
										disabled={dirty}
									>
										<ArrowBackwardIcon />
									</Fab>
								</Box>
							) : null}

							<Box ml={2}>
								<div className={classes.wrapperRel}>
									<Fab
										size="small"
										color="secondary"
										aria-label="add"
										disabled={progress}
										onClick={HandleReload}
									>
										<FontAwesomeIcon icon={faSync} />
									</Fab>
									{progress && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</div>
							</Box>
						</Box>

						<Box>
							<Typography variant="h5" noWrap>
								{t(TransText.customerDetail.headTitle, null, lang, [])}
							</Typography>
						</Box>
						<Box width="80px"></Box>
					</Box>
					{/* Settings */}
					<Box p={4}>
						<Paper elevation={4} style={{ maxWidth: '900px' }}>
							<Box m={4} p={4}>
								{/* Name row */}
								<Box
									display="flex"
									justifyContent="flex-start"
									flexWrap="wrap"
									m={2}
								>
									<TextField
										className={classes.textField}
										InputProps={{
											classes: {
												input: classes.smallSize,
											},
										}}
										InputLabelProps={{
											shrink: true,
										}}
										margin="dense"
										size="small"
										label={t(TransText.customerDetail.name, null, lang, [])}
										value={entity?.name}
										onChange={(e) => {
											setEntity({
												...entity,
												name: e.target.value,
											});
										}}
									/>
									<TextField
										className={classes.textField}
										InputProps={{
											classes: {
												input: classes.smallSize,
											},
										}}
										InputLabelProps={{
											shrink: true,
										}}
										margin="dense"
										size="small"
										label={t(TransText.customerDetail.address, null, lang, [])}
										value={entity?.adress}
										onChange={(e) => {
											setEntity({
												...entity,
												adress: e.target.value,
											});
										}}
									/>
									<TextField
										className={classes.textField}
										style={{ width: '15ch' }}
										InputProps={{
											classes: {
												input: classes.smallSize,
											},
										}}
										InputLabelProps={{
											shrink: true,
										}}
										margin="dense"
										size="small"
										label={t(TransText.customerDetail.zipcode, null, lang, [])}
										value={entity?.zipCode}
										onChange={(e) => {
											setEntity({
												...entity,
												zipCode: e.target.value,
											});
										}}
									/>
									<TextField
										className={classes.textField}
										InputProps={{
											classes: {
												input: classes.smallSize,
											},
										}}
										InputLabelProps={{
											shrink: true,
										}}
										margin="dense"
										size="small"
										label={t(TransText.customerDetail.city, null, lang, [])}
										value={entity?.city}
										onChange={(e) => {
											setEntity({
												...entity,
												city: e.target.value,
											});
										}}
									/>
									<TextField
										className={classes.textField}
										InputProps={{
											classes: {
												input: classes.smallSize,
											},
										}}
										InputLabelProps={{
											shrink: true,
										}}
										margin="dense"
										size="small"
										label={t(TransText.customerDetail.country, null, lang, [])}
										value={entity?.country}
										onChange={(e) => {
											setEntity({
												...entity,
												country: e.target.value,
											});
										}}
									/>
								</Box>
								{/* Contact */}
								<Box
									display="flex"
									justifyContent="flex-start"
									flexWrap="wrap"
									m={2}
								>
									<TextField
										className={classes.textField}
										InputProps={{
											classes: {
												input: classes.smallSize,
											},
										}}
										InputLabelProps={{
											shrink: true,
										}}
										margin="dense"
										size="small"
										label={t(
											TransText.customerDetail.contactName,
											null,
											lang,
											[]
										)}
										value={entity?.contactName}
										onChange={(e) => {
											setEntity({
												...entity,
												contactName: e.target.value,
											});
										}}
									/>
									<TextField
										className={classes.textField}
										InputProps={{
											classes: {
												input: classes.smallSize,
											},
										}}
										InputLabelProps={{
											shrink: true,
										}}
										margin="dense"
										size="small"
										label={t(TransText.app.email, null, lang, [])}
										value={entity?.contactEmail}
										onChange={(e) => {
											setEntity({
												...entity,
												contactEmail: e.target.value,
											});
										}}
									/>
									<TextField
										className={classes.textField}
										style={{ width: '15ch' }}
										InputProps={{
											classes: {
												input: classes.smallSize,
											},
										}}
										InputLabelProps={{
											shrink: true,
										}}
										margin="dense"
										size="small"
										label={t(TransText.app.phone, null, lang, [])}
										value={entity?.contactPhone}
										onChange={(e) => {
											setEntity({
												...entity,
												contactPhone: e.target.value,
											});
										}}
									/>
								</Box>
								{/* Command */}
								<Box
									display="flex"
									flexDirection="row"
									justifyContent="space-between"
									m={2}
								>
									<Button
										variant="contained"
										size="small"
										disabled={!dirty}
										onClick={onCancel}
									>
										{Translation.t(TransText.app.cancel, null, lang, [])}
									</Button>

									<div className={classes.wrapperRel}>
										<Button
											variant="contained"
											size="small"
											color="primary"
											disabled={!dirty || progress}
											type="submit"
											onClick={onSave}
										>
											{Translation.t(TransText.app.save, null, lang, [])}
										</Button>
										{progress && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</div>
								</Box>
							</Box>
						</Paper>
					</Box>

					{/* Point controls */}
					<Box display="Flex" flexDirection="row" m={4}>
						<Fab
							size="small"
							color="primary"
							aria-label="add"
							onClick={() => {}}
						>
							<FontAwesomeIcon icon={faPlus} />
						</Fab>
						<Box ml={2}>
							<Fab
								size="small"
								color="primary"
								aria-label="upload"
								onClick={() => {}}
							>
								<FontAwesomeIcon icon={faUpload} />
							</Fab>
						</Box>
						<Box ml={2}>
							<Fab
								size="small"
								color="primary"
								aria-label="download"
								onClick={() => {}}
							>
								<FontAwesomeIcon icon={faDownload} />
							</Fab>
						</Box>
					</Box>
					{/* Grid */}
					<Box
						m={4}
						style={{
							height: `calc(100% - (270px))`,
							//minHeight: '300px',
							overflow: 'auto',
						}}
					>
						<DataGridPro
							rows={entity.accounts}
							columns={columns}
							getRowId={(row) => row.userId}
							density="compact"
							disableMultipleSelection={true}
						/>
					</Box>
				</Paper>
			</div>
		</React.Fragment>
	);
};
