import React, { useEffect } from 'react';
import {
	Box,
	TextField,
	makeStyles,
	Divider,
	Button,
	CircularProgress,
} from '@material-ui/core';
import TransText from '../../../resource/transText';
import Translation from '../../../services/translation';
import { IAppState } from '../../../redux/store';
import { SerieFuncParameterItem } from './serieFuncParameterItem';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { SerieSetting } from '../../../model/dashModel';
import { SerieSettingDialog } from './serieSettingDialog';
import { useSnackbar } from 'notistack';
import {
	enPointState,
	enPointValueType,
	PointDTO,
	PointHistoryDTO,
	PointHistoryResponseDTO,
} from '../../../model/pointModel';
import { PointAPI } from '../../../services/pointAPI';
import { INameValueHash } from './types';

interface IProps {
	serie: SerieSetting;
	onUpdate: (serie: SerieSetting) => any;
	onCancel: () => any;
}

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
			width: '25ch',
		},
	},
	appBarDialog: {
		position: 'relative',
	},
	titleDialog: {
		marginLeft: theme.spacing(2),
		flexGrow: 1,
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		//width: '25ch',
	},
	smallSize: {
		fontSize: 13,
		fontWeight: 400,
	},
	paper: {
		width: '100%',
		height: '100%',

		backgroundColor: 'white',
	},
	margin: {
		margin: theme.spacing(3),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 90,
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative',
	},
	progress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}));

export const SerieFunctionTest: React.FC<IProps> = (props) => {
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const { lang, loggedOnUser } = useSelector((state: IAppState) => state.app);
	const { t } = Translation;

	const [editPointParam, setEditPointParam] = React.useState<SerieSetting>();
	const serieRefArray = React.useRef(new Map<number, any>());

	const [reloadSpinner, setReloadSpinner] = React.useState<boolean>(false);

	const [scriptOutput, SetScriptOutput] = React.useState<any>('');

	useEffect(() => {
		let isMounted = true;

		let pointDTOS: PointDTO[] = [];

		if (props.serie.valueCalcParams !== undefined)
			pointDTOS = [
				...props.serie.valueCalcParams.map((x: SerieSetting) => x.pointDTO!),
			];

		pointDTOS.push(props.serie.pointDTO!);

		getPointsLastValue(pointDTOS);

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const AddPointParam = () => {
		let date = new Date();
		let newSerie: SerieSetting = {
			id: date.getTime(),
			name: '',
			pointDTO: undefined,
			valueCalcExpresion: '',
			valueCalcParams: [],
			valuePathExpression: '',
			graphicalType: '',

			color: '',

			colorLevels: [],

			top: 0,
			left: 0,
			width: 0,
			height: 0,

			value: '',

			controlRef: 0,
		};

		setEditPointParam(newSerie);
	};

	const getPointsLastValue = (paramPoints: PointDTO[]) => {
		setReloadSpinner(true);
		let pointAPI: PointAPI = new PointAPI();
		pointAPI
			.GetPointsHistory({
				customerId: loggedOnUser?.tenant == null ? '' : loggedOnUser.tenant,
				pointids: [...paramPoints.map((x: PointDTO) => x.pointID)],
				fromDateTime: '',
				toDateTime: '',
				groupByIntervalUnit: '',
				groupByInterval: 0,
				maxRowsToReturn: 0,
				onlyReturnCount: false,
				latestValue: true,
			})
			.then((resp) => {
				if (resp.status >= 200 && resp.status < 300) {
					let pointHistoryResp: PointHistoryResponseDTO;
					pointHistoryResp = resp.data;

					if (
						pointHistoryResp.pointHistory &&
						pointHistoryResp.pointHistory.length > 0
					) {
						let pointDTO = pointHistoryResp.pointHistory.find(
							(x: PointHistoryDTO) => {
								return props.serie.pointDTO?.pointID === x.pointId;
							}
						);

						if (pointDTO === undefined) {
							alert(
								'PointDTO for id:' +
									props.serie.pointDTO?.pointID +
									' not found'
							);
							return;
						}

						let pointPVS = pointDTO.points[0];

						let value: number | null = null;

						let s = { ...props.serie };

						{
							/* Update pointValue in serie */
						}
						if (
							pointPVS !== undefined &&
							pointPVS.state !== enPointState.NULL &&
							pointPVS.state === enPointState.OK
						) {
							value = getPointValue(
								pointPVS.value,
								props.serie.pointDTO!.valueType,
								props.serie.valuePathExpression
							);
							s.value = value;
						} else s.value = 'NOT_OK';

						{
							/* Update funcParam value in serie */
						}
						if (s.valueCalcParams !== undefined) {
							s.valueCalcParams.map((paramPoint: SerieSetting) => {
								let pointDTO = pointHistoryResp.pointHistory.find(
									(x: PointHistoryDTO) => {
										return paramPoint.pointDTO?.pointID === x.pointId;
									}
								);
								if (pointDTO !== undefined) {
									if (pointDTO.points[0].state === enPointState.OK)
										paramPoint.value = pointDTO.points[0].value;
									else paramPoint.value = 'NOT_OK';
								}
							});
						}
						props.onUpdate(s);
					}
				}
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				setReloadSpinner(false);
			});
	};

	const getPointValue = (
		value: any,
		valueType: enPointValueType,
		valuePathExpression: string
	): any => {
		if (valueType === enPointValueType.json_type) {
			let obj = JSON.parse(value);

			if (valuePathExpression !== undefined) {
				var theInstructions = valuePathExpression;
				//"return obj[0].filter((x) => {if (x.name === 'KM1_OEE_CurrShift') return true;})[0].value;";

				// eslint-disable-next-line
				var F = new Function('obj', theInstructions);
				let res = F(obj);
				value = parseFloat(res.replace(',', '.'));
			}
		}

		return value;
	};

	const TestScript = () => {
		let paramStr = 'value,params';
		let pointHash: INameValueHash = {};

		// get parameter values
		if (props.serie.valueCalcParams !== undefined) {
			for (let c = 0; c < props.serie.valueCalcParams.length; c++) {
				pointHash[props.serie.valueCalcParams[c].name] = parseFloat(
					props.serie.valueCalcParams[c].value.replace(',', '.')
				);
			}
		}

		// get point Value
		let value = null;

		if (
			props.serie.valueCalcExpresion !== undefined &&
			props.serie.valueCalcExpresion.length > 0
		) {
			try {
				let F = new Function(paramStr, props.serie.valueCalcExpresion);
				value = F!(parseFloat(props.serie.value.replace(',', '.')), pointHash);
			} catch (e) {
				enqueueSnackbar(e.message, {
					variant: 'error',
				});
			}
		}

		SetScriptOutput(value);
	};

	return (
		<React.Fragment>
			<Box display="flex" flexDirection="column">
				{/* Func point parameters */}
				<Box
					display="flex"
					justifyContent="flex-start"
					alignItems="center"
					flexWrap="wrap"
					m={2}
				>
					{t(TransText.serieFunc.addParam, null, lang, [])}
					<Box
						m={6}
						onTouchStart={(e) => {
							e.stopPropagation();
							AddPointParam();
						}}
						onClick={(e) => {
							e.stopPropagation();
							AddPointParam();
						}}
					>
						<FontAwesomeIcon icon={faPlus} />
					</Box>
				</Box>
				<Box
					style={{ backgroundColor: 'blue' }}
					p={2}
					maxHeight={'300px'}
					overflow={'auto'}
				>
					{props.serie.valueCalcParams !== undefined
						? props.serie.valueCalcParams.map(
								(serie: SerieSetting, index: number) => {
									return (
										<Box
											style={
												editPointParam !== undefined
													? { backgroundColor: 'yellow' }
													: { backgroundColor: 'white' }
											}
										>
											<SerieFuncParameterItem
												ref={(ref) => {
													serieRefArray.current.set(index, ref);
												}}
												key={serie.id}
												serie={serie}
												onEdit={(serie: SerieSetting) => {
													setEditPointParam(serie);
												}}
											/>
											<Divider />
										</Box>
									);
								}
						  )
						: ''}
				</Box>

				{/* FuncExpresion */}
				<Box mt={2}>
					<TextField
						fullWidth
						multiline
						rows={8}
						className={classes.textField}
						InputProps={{
							classes: {
								//
							},
						}}
						InputLabelProps={{
							shrink: true,
						}}
						label={t(TransText.serieFunc.functionExpression, null, lang, [])}
						value={
							props.serie.valueCalcExpresion !== undefined
								? props.serie.valueCalcExpresion
								: ''
						}
						onChange={(e) => {
							let s = { ...props.serie };
							s.valueCalcExpresion = e.target.value;

							props.onUpdate(s);
						}}
					/>
				</Box>

				{/* Refresh */}
				<Box mt={2} display="flex" flexDirection="row" justifyContent="end">
					<div className={classes.wrapper}>
						<Button
							color="primary"
							disabled={reloadSpinner}
							size="small"
							variant="contained"
							onClick={() => {
								let pointDTOs: PointDTO[] = [];
								if (props.serie.valueCalcParams !== undefined)
									pointDTOs = [
										...props.serie.valueCalcParams.map(
											(x: SerieSetting) => x.pointDTO!
										),
									];
								pointDTOs.push(props.serie.pointDTO!);

								getPointsLastValue(pointDTOs);
							}}
						>
							{t(TransText.serieFunc.refreshParameterValues, null, lang, [])}
						</Button>
						{reloadSpinner && (
							<CircularProgress size={24} className={classes.progress} />
						)}
					</div>
				</Box>

				{/* PointValue */}
				<Box>
					<Box
						mt={2}
						mb={4}
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
					>
						<Box>{props.serie.name}</Box>
						<Box>
							<TextField
								className={classes.textField}
								InputProps={{
									classes: {
										input: classes.smallSize,
									},
								}}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								margin="dense"
								size="small"
								value={props.serie.value}
								onChange={(e) => {
									let s = { ...props.serie };
									s.value = e.target.value;
									props.onUpdate(s);
								}}
							/>
						</Box>
					</Box>
				</Box>

				{/* PVS list of point and all params */}
				<Box mt={2} mb={2}>
					{props.serie.valueCalcParams !== undefined
						? props.serie.valueCalcParams.map((funcParam: SerieSetting) => {
								return (
									<Box
										mt={2}
										display="flex"
										flexDirection="row"
										justifyContent="space-between"
									>
										<Box>{funcParam.name}</Box>
										<Box>
											<TextField
												className={classes.textField}
												InputProps={{
													classes: {
														input: classes.smallSize,
													},
												}}
												variant="outlined"
												InputLabelProps={{
													shrink: true,
												}}
												margin="dense"
												size="small"
												value={funcParam.value}
												onChange={(e) => {
													let s = { ...props.serie };
													let fp = s.valueCalcParams.find((x) => {
														return x.id === funcParam.id;
													});
													if (fp !== undefined) {
														fp.value = e.target.value;
														props.onUpdate(s);
													}
												}}
											/>
										</Box>
									</Box>
								);
						  })
						: ''}
				</Box>

				{/* OutputValue */}
				<Box>
					<Box
						mt={2}
						mb={4}
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
					>
						<Box>{'Script OutPut'}</Box>
						<Box>
							<TextField
								className={classes.textField}
								InputProps={{
									readOnly: true,
									classes: {
										input: classes.smallSize,
									},
								}}
								variant="outlined"
								InputLabelProps={{
									shrink: true,
								}}
								margin="dense"
								size="small"
								value={scriptOutput}
							/>
						</Box>
					</Box>
				</Box>

				{/* Buttons */}
				<Divider />
				<Box
					mt={4}
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
				>
					<Button
						size="small"
						variant="contained"
						onClick={() => props.onCancel()}
					>
						{t(TransText.app.cancel, null, lang, [])}
					</Button>

					<Button
						color="primary"
						size="small"
						variant="contained"
						onClick={() => {
							TestScript();
						}}
					>
						{t(TransText.serieFunc.testScript, null, lang, [])}
					</Button>
				</Box>
			</Box>

			{/* Manage func point param  */}
			{editPointParam ? (
				<SerieSettingDialog
					open={true}
					title={t(TransText.serieFunc.SelectParameter, null, lang, [])}
					serie={editPointParam}
					onOk={function (serie: SerieSetting) {
						// Check if update
						let s = { ...props.serie };
						let index = s.valueCalcParams.findIndex((x) => {
							return x.id === serie.id;
						});

						if (index < 0) {
							// New Func param
							// Filter duplicates
							let found = props.serie.valueCalcParams.find((x) => {
								return x.name === serie.name;
							});
							if (found === undefined) {
								let s = { ...props.serie };
								s.valueCalcParams.push(serie);
							} else {
								enqueueSnackbar(
									t(TransText.serieFunc.duplicatedParamName, null, lang, [
										serie.name,
									]),
									{
										variant: 'error',
									}
								);
								return;
							}
						} else {
							// Update
							s.valueCalcParams[index] = serie;
						}

						setEditPointParam(undefined);
						props.onUpdate(s);
					}}
					onRemove={(serie: SerieSetting) => {
						let s = { ...props.serie };
						let index = s.valueCalcParams.findIndex((x) => {
							return x.id === serie.id;
						});
						if (index > -1) {
							s.valueCalcParams.splice(index, 1);
							props.onUpdate(s);
							setEditPointParam(undefined);
						}
					}}
					onCancel={() => {
						setEditPointParam(undefined);
					}}
					showGraphicalType={false}
					showColorLevels={false}
					showColor={false}
					showFunction={false}
				/>
			) : (
				''
			)}
		</React.Fragment>
	);
};
