import React from 'react';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import {
	Box,
	createStyles,
	DialogTitle,
	makeStyles,
	TextField,
	Theme,
	Typography,
} from '@material-ui/core';
import Translation from '../services/translation';
import TransText from '../resource/transText';
import { useSelector } from 'react-redux';
import { IAppState } from '../redux/store';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexWrap: 'wrap',
		},
		textField: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			width: '25ch',
		},
		smallSize: {
			fontSize: 13,
			fontWeight: 400,
		},
		wrapperRel: {
			position: 'relative',
		},
		buttonProgress: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
		},
	})
);

interface IProps {
	onCancel: () => any;
	onConfirm: (contentText: string) => any;

	open: boolean;
	titleText: string;
	contentText: string;
	cancelButtonText: string;
	confirmButtonText: string;
}

export const GetStringDialog: React.FC<IProps> = (props) => {
	const { t } = Translation;
	const { lang } = useSelector((state: IAppState) => state.app);
	const classes = useStyles();

	const [contentText, setContentText] = React.useState<string>(
		props.contentText
	);

	return (
		<Dialog open={props.open}>
			<DialogTitle>
				<Box m={1}>
					<Typography variant="body1">{props.titleText}</Typography>
				</Box>
			</DialogTitle>
			<DialogContent dividers>
				<TextField
					className={classes.textField}
					InputProps={{
						classes: {
							input: classes.smallSize,
						},
					}}
					InputLabelProps={{
						shrink: true,
					}}
					margin="dense"
					size="small"
					label={t(TransText.customerDetail.name, null, lang, [])}
					value={contentText}
					onChange={(e) => {
						setContentText(e.target.value);
					}}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					size="small"
					variant="contained"
					onClick={() => props.onConfirm(contentText)}
				>
					{props.confirmButtonText}
				</Button>
				<Button
					size="small"
					variant="contained"
					onClick={() => props.onCancel()}
				>
					{props.cancelButtonText}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
