import {
	AppBar,
	CircularProgress,
	Box,
	Button,
	Checkbox,
	CssBaseline,
	Dialog,
	DialogContent,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	InputLabel,
	makeStyles,
	MenuItem,
	Paper,
	Select,
	TextField,
	Toolbar,
	Typography,
} from '@material-ui/core';
import Highcharts, { Series } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import boost from 'highcharts/modules/boost';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../redux/store';
import TransText from '../../../../resource/transText';
import Translation from '../../../../services/translation';
import { OrangeButton } from '../../../../styles/buttons';
import {
	enPointState,
	enTimeUnitType,
	PointDTO,
	PointHistoryResponseDTO,
	WidgetInfo,
} from '../../../../model/pointModel';
import { SerieSetting } from '../../../../model/dashModel';
import { PointAPI } from '../../../../services/pointAPI';
import moment from 'moment';
import { PointSeries } from '../../common/pointSeries';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
			width: '25ch',
		},
	},
	appBarDialog: {
		position: 'relative',
	},
	titleDialog: {
		marginLeft: theme.spacing(2),
		flexGrow: 1,
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '25ch',
	},
	smallSize: {
		fontSize: 13,
		fontWeight: 400,
	},
	paper: {
		width: '100%',
		height: '100%',

		backgroundColor: 'white',
	},
	margin: {
		margin: theme.spacing(3),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 90,
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative',
	},
	progress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}));

interface IProps {
	selectedWidgetInfo: WidgetInfo;
	open: boolean;
	remove: (id: string) => any;
	save: (widgetInfo: WidgetInfo) => any;
	cancel: () => any;
}

export const WidgetEditTextVar2: React.FC<IProps> = ({
	selectedWidgetInfo,
	open,
	save,
	remove,
	cancel,
}) => {
	const classes = useStyles();
	const { t } = Translation;
	const { lang, loggedOnUser } = useSelector((state: IAppState) => state.app);

	const [widgetInfo, setWidgetInfo] =
		React.useState<WidgetInfo>(selectedWidgetInfo);

	const [serieSettings, setSerieSettings] = React.useState<SerieSetting[]>([]);

	const [reloadSpinner, setReloadSpinner] = React.useState<boolean>();

	const { enqueueSnackbar } = useSnackbar();

	boost(Highcharts);

	useEffect(() => {
		let serieCount = 0;
		let sSettings: SerieSetting[] = [];

		widgetInfo.widgetTypeConfig.series.map((serie: any) => {
			// Default values if missing in typeConfig
			let color: string = '#000000';
			let point: PointDTO | undefined = undefined;

			// Build the serieSetting for each serie
			if (
				widgetInfo.points !== undefined &&
				widgetInfo.points.length >= serieCount
			)
				point = widgetInfo.points[serieCount];

			sSettings.push({
				id: serieCount,
				name: serie.name,
				color: color,
				pointDTO: point,
				value: serie.data,
				valueCalcExpresion: serie.valueCalcExpresion,
				valueCalcParams: serie.valueCalcParams,
				valuePathExpression: '',
				controlRef: 0,
				graphicalType: '',
				colorLevels: serie.colorLevels,
				top: 0,
				left: 0,
				width: 0,
				height: 0,
			});

			serieCount++;
		});

		setSerieSettings(sSettings);
		return () => {};
	}, []);

	const AddSerie = (serie: SerieSetting): boolean => {
		if (CheckForDuplicatedSeries(serie)) {
			let wi = { ...widgetInfo };

			// Update, point, color and serie
			wi.points.push(serie.pointDTO!);
			wi.widgetTypeConfig.series.push({
				name: serie.name,
				colorLevels: serie.colorLevels,
				valueCalcExpresion: serie.valueCalcExpresion,
				valueCalcParams: serie.valueCalcParams,
			});
			setWidgetInfo(wi);

			// sync serieIds with the index of series in typeConfig
			serie.id = wi.widgetTypeConfig.series.length - 1;
			let s = [...serieSettings, serie];
			setSerieSettings(s);

			return true;
		}
		return false;
	};

	const UpdateSerie = (serie: SerieSetting): boolean => {
		// Update widget config
		let wi = { ...widgetInfo };

		wi.points[serie.id] = serie.pointDTO!;

		let confSerie = wi.widgetTypeConfig.series[serie.id];

		if (confSerie !== undefined) {
			confSerie.name = serie.name;
			confSerie.colorLevels = serie.colorLevels;
			confSerie.valueCalcExpresion = serie.valueCalcExpresion;
			confSerie.valueCalcParams = serie.valueCalcParams;
		}

		setWidgetInfo(wi);

		// Update seriessettings
		let s = [...serieSettings];
		let index = s.findIndex((x) => {
			return x.id === serie.id;
		});
		s[index] = serie;
		setSerieSettings(s);

		return true;
	};

	const RemoveSerie = (serie: SerieSetting): boolean => {
		// Update
		let wi = { ...widgetInfo };

		wi.points.splice(serie.id, 1);
		wi.widgetTypeConfig.series.splice(serie.id, 1);
		//wi.widgetTypeConfig.colors.splice(serie.id, 1);
		setWidgetInfo(wi);

		let s = [...serieSettings];

		let index = s.findIndex((x) => {
			return x.id === serie.id;
		});

		s.splice(index, 1);

		setSerieSettings(s);

		return true;
	};

	const CheckForDuplicatedSeries = (serie: SerieSetting): boolean => {
		// Check for duplicates
		let nameDup = widgetInfo.widgetTypeConfig.series.find((x: any) => {
			return x.name === serie.name;
		});

		let pointDup = widgetInfo.points.find((x) => {
			return x.pointID == serie.pointDTO?.pointID;
		});

		if (nameDup !== undefined || pointDup !== undefined) {
			enqueueSnackbar(t(TransText.widgetEdit.serieDuplicated, null, lang, []), {
				variant: 'error',
			});
			return false;
		} else return true;
	};

	const onSaveWidget = (widgetInfo: WidgetInfo) => {
		widgetInfo.widgetTypeConfig.series.map((serie: any) => {
			serie.data = [];
			return serie;
		});

		save(widgetInfo);
	};

	const ReloadSeries = () => {
		GetPointsHistory(widgetInfo.points);
	};

	const GetPointsHistory = (pointDTOs: PointDTO[]) => {
		if (!pointDTOs) return;

		setReloadSpinner(true);

		let pointAPI: PointAPI = new PointAPI();
		pointAPI
			.GetPointsHistory({
				customerId: loggedOnUser?.tenant == null ? '' : loggedOnUser.tenant,
				pointids: [...pointDTOs.map((x) => x.pointID)],
				fromDateTime: '',
				toDateTime: '',

				groupByIntervalUnit: '',
				groupByInterval: 0,
				maxRowsToReturn: 0,
				onlyReturnCount: false,
				latestValue: true,
			})
			.then((resp) => {
				if (resp.status >= 200 && resp.status < 300) {
					let pointHistoryResp: PointHistoryResponseDTO;
					pointHistoryResp = resp.data;

					if (
						pointHistoryResp.pointHistory &&
						pointHistoryResp.pointHistory.length > 0
					) {
						let wi = { ...widgetInfo };

						for (
							let cPointId = 0;
							cPointId < pointHistoryResp.pointHistory.length;
							cPointId++
						) {
							let pointIndex = wi.points.findIndex((x) => {
								return (
									x.pointID === pointHistoryResp.pointHistory[cPointId].pointId
								);
							});

							let serie = wi.widgetTypeConfig.series[pointIndex];
							if (serie === undefined) continue;

							serie.data = [];

							// Each pointID
							for (
								let cPoint = 0;
								cPoint < pointHistoryResp.pointHistory[cPointId].points.length;
								cPoint++
							) {
								// parse all PVS for point
								let samp =
									pointHistoryResp.pointHistory[cPointId].points[cPoint];

								let utc = moment(samp.time);
								let t = utc.add(utc.utcOffset(), 'minutes').toDate();

								let value: number | null = null;
								if (
									samp.state !== enPointState.NULL &&
									samp.state === enPointState.OK
								)
									value = parseFloat(samp.value);

								serie.value = value;
							}
						}

						setWidgetInfo(wi);
					}
				}
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				setReloadSpinner(false);
			});
	};

	const MergeTextAndVar = () => {
		if (widgetInfo.widgetTypeConfig.text === undefined) return 'Nan';

		// Splice out all sub parts of the string
		let subParts = [];
		let tempStr: string = widgetInfo.widgetTypeConfig.text;
		let pos = 0;
		while (true) {
			let paramPos = tempStr.indexOf('{', pos);
			if (paramPos > -1) {
				subParts.push({
					type: 'TEXT',
					data: tempStr.substring(pos, paramPos),
				});

				pos = paramPos;
				paramPos = tempStr.indexOf('}', pos);
				if (paramPos > -1) {
					subParts.push({
						type: 'VAR',
						data: tempStr
							.substring(pos, paramPos + 1)
							.replace('{', '')
							.replace('}', ''),
					});
					pos = paramPos + 1;
				} else {
					// Unclosed parameter
					break;
				}
			} else {
				// Cut of the last
				if (pos >= tempStr.length) {
					break;
				} else {
					subParts.push({
						type: 'TEXT',
						data: tempStr.substring(pos),
					});
					break;
				}
			}
		}

		subParts.map((part: any) => {
			if (part.type === 'VAR') {
				// Find point
				let serie = widgetInfo.widgetTypeConfig.series.find(
					(x: SerieSetting) => {
						return x.name === part.data;
					}
				);

				if (serie === undefined) part.data = 'undefined';
				else {
					part.serie = serie;
					part.data = serie.value !== null ? serie.value : 'No data';
				}
			}
		});

		// Loop all subparts of the string and style all variabels accordingly
		return (
			<React.Fragment>
				<span
					style={{
						fontSize: widgetInfo.widgetTypeConfig.fontSize + 'px',
						fontWeight: widgetInfo.widgetTypeConfig.fontWeight,
					}}
				>
					{subParts.map((x: any) => {
						if (x.type === 'VAR') {
							return (
								<span
									style={{
										color:
											x.serie !== undefined
												? getLevelColor(x.serie, x.data)
												: '#000000',
									}}
								>
									{x.data}
								</span>
							);
						} else return x.data;
					})}
				</span>
			</React.Fragment>
		);
	};

	const getLevelColor = (serie: any, value: number): string => {
		let textColor: string = '#000000';

		for (let c = 0; c < serie.colorLevels.length; c++) {
			if (value >= serie.colorLevels[c].level)
				textColor = serie.colorLevels[c].color;
		}

		return textColor;
	};

	return (
		<React.Fragment>
			<Dialog fullScreen open={open}>
				<AppBar className={classes.appBarDialog}>
					<Toolbar>
						<Typography variant="h6" className={classes.titleDialog}>
							{widgetInfo !== undefined ? widgetInfo.title : ''}
						</Typography>

						<Button
							autoFocus
							color="inherit"
							onClick={() => {
								remove(widgetInfo === undefined ? '' : widgetInfo.i);
							}}
						>
							Delete
						</Button>

						<Button
							autoFocus
							color="inherit"
							onClick={() => {
								onSaveWidget(widgetInfo);
							}}
						>
							Save
						</Button>
						<OrangeButton
							autoFocus
							color="inherit"
							onClick={() => {
								cancel();
							}}
						>
							{t(TransText.app.cancel, null, lang, [])}
						</OrangeButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<CssBaseline />
					<Grid container spacing={2}>
						{/* Widget */}
						<Grid item md={8}>
							<Paper elevation={4}>
								<Box display="flex" flexDirection="column" m={4} p={4}>
									<Box display="flex" justifyContent="space-between">
										<Box>{''}</Box>
										<Box>
											<Typography variant="h6">
												<Box fontSize="16px">
													{widgetInfo !== undefined ? widgetInfo.title : ''}
												</Box>
											</Typography>
										</Box>
										<Box>{''}</Box>
									</Box>

									<div key={1} id="textvar_2">
										<Box display="flex" justifyContent="center" m={2}>
											<Typography variant="h5">{MergeTextAndVar()}</Typography>
										</Box>
									</div>
								</Box>
							</Paper>
						</Grid>

						{/* Widget settings */}
						<Grid item md={4}>
							<Paper elevation={4}>
								<Box m={2}>
									{/* Title */}
									<Box
										display="flex"
										justifyContent="flex-start"
										flexWrap="wrap"
										m={2}
										mb={2}
									>
										<TextField
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											margin="dense"
											size="small"
											label={t(
												TransText.widgetEdit.widgetTitle,
												null,
												lang,
												[]
											)}
											value={widgetInfo !== undefined ? widgetInfo.title : ''}
											onChange={(e) => {
												setWidgetInfo({
													...widgetInfo,
													title: e.target.value,
												});
											}}
										/>
									</Box>

									<Divider></Divider>

									{/* Font */}
									<Box
										display="flex"
										justifyContent="flex-start"
										flexWrap="wrap"
										m={2}
									>
										<Box mr={2}>
											<TextField
												InputLabelProps={{ shrink: true }}
												type="number"
												margin="dense"
												size="small"
												label={
													t(TransText.app.fontSize, null, lang, []) + '8-36'
												}
												value={
													widgetInfo.widgetTypeConfig.fontSize === undefined
														? 8
														: widgetInfo.widgetTypeConfig.fontSize
												}
												onChange={(e) => {
													let wi = { ...widgetInfo };
													if (parseInt(e.target.value) > 36) {
														wi.widgetTypeConfig.fontSize = 36;
													} else if (parseInt(e.target.value) < 8) {
														wi.widgetTypeConfig.fontSize = 8;
													} else wi.widgetTypeConfig.fontSize = e.target.value;

													setWidgetInfo(wi);
												}}
											/>
										</Box>
										<TextField
											InputLabelProps={{ shrink: true }}
											type="number"
											margin="dense"
											size="small"
											label={
												t(TransText.app.fontWeight, null, lang, []) + '100-900'
											}
											value={
												widgetInfo.widgetTypeConfig.fontWeight === undefined
													? 200
													: widgetInfo.widgetTypeConfig.fontWeight
											}
											onChange={(e) => {
												let wi = { ...widgetInfo };
												if (parseInt(e.target.value) > 900)
													wi.widgetTypeConfig.fontWeight = 900;
												else if (parseInt(e.target.value) < 0)
													wi.widgetTypeConfig.fontWeight = 0;
												else wi.widgetTypeConfig.fontWeight = e.target.value;
												setWidgetInfo(wi);
											}}
										/>
									</Box>

									{/* Series */}
									<Box
										display="flex"
										justifyContent="flex-start"
										alignItems="center"
										flexWrap="wrap"
										m={2}
									>
										<PointSeries
											series={serieSettings}
											updateSerie={(serie: SerieSetting) => {
												return UpdateSerie(serie);
											}}
											addSerie={(serie: SerieSetting) => {
												return AddSerie(serie);
											}}
											removeSerie={(serie: SerieSetting) => {
												return RemoveSerie(serie);
											}}
											showColor={false}
											showColorLevels={true}
											showFunction={true}
											showGraphicalType={false}
											maxNrOfSeries={0}
										/>
									</Box>

									<Divider></Divider>

									{/* Static text */}
									<Box m={2} mb={2}>
										<TextField
											fullWidth
											multiline
											rows={4}
											InputProps={{
												classes: {},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											label={t(
												TransText.widgetTextVar.staticText,
												null,
												lang,
												[]
											)}
											value={
												widgetInfo !== undefined
													? widgetInfo.widgetTypeConfig.text
													: ''
											}
											onChange={(e) => {
												let wi = { ...widgetInfo };
												wi.widgetTypeConfig.text = e.target.value;

												setWidgetInfo(wi);
											}}
										/>
									</Box>

									{/* Time range Refresh */}
									<Box display="flex" flexWrap="wrap" m={2}>
										<FormGroup row>
											<FormControlLabel
												control={
													<Checkbox
														color="primary"
														checked={
															widgetInfo !== undefined
																? widgetInfo.refresh?.subscription
																: false
														}
														onChange={(e) => {
															let wi = { ...widgetInfo };
															wi.refresh!.subscription = e.target.checked;

															setWidgetInfo(wi);
														}}
													/>
												}
												label={t(
													TransText.widgetEdit.timeRangeSubscription,
													null,
													lang,
													[]
												)}
											/>
										</FormGroup>
									</Box>
									<Box display="flex" flexWrap="wrap" m={2}>
										<TextField
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											type="number"
											margin="dense"
											size="small"
											disabled={widgetInfo.refresh?.subscription}
											label={t(
												TransText.widgetEdit.timeRangeRefresh,
												null,
												lang,
												[]
											)}
											value={
												widgetInfo !== undefined
													? widgetInfo.refresh?.refresh
													: 0
											}
											onChange={(e) => {
												let wi = { ...widgetInfo };
												wi.refresh!.refresh = Number.parseInt(e.target.value);

												setWidgetInfo(wi);
											}}
										/>
										<Box ml={2}>
											<FormControl className={classes.formControl}>
												<InputLabel id="demo-simple-select-label">
													{t(
														TransText.widgetEdit.timeRangeRefreshUnit,
														null,
														lang,
														[]
													)}
												</InputLabel>

												<Select
													disabled={widgetInfo.refresh?.subscription}
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={
														widgetInfo !== undefined
															? widgetInfo.refresh?.refreshUnit.toString()
															: ''
													}
													onChange={(e) => {
														let wi = { ...widgetInfo };
														wi.refresh!.refreshUnit = e.target
															.value as keyof typeof enTimeUnitType;

														setWidgetInfo(wi);
													}}
												>
													<MenuItem value={'Second'}>sec</MenuItem>
													<MenuItem value={'Minute'}>min</MenuItem>
													<MenuItem value={'Hour'}>hour</MenuItem>
													<MenuItem value={'Day'}>day</MenuItem>
													<MenuItem value={'Week'}>week</MenuItem>
													<MenuItem value={'Month'}>month</MenuItem>
												</Select>
											</FormControl>
										</Box>
									</Box>
									<Box m={2}>
										<div className={classes.wrapper}>
											<Button
												onClick={ReloadSeries}
												fullWidth
												variant="contained"
												color="primary"
												disabled={reloadSpinner}
											>
												{t(TransText.widgetEdit.loadAllSeries, null, lang, [])}
											</Button>
											{reloadSpinner && (
												<CircularProgress
													size={24}
													className={classes.progress}
												/>
											)}
										</div>
									</Box>

									<Divider></Divider>

									<Divider></Divider>
								</Box>
							</Paper>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
};
