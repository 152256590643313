import {
	AppBar,
	CircularProgress,
	Box,
	Button,
	Checkbox,
	CssBaseline,
	Dialog,
	DialogContent,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	InputLabel,
	makeStyles,
	MenuItem,
	Paper,
	Select,
	TextField,
	Toolbar,
	Typography,
} from '@material-ui/core';
import Highcharts, { Series } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import boost from 'highcharts/modules/boost';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../redux/store';
import TransText from '../../../../resource/transText';
import Translation from '../../../../services/translation';
import { OrangeButton } from '../../../../styles/buttons';
import {
	enPointState,
	enPointValueType,
	enTimeUnitType,
	PointDTO,
	PointHistoryDTO,
	PointHistoryResponseDTO,
	WidgetInfo,
} from '../../../../model/pointModel';
import { ColorLevel, SerieSetting } from '../../../../model/dashModel';
import { PointAPI } from '../../../../services/pointAPI';
import moment from 'moment';
import { PointSeries } from '../../common/pointSeries';
import { useSnackbar } from 'notistack';
import { CreateGuid } from '../../../../services/misc';
import GaugeChart from 'react-gauge-chart';
import { INameValueHash } from '../../common/types';

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
			width: '25ch',
		},
	},
	appBarDialog: {
		position: 'relative',
	},
	titleDialog: {
		marginLeft: theme.spacing(2),
		flexGrow: 1,
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '25ch',
	},
	smallSize: {
		fontSize: 13,
		fontWeight: 400,
	},
	paper: {
		width: '100%',
		height: '100%',

		backgroundColor: 'white',
	},
	margin: {
		margin: theme.spacing(3),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 90,
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative',
	},
	progress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}));

interface IProps {
	selectedWidgetInfo: WidgetInfo;
	open: boolean;
	remove: (id: string) => any;
	save: (widgetInfo: WidgetInfo) => any;
	cancel: () => any;
}

export const WidgetEditGauge2: React.FC<IProps> = ({
	selectedWidgetInfo,
	open,
	save,
	remove,
	cancel,
}) => {
	const classes = useStyles();
	const { t } = Translation;
	const { lang, loggedOnUser } = useSelector((state: IAppState) => state.app);

	const [widgetInfo, setWidgetInfo] =
		React.useState<WidgetInfo>(selectedWidgetInfo);

	const [serieSettings, setSerieSettings] = React.useState<SerieSetting[]>([]);

	const [reloadSpinner, setReloadSpinner] = React.useState<boolean>();

	const { enqueueSnackbar } = useSnackbar();

	boost(Highcharts);

	useEffect(() => {
		let serieCount = 0;
		let sSettings: SerieSetting[] = [];
		let colLevels: ColorLevel[] = [];

		if (
			widgetInfo.widgetTypeConfig.levelColors !== undefined &&
			widgetInfo.widgetTypeConfig.levelColors.length > 0
		) {
			for (let c = 0; c < widgetInfo.widgetTypeConfig.levelColors.length; c++) {
				colLevels.push({
					color: widgetInfo.widgetTypeConfig.levelColors[c],
					level: widgetInfo.widgetTypeConfig.levelStartValues[c],
				});
			}
		}

		if (widgetInfo.points !== undefined && widgetInfo.points.length > 0) {
			sSettings.push({
				id: serieCount,
				name: widgetInfo.widgetTypeConfig.name,
				color: widgetInfo.widgetTypeConfig.textColor,
				pointDTO: widgetInfo.points[0],
				value: '',
				valueCalcExpresion: widgetInfo.widgetTypeConfig.valueCalcExpresion,
				valueCalcParams: widgetInfo.widgetTypeConfig.valueCalcParamPoints,
				valuePathExpression: widgetInfo.widgetTypeConfig.valuePathExpression,
				controlRef: 0,
				graphicalType: '',
				colorLevels: colLevels,
				top: 0,
				left: 0,
				width: 0,
				height: 0,
			});
			serieCount++;
			setSerieSettings(sSettings);
		}

		return () => {};
	}, []);

	const AddSerie = (serie: SerieSetting): boolean => {
		let colLevels: ColorLevel[] = [];
		let wi = { ...widgetInfo };

		// Update, point, color and serie
		wi.widgetTypeConfig.name = serie.name;
		wi.points.push(serie.pointDTO!);

		wi.widgetTypeConfig.valueCalcExpresion = serie.valueCalcExpresion;
		wi.widgetTypeConfig.valueCalcParamPoints = serie.valueCalcParams;
		wi.widgetTypeConfig.valuePathExpression = serie.valuePathExpression;

		wi.widgetTypeConfig.levelColors = [];
		wi.widgetTypeConfig.levelStartValues = [];

		if (serie.colorLevels !== undefined && serie.colorLevels.length > 0) {
			for (let c = 0; c < serie.colorLevels.length; c++) {
				wi.widgetTypeConfig.levelColors.push(serie.colorLevels[c].color);
				wi.widgetTypeConfig.levelStartValues.push(serie.colorLevels[c].level);
			}
		}

		// sync serieIds with the index of series in typeConfig
		serie.id = wi.points.length - 1;
		let s = [...serieSettings, serie];
		setSerieSettings(s);

		return true;
	};

	const UpdateSerie = (serie: SerieSetting): boolean => {
		// Update widget config
		let wi = { ...widgetInfo };

		wi.widgetTypeConfig.name = serie.name;
		wi.points[serie.id] = serie.pointDTO!;

		wi.widgetTypeConfig.valueCalcExpresion = serie.valueCalcExpresion;
		wi.widgetTypeConfig.valueCalcParamPoints = serie.valueCalcParams;
		wi.widgetTypeConfig.valuePathExpression = serie.valuePathExpression;

		wi.widgetTypeConfig.levelColors = [];
		wi.widgetTypeConfig.levelStartValues = [];

		if (serie.colorLevels !== undefined && serie.colorLevels.length > 0) {
			for (let c = 0; c < serie.colorLevels.length; c++) {
				wi.widgetTypeConfig.levelColors.push(serie.colorLevels[c].color);
				wi.widgetTypeConfig.levelStartValues.push(serie.colorLevels[c].level);
			}
		}

		setWidgetInfo(wi);

		// Update seriessettings
		let s = [...serieSettings];
		let index = s.findIndex((x) => {
			return x.id === serie.id;
		});
		s[index] = serie;
		setSerieSettings(s);

		return true;
	};

	const RemoveSerie = (serie: SerieSetting): boolean => {
		// Update
		let wi = { ...widgetInfo };

		wi.points.splice(serie.id, 1);
		wi.widgetTypeConfig.colors.splice(serie.id, 1);
		setWidgetInfo(wi);

		let s = [...serieSettings];

		let index = s.findIndex((x) => {
			return x.id === serie.id;
		});

		s.splice(index, 1);

		setSerieSettings(s);

		return true;
	};

	const onSaveWidget = (widgetInfo: WidgetInfo) => {
		widgetInfo.widgetTypeConfig.value = '';

		save(widgetInfo);
	};

	const ReloadSeries = () => {
		if (widgetInfo.points.length > 0) {
			if (widgetInfo.widgetTypeConfig.valueCalcParamPoints != undefined) {
				let pointDTOs: PointDTO[] = [
					...widgetInfo.widgetTypeConfig.valueCalcParamPoints.map(
						(x: SerieSetting) => x.pointDTO
					),
					widgetInfo.points,
				].flat();
				getPointsLastestValue(pointDTOs);
			} else getPointsLastestValue(widgetInfo.points);
		}
	};

	const getPointsLastestValue = (pointDTOs: PointDTO[]) => {
		if (!pointDTOs) return;

		setReloadSpinner(true);

		let pointAPI: PointAPI = new PointAPI();
		pointAPI
			.GetPointsHistory({
				customerId: loggedOnUser?.tenant == null ? '' : loggedOnUser.tenant,
				pointids: [...pointDTOs.map((x) => x.pointID)],
				fromDateTime: '',
				toDateTime: '',

				groupByIntervalUnit: '',
				groupByInterval: 0,
				maxRowsToReturn: 10000,
				onlyReturnCount: false,
				latestValue: true,
			})
			.then((resp) => {
				if (resp.status >= 200 && resp.status < 300) {
					let pointHistoryResp: PointHistoryResponseDTO;
					pointHistoryResp = resp.data;

					if (
						pointHistoryResp.pointHistory &&
						pointHistoryResp.pointHistory.length > 0
					) {
						// Get the widgets pointValue
						let valuePvs = pointHistoryResp.pointHistory.filter(
							(x: PointHistoryDTO) => {
								return x.pointId === widgetInfo.points[0].pointID;
							}
						);

						let samp = valuePvs[0].points[0];
						let value: number | null = null;

						if (
							samp.state !== enPointState.NULL &&
							samp.state === enPointState.OK
						) {
							if (
								widgetInfo.points[0].valueType === enPointValueType.json_type
							) {
								let obj = JSON.parse(samp.value);

								if (
									widgetInfo.widgetTypeConfig.valuePathExpression !== undefined
								) {
									var theInstructions =
										widgetInfo.widgetTypeConfig.valuePathExpression ===
										undefined
											? ''
											: widgetInfo.widgetTypeConfig.valuePathExpression;

									//"return obj[0].filter((x) => {if (x.name === 'KM1_OEE_CurrShift') return true;})[0].value;";

									// eslint-disable-next-line
									var F = new Function('obj', theInstructions);
									let res = F(obj);
									value = parseFloat(res.replace(',', '.'));
								}
							}

							if (
								widgetInfo.widgetTypeConfig.valueCalcExpresion !== undefined &&
								widgetInfo.widgetTypeConfig.valueCalcExpresion !== ''
							) {
								widgetInfo.widgetTypeConfig.valueCalcParamPoints.map(
									(paramPoint: SerieSetting) => {
										let paramPVS = pointHistoryResp.pointHistory.find(
											(x: PointHistoryDTO) => {
												return x.pointId === paramPoint.pointDTO?.pointID;
											}
										);

										if (paramPVS !== undefined) {
											paramPoint.value = paramPVS.points[0].value;
										}
									}
								);

								let paramStr = 'value,params';
								let pointHash: INameValueHash = {};

								if (
									widgetInfo.widgetTypeConfig.valueCalcParamPoints !== undefined
								) {
									for (
										let c = 0;
										c < widgetInfo.widgetTypeConfig.valueCalcParamPoints.length;
										c++
									) {
										pointHash[
											widgetInfo.widgetTypeConfig.valueCalcParamPoints[c].name
										] =
											widgetInfo.widgetTypeConfig.valueCalcParamPoints[c].value;
									}
								}

								try {
									let F = new Function(
										paramStr,
										widgetInfo.widgetTypeConfig.valueCalcExpresion
									);
									value = F!(samp.value.replace(',', '.'), pointHash);
								} catch (e) {
									enqueueSnackbar(e.message, {
										variant: 'error',
									});
								}
							} else {
								value = parseFloat(samp.value.replace(',', '.'));
							}
						}

						let wi = { ...widgetInfo };
						wi.widgetTypeConfig.value =
							Math.round(value === null ? 0 : value * 10) / 10;

						setWidgetInfo(wi);
					}
				}
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				setReloadSpinner(false);
			});
	};

	const CalculatePrecent = (widgetTypeConfig: any, value: number): number => {
		if (
			widgetTypeConfig.rangeMax === undefined ||
			Number.isNaN(widgetTypeConfig.rangeMax) ||
			widgetTypeConfig.rangeMin === undefined ||
			Number.isNaN(widgetTypeConfig.rangeMin) ||
			widgetTypeConfig.levelStartValues === undefined ||
			widgetTypeConfig.levelStartValues.length === 0
		)
			return 0;

		let diff = widgetTypeConfig.rangeMax - widgetTypeConfig.rangeMin;
		let k = 100 / diff;
		let slope = 0 - widgetTypeConfig.rangeMin;

		if (value < widgetTypeConfig.rangeMin) value = widgetTypeConfig.rangeMin;
		if (value > widgetTypeConfig.rangeMax) value = widgetTypeConfig.rangeMax;

		let res = ((value + slope) * k) / 100;

		return res;
	};

	const CalculateArcLengts = (widgetTypeConfig: any): number[] => {
		if (
			widgetTypeConfig.rangeMax === undefined ||
			Number.isNaN(widgetTypeConfig.rangeMax) ||
			widgetTypeConfig.rangeMin === undefined ||
			Number.isNaN(widgetTypeConfig.rangeMin) ||
			widgetTypeConfig.levelStartValues === undefined ||
			widgetTypeConfig.levelStartValues.length === 0
		)
			return [0.25, 0.25, 0.25, 0.25];

		let diff = widgetTypeConfig.rangeMax - widgetTypeConfig.rangeMin;
		let k = 100 / diff;

		let diffs: number[] = [];
		for (let c = 0; c < widgetTypeConfig.levelStartValues.length; c++) {
			let diff = 0;
			if (c < widgetTypeConfig.levelStartValues.length - 1) {
				diff =
					widgetTypeConfig.levelStartValues[c + 1] -
					widgetTypeConfig.levelStartValues[c];
			} else {
				diff = widgetTypeConfig.rangeMax - widgetTypeConfig.levelStartValues[c];
			}
			diffs.push((diff * k) / 100);
		}

		return diffs;
	};
	return (
		<React.Fragment>
			<Dialog fullScreen open={open}>
				<AppBar className={classes.appBarDialog}>
					<Toolbar>
						<Typography variant="h6" className={classes.titleDialog}>
							{widgetInfo !== undefined ? widgetInfo.title : ''}
						</Typography>

						<Button
							autoFocus
							color="inherit"
							onClick={() => {
								remove(widgetInfo === undefined ? '' : widgetInfo.i);
							}}
						>
							Delete
						</Button>

						<Button
							autoFocus
							color="inherit"
							onClick={() => {
								onSaveWidget(widgetInfo);
							}}
						>
							Save
						</Button>
						<OrangeButton
							autoFocus
							color="inherit"
							onClick={() => {
								cancel();
							}}
						>
							{t(TransText.app.cancel, null, lang, [])}
						</OrangeButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<CssBaseline />
					<Grid container spacing={2}>
						{/* Widget */}
						<Grid item md={7}>
							<Paper elevation={4}>
								<Box
									display="flex"
									flexDirection="column"
									style={{ height: '100%', width: '100%' }}
									m={2}
								>
									<Box display="flex" justifyContent="space-between">
										<Box>{''}</Box>
										<Box>
											<Typography variant="h6">
												<Box fontSize="16px">
													{widgetInfo !== undefined ? widgetInfo.title : ''}
												</Box>
											</Typography>
										</Box>
										<Box>{''}</Box>
									</Box>
									<Box>
										<GaugeChart
											key={widgetInfo.widgetTypeConfig.i}
											id="gauge-chart2"
											percent={CalculatePrecent(
												widgetInfo.widgetTypeConfig,
												widgetInfo.widgetTypeConfig.value === undefined
													? 0
													: widgetInfo.widgetTypeConfig.value
											)}
											textColor={
												widgetInfo.widgetTypeConfig.textColor === undefined
													? '#000000'
													: widgetInfo.widgetTypeConfig.textColor
											}
											formatTextValue={(value) =>
												(widgetInfo.widgetTypeConfig.value === undefined
													? 0
													: widgetInfo.widgetTypeConfig.value) +
												(widgetInfo.widgetTypeConfig.unitText === undefined
													? ' Unit'
													: widgetInfo.widgetTypeConfig.unitText)
											}
											hideText={false}
											colors={
												widgetInfo.widgetTypeConfig.levelColors === undefined
													? []
													: widgetInfo.widgetTypeConfig.levelColors
											}
											nrOfLevels={
												widgetInfo.widgetTypeConfig.levelColors === undefined
													? 0
													: widgetInfo.widgetTypeConfig.levelColors.length
											}
											arcsLength={CalculateArcLengts(
												widgetInfo.widgetTypeConfig
											)}
										/>
									</Box>
								</Box>
							</Paper>
						</Grid>

						{/* Widget settings */}
						<Grid item md={5}>
							<Paper elevation={4}>
								<Box m={2}>
									{/* Title */}
									<Box
										display="flex"
										justifyContent="flex-start"
										flexWrap="wrap"
										m={2}
										mb={2}
									>
										<TextField
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											margin="dense"
											size="small"
											label={t(
												TransText.widgetEdit.widgetTitle,
												null,
												lang,
												[]
											)}
											value={widgetInfo !== undefined ? widgetInfo.title : ''}
											onChange={(e) => {
												setWidgetInfo({
													...widgetInfo,
													title: e.target.value,
												});
											}}
										/>
									</Box>
									<Divider></Divider>
									{/* UnitText */}
									<Box m={2} mb={2}>
										<TextField
											fullWidth
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											label={t(TransText.widgetEdit.widgetUnit, null, lang, [])}
											value={
												widgetInfo.widgetTypeConfig.unitText !== undefined
													? widgetInfo.widgetTypeConfig.unitText
													: ' unitText'
											}
											onChange={(e) => {
												let wi = { ...widgetInfo };
												wi.widgetTypeConfig.unitText = e.target.value;

												setWidgetInfo(wi);
											}}
										/>
									</Box>
									{/* Series */}
									<Box
										display="flex"
										justifyContent="flex-start"
										alignItems="center"
										flexWrap="wrap"
										m={2}
									>
										<PointSeries
											series={serieSettings}
											updateSerie={(serie: SerieSetting) => {
												return UpdateSerie(serie);
											}}
											addSerie={(serie: SerieSetting) => {
												return AddSerie(serie);
											}}
											removeSerie={(serie: SerieSetting) => {
												return RemoveSerie(serie);
											}}
											showColor={false}
											showColorLevels={true}
											showFunction={true}
											showGraphicalType={false}
											maxNrOfSeries={1}
										/>
									</Box>

									{/* Range */}
									<Box display="flex" flexWrap="wrap" m={2}>
										<TextField
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											type="number"
											margin="dense"
											size="small"
											disabled={widgetInfo.refresh?.subscription}
											label={t(TransText.widgetGauge2.rangeMin, null, lang, [])}
											value={
												widgetInfo.widgetTypeConfig.rangeMin !== undefined
													? widgetInfo.widgetTypeConfig.rangeMin
													: 0
											}
											onChange={(e) => {
												let wi = { ...widgetInfo };
												wi.widgetTypeConfig.rangeMin = Number.parseInt(
													e.target.value
												);

												setWidgetInfo(wi);
											}}
										/>
										<TextField
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											type="number"
											margin="dense"
											size="small"
											disabled={widgetInfo.refresh?.subscription}
											label={t(TransText.widgetGauge2.rangeMax, null, lang, [])}
											value={
												widgetInfo.widgetTypeConfig.rangeMax !== undefined
													? widgetInfo.widgetTypeConfig.rangeMax
													: 100
											}
											onChange={(e) => {
												let wi = { ...widgetInfo };
												wi.widgetTypeConfig.rangeMax = Number.parseInt(
													e.target.value
												);

												setWidgetInfo(wi);
											}}
										/>
									</Box>

									<Divider></Divider>

									{/* Time range Refresh */}
									<Box display="flex" flexWrap="wrap" m={2}>
										<FormGroup row>
											<FormControlLabel
												control={
													<Checkbox
														color="primary"
														checked={
															widgetInfo !== undefined
																? widgetInfo.refresh?.subscription
																: false
														}
														onChange={(e) => {
															let wi = { ...widgetInfo };
															wi.refresh!.subscription = e.target.checked;

															setWidgetInfo(wi);
														}}
													/>
												}
												label={t(
													TransText.widgetEdit.timeRangeSubscription,
													null,
													lang,
													[]
												)}
											/>
										</FormGroup>
									</Box>
									<Box display="flex" flexWrap="wrap" m={2}>
										<TextField
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											type="number"
											margin="dense"
											size="small"
											disabled={widgetInfo.refresh?.subscription}
											label={t(
												TransText.widgetEdit.timeRangeRefresh,
												null,
												lang,
												[]
											)}
											value={
												widgetInfo !== undefined
													? widgetInfo.refresh?.refresh
													: 0
											}
											onChange={(e) => {
												let wi = { ...widgetInfo };
												wi.refresh!.refresh = Number.parseInt(e.target.value);

												setWidgetInfo(wi);
											}}
										/>
										<Box ml={2}>
											<FormControl className={classes.formControl}>
												<InputLabel id="demo-simple-select-label">
													{t(
														TransText.widgetEdit.timeRangeRefreshUnit,
														null,
														lang,
														[]
													)}
												</InputLabel>

												<Select
													disabled={widgetInfo.refresh?.subscription}
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={
														widgetInfo !== undefined
															? widgetInfo.refresh?.refreshUnit.toString()
															: ''
													}
													onChange={(e) => {
														let wi = { ...widgetInfo };
														wi.refresh!.refreshUnit = e.target
															.value as keyof typeof enTimeUnitType;

														setWidgetInfo(wi);
													}}
												>
													<MenuItem value={'Second'}>sec</MenuItem>
													<MenuItem value={'Minute'}>min</MenuItem>
													<MenuItem value={'Hour'}>hour</MenuItem>
													<MenuItem value={'Day'}>day</MenuItem>
													<MenuItem value={'Week'}>week</MenuItem>
													<MenuItem value={'Month'}>month</MenuItem>
												</Select>
											</FormControl>
										</Box>
									</Box>
									<Box m={4}>
										<div className={classes.wrapper}>
											<Button
												onClick={ReloadSeries}
												fullWidth
												variant="contained"
												color="primary"
												disabled={reloadSpinner}
											>
												{t(TransText.widgetEdit.loadAllSeries, null, lang, [])}
											</Button>
											{reloadSpinner && (
												<CircularProgress
													size={24}
													className={classes.progress}
												/>
											)}
										</div>
									</Box>

									<Divider></Divider>
								</Box>
							</Paper>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
};
