import React, { Ref } from 'react';
import { WidgetLine1 } from './widgets/widgetLine1/widgetLine1';
import { WidgetBar1 } from './widgets/widgetBar1/widgetBar1';
import { WidgetGauge2 } from './widgets/widgetGauge2/widgetGauge2';
import { WidgetImage1 } from './widgets/widgetImage1/widgetImage1';
import { WidgetTextVar2 } from './widgets/widgetTextVar2/widgetTextVar2';
import { WidgetTextvar1 } from './widgets/widgetTextVar1/widgetTextvar1';
import { WidgetTable1 } from './widgets/widgetTable1/widgetTable1';
import { RefObj } from '../../model/pointModel';
import { IWidgetProps } from './widgetTypes';

export const Widget = React.forwardRef(
	(props: IWidgetProps, ref: Ref<RefObj>) => {
		const components: any = {
			line_1: WidgetLine1,
			bar_1: WidgetBar1,
			gauge_2: WidgetGauge2,
			textvar_1: WidgetTextvar1,
			textvar_2: WidgetTextVar2,
			image_1: WidgetImage1,
			table_1: WidgetTable1,
		};

		const BootComponent = components[props.widgetInfo.type];

		return (
			<BootComponent
				ref={ref}
				widgetInfo={props.widgetInfo}
				widgetUpdateDefaultConfigUpdate={props.widgetUpdateDefaultConfigUpdate}
				widgetError={props.widgetError}
				key={props.widgetInfo.i}
			/>
		);
	}
);
