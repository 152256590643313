import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import boost from 'highcharts/modules/boost';
import moment from 'moment';
import React, { Ref, useEffect, useImperativeHandle, useReducer } from 'react';
import GaugeChart from 'react-gauge-chart';
import { useSelector } from 'react-redux';
import { SerieSetting } from '../../../../model/dashModel';
import {
	enPointState,
	enPointValueType,
	PointDTO,
	PointHistoryDTO,
	PointHistoryResponseDTO,
	RefObj,
	WidgetInfo,
} from '../../../../model/pointModel';
import { IAppState } from '../../../../redux/store';
import { CreateGuid } from '../../../../services/misc';
import { PointAPI } from '../../../../services/pointAPI';
import { INameValueHash } from '../../common/types';
import { IWidgetProps } from '../../widgetTypes';

export const WidgetDefaultOptions = {
	textColor: '#000000',
	unitText: ' ton',

	rangeMin: 0,
	rangeMax: 100,
	levelColors: ['#ff1a1a', '#f5e942', '#8cff1a', '#1aff1a'],
	levelStartValues: [0, 25, 50, 75],

	valuePathExpression: '',

	valueCalcExpresion: '',
	valueCalcParamPoints: [],
};

export const WidgetGauge2 = React.forwardRef(
	(props: IWidgetProps, ref: Ref<RefObj>) => {
		const { loggedOnUser } = useSelector((state: IAppState) => state.app);

		const [widgetTypeConfig, setWidgetTypeConfig] = React.useState<any>();

		boost(Highcharts);

		useEffect(() => {
			let isMounted = true;
			let timer: any = null;
			let getHistoryDone = true;

			// At first render the widget updates widgetInfo.widgetTypeConfig
			// with default configuration, so we get a first unconfigured render
			if (props.widgetInfo.widgetTypeConfig === undefined) {
				let wi = { ...props.widgetInfo };
				wi.widgetTypeConfig = JSON.parse(JSON.stringify(WidgetDefaultOptions));
				props.widgetUpdateDefaultConfigUpdate(wi);
				return;
			}

			// Read all points data direct on props.widgets update and start a
			// refresh timer
			if (isMounted && !props.widgetInfo.refresh?.subscription) {
				getPointsLastestValue();

				if (timer == null) {
					timer = setInterval(
						() => {
							if (isMounted) {
								if (getHistoryDone) {
									getHistoryDone = false;
									getPointsLastestValue().then(() => {
										getHistoryDone = true;
									});
								}
							}
						},
						props.widgetInfo.refresh === undefined
							? 30000
							: props.widgetInfo.refresh.refresh * 1000
					);
				}
			}

			return () => {
				clearInterval(timer);
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [props.widgetInfo, widgetTypeConfig]);

		// Links the function CleanWidgetTypeConfigFromPvs to the parent
		useImperativeHandle(ref, () => ({ CleanWidgetTypeConfigFromPvs }));

		// Called by the dash when saving the widgets info
		// This fuinction removes any pointdata from the structure so
		// it will not end up in the database.
		const CleanWidgetTypeConfigFromPvs = (): any => {
			let wi = { ...props.widgetInfo };

			if (
				wi.widgetTypeConfig.series === undefined ||
				wi.widgetTypeConfig.series.length === 0
			)
				return wi;

			wi.widgetTypeConfig.series.map((x: any) => {
				x.data = [];
				return x;
			});

			return wi;
		};

		const getPointsLastestValue = () => {
			let pointDTOs: PointDTO[] = [];

			if (props.widgetInfo.widgetTypeConfig.valueCalcParamPoints != undefined) {
				pointDTOs = [
					...props.widgetInfo.widgetTypeConfig.valueCalcParamPoints.map(
						(x: SerieSetting) => x.pointDTO
					),
					props.widgetInfo.points,
				].flat();
			}
			let pointAPI: PointAPI = new PointAPI();
			return pointAPI
				.GetPointsHistory({
					customerId: loggedOnUser?.tenant == null ? '' : loggedOnUser.tenant,
					pointids: [...pointDTOs.map((x) => x.pointID)],
					fromDateTime: '',
					toDateTime: '',

					groupByIntervalUnit: '',
					groupByInterval: 0,
					maxRowsToReturn: 10000,
					onlyReturnCount: false,
					latestValue: true,
				})
				.then((resp) => {
					if (resp.status >= 200 && resp.status < 300) {
						let pointHistoryResp: PointHistoryResponseDTO;
						pointHistoryResp = resp.data;

						if (
							pointHistoryResp.pointHistory &&
							pointHistoryResp.pointHistory.length > 0
						) {
							//let wi = { ...props.widgetInfo };

							// Get the widgets pointValue
							let valuePvs = pointHistoryResp.pointHistory.filter(
								(x: PointHistoryDTO) => {
									return x.pointId === props.widgetInfo.points![0].pointID;
								}
							);

							let samp = valuePvs[0].points[0];
							let value: number | null = null;

							if (
								samp.state !== enPointState.NULL &&
								samp.state === enPointState.OK
							) {
								if (
									props.widgetInfo.points![0].valueType ===
									enPointValueType.json_type
								) {
									let obj = JSON.parse(samp.value);

									if (
										props.widgetInfo.widgetTypeConfig.valuePathExpression !==
										undefined
									) {
										var theInstructions =
											props.widgetInfo.widgetTypeConfig.valuePathExpression ===
											undefined
												? ''
												: props.widgetInfo.widgetTypeConfig.valuePathExpression;

										//"return obj[0].filter((x) => {if (x.name === 'KM1_OEE_CurrShift') return true;})[0].value;";

										// eslint-disable-next-line
										var F = new Function('obj', theInstructions);
										let res = F(obj);
										value = parseFloat(res.replace(',', '.'));
									}
								}

								if (
									props.widgetInfo.widgetTypeConfig.valueCalcExpresion !==
										undefined &&
									props.widgetInfo.widgetTypeConfig.valueCalcExpresion !== ''
								) {
									if (
										props.widgetInfo.widgetTypeConfig.valueCalcParamPoints !==
										undefined
									) {
										props.widgetInfo.widgetTypeConfig.valueCalcParamPoints.map(
											(paramPoint: SerieSetting) => {
												let paramPVS = pointHistoryResp.pointHistory.find(
													(x: PointHistoryDTO) => {
														return x.pointId === paramPoint.pointDTO?.pointID;
													}
												);

												if (paramPVS !== undefined) {
													paramPoint.value = paramPVS.points[0].value;
												}
											}
										);
									}

									let paramStr = 'value,params';
									let pointHash: INameValueHash = {};

									if (
										props.widgetInfo.widgetTypeConfig.valueCalcParamPoints !==
										undefined
									) {
										for (
											let c = 0;
											c <
											props.widgetInfo.widgetTypeConfig.valueCalcParamPoints
												.length;
											c++
										) {
											pointHash[
												props.widgetInfo.widgetTypeConfig.valueCalcParamPoints[
													c
												].name
											] =
												props.widgetInfo.widgetTypeConfig.valueCalcParamPoints[
													c
												].value;
										}
									}

									try {
										let F = new Function(
											paramStr,
											props.widgetInfo.widgetTypeConfig.valueCalcExpresion
										);
										value = F!(samp.value.replace(',', '.'), pointHash);
										console.log(samp.value + ' - ' + pointHash['Viktbandvåg']);
									} catch (e) {
										console.log('Error');
									}
								} else {
									value = parseFloat(samp.value.replace(',', '.'));
								}
							}

							let wtc = { ...widgetTypeConfig };
							wtc.value = Math.round(value === null ? 0 : value * 10) / 10;

							if (wtc.value !== widgetTypeConfig?.value) {
								setWidgetTypeConfig(wtc);
							}
						}
					}
				})
				.catch((e) => {
					console.log(e);
				})
				.finally(() => {});
		};

		const CalculatePrecent = (widgetTypeConfig: any, value: number): number => {
			if (
				widgetTypeConfig.rangeMax === undefined ||
				Number.isNaN(widgetTypeConfig.rangeMax) ||
				widgetTypeConfig.rangeMin === undefined ||
				Number.isNaN(widgetTypeConfig.rangeMin) ||
				widgetTypeConfig.levelStartValues === undefined ||
				widgetTypeConfig.levelStartValues.length === 0
			)
				return 0;

			let diff = widgetTypeConfig.rangeMax - widgetTypeConfig.rangeMin;
			let k = 100 / diff;
			let slope = 0 - widgetTypeConfig.rangeMin;

			if (value < widgetTypeConfig.rangeMin) value = widgetTypeConfig.rangeMin;
			if (value > widgetTypeConfig.rangeMax) value = widgetTypeConfig.rangeMax;

			let res = ((value + slope) * k) / 100;

			return res;
		};

		const CalculateArcLengts = (widgetTypeConfig: any): number[] => {
			if (
				widgetTypeConfig.rangeMax === undefined ||
				Number.isNaN(widgetTypeConfig.rangeMax) ||
				widgetTypeConfig.rangeMin === undefined ||
				Number.isNaN(widgetTypeConfig.rangeMin) ||
				widgetTypeConfig.levelStartValues === undefined ||
				widgetTypeConfig.levelStartValues.length === 0
			)
				return [0.25, 0.25, 0.25, 0.25];

			let diff = widgetTypeConfig.rangeMax - widgetTypeConfig.rangeMin;
			let k = 100 / diff;

			let diffs: number[] = [];
			for (let c = 0; c < widgetTypeConfig.levelStartValues.length; c++) {
				let diff = 0;
				if (c < widgetTypeConfig.levelStartValues.length - 1) {
					diff =
						widgetTypeConfig.levelStartValues[c + 1] -
						widgetTypeConfig.levelStartValues[c];
				} else {
					diff =
						widgetTypeConfig.rangeMax - widgetTypeConfig.levelStartValues[c];
				}
				diffs.push((diff * k) / 100);
			}

			return diffs;
		};

		return (
			<React.Fragment>
				{props.widgetInfo.widgetTypeConfig ? (
					<GaugeChart
						key={1}
						id={'gauge-chart2_' + CreateGuid()}
						percent={CalculatePrecent(
							props.widgetInfo.widgetTypeConfig,
							widgetTypeConfig !== undefined ? widgetTypeConfig.value : 0
						)}
						textColor={
							props.widgetInfo.widgetTypeConfig.textColor === undefined
								? '#000000'
								: props.widgetInfo.widgetTypeConfig.textColor
						}
						formatTextValue={(value) =>
							(widgetTypeConfig !== undefined ? widgetTypeConfig.value : '0') +
							(props.widgetInfo.widgetTypeConfig.unitText === undefined
								? ' Unit'
								: props.widgetInfo.widgetTypeConfig.unitText)
						}
						hideText={false}
						animate={true}
						colors={
							props.widgetInfo.widgetTypeConfig.levelColors === undefined
								? []
								: props.widgetInfo.widgetTypeConfig.levelColors
						}
						nrOfLevels={
							props.widgetInfo.widgetTypeConfig.levelColors === undefined
								? 0
								: props.widgetInfo.widgetTypeConfig.levelColors.length
						}
						arcsLength={CalculateArcLengts(props.widgetInfo.widgetTypeConfig)}
					/>
				) : (
					''
				)}
				;
			</React.Fragment>
		);
	}
);
