import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import boost from 'highcharts/modules/boost';
import moment from 'moment';
import React, { Ref, useEffect, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { SerieSetting } from '../../../../model/dashModel';
import {
	enPointState,
	PointDTO,
	PointHistoryDTO,
	PointHistoryResponseDTO,
	RefObj,
	WidgetInfo,
} from '../../../../model/pointModel';
import { IAppState } from '../../../../redux/store';
import { PointAPI } from '../../../../services/pointAPI';
import { INameValueHash } from '../../common/types';
import { IWidgetProps } from '../../widgetTypes';

export const WidgetDefaultOptions = {
	chart: {
		type: 'column',
	},
	title: {
		text: null,
	},
	xAxis: {
		categories: [],
		crosshair: true,
	},
	yAxis: {
		min: 0,
		title: {
			text: 'Unit',
		},
	},
	tooltip: {
		headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
		pointFormat:
			'<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
			'<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
		footerFormat: '</table>',
		shared: true,
		useHTML: true,
	},
	plotOptions: {
		column: {
			stacking: 'normal',
			pointPadding: 0.2,
			borderWidth: 0,
		},
	},
	series: [],
	colors: [],
};
// Serie definition
// 		name:
// 		data:

export const WidgetBar1 = React.forwardRef(
	(props: IWidgetProps, ref: Ref<RefObj>) => {
		const { loggedOnUser } = useSelector((state: IAppState) => state.app);

		const [widgetInfo, setWidgetInfo] = React.useState<WidgetInfo>();

		boost(Highcharts);

		useEffect(() => {
			let isMounted = true;
			let timer: any = null;
			let getHistoryDone = true;

			// At first render the widget updates widgetInfo.widgetTypeConfig
			// with default configuration, so we get a first unconfigured render
			if (props.widgetInfo.widgetTypeConfig === undefined) {
				let wi = { ...props.widgetInfo };

				wi.widgetTypeConfig = JSON.parse(JSON.stringify(WidgetDefaultOptions));
				// WO:Set Formatter for the datelable, can't set with TS in default options...
				wi.widgetTypeConfig.xAxis.labels = {
					formatter: function () {
						return Highcharts.dateFormat('%e %b %H:%M', this.value);
					},
				};
				props.widgetUpdateDefaultConfigUpdate(wi);
				return;
			}

			// Read all points data direct on props.widgets update and start a
			// refresh timer
			if (isMounted && !props.widgetInfo.refresh?.subscription) {
				GetPointsLatestValue(props.widgetInfo.points);

				if (timer == null) {
					timer = setInterval(
						() => {
							if (isMounted) {
								if (getHistoryDone) {
									getHistoryDone = false;
									GetPointsLatestValue(props.widgetInfo.points)?.then(() => {
										getHistoryDone = true;
									});
								}
							}
						},
						props.widgetInfo.refresh === undefined
							? 30000
							: props.widgetInfo.refresh.refresh * 1000
					);
				}
			}

			return () => {
				clearInterval(timer);
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [props.widgetInfo]);

		// Links the function CleanWidgetTypeConfigFromPvs to the parent
		useImperativeHandle(ref, () => ({ CleanWidgetTypeConfigFromPvs }));

		// Called by the dash when saving the widgets info
		// This fuinction removes any pointdata from the structure so
		// it will not end up in the database.
		const CleanWidgetTypeConfigFromPvs = (): any => {
			let wi = { ...props.widgetInfo };

			if (
				wi.widgetTypeConfig.series === undefined ||
				wi.widgetTypeConfig.series.length === 0
			)
				return wi;

			wi.widgetTypeConfig.series.map((x: any) => {
				x.data = [];
				return x;
			});

			return wi;
		};

		const GetPointsLatestValue = (pointDTOs: PointDTO[]) => {
			let pointAPI: PointAPI = new PointAPI();

			let pointIds: string[] = [];
			for (let pointC = 0; pointC < pointDTOs.length; pointC++)
				pointIds.push(pointDTOs[pointC].pointID);

			return pointAPI
				.GetPointsHistory({
					customerId: loggedOnUser?.tenant == null ? '' : loggedOnUser.tenant,
					pointids: pointIds,
					fromDateTime: '',
					toDateTime: '',
					groupByIntervalUnit: '',
					groupByInterval: 0,
					maxRowsToReturn: 0,
					onlyReturnCount: false,
					latestValue: true,
				})
				.then((resp) => {
					if (resp.status >= 200 && resp.status < 300) {
						let pointHistoryResp: PointHistoryResponseDTO;
						pointHistoryResp = resp.data;

						// Get the points for the serie
						let wi = { ...props.widgetInfo };

						// Loop every widget serie
						for (
							let cSerie = 0;
							cSerie < props.widgetInfo.widgetTypeConfig.series.length;
							cSerie++
						) {
							let serie = wi.widgetTypeConfig.series[cSerie];
							if (serie === undefined) continue;

							let t: any;
							serie.data = [];

							// check if we have a calcExpression
							if (
								serie.valueCalcExpresion !== undefined &&
								serie.valueCalcExpresion.length > 0
							) {
								let paramStr = 'value,params';
								let parameterHash: INameValueHash = {};

								let calcExpressionFunction: any = undefined;
								try {
									calcExpressionFunction = new Function(
										paramStr,
										serie.valueCalcExpresion
									);
								} catch (e) {
									return;
								}

								// Get serie samp
								let samp = null;
								let pHist: PointHistoryDTO | undefined =
									pointHistoryResp.pointHistory.find((x) => {
										return x.pointId === serie.pointDTO.pointID;
									});
								if (pHist !== undefined) samp = pHist!.points[0];
								else {
									return;
								}

								// Get parameters samp
								if (serie.valueCalcParamPoints !== undefined) {
									serie.valueCalcParamPoints.map((paramPoint: SerieSetting) => {
										let paramPVS = pointHistoryResp.pointHistory.find(
											(x: PointHistoryDTO) => {
												return x.pointId === paramPoint.pointDTO?.pointID;
											}
										);

										if (
											paramPVS !== undefined &&
											paramPVS.points[0].state === enPointState.OK
										) {
											paramPoint.value = parseFloat(
												paramPVS.points[0].value.replace(',', '.')
											);
										} else {
											paramPoint.value = null;
										}
									});

									// Set values to the parameterHash
									for (let c = 0; c < serie.valueCalcParamPoints.length; c++) {
										parameterHash[serie.valueCalcParamPoints[c].name] =
											serie.valueCalcParamPoints[c].value;
									}
								}

								try {
									let utc = moment(samp.time);
									t = utc.add(utc.utcOffset(), 'minutes').toDate();

									let value: number | null = null;

									if (
										(serie.valueCalcParamPoints !== undefined &&
											samp.state === enPointState.OK &&
											serie.valueCalcParamPoints.find((x: SerieSetting) => {
												return x.value === null;
											}) === undefined) ||
										serie.valueCalcParamPoints === undefined
									) {
										value = calcExpressionFunction!(
											parseFloat(samp.value.replace(',', '.')),
											parameterHash
										);
									} else {
										value = null;
									}

									serie.data.push(value);
								} catch (e) {
									break;
								}
							} else {
								// Raw serie
								let samp = null;
								let pHist: PointHistoryDTO | undefined =
									pointHistoryResp.pointHistory.find((x) => {
										return x.pointId === serie.pointDTO.pointID;
									});
								if (pHist !== undefined) samp = pHist!.points[0];
								else return;

								let utc = moment(samp.time);
								t = utc.add(utc.utcOffset(), 'minutes').toDate();

								let value: number | null = null;
								if (samp.state !== enPointState.NULL)
									value = parseFloat(samp.value);

								serie.data.push(value);
							}

							wi.widgetTypeConfig.xAxis.categories = [
								moment(t).format('DD MMM HH:mm'),
							];
						}

						setWidgetInfo(wi);
					}
				})
				.catch((e) => {
					console.log(e);
				})
				.finally(() => {});
		};

		const getToDateTime = (): string => {
			if (
				props.widgetInfo.refresh?.start === 0 ||
				props.widgetInfo.refresh?.startUnit === undefined
			)
				return moment().toISOString();
			else {
				if (props.widgetInfo.refresh?.startUnit === 'Second') {
					return moment()
						.add(props.widgetInfo.refresh?.start, 'seconds')
						.toISOString();
				} else if (props.widgetInfo.refresh?.startUnit === 'Minute') {
					return moment()
						.add(props.widgetInfo.refresh?.start, 'minutes')
						.toISOString();
				} else if (props.widgetInfo.refresh?.startUnit === 'Hour') {
					return moment()
						.add(props.widgetInfo.refresh?.start, 'hours')
						.toISOString();
				} else if (props.widgetInfo.refresh?.startUnit === 'Day') {
					return moment()
						.add(props.widgetInfo.refresh?.start, 'days')
						.toISOString();
				} else if (props.widgetInfo.refresh?.startUnit === 'Week') {
					return moment()
						.add(props.widgetInfo.refresh?.start, 'weeks')
						.toISOString();
				} else if (props.widgetInfo.refresh?.startUnit === 'Month') {
					return moment()
						.add(props.widgetInfo.refresh?.start, 'months')
						.toISOString();
				}
				return 'NA';
			}
		};

		const getFromDateTime = (): string => {
			let toDateTime = moment();
			if (
				props.widgetInfo.refresh?.start === 0 ||
				props.widgetInfo.refresh?.startUnit === undefined
			)
				toDateTime = moment();
			else {
				toDateTime = moment(getToDateTime());
			}
			if (props.widgetInfo.refresh?.lastUnit === 'Second') {
				return toDateTime
					.add(-props.widgetInfo.refresh?.last, 'seconds')
					.toISOString();
			} else if (props.widgetInfo.refresh?.lastUnit === 'Minute') {
				return toDateTime
					.add(-props.widgetInfo.refresh?.last, 'minutes')
					.toISOString();
			} else if (props.widgetInfo.refresh?.lastUnit === 'Hour') {
				return toDateTime
					.add(-props.widgetInfo.refresh?.last, 'hours')
					.toISOString();
			} else if (props.widgetInfo.refresh?.lastUnit === 'Day') {
				return toDateTime
					.add(-props.widgetInfo.refresh?.last, 'days')
					.toISOString();
			} else if (props.widgetInfo.refresh?.lastUnit === 'Week') {
				return toDateTime
					.add(-props.widgetInfo.refresh?.last, 'weeks')
					.toISOString();
			} else if (props.widgetInfo.refresh?.lastUnit === 'Month') {
				return toDateTime
					.add(-props.widgetInfo.refresh?.last, 'months')
					.toISOString();
			}
			return 'NA';
		};

		return (
			<React.Fragment>
				{widgetInfo ? (
					<HighchartsReact
						containerProps={{
							style: { height: '100%', width: '100%' },
						}}
						key={props.widgetInfo.i}
						highcharts={Highcharts}
						options={widgetInfo!.widgetTypeConfig}
					/>
				) : (
					''
				)}
			</React.Fragment>
		);
	}
);
