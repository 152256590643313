import {
	AppBar,
	CircularProgress,
	Box,
	Button,
	Checkbox,
	CssBaseline,
	Dialog,
	DialogContent,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	InputLabel,
	makeStyles,
	MenuItem,
	Paper,
	Select,
	TextField,
	Toolbar,
	Typography,
} from '@material-ui/core';
import Highcharts, { Series } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import boost from 'highcharts/modules/boost';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../redux/store';
import TransText from '../../../../resource/transText';
import Translation from '../../../../services/translation';
import { OrangeButton } from '../../../../styles/buttons';
import {
	enPointState,
	enTimeUnitType,
	PointDTO,
	PointHistoryDTO,
	PointHistoryResponseDTO,
	WidgetInfo,
} from '../../../../model/pointModel';
import { SerieSetting } from '../../../../model/dashModel';
import { PointAPI } from '../../../../services/pointAPI';
import moment from 'moment';
import { PointSeries } from '../../common/pointSeries';
import { useSnackbar } from 'notistack';
import { INameValueHash } from '../../common/types';

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
			width: '25ch',
		},
	},
	appBarDialog: {
		position: 'relative',
	},
	titleDialog: {
		marginLeft: theme.spacing(2),
		flexGrow: 1,
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '25ch',
	},
	smallSize: {
		fontSize: 13,
		fontWeight: 400,
	},
	paper: {
		width: '100%',
		height: '100%',

		backgroundColor: 'white',
	},
	margin: {
		margin: theme.spacing(3),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 90,
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative',
	},
	progress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}));

interface IProps {
	selectedWidgetInfo: WidgetInfo;
	open: boolean;
	remove: (id: string) => any;
	save: (widgetInfo: WidgetInfo) => any;
	cancel: () => any;
}

export const WidgetEditBar1: React.FC<IProps> = ({
	selectedWidgetInfo,
	open,
	save,
	remove,
	cancel,
}) => {
	const classes = useStyles();
	const { t } = Translation;
	const { lang, loggedOnUser } = useSelector((state: IAppState) => state.app);

	const [widgetInfo, setWidgetInfo] =
		React.useState<WidgetInfo>(selectedWidgetInfo);

	const [serieSettings, setSerieSettings] = React.useState<SerieSetting[]>([]);

	const [reloadSpinner, setReloadSpinner] = React.useState<boolean>();

	const { enqueueSnackbar } = useSnackbar();

	boost(Highcharts);

	useEffect(() => {
		let serieCount = 0;
		let sSettings: SerieSetting[] = [];

		widgetInfo.widgetTypeConfig.series.map((serie: any) => {
			// Default values if missing in typeConfig
			let color: string = '#000000';
			let point: PointDTO | undefined = undefined;

			// Build the serieSetting for each serie
			if (
				widgetInfo.widgetTypeConfig.colors !== undefined &&
				widgetInfo.widgetTypeConfig.colors.length >= serieCount
			)
				color = widgetInfo.widgetTypeConfig.colors[serieCount];

			if (
				widgetInfo.points !== undefined &&
				widgetInfo.points.length >= serieCount
			)
				point = widgetInfo.points[serieCount];

			sSettings.push({
				id: serieCount,
				name: serie.name,
				color: color,
				pointDTO: point,
				value: serie.data,
				valueCalcExpresion: serie.valueCalcExpresion,
				valueCalcParams: serie.valueCalcParams,
				valuePathExpression: serie.valuePathExpression,
				controlRef: 0,
				graphicalType: '',
				colorLevels: [],
				top: 0,
				left: 0,
				width: 0,
				height: 0,
			});

			serieCount++;
		});

		setSerieSettings(sSettings);
		return () => {};
	}, []);

	const AddSerie = (serie: SerieSetting): boolean => {
		let wi = { ...widgetInfo };

		// Update, point, color and serie
		wi.points.push(serie.pointDTO!);
		wi.widgetTypeConfig.colors.push(serie.color);
		wi.widgetTypeConfig.series.push({
			name: serie.name,
			pointDTO: serie.pointDTO,

			valueCalcExpresion: serie.valueCalcExpresion,
			valueCalcParamPoints: serie.valueCalcParams,
			valuePathExpression: serie.valuePathExpression,
		});

		setWidgetInfo(wi);

		// sync serieIds with the index of series in typeConfig
		serie.id = wi.widgetTypeConfig.series.length - 1;
		let s = [...serieSettings, serie];
		setSerieSettings(s);

		return true;
	};

	const UpdateSerie = (serie: SerieSetting): boolean => {
		// Update widget config
		let wi = { ...widgetInfo };
		wi.widgetTypeConfig.series[serie.id].name = serie.name;
		wi.widgetTypeConfig.series[serie.id].pointDTO = serie.pointDTO;
		wi.widgetTypeConfig.colors[serie.id] = serie.color;
		wi.points[serie.id] = serie.pointDTO!;

		wi.widgetTypeConfig.series[serie.id].valueCalcExpresion =
			serie.valueCalcExpresion;
		wi.widgetTypeConfig.series[serie.id].valueCalcParamPoints =
			serie.valueCalcParams;
		wi.widgetTypeConfig.series[serie.id].valuePathExpression =
			serie.valuePathExpression;

		setWidgetInfo(wi);

		// Update seriessettings
		let s = [...serieSettings];
		let index = s.findIndex((x) => {
			return x.id === serie.id;
		});
		s[index] = serie;
		setSerieSettings(s);

		return true;
	};

	const RemoveSerie = (serie: SerieSetting): boolean => {
		// Update
		let wi = { ...widgetInfo };
		wi.points.splice(serie.id, 1);
		wi.widgetTypeConfig.series.splice(serie.id, 1);
		wi.widgetTypeConfig.colors.splice(serie.id, 1);
		setWidgetInfo(wi);

		let s = [...serieSettings];

		let index = s.findIndex((x) => {
			return x.id === serie.id;
		});

		s.splice(index, 1);

		setSerieSettings(s);

		return true;
	};

	const CheckForDuplicatedSeries = (serie: SerieSetting): boolean => {
		// Check for duplicates
		let nameDup = widgetInfo.widgetTypeConfig.series.find((x: any) => {
			return x.name === serie.name;
		});

		let pointDup = widgetInfo.points.find((x) => {
			return x.pointID == serie.pointDTO?.pointID;
		});

		if (nameDup !== undefined || pointDup !== undefined) {
			enqueueSnackbar(t(TransText.widgetEdit.serieDuplicated, null, lang, []), {
				variant: 'error',
			});
			return false;
		} else return true;
	};

	const onSaveWidget = (widgetInfo: WidgetInfo) => {
		widgetInfo.widgetTypeConfig.series.map((serie: any) => {
			serie.data = [];
			return serie;
		});

		save(widgetInfo);
	};

	const ReloadSeries = () => {
		GetPointsLatestValue(widgetInfo.points);
	};

	const GetPointsLatestValue = (pointDTOs: PointDTO[]) => {
		if (!pointDTOs) return;

		setReloadSpinner(true);

		let pointAPI: PointAPI = new PointAPI();
		pointAPI
			.GetPointsHistory({
				customerId: loggedOnUser?.tenant == null ? '' : loggedOnUser.tenant,
				pointids: [...pointDTOs.map((x) => x.pointID)],
				fromDateTime: '',
				toDateTime: '',
				groupByIntervalUnit: '',
				groupByInterval: 0,
				maxRowsToReturn: 0,
				onlyReturnCount: false,
				latestValue: true,
			})
			.then((resp) => {
				if (resp.status >= 200 && resp.status < 300) {
					let pointHistoryResp: PointHistoryResponseDTO;
					pointHistoryResp = resp.data;

					// Get the points for the serie
					let wi = { ...widgetInfo };

					// Loop every widget serie
					for (
						let cSerie = 0;
						cSerie < widgetInfo.widgetTypeConfig.series.length;
						cSerie++
					) {
						let serie = wi.widgetTypeConfig.series[cSerie];
						if (serie === undefined) continue;

						let t: any;
						serie.data = [];

						// check if we have a calcExpression
						if (
							serie.valueCalcExpresion !== undefined &&
							serie.valueCalcExpresion.length > 0
						) {
							let paramStr = 'value,params';
							let parameterHash: INameValueHash = {};

							let calcExpressionFunction: any = undefined;
							try {
								calcExpressionFunction = new Function(
									paramStr,
									serie.valueCalcExpresion
								);
							} catch (e) {
								enqueueSnackbar(e.message, {
									variant: 'error',
								});
								return;
							}

							// Get serie samp
							let samp = null;
							let pHist: PointHistoryDTO | undefined =
								pointHistoryResp.pointHistory.find((x) => {
									return x.pointId === serie.pointDTO.pointID;
								});
							if (pHist !== undefined) samp = pHist!.points[0];
							else return;

							// Get parameters samp
							if (serie.valueCalcParamPoints !== undefined) {
								serie.valueCalcParamPoints.map((paramPoint: SerieSetting) => {
									let paramPVS = pointHistoryResp.pointHistory.find(
										(x: PointHistoryDTO) => {
											return x.pointId === paramPoint.pointDTO?.pointID;
										}
									);

									if (
										paramPVS !== undefined &&
										paramPVS.points[0].state === enPointState.OK
									) {
										paramPoint.value = parseFloat(
											paramPVS.points[0].value.replace(',', '.')
										);
									} else {
										paramPoint.value = null;
									}
								});

								// Set values to the parameterHash
								for (let c = 0; c < serie.valueCalcParamPoints.length; c++) {
									parameterHash[serie.valueCalcParamPoints[c].name] =
										serie.valueCalcParamPoints[c].value;
								}
							}

							try {
								let utc = moment(samp.time);
								t = utc.add(utc.utcOffset(), 'minutes').toDate();

								let value: number | null = null;

								if (
									(serie.valueCalcParamPoints !== undefined &&
										samp.state === enPointState.OK &&
										serie.valueCalcParamPoints.find((x: SerieSetting) => {
											return x.value === null;
										}) === undefined) ||
									serie.valueCalcParamPoints === undefined
								) {
									value = calcExpressionFunction!(
										parseFloat(samp.value.replace(',', '.')),
										parameterHash
									);
								} else {
									value = null;
								}

								serie.data.push(value);
							} catch (e) {
								enqueueSnackbar(e.message, {
									variant: 'error',
								});
								break;
							}
						} else {
							// Raw serie
							let samp = null;
							let pHist: PointHistoryDTO | undefined =
								pointHistoryResp.pointHistory.find((x) => {
									return x.pointId === serie.pointDTO.pointID;
								});
							if (pHist !== undefined) samp = pHist!.points[0];
							else return;

							let utc = moment(samp.time);
							t = utc.add(utc.utcOffset(), 'minutes').toDate();

							let value: number | null = null;
							if (samp.state !== enPointState.NULL)
								value = parseFloat(samp.value);

							serie.data.push(value);
						}

						wi.widgetTypeConfig.xAxis.categories = [
							moment(t).format('DD MMM HH:mm'),
						];

						setWidgetInfo(wi);
					}
				}
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				setReloadSpinner(false);
			});
	};

	const getToDateTime = (): string => {
		if (
			widgetInfo.refresh?.start === 0 ||
			widgetInfo.refresh?.startUnit === undefined
		)
			return moment().toISOString();
		else {
			if (widgetInfo.refresh?.startUnit === 'Second') {
				return moment().add(widgetInfo.refresh?.start, 'seconds').toISOString();
			} else if (widgetInfo.refresh?.startUnit === 'Minute') {
				return moment().add(widgetInfo.refresh?.start, 'minutes').toISOString();
			} else if (widgetInfo.refresh?.startUnit === 'Hour') {
				return moment().add(widgetInfo.refresh?.start, 'hours').toISOString();
			} else if (widgetInfo.refresh?.startUnit === 'Day') {
				return moment().add(widgetInfo.refresh?.start, 'days').toISOString();
			} else if (widgetInfo.refresh?.startUnit === 'Week') {
				return moment().add(widgetInfo.refresh?.start, 'weeks').toISOString();
			} else if (widgetInfo.refresh?.startUnit === 'Month') {
				return moment().add(widgetInfo.refresh?.start, 'months').toISOString();
			}
			return 'NA';
		}
	};

	const getFromDateTime = (): string => {
		let toDateTime = moment();
		if (
			widgetInfo.refresh?.start === 0 ||
			widgetInfo.refresh?.startUnit === undefined
		)
			toDateTime = moment();
		else {
			toDateTime = moment(getToDateTime());
		}
		if (widgetInfo.refresh?.lastUnit === 'Second') {
			return toDateTime.add(-widgetInfo.refresh?.last, 'seconds').toISOString();
		} else if (widgetInfo.refresh?.lastUnit === 'Minute') {
			return toDateTime.add(-widgetInfo.refresh?.last, 'minutes').toISOString();
		} else if (widgetInfo.refresh?.lastUnit === 'Hour') {
			return toDateTime.add(-widgetInfo.refresh?.last, 'hours').toISOString();
		} else if (widgetInfo.refresh?.lastUnit === 'Day') {
			return toDateTime.add(-widgetInfo.refresh?.last, 'days').toISOString();
		} else if (widgetInfo.refresh?.lastUnit === 'Week') {
			return toDateTime.add(-widgetInfo.refresh?.last, 'weeks').toISOString();
		} else if (widgetInfo.refresh?.lastUnit === 'Month') {
			return toDateTime.add(-widgetInfo.refresh?.last, 'months').toISOString();
		}
		return 'NA';
	};

	return (
		<React.Fragment>
			<Dialog fullScreen open={open}>
				<AppBar className={classes.appBarDialog}>
					<Toolbar>
						<Typography variant="h6" className={classes.titleDialog}>
							{widgetInfo !== undefined ? widgetInfo.title : ''}
						</Typography>

						<Button
							autoFocus
							color="inherit"
							onClick={() => {
								remove(widgetInfo === undefined ? '' : widgetInfo.i);
							}}
						>
							Delete
						</Button>

						<Button
							autoFocus
							color="inherit"
							onClick={() => {
								onSaveWidget(widgetInfo);
							}}
						>
							Save
						</Button>
						<OrangeButton
							autoFocus
							color="inherit"
							onClick={() => {
								cancel();
							}}
						>
							{t(TransText.app.cancel, null, lang, [])}
						</OrangeButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<CssBaseline />
					<Grid container spacing={2}>
						{/* Widget */}
						<Grid item md={7}>
							<Paper elevation={4}>
								<Box
									display="flex"
									flexDirection="column"
									style={{ height: '100%', width: '100%' }}
									m={2}
								>
									<Box display="flex" justifyContent="space-between">
										<Box>{''}</Box>
										<Box>
											<Typography variant="h6">
												<Box fontSize="16px">
													{widgetInfo !== undefined ? widgetInfo.title : ''}
												</Box>
											</Typography>
										</Box>
										<Box>{''}</Box>
									</Box>
									<Box>
										<HighchartsReact
											containerProps={{
												style: { height: '300px', width: '99%' },
											}}
											key={widgetInfo !== undefined ? widgetInfo.i : ''}
											highcharts={Highcharts}
											options={
												widgetInfo !== undefined
													? widgetInfo.widgetTypeConfig
													: {}
											}
										/>
									</Box>
								</Box>
							</Paper>
						</Grid>

						{/* Widget settings */}
						<Grid item md={5}>
							<Paper elevation={4}>
								<Box m={2}>
									{/* Title */}
									<Box
										display="flex"
										justifyContent="flex-start"
										flexWrap="wrap"
										m={2}
										mb={2}
									>
										<TextField
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											margin="dense"
											size="small"
											label={t(
												TransText.widgetEdit.widgetTitle,
												null,
												lang,
												[]
											)}
											value={widgetInfo !== undefined ? widgetInfo.title : ''}
											onChange={(e) => {
												setWidgetInfo({
													...widgetInfo,
													title: e.target.value,
												});
											}}
										/>
									</Box>
									<Divider></Divider>

									{/* Series */}
									<Box
										display="flex"
										justifyContent="flex-start"
										alignItems="center"
										flexWrap="wrap"
										m={2}
									>
										<PointSeries
											series={serieSettings}
											updateSerie={(serie: SerieSetting) => {
												return UpdateSerie(serie);
											}}
											addSerie={(serie: SerieSetting) => {
												return AddSerie(serie);
											}}
											removeSerie={(serie: SerieSetting) => {
												return RemoveSerie(serie);
											}}
											showColor={true}
											showColorLevels={false}
											showFunction={true}
											showGraphicalType={false}
											maxNrOfSeries={0}
										/>
									</Box>
									<Divider></Divider>

									{/* Stacked */}
									<Box display="flex" flexWrap="wrap" m={2}>
										<FormGroup row>
											<FormControlLabel
												control={
													<Checkbox
														color="primary"
														checked={
															widgetInfo !== undefined
																? widgetInfo.widgetTypeConfig.plotOptions !==
																		undefined &&
																  widgetInfo.widgetTypeConfig.plotOptions.column
																		.stacking == 'normal'
																	? true
																	: false
																: false
														}
														onChange={(e) => {
															let wi = { ...widgetInfo };

															e.target.checked
																? (wi.widgetTypeConfig.plotOptions.column.stacking =
																		'normal')
																: (wi.widgetTypeConfig.plotOptions.column.stacking =
																		'');

															setWidgetInfo(wi);
														}}
													/>
												}
												label={t(
													TransText.widgetEdit.barStacked,
													null,
													lang,
													[]
												)}
											/>
										</FormGroup>
									</Box>
									<Divider></Divider>

									{/* Time range Refresh */}
									<Box display="flex" flexWrap="wrap" m={2}>
										<FormGroup row>
											<FormControlLabel
												control={
													<Checkbox
														color="primary"
														checked={
															widgetInfo !== undefined
																? widgetInfo.refresh?.subscription
																: false
														}
														onChange={(e) => {
															let wi = { ...widgetInfo };
															wi.refresh!.subscription = e.target.checked;

															setWidgetInfo(wi);
														}}
													/>
												}
												label={t(
													TransText.widgetEdit.timeRangeSubscription,
													null,
													lang,
													[]
												)}
											/>
										</FormGroup>
									</Box>
									<Box display="flex" flexWrap="wrap" m={2}>
										<TextField
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											type="number"
											margin="dense"
											size="small"
											disabled={widgetInfo.refresh?.subscription}
											label={t(
												TransText.widgetEdit.timeRangeRefresh,
												null,
												lang,
												[]
											)}
											value={
												widgetInfo !== undefined
													? widgetInfo.refresh?.refresh
													: 0
											}
											onChange={(e) => {
												let wi = { ...widgetInfo };
												wi.refresh!.refresh = Number.parseInt(e.target.value);

												setWidgetInfo(wi);
											}}
										/>
										<Box ml={2}>
											<FormControl className={classes.formControl}>
												<InputLabel id="demo-simple-select-label">
													{t(
														TransText.widgetEdit.timeRangeRefreshUnit,
														null,
														lang,
														[]
													)}
												</InputLabel>

												<Select
													disabled={widgetInfo.refresh?.subscription}
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={
														widgetInfo !== undefined
															? widgetInfo.refresh?.refreshUnit.toString()
															: ''
													}
													onChange={(e) => {
														let wi = { ...widgetInfo };
														wi.refresh!.refreshUnit = e.target
															.value as keyof typeof enTimeUnitType;

														setWidgetInfo(wi);
													}}
												>
													<MenuItem value={'Second'}>sec</MenuItem>
													<MenuItem value={'Minute'}>min</MenuItem>
													<MenuItem value={'Hour'}>hour</MenuItem>
													<MenuItem value={'Day'}>day</MenuItem>
													<MenuItem value={'Week'}>week</MenuItem>
													<MenuItem value={'Month'}>month</MenuItem>
												</Select>
											</FormControl>
										</Box>
									</Box>
									<Box m={2}>
										<div className={classes.wrapper}>
											<Button
												onClick={ReloadSeries}
												fullWidth
												variant="contained"
												color="primary"
												disabled={reloadSpinner}
											>
												{t(TransText.widgetEdit.loadAllSeries, null, lang, [])}
											</Button>
											{reloadSpinner && (
												<CircularProgress
													size={24}
													className={classes.progress}
												/>
											)}
										</div>
									</Box>

									<Divider></Divider>

									{/* yAxis */}
									<Box
										display="flex"
										justifyContent="flex-start"
										flexWrap="wrap"
										m={2}
									>
										<TextField
											multiline
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											margin="dense"
											size="small"
											label={t(TransText.widgetEdit.yAxisLabel, null, lang, [])}
											value={
												widgetInfo !== undefined
													? widgetInfo.widgetTypeConfig.yAxis.title.text
													: ''
											}
											onChange={(e) => {
												let wi = { ...widgetInfo };
												wi.widgetTypeConfig.yAxis.title.text = e.target.value;

												setWidgetInfo(wi);
											}}
										/>

										<Box display="flex" flexWrap="wrap">
											<FormGroup row>
												<FormControlLabel
													control={
														<Checkbox
															color="primary"
															checked={
																widgetInfo !== undefined
																	? widgetInfo.widgetTypeConfig.yAxis.max ===
																	  null
																		? true
																		: false
																	: false
															}
															onChange={(e) => {
																let wi = { ...widgetInfo };
																wi.widgetTypeConfig.yAxis.max = e.target.checked
																	? null
																	: 10;

																setWidgetInfo(wi);
															}}
														/>
													}
													label={t(
														TransText.widgetEdit.yAxisAutomaticMax,
														null,
														lang,
														[]
													)}
												/>
											</FormGroup>
											<TextField
												className={classes.textField}
												InputProps={{
													classes: {
														input: classes.smallSize,
													},
												}}
												InputLabelProps={{
													shrink: true,
												}}
												type="number"
												margin="dense"
												size="small"
												disabled={
													widgetInfo !== undefined
														? widgetInfo.widgetTypeConfig.yAxis.max === null
															? true
															: false
														: false
												}
												label={t(TransText.widgetEdit.yAxisMax, null, lang, [])}
												value={
													widgetInfo !== undefined
														? widgetInfo.widgetTypeConfig.yAxis.max == null
															? ''
															: widgetInfo.widgetTypeConfig.yAxis.max
														: undefined
												}
												onChange={(e) => {
													let wi = { ...widgetInfo };
													wi.widgetTypeConfig.yAxis.max = e.target.value;

													setWidgetInfo(wi);
												}}
											/>

											<TextField
												className={classes.textField}
												InputProps={{
													classes: {
														input: classes.smallSize,
													},
												}}
												InputLabelProps={{
													shrink: true,
												}}
												type="number"
												margin="dense"
												size="small"
												label={t(TransText.widgetEdit.yAxisMin, null, lang, [])}
												value={
													widgetInfo !== undefined
														? widgetInfo.widgetTypeConfig.yAxis.min
														: null
												}
												onChange={(e) => {
													let wi = { ...widgetInfo };
													wi.widgetTypeConfig.yAxis.min = e.target.value;

													setWidgetInfo(wi);
												}}
											/>
										</Box>
									</Box>

									<Divider></Divider>
								</Box>
							</Paper>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
};
