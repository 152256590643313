import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { IAppState } from '../../../../../redux/store';
import { PointDTO } from '../../../../../model/pointModel';
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import Translation from '../../../../../services/translation';
import TransText from '../../../../../resource/transText';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { PointAPI } from '../../../../../services/pointAPI';

import { ControlSerie, ValueControlTypes } from './controlTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { SerieItemControl } from './serieItemControl';

const useStyles = makeStyles((theme) => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '25ch',
	},
	smallSize: {
		fontSize: 13,
		fontWeight: 400,
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative',
	},
	progress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 90,
	},
}));

interface IProps {
	open: boolean;
	controlSerie: ControlSerie;
	onCancel: () => any;
	onUpdate: (controlSerie: ControlSerie) => any;
	onDelete: () => any;
	showType: boolean;
	showLevels: boolean;
}

export const SerieControlDialog: React.FC<IProps> = (props) => {
	const classes = useStyles();
	const { lang, loggedOnUser } = useSelector((state: IAppState) => state.app);
	const { t } = Translation;

	const [serie, setSerie] = React.useState<ControlSerie>(props.controlSerie);
	const [selectedFuncParamSerie, setSelectedFuncParamSerie] =
		React.useState<ControlSerie>();
	const [editFuncParamSerie, setEditFuncParamSerie] =
		React.useState<ControlSerie>();
	const [openColor, setOpenColor] = React.useState<boolean>(false);
	const [points, setPoints] = React.useState<PointDTO[]>([]);
	const [reloadSpinner, setReloadSpinner] = React.useState<boolean>();
	const serieRefArray = React.useRef(new Map<number, any>());

	useEffect(() => {
		let isMounted = true;

		if (isMounted) {
			if (points.length === 0) {
				let pointAPi = new PointAPI();

				pointAPi
					.GetPoints(loggedOnUser?.tenant!, '', 50)
					.then((resp) => {
						if (resp.status >= 200 && resp.status < 300) {
						}
						setPoints(resp.data.pointDTOs);
					})
					.catch((e) => {
						console.log(e.message);
					});
			}
			setReloadSpinner(false);
		}
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const AddFuncParamSerie = () => {
		let date = new Date();
		let newFuncSerie: ControlSerie = {
			id: date.getTime(),
			name: '',
			pointDTO: undefined,
			colorLevels: [
				{ level: 0, color: '#0d233a' },
				{ level: 25, color: '#8bbc21' },
				{ level: 75, color: '#910000' },
				{ level: 90, color: '#f28f43' },
			],
			valueCalcExpresion: '',
			valueCalcParamPointDTOs: [],
			newWidget: true,
			type: 'Text',
			top: 10,
			left: 10,
			width: 36,
			height: 16,
			value: undefined,
			controlRef: 0,
		};

		setSelectedFuncParamSerie(newFuncSerie);
	};

	// const HandleSelectFuncParamSerie = (serie: ControlSerie) => {
	// 	setSelectedFuncParamSerie(serie);
	// };

	// const onSaveFuncParamSerie = (controlSerie: ControlSerie) => {
	// 	setSelectedFuncParamSerie(controlSerie);

	// 	onUpdateEditFuncParamSerie(controlSerie);
	// };

	// const onUpdateEditFuncParamSerie = (controlSerie: ControlSerie) => {
	// 	let wi = { ...widgetInfo };
	// 	let serieIndex = getEditFuncParamSerieIndex(controlSerie);

	// 	if (serieIndex > -1) {
	// 		wi.widgetTypeConfig.valueCalcParamPoints[serieIndex] = controlSerie;

	// 		wi.points[serieIndex] = controlSerie.pointDTO!;
	// 	} else {
	// 		if (wi.widgetTypeConfig.valueCalcParamPoints === undefined)
	// 			wi.widgetTypeConfig.valueCalcParamPoints = [];
	// 		wi.widgetTypeConfig.valueCalcParamPoints.push(controlSerie);
	// 		wi.points.push(controlSerie.pointDTO!);
	// 	}

	// 	setWidgetInfo(wi);

	// 	setEditFuncParamSerie(undefined);
	// };

	// const getEditFuncParamSerieIndex = (controlSerie: ControlSerie) => {
	// 	let serieIndex = -1;

	// 	if (
	// 		widgetInfo.widgetTypeConfig.valueCalcParamPoints === undefined ||
	// 		widgetInfo.widgetTypeConfig.valueCalcParamPoints.lengt === 0
	// 	)
	// 		return serieIndex;

	// 	let dSerie = editSerie === undefined ? controlSerie : editSerie;

	// 	for (
	// 		let c: number = 0;
	// 		c < widgetInfo.widgetTypeConfig.valueCalcParamPoints.length;
	// 		c++
	// 	) {
	// 		if (
	// 			widgetInfo.widgetTypeConfig.valueCalcParamPoints[c].id === dSerie!.id
	// 		) {
	// 			serieIndex = c;
	// 			break;
	// 		}
	// 	}

	// 	return serieIndex;
	// };

	// const onCancelEditFuncParamSerie = () => {
	// 	setEditFuncParamSerie(undefined);
	// };

	// const onCancelRemoveFuncParamSerie = () => {
	// 	setRemoveFuncParamSerie(undefined);
	// };

	// const onConfirmRemoveFuncParamSerie = () => {
	// 	let wi = { ...widgetInfo };
	// 	let serieIndex = getEditFuncParamSerieIndex(removeSerie!);

	// 	if (serieIndex > -1) {
	// 		wi.points.splice(serieIndex, 1);
	// 		wi.widgetTypeConfig.valueCalcParamPoints.splice(serieIndex, 1);
	// 	}

	// 	setWidgetInfo(wi);

	// 	setRemoveFuncParamSerie(undefined);
	// };

	// const onDeleteEditFuncParamSerie = () => {
	// 	setEditFuncParamSerie(undefined);
	// 	setRemoveFuncParamSerie(editSerie);
	// };

	return (
		<React.Fragment>
			<Dialog fullWidth open={props.open}>
				<DialogTitle>Serie</DialogTitle>
				<DialogContent dividers>
					<Box display="flex" flexDirection="column">
						{/* Name */}
						<TextField
							className={classes.textField}
							InputProps={{
								classes: {
									input: classes.smallSize,
								},
							}}
							InputLabelProps={{
								shrink: true,
							}}
							margin="dense"
							size="small"
							label={t(TransText.app.name, null, lang, [])}
							value={serie.name}
							onChange={(e) => {
								setSerie({ ...serie, name: e.target.value });
							}}
						/>

						{/* Graphical Type */}
						{props.showType ? (
							<Box display="flex" flexDirection="column" mt={4}>
								<FormControl className={classes.formControl}>
									<InputLabel id="selectType">
										{t(
											TransText.widgetEdit.timeRangeRefreshUnit,
											null,
											lang,
											[]
										)}
									</InputLabel>

									<Select
										labelId="selectType"
										id="selectType"
										value={serie !== undefined ? serie.type : ''}
										onChange={(e) => {
											let ser = { ...serie };
											ser.type =
												typeof e.target.value === 'string'
													? e.target.value
													: ValueControlTypes.Text;

											setSerie(ser);
										}}
									>
										<MenuItem value={ValueControlTypes.Text}>
											{ValueControlTypes.Text}
										</MenuItem>
										<MenuItem value={ValueControlTypes.Rectangle}>
											{ValueControlTypes.Rectangle}
										</MenuItem>
									</Select>
								</FormControl>
							</Box>
						) : (
							''
						)}

						{/* Levels */}
						{props.showLevels ? (
							<Box display="flex" flexDirection="column" mt={4}>
								{/* Level 1 */}
								<Box display="flex" flexWrap="wrap">
									<Box mb={2}>
										{/* Color */}
										<TextField
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											label={t(TransText.widgetGauge2.levelColor, null, lang, [
												'1',
											])}
											value={
												serie.colorLevels !== undefined
													? serie.colorLevels[0].color
													: ''
											}
											onChange={(e) => {
												let ser = { ...serie };
												ser.colorLevels[0].color = e.target.value;

												setSerie(ser);
											}}
										/>
										{/* Level */}
										<TextField
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											type="number"
											label={t(TransText.widgetGauge2.levelStart, null, lang, [
												'1',
											])}
											value={
												serie.colorLevels === undefined
													? 0
													: serie.colorLevels[0].level
											}
											onChange={(e) => {
												let ser = { ...serie };
												ser.colorLevels[0].level = Number.parseInt(
													e.target.value
												);

												setSerie(ser);
											}}
										/>
									</Box>
								</Box>
								{/* Level 2 */}
								<Box display="flex" flexWrap="wrap">
									<Box mb={2}>
										{/* Color */}
										<TextField
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											label={t(TransText.widgetGauge2.levelColor, null, lang, [
												'2',
											])}
											value={
												serie.colorLevels !== undefined
													? serie.colorLevels[1].color
													: ''
											}
											onChange={(e) => {
												let ser = { ...serie };
												ser.colorLevels[1].color = e.target.value;

												setSerie(ser);
											}}
										/>
										{/* Level */}
										<TextField
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											type="number"
											label={t(TransText.widgetGauge2.levelStart, null, lang, [
												'2',
											])}
											value={
												serie.colorLevels === undefined
													? 0
													: serie.colorLevels[1].level
											}
											onChange={(e) => {
												let ser = { ...serie };
												ser.colorLevels[1].level = Number.parseInt(
													e.target.value
												);

												setSerie(ser);
											}}
										/>
									</Box>
								</Box>
								{/* Level 3 */}
								<Box display="flex" flexWrap="wrap">
									<Box mb={2}>
										{/* Color */}
										<TextField
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											label={t(TransText.widgetGauge2.levelColor, null, lang, [
												'3',
											])}
											value={
												serie.colorLevels !== undefined
													? serie.colorLevels[2].color
													: ''
											}
											onChange={(e) => {
												let ser = { ...serie };
												ser.colorLevels[2].color = e.target.value;

												setSerie(ser);
											}}
										/>
										{/* Level */}
										<TextField
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											type="number"
											label={t(TransText.widgetGauge2.levelStart, null, lang, [
												'3',
											])}
											value={
												serie.colorLevels === undefined
													? 0
													: serie.colorLevels[2].level
											}
											onChange={(e) => {
												let ser = { ...serie };
												ser.colorLevels[2].level = Number.parseInt(
													e.target.value
												);

												setSerie(ser);
											}}
										/>
									</Box>
								</Box>
								{/* Level 4 */}
								<Box display="flex" flexWrap="wrap">
									<Box mb={2}>
										{/* Color */}
										<TextField
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											label={t(TransText.widgetGauge2.levelColor, null, lang, [
												'4',
											])}
											value={
												serie.colorLevels !== undefined
													? serie.colorLevels[3].color
													: ''
											}
											onChange={(e) => {
												let ser = { ...serie };
												ser.colorLevels[3].color = e.target.value;

												setSerie(ser);
											}}
										/>
										{/* Level */}
										<TextField
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											type="number"
											label={t(TransText.widgetGauge2.levelStart, null, lang, [
												'4',
											])}
											value={
												serie.colorLevels === undefined
													? 0
													: serie.colorLevels[3].level
											}
											onChange={(e) => {
												let ser = { ...serie };
												ser.colorLevels[3].level = Number.parseInt(
													e.target.value
												);

												setSerie(ser);
											}}
										/>
									</Box>
								</Box>
							</Box>
						) : (
							''
						)}

						{/* Select point */}
						<Box mt={4}>
							<Autocomplete
								size="small"
								options={points}
								getOptionLabel={(option) =>
									option.pointName + ' , ' + option.pointDesc
								}
								fullWidth
								renderInput={(params) => (
									<TextField
										{...params}
										label={t(TransText.widgetEdit.selectPoint, null, lang, [])}
										variant="outlined"
									/>
								)}
								value={serie.pointDTO}
								onChange={(e, value) => {
									if (value !== null) setSerie({ ...serie, pointDTO: value! });
								}}
							/>
						</Box>

						{/* Function param points */}
						<Box
							display="flex"
							justifyContent="flex-start"
							alignItems="center"
							flexWrap="wrap"
							m={2}
						>
							{t(TransText.widgetGauge2.functionParams, null, lang, [])}
							<Box
								m={6}
								onTouchStart={(e) => {
									e.stopPropagation();
									AddFuncParamSerie();
								}}
								onClick={(e) => {
									e.stopPropagation();
									AddFuncParamSerie();
								}}
							>
								<FontAwesomeIcon icon={faPlus} />
							</Box>
						</Box>
						<Box flexDirection="column" display="flex" m={2} mb={2}>
							<Box
								style={{ backgroundColor: 'blue' }}
								p={2}
								maxHeight={'300px'}
								overflow={'auto'}
							>
								{serie.valueCalcParamPointDTOs !== undefined
									? serie.valueCalcParamPointDTOs.map(
											(serie: ControlSerie, index: number) => {
												return (
													<Box
													// onClick={() => {
													// 	HandleSelectSerie(serie);
													// }}
													>
														<SerieItemControl
															ref={(ref) => {
																serieRefArray.current.set(index, ref);
															}}
															key={serie.id}
															controlSerie={serie}
															onEdit={() => {}}
														/>
													</Box>
												);
											}
									  )
									: ''}
							</Box>
						</Box>
						{/* Function convert value */}
						<Box m={2} mt={4}>
							<TextField
								fullWidth
								multiline
								rows={4}
								className={classes.textField}
								InputProps={{
									classes: {
										input: classes.smallSize,
									},
								}}
								InputLabelProps={{
									shrink: true,
								}}
								label={t(
									TransText.widgetGauge2.functionConvert,
									null,
									lang,
									[]
								)}
								value={serie.valueCalcExpresion}
								onChange={(e) => {
									let s = { ...serie };
									s.valueCalcExpresion = e.target.value;

									setSerie(s);
								}}
							/>
						</Box>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						size="small"
						variant="contained"
						disabled={serie.newWidget}
						onClick={() => props.onDelete()}
					>
						{t(TransText.app.remove, null, lang, [])}
					</Button>
					<Button
						size="small"
						variant="contained"
						onClick={() => props.onCancel()}
					>
						{t(TransText.app.cancel, null, lang, [])}
					</Button>
					<div className={classes.wrapper}>
						<Button
							disabled={serie.name.length === 0 || reloadSpinner}
							color="primary"
							size="small"
							variant="contained"
							onClick={() => {
								props.onUpdate(serie);
							}}
						>
							{t(TransText.app.ok, null, lang, [])}
						</Button>
						{reloadSpinner && (
							<CircularProgress size={24} className={classes.progress} />
						)}
					</div>
				</DialogActions>
			</Dialog>

			<Dialog
				onClose={() => {
					setOpenColor(false);
				}}
				open={openColor}
			>
				<DialogContent>
					<Box m={4}>
						{/* <TwitterPicker
							color={serie.color}
							onChange={onChangeColor}
							colors={[
								'#2f7ed8',
								'#0d233a',
								'#8bbc21',
								'#910000',
								'#1aadce',
								'#492970',
								'#f28f43',
								'#77a1e5',
								'#c42525',
								'#a6c96a',
							]}
						/> */}
					</Box>
					{editFuncParamSerie != null ? (
						<SerieControlDialog
							open={editFuncParamSerie != null ? true : false}
							controlSerie={editFuncParamSerie!}
							onCancel={() => {}} //onCancelEditSerie}
							onUpdate={() => {}} //onSaveEditSerie}
							onDelete={() => {}} //onDeleteEditSerie}
							showType={false}
							showLevels={false}
						/>
					) : (
						''
					)}
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
};
