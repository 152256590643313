import { PointDTO } from '../../../../../model/pointModel';

export interface IValueControlProps {
	serie: ControlSerie;
	value: any;
	selected: boolean;
	valueUpdate: (id: string, value: string) => any;
	controlSelected: (id: number) => any;
}

export interface ControlSerie {
	id: any;
	name: string;
	type: string;

	pointDTO: PointDTO | undefined;
	colorLevels: ControlColorLevel[];

	valueCalcExpresion: string;
	valueCalcParamPointDTOs: ControlSerie[];

	newWidget: boolean;

	top: number;
	left: number;
	width: number;
	height: number;

	value: any;

	controlRef: number;
}

export interface INameValueHash {
	[pointname: string]: string;
}

export interface ControlColorLevel {
	level: number;
	color: string;
}

export const DragItemTypes = {
	VALUE_CONTROL: 'valueControl',
};

export const ValueControlTypes = {
	Text: 'Text',
	Rectangle: 'Rectangle',
};
