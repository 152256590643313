import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { orange, red } from '@material-ui/core/colors';
export const RedButton = withStyles({
	root: {
		background: red[500],
		'&:hover': {
			backgroundColor: red[900],
			color: '#FFF',
		},
		color: 'white',
	},
})(Button);

export const OrangeButton = withStyles({
	root: {
		background: orange[500],
		'&:hover': {
			backgroundColor: orange[700],
			color: 'black',
		},
		color: 'black',
	},
})(Button);
