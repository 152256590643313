import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { IAppState } from '../redux/store';
import { CustomerDTO } from '../model/customerModel';
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from '@material-ui/core';
import Translation from '../services/translation';
import TransText from '../resource/transText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AdminAPI } from '../services/adminAPI';

const useStyles = makeStyles((theme) => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '25ch',
	},
	smallSize: {
		fontSize: 13,
		fontWeight: 400,
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	wrapper: {
		position: 'relative',
	},
}));

interface IProps {
	open: boolean;
	onConfirm: (customer: CustomerDTO) => any;
	onCancel: () => any;
}

export const SelectCustomerDialog: React.FC<IProps> = (props) => {
	const classes = useStyles();
	const { lang } = useSelector((state: IAppState) => state.app);
	const { t } = Translation;
	const [selectedCustomer, setSelectedCustomer] = React.useState<CustomerDTO>();
	const [customers, setCustomers] = React.useState<CustomerDTO[]>([]);
	const [spinner, setSpinner] = React.useState(false);

	useEffect(() => {
		let isMounted = true;

		if (isMounted) {
			let adminAPI = new AdminAPI();

			setSpinner(true);
			adminAPI
				.GetCustomers()
				.then((resp) => {
					if (resp.status >= 200 && resp.status < 300) {
						setCustomers(resp.data.customerDTOs);
					}
				})
				.catch((e) => {
					console.log(e.message);
				})
				.finally(() => {
					setSpinner(false);
				});
		}
		return () => {
			isMounted = false;
		};
	}, []);

	const onSelectCustomer = (event: object, value: any) => {
		setSelectedCustomer(value);
	};

	return (
		<React.Fragment>
			<Dialog fullWidth open={props.open}>
				<DialogTitle>
					{t(TransText.login.selectCustomerTitle, null, lang, [])}
				</DialogTitle>
				<DialogContent dividers>
					<Box display="flex" flexDirection="column">
						<Box mt={4}>
							<div className={classes.wrapper}>
								<Autocomplete
									disabled={customers.length === 0}
									size="small"
									options={customers}
									getOptionLabel={(option) => option.name}
									fullWidth
									renderInput={(params) => (
										<TextField
											{...params}
											label={t(TransText.login.selectCustomer, null, lang, [])}
											variant="outlined"
										/>
									)}
									onChange={onSelectCustomer}
								/>
								{spinner && (
									<CircularProgress
										size={24}
										className={classes.buttonProgress}
									/>
								)}
							</div>
						</Box>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						size="small"
						variant="contained"
						onClick={() => props.onCancel()}
					>
						{t(TransText.app.cancel, null, lang, [])}
					</Button>
					<Button
						disabled={!selectedCustomer}
						color="primary"
						size="small"
						variant="contained"
						onClick={() => props.onConfirm(selectedCustomer!)}
					>
						{t(TransText.app.ok, null, lang, [])}
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};
