import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { SerieSetting } from '../../../model/dashModel';
import { useSnackbar } from 'notistack';
import { SerieItem } from './serieItem';
import Translation from '../../../services/translation';
import TransText from '../../../resource/transText';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../redux/store';
import { SerieSettingDialog } from './serieSettingDialog';
import { CreateGuid } from '../../../services/misc';
import { getThemeProps } from '@material-ui/styles';

interface ISeriesProps {
	series: SerieSetting[];
	updateSerie: (serie: SerieSetting) => boolean;
	addSerie: (serie: SerieSetting) => boolean;
	removeSerie: (serie: SerieSetting) => boolean;

	showColor: boolean;
	showGraphicalType: boolean;
	showColorLevels: boolean;
	showFunction: boolean;
	maxNrOfSeries: number;
}
export const PointSeries = (props: ISeriesProps) => {
	const { lang, loggedOnUser } = useSelector((state: IAppState) => state.app);
	const { t } = Translation;

	const [selectedSerie, setSelectedSerie] = React.useState<SerieSetting>();

	const { enqueueSnackbar } = useSnackbar();
	const serieRefArray = React.useRef(new Map<number, any>());

	const AddNewSerie = () => {
		let date = new Date();
		let newSerie: SerieSetting = {
			id: CreateGuid(),
			name: '',
			pointDTO: undefined,
			valueCalcExpresion: '',
			valueCalcParams: [],
			valuePathExpression: '',
			graphicalType: '',

			color: '#000000',

			colorLevels: [
				{
					level: 0,
					color: '#999999',
				},
				{
					level: 25,
					color: '#194d33',
				},
				{
					level: 50,
					color: '#FDFF0D',
				},
				{
					level: 75,
					color: '#FF0000',
				},
			],

			top: 0,
			left: 0,
			width: 0,
			height: 0,

			value: '',

			controlRef: 0,
		};

		setSelectedSerie(newSerie);
	};

	return (
		<React.Fragment>
			<Box display="flex" flexDirection="column" minWidth={'100%'}>
				{/* Header */}
				{props.maxNrOfSeries == 0 ||
				props.maxNrOfSeries > props.series.length ? (
					<Box
						display="flex"
						justifyContent="flex-start"
						alignItems="center"
						m={2}
					>
						{t(TransText.series.addSerie, null, lang, [])}
						<Box
							m={6}
							onTouchStart={(e) => {
								e.stopPropagation();
								AddNewSerie();
							}}
							onClick={(e) => {
								e.stopPropagation();
								AddNewSerie();
							}}
						>
							<FontAwesomeIcon icon={faPlus} />
						</Box>
					</Box>
				) : (
					''
				)}
				{/* Series */}
				<Box
					style={{ backgroundColor: 'blue' }}
					p={2}
					maxHeight={'300px'}
					overflow={'auto'}
				>
					{props.series.map((serie: SerieSetting, index: number) => {
						return (
							<Box
								style={
									selectedSerie !== undefined && selectedSerie.id === serie.id
										? { backgroundColor: 'yellow' }
										: { backgroundColor: 'white' }
								}
							>
								<SerieItem
									ref={(ref) => {
										serieRefArray.current.set(index, ref);
									}}
									key={serie.id}
									serie={serie}
									onEdit={function (serie: SerieSetting) {
										setSelectedSerie(serie);
									}}
									showColor={props.showColor}
								/>
							</Box>
						);
					})}
				</Box>
			</Box>

			{selectedSerie ? (
				<SerieSettingDialog
					title={t(TransText.serieFunc.serieSettingsTitle, null, lang, [])}
					open={true}
					serie={selectedSerie}
					onOk={function (serie: SerieSetting) {
						let foundserie = props.series.find((x) => {
							return x.id === serie.id;
						});
						if (foundserie) {
							// Update serie
							if (props.updateSerie(serie)) {
								// let s = [...props.series];
								// let index = s.indexOf(foundserie);
								// s[index] = serie;

								setSelectedSerie(undefined);
							}
						} else {
							// New serie
							// let s = [...props.series];
							// s.push(serie);

							if (props.addSerie(serie)) setSelectedSerie(undefined);
						}
					}}
					onRemove={(serie: SerieSetting) => {
						let index = props.series.findIndex((x) => {
							return x.id === serie.id;
						});
						if (index > -1) {
							if (props.removeSerie(serie)) setSelectedSerie(undefined);
							// let s = [...props.series];
							// s.splice(index, 1);
						} else setSelectedSerie(undefined);
					}}
					onCancel={() => {
						setSelectedSerie(undefined);
					}}
					showGraphicalType={props.showGraphicalType}
					showColorLevels={props.showColorLevels}
					showColor={props.showColor}
					showFunction={props.showFunction}
				/>
			) : (
				''
			)}
		</React.Fragment>
	);
};
