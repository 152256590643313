import { Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { Ref, useEffect, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { ArtifactResponseWTO } from '../../../../model/customerModel';
import { TranslationErrorText } from '../../../../model/generelModel';
import {
	enPointState,
	PointDTO,
	PointHistoryResponseDTO,
	RefObj,
	WidgetInfo,
} from '../../../../model/pointModel';
import { IAppState } from '../../../../redux/store';
import TransText from '../../../../resource/transText';
import { AdminAPI } from '../../../../services/adminAPI';
import { PointAPI } from '../../../../services/pointAPI';
import Translation from '../../../../services/translation';
import { IWidgetProps } from '../../widgetTypes';
import { ControlSerie, ValueControlTypes } from './ValueControls/controlTypes';
import { ValueControl } from './ValueControls/valueControl';

export const WidgetDefaultOptions = {
	imageArtifactId: '',
	imageArtifactUptime: '1900-01-01',
	imageWidth: 300,
	imageHeight: 150,
	series: [],
};

export interface ImageArtifact {
	imageArtifactId: string;
	imageArtifactUptime: string;
}

export const WidgetImage1 = React.forwardRef(
	(props: IWidgetProps, ref: Ref<RefObj>) => {
		const { t } = Translation;
		const { lang, loggedOnUser } = useSelector((state: IAppState) => state.app);

		const [widgetInfo, setWidgetInfo] = React.useState<WidgetInfo>();
		const [image, setImage] = React.useState<string>();

		const [imageArtifact] = React.useState<ImageArtifact>({
			imageArtifactId: '',
			imageArtifactUptime: '',
		});

		const [controlSeries, setControlSeries] = React.useState<ControlSerie[]>(
			[]
		);

		useImperativeHandle(ref, () => ({ CleanWidgetTypeConfigFromPvs }));

		const CleanWidgetTypeConfigFromPvs = (): any => {
			let copyJson = JSON.stringify(props.widgetInfo);
			let copyObj: WidgetInfo = JSON.parse(copyJson);

			return copyObj;
		};

		const { enqueueSnackbar } = useSnackbar();

		// Called every change on props.widgetInfo
		useEffect(() => {
			let isMounted = true;
			let timer: any = null;
			let getHistoryDone = true;

			// At first render the widget updates widgetInfo.widgetTypeConfig
			// with default configuration, so we get a first unconfigured render
			if (props.widgetInfo.widgetTypeConfig === undefined) {
				let wi = { ...props.widgetInfo };
				wi.widgetTypeConfig = JSON.parse(JSON.stringify(WidgetDefaultOptions));
				props.widgetUpdateDefaultConfigUpdate(wi);
				return;
			}

			if (
				props.widgetInfo.widgetTypeConfig.imageArtifactId !== undefined &&
				props.widgetInfo.widgetTypeConfig.imageArtifactId.length > 0 &&
				(props.widgetInfo.widgetTypeConfig.imageArtifactId !==
					imageArtifact.imageArtifactId ||
					props.widgetInfo.widgetTypeConfig.imageArtifactUptime !==
						imageArtifact.imageArtifactUptime ||
					image === undefined)
			) {
				GetImageArtifact(props.widgetInfo.widgetTypeConfig.imageArtifactId);
			}

			let wi = { ...props.widgetInfo };
			setWidgetInfo(wi);

			setControlSeries([]);

			if (isMounted && !props.widgetInfo.refresh?.subscription) {
				if (props.widgetInfo.points.length > 0) {
					getPointsHistory(props.widgetInfo.points);

					if (timer == null) {
						timer = setInterval(
							() => {
								if (isMounted) {
									if (getHistoryDone) {
										getHistoryDone = false;
										getPointsHistory(props.widgetInfo.points)?.then(() => {
											getHistoryDone = true;
										});
									}
								}
							},
							props.widgetInfo.refresh === undefined
								? 30000
								: props.widgetInfo.refresh.refresh * 1000
						);
					}
				}
			}

			return () => {
				clearInterval(timer);
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [props.widgetInfo]);

		const getPointsHistory = (pointDTOs: PointDTO[]) => {
			if (!pointDTOs) return;

			let pointAPI: PointAPI = new PointAPI();
			return pointAPI
				.GetPointsHistory({
					customerId: loggedOnUser?.tenant == null ? '' : loggedOnUser.tenant,
					pointids: pointDTOs.map((x) => x.pointID),
					fromDateTime: '',
					toDateTime: '',
					groupByIntervalUnit: '',
					groupByInterval: 0,
					maxRowsToReturn: 0,
					onlyReturnCount: false,
					latestValue: true,
				})
				.then((resp) => {
					if (resp.status >= 200 && resp.status < 300) {
						let pointHistoryResp: PointHistoryResponseDTO;

						pointHistoryResp = resp.data;

						if (
							pointHistoryResp.pointHistory &&
							pointHistoryResp.pointHistory.length > 0
						) {
							for (let c = 0; c < pointHistoryResp.pointHistory.length; c++) {
								// Each points

								let series: ControlSerie[] =
									props.widgetInfo.widgetTypeConfig.series.filter(
										(x: ControlSerie) => {
											return (
												x.pointDTO?.pointID ===
												pointHistoryResp.pointHistory[c].pointId
											);
										}
									);

								series.map((serie) => {
									let samp = pointHistoryResp.pointHistory[c].points[0];

									// Convert to correct type
									if (serie.type === ValueControlTypes.Rectangle) {
										let value: number | null = null;
										value = parseInt(samp.value);

										if (
											samp.state !== enPointState.NULL &&
											samp.state === enPointState.OK
										) {
											serie.value = value;
										} else {
											// TODO: Handle not Ok state
										}
									} else if (serie.type === ValueControlTypes.Text) {
										if (
											samp.state !== enPointState.NULL &&
											samp.state === enPointState.OK
										) {
											serie.value = samp.value;
										} else {
											// TODO: Handle not Ok state
											serie.value = '';
										}
									}

									let index = controlSeries.findIndex((x: ControlSerie) => {
										return x.id === serie.id;
									});
									if (index >= 0) {
										setControlSeries((controlSeries) => [
											...controlSeries.slice(0, index),
											serie,
											...controlSeries.slice(index + 1),
										]);
									} else {
										setControlSeries((controlSeries) => [
											...controlSeries,
											serie,
										]);
									}
								});
							}
						}
					}
				})
				.catch((e) => {
					console.log(e);
				})
				.finally(() => {});
		};

		const GetImageArtifact = (artifcatId: string) => {
			let adminAPI: AdminAPI = new AdminAPI();
			adminAPI
				.GetCustomerArtifact(
					loggedOnUser?.tenant ? loggedOnUser?.tenant : '',
					artifcatId
				)
				.then((resp) => {
					if (resp.status >= 200 && resp.status < 300) {
						var artifactResponse: ArtifactResponseWTO = resp.data;

						setImage(artifactResponse.artifactWTOs[0].imreBase64Data);
					}
				})
				.catch((e) => {
					let translationErrorText: TranslationErrorText = {
						ErrorCode: 'SERVER_ERROR',
						Parameters: [],
					};

					if (
						e.response.data !== undefined &&
						typeof e.response.data !== 'string'
					) {
						translationErrorText = e.response.data;
					}
					enqueueSnackbar(
						t(
							TransText.error[
								translationErrorText.ErrorCode as keyof typeof TransText.error
							],
							null,
							lang,
							translationErrorText.Parameters
						),
						{
							variant: 'error',
						}
					);
				});
		};

		return (
			<React.Fragment>
				{widgetInfo !== undefined ? (
					<Box display="flex" justifyContent="center" ml={4}>
						<div style={{ position: 'relative', overflow: 'auto' }}>
							<img
								alt={'PoitImage'}
								src={image}
								width={widgetInfo.widgetTypeConfig.imageWidth}
								height={widgetInfo.widgetTypeConfig.imageHeight}
							/>
							{widgetInfo.widgetTypeConfig.series.map((serie: ControlSerie) => (
								<ValueControl
									key={serie.id}
									serie={serie}
									value={serie.value}
									selected={false}
									valueUpdate={() => {}}
									controlSelected={() => {}}
								/>
							))}
						</div>
					</Box>
				) : (
					''
				)}
			</React.Fragment>
		);
	}
);
