export interface User {
	userId: string;
	userName: string;
	email: string;
}

export interface LoggedOnUser {
	user: User;
	accessToken: string;
	refreshToken: string;
	roles: string[];
	tenant: string;
	tenantName: string;
}

export enum EnumRoleTypes {
	SUPER = 'SUPER',
	SUPPORT = 'SUPPORT',
	CUST_ADMIN = 'CUST_ADMIN',
	CUST_VIEW = 'CUST_VIEW',
	CUST_CONTRIB = 'CUST_CONTRIB',
	NANO = 'NANO',
}

export enum EnumConstant {
	LOGGEDONUSER = 'LOGGEDONUSER',
}
