import { PointDTO } from '../model/pointModel';

export interface LineSerie {
	name: string;
	color: string;
	pointDTO: PointDTO | null;
	data: any[];
	marker: { enabled: false };
	lineWidth: 1;
	boostThreshold: 1;
	turboThreshold: 0;
}

export interface Table1Column {
	field: string;
	headerName: string;
	width: number;
}

export interface SerieSetting {
	id: any;
	name: string; // If empty set to pointDTO.Name
	pointDTO: PointDTO | undefined;

	valuePathExpression: string;
	valueCalcExpresion: string;
	valueCalcParams: SerieSetting[];

	graphicalType: string;

	color: string;
	colorLevels: ColorLevel[];

	top: number;
	left: number;
	width: number;
	height: number;

	value: any;

	controlRef: number;
}

export interface ColorLevel {
	level: number;
	color: string;
}

export const GraphicalTypes = {
	Text: 'Text',
	Rectangle: 'Rectangle',
};
