import {
	Box,
	CircularProgress,
	createStyles,
	CssBaseline,
	Fab,
	IconButton,
	makeStyles,
	Paper,
	Theme,
	Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from '../redux/store';
import Translation from '../services/translation';
import TransText from '../resource/transText';

import { NavBar } from '../components/navBar';
import {
	CustomerSiteNanoPcResponse,
	enNanoStateCodes,
	NanoConfig,
} from '../model/pointModel';
import { AdminAPI } from '../services/adminAPI';
import { useHistory } from 'react-router';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { useSnackbar } from 'notistack';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheckCircle,
	faExclamationCircle,
	faPen,
	faPlus,
	faStopCircle,
	faSync,
} from '@fortawesome/free-solid-svg-icons';
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexFlow: 'column',
			height: '100vh',
		},
		paper: {
			flex: '1 1 auto',
			margin: '12px',
		},
		wrapperRel: {
			position: 'relative',
		},
		buttonProgress: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
		},
	})
);

export const Nanos: React.FC = () => {
	const { lang, loggedOnUser } = useSelector((state: IAppState) => state.app);
	const { t } = Translation;
	const classes = useStyles();
	const [nanos, setNanos] = React.useState<NanoConfig[]>([]);
	const [progress, setProgress] = React.useState<boolean>(false);
	const [reloadToggle, setReloadToogle] = React.useState<boolean>(false);
	const history = useHistory();
	const adminAPI = new AdminAPI();
	const { enqueueSnackbar } = useSnackbar();

	const columns: any[] = [
		{
			field: 'edit',
			headerName: t(TransText.app.edit, null, lang, []),
			sortable: false,
			width: 80,
			disableClickEventBubbling: true,
			renderCell: (params: any) => {
				const onClick = () => {
					history.push({
						pathname: '/nano/' + params.id,
					});
				};
				return (
					<IconButton size="small" color={'primary'} onClick={onClick}>
						<FontAwesomeIcon icon={faPen} />
					</IconButton>
				);
			},
		},
		{
			field: 'state',
			headerName: t(TransText.app.state, null, lang, []),
			sortable: false,
			width: 90,
			disableClickEventBubbling: true,
			renderCell: (params: any) => {
				if (
					params.row.nanoState === enNanoStateCodes.NANO_UN_INITIALIZED ||
					params.row.nanoState === enNanoStateCodes.NANO_FAILED ||
					params.row.nanoState === enNanoStateCodes.NANO_NOT_ACTIVE
				) {
					return (
						<FontAwesomeIcon style={{ color: 'red' }} icon={faStopCircle} />
					);
				} else if (
					params.row.nanoState === enNanoStateCodes.NANO_CONFIGURED ||
					params.row.nanoState === enNanoStateCodes.NANO_BOOTING_PC ||
					params.row.nanoState === enNanoStateCodes.NANO_OPEN_SERVERREQUEST
				) {
					return (
						<FontAwesomeIcon
							style={{ color: 'orange' }}
							icon={faExclamationCircle}
						/>
					);
				} else {
					return (
						<FontAwesomeIcon style={{ color: 'green' }} icon={faCheckCircle} />
					);
				}
			},
		},
		{
			field: 'Id',
			headerName: 'Id',
			width: 50,
			hide: true,
		},
		{
			field: 'name',
			headerName: t(TransText.app.name, null, lang, []),
			width: 180,
		},
		{
			field: 'description',
			headerName: t(TransText.app.description, null, lang, []),
			width: 180,
		},
	];

	useEffect(() => {
		let isMounted = true;
		setProgress(true);
		adminAPI
			.GetCustomerSiteNanoAndPc(
				loggedOnUser?.tenant === undefined ? '' : loggedOnUser?.tenant
			)
			.then((response) => {
				if (isMounted) {
					if (response.status >= 200 && response.status < 300) {
						let customerSiteNanoPcResponse: CustomerSiteNanoPcResponse =
							response.data;
						if (
							customerSiteNanoPcResponse.result === null ||
							customerSiteNanoPcResponse.result === ''
						) {
							setNanos(customerSiteNanoPcResponse.nanoConfigs);
						} else {
							enqueueSnackbar(
								t(
									TransText.error,
									response.data.result + '_CUSTOMER',
									lang,
									[]
								),
								{
									variant: 'error',
								}
							);
						}
					}
				}
			})
			.catch((ex) => {
				if (isMounted) {
					enqueueSnackbar(ex.message, {
						variant: 'error',
					});
				}
			})
			.finally(() => {
				if (isMounted) {
					setProgress(false);
				}
			});

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadToggle]);

	return (
		<React.Fragment>
			<CssBaseline />

			<div className={classes.root}>
				<NavBar />
				<Paper className={classes.paper}>
					{/* Title */}
					<Box display="flex" justifyContent="space-between" m={2}>
						<Box m={2} display="flex" flexDirection="row">
							<Fab size="small" color="primary" aria-label="add">
								<FontAwesomeIcon icon={faPlus} />
							</Fab>
							<Box ml={2}>
								<div className={classes.wrapperRel}>
									<Fab
										size="small"
										color="secondary"
										aria-label="add"
										disabled={progress}
										onClick={() => {
											setReloadToogle(!reloadToggle);
										}}
									>
										<FontAwesomeIcon icon={faSync} />
									</Fab>
									{progress && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</div>
							</Box>
						</Box>

						<Box>
							<Typography variant="h5" noWrap>
								{t(TransText.nanosList.headTitle, null, lang, [])}
							</Typography>
						</Box>
						<Box width="80px"></Box>
					</Box>

					<Box
						p={4}
						style={{
							height: `calc(100% - (60px))`,
							minHeight: '300px',
							overflow: 'auto',
						}}
					>
						<DataGridPro
							rows={nanos}
							columns={columns}
							density="compact"
							disableMultipleSelection={true}
						/>
					</Box>
				</Paper>
			</div>
		</React.Fragment>
	);
};
