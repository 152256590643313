import {
	Box,
	CssBaseline,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	makeStyles,
	Menu,
	MenuItem,
	Paper,
	Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import '../../node_modules/react-grid-layout/css/styles.css';
import { Widget } from '../components/dashboard/widget';
import Highcharts from 'highcharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faHome } from '@fortawesome/free-solid-svg-icons';
import Translation from '../services/translation';
import TransText from '../resource/transText';
import { IAppState } from '../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { RefObj, WidgetInfo } from '../model/pointModel';
import { DashBoardDTO, DashBoardResponse } from '../model/customerModel';
import axios from 'axios';
import { SetLoggedOnUser } from '../redux/actions';
import { LoggedOnUser } from '../model/userModel';
import { AdminAPI } from '../services/adminAPI';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	paper: {
		width: '100%',
		height: '100%',

		backgroundColor: 'white',
	},
	layout: {
		backgroundColor: '#e0e0e0',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaper: {
		width: drawerWidth,
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(28) + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(28) + 1,
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(1),
	},
	appBarDialog: {
		position: 'relative',
	},
	titleDialog: {
		marginLeft: theme.spacing(2),
		flexGrow: 1,
	},
	wrapper: {
		position: 'relative',
	},
	progress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}));

const ResponsiveGridLayout = WidthProvider(Responsive);

export const PublicDash: React.FC = () => {
	const dispatch = useDispatch();

	const history = useHistory();
	const { baseUrl, lang } = useSelector((state: IAppState) => state.app);
	const classes = useStyles();
	const { t } = Translation;
	const [open, setOpen] = React.useState(false);
	const [edit] = React.useState<boolean>(false);
	const [widgets, setWidgets] = React.useState<WidgetInfo[]>([]);
	const [layout, setLayout] = React.useState<any>({
		lg: [],
		md: [],
		sm: [],
	});
	const widgetRefs = React.useRef<RefObj[]>([]);
	const [dashBoard, setDashBoard] = React.useState<DashBoardDTO>();
	const [error, setError] = React.useState<string>('');
	const [getDashBoardsSpinner, setGetDashBoardsSpinner] =
		React.useState<boolean>(false);

	const [apiKeyUser, setApiKeyUser] = React.useState<LoggedOnUser>();

	let params: any = useParams();
	useEffect(() => {
		let IsMounted = true;

		onResize(null);

		if (dashBoard === undefined) {
			let apiKey = params.apiKey !== undefined ? params.apiKey : '';
			logonWithApiKey(apiKey)
				.then((res) => {
					if (IsMounted) {
						let user: LoggedOnUser = res.data;

						setApiKeyUser(res.data);
						dispatch(SetLoggedOnUser(res.data));

						let dashBoardId =
							params.dashBoardId !== undefined ? params.dashBoardId : '';
						loadDashBoard(user, dashBoardId)
							.then((resp) => {
								if (resp.status >= 200 && resp.status < 300) {
									let dashBoardResponse: DashBoardResponse;
									dashBoardResponse = resp.data;

									if (
										dashBoardResponse.result === '' &&
										dashBoardResponse.dashBoardDTOs.length > 0
									) {
										setDashBoard(dashBoardResponse.dashBoardDTOs[0]);
										activateSelectedDash(dashBoardResponse.dashBoardDTOs[0]);

										onResize(null);
									}
								}
							})
							.catch((e) => {
								setError(
									'Load failed of dashBoard ' + dashBoardId + ' :' + e.message
								);
							})
							.finally(() => {});
					}
				})
				.catch((e) => {
					setError('Logon failed with apiKey ' + apiKey);
				});
		}

		return () => {
			IsMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params]);

	const logonWithApiKey = (apiKey: string) => {
		let data = JSON.stringify({
			Username: apiKey,
			Password: '',
			AppCode: process.env.REACT_APP_APPCODE,
		});

		return axios.post(baseUrl + 'Authenticate/Login', data, {
			headers: {
				'Content-Type': 'application/json',
			},
		});
	};

	const loadDashBoard = (apiKeyUser: LoggedOnUser, dashBoardId: string) => {
		return axios.get(
			baseUrl +
				'admin/GetCustomerDashBoard?customerId=' +
				apiKeyUser.tenant +
				'&dashBoardId=' +
				dashBoardId,
			{
				headers: { Authorization: `Bearer ${apiKeyUser?.accessToken}` },
			}
		);
	};

	const activateSelectedDash = (dashBoard: DashBoardDTO) => {
		let wi = JSON.parse(dashBoard.widgetJson);
		if (wi.length > 0) if (wi[0] == null) wi = [];

		let c = 0;
		wi.map((x: WidgetInfo) => {
			x.widgetRefIndex = c;
			c++;
			return x;
		});

		setWidgets(wi);
		setLayout(JSON.parse(dashBoard.layoutJson));
		setDashBoard(dashBoard);
	};

	const handleDrawerCloseOpen = () => {
		setOpen(!open);
	};

	const onResize = (e: any) => {
		for (var i = 0; i < Highcharts.charts.length; i++) {
			Highcharts.charts[i]?.reflow();
		}
	};

	const onLayoutChange = (currentLayout: Layout[], allLayouts: Layouts) => {
		setLayout(allLayouts);
	};

	const handleWidgetUpdateDefaultConfig = (widgetInfo: WidgetInfo) => {};
	const handleWidgetError = (widgetInfo: WidgetInfo) => {};

	const errorBlock = () => {
		return (
			<Box display="flex" justifyContent="Center">
				<Typography variant="h3">
					<Box fontSize="36px" fontWeight="bold">
						{error}
					</Box>
				</Typography>
			</Box>
		);
	};

	const [dashBoards, setDashBoards] = React.useState<DashBoardDTO[]>([]);
	const [selectDashMenuAncorEl, setSelectDashMenuAncorEl] =
		React.useState<null | HTMLElement>(null);
	const handleSelectDash = async (event: any) => {
		getDashBoards();
		setSelectDashMenuAncorEl(event.target);
	};

	const getDashBoards = () => {
		setGetDashBoardsSpinner(true);

		let API: AdminAPI = new AdminAPI();
		API.GetCustomerDashBoards(
			apiKeyUser?.tenant == null ? '' : apiKeyUser.tenant
		)
			.then((resp) => {
				if (resp.status >= 200 && resp.status < 300) {
					let dashBoardResponse: DashBoardResponse;
					dashBoardResponse = resp.data;

					if (
						dashBoardResponse.result === '' &&
						dashBoardResponse.dashBoardDTOs.length > 0
					) {
						setDashBoards(dashBoardResponse.dashBoardDTOs);
					}
				}
			})
			.catch(() => {})
			.finally(() => {
				setGetDashBoardsSpinner(false);
			});
	};

	const getDashBoard = (dashBoardId: string) => {
		setGetDashBoardsSpinner(true);

		let API: AdminAPI = new AdminAPI();
		API.GetCustomerDashBoard(
			apiKeyUser?.tenant == null ? '' : apiKeyUser.tenant,
			dashBoardId
		)
			.then((resp) => {
				if (resp.status >= 200 && resp.status < 300) {
					let dashBoardResponse: DashBoardResponse;
					dashBoardResponse = resp.data;

					if (
						dashBoardResponse.result === '' &&
						dashBoardResponse.dashBoardDTOs.length > 0
					) {
						activateSelectedDash(dashBoardResponse.dashBoardDTOs[0]);
					}
				}
			})
			.catch(() => {})
			.finally(() => {
				setGetDashBoardsSpinner(false);
			});
	};
	return (
		<React.Fragment>
			<div className={classes.root}>
				<CssBaseline />
				{apiKeyUser !== undefined && !apiKeyUser.roles.includes('EXT_VIEW') ? (
					<Drawer
						variant="permanent"
						className={clsx(classes.drawer, {
							[classes.drawerOpen]: open,
							[classes.drawerClose]: !open,
						})}
						classes={{
							paper: clsx({
								[classes.drawerOpen]: open,
								[classes.drawerClose]: !open,
							}),
						}}
					>
						<div className={classes.toolbar}>
							<IconButton onClick={handleDrawerCloseOpen}>
								{!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
							</IconButton>
						</div>

						<Divider />

						<List>
							{!edit ? (
								<div>
									{apiKeyUser !== undefined &&
									apiKeyUser.roles.includes('CUST_VIEW') ? (
										<ListItem
											button
											key="SelectDash"
											disabled={getDashBoardsSpinner}
											aria-controls="simple-menu"
											aria-haspopup="true"
											onClick={handleSelectDash}
										>
											<ListItemIcon>
												<FontAwesomeIcon icon={faFolderOpen} />
											</ListItemIcon>
											<ListItemText
												primary={t(TransText.dash.selectDash, null, lang, [])}
											/>
										</ListItem>
									) : (
										''
									)}
									<Menu
										id="simple-menu"
										anchorEl={selectDashMenuAncorEl}
										keepMounted
										open={Boolean(selectDashMenuAncorEl)}
										onClose={() => {
											setSelectDashMenuAncorEl(null);
										}}
									>
										{dashBoards.map((dash, key) => {
											return (
												<MenuItem
													key={key}
													onClick={() => {
														getDashBoard(dash.id);
													}}
												>
													{dash.name}
												</MenuItem>
											);
										})}
									</Menu>
								</div>
							) : (
								''
							)}
						</List>
					</Drawer>
				) : (
					''
				)}
				<main className={classes.content}>
					<Box display="flex" justifyContent="center">
						<Typography variant="h6">
							{apiKeyUser?.tenantName} {' ' + dashBoard?.name}
						</Typography>
					</Box>

					{error !== '' ? errorBlock() : ''}

					<ResponsiveGridLayout
						isDraggable={edit === true} // Readonly
						isResizable={edit === true} // Readonly
						layouts={layout}
						breakpoints={{ lg: 1200, md: 900, sm: 600, xs: 480 }}
						cols={{ lg: 120, md: 30, sm: 30, xs: 10 }}
						rowHeight={10}
						resizeHandles={['se', 'e', 's']}
						onLayoutChange={onLayoutChange}
					>
						{widgets.map((widgetInfo) => {
							return (
								<div
									key={widgetInfo.i}
									data-grid={widgetInfo}
									className={classes.layout}
								>
									<Paper elevation={4} className={classes.paper}>
										<Box
											display="flex"
											flexDirection="column"
											style={{ height: '100%', width: '100%' }}
										>
											<Box display="flex" justifyContent="space-between">
												<Box>{''}</Box>

												<Box>
													<Typography variant="h6">
														<Box fontSize="16px">{widgetInfo.title}</Box>
													</Typography>
												</Box>

												<Box>{''}</Box>
											</Box>

											<Widget
												widgetUpdateDefaultConfigUpdate={
													handleWidgetUpdateDefaultConfig
												}
												widgetError={handleWidgetError}
												widgetInfo={widgetInfo}
												ref={(ref: any) => {
													if (ref != null)
														widgetRefs.current[widgetInfo.widgetRefIndex] = ref;
												}}
											/>
										</Box>
									</Paper>
								</div>
							);
						})}
					</ResponsiveGridLayout>
				</main>
			</div>
		</React.Fragment>
	);
};
