import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { IAppState } from '../../../../redux/store';
import { PointDTO } from '../../../../model/pointModel';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from '@material-ui/core';
import Translation from '../../../../services/translation';
import TransText from '../../../../resource/transText';
import { PointAPI } from '../../../../services/pointAPI';
import { Table1Column } from '../../../../model/dashModel';

const useStyles = makeStyles((theme) => ({
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '25ch',
	},
	smallSize: {
		fontSize: 13,
		fontWeight: 400,
	},
}));

interface IProps {
	open: boolean;
	column: Table1Column;
	onCancel: () => any;
	onUpdate: (column: Table1Column) => any;
}

export const ColumnDialogTable1: React.FC<IProps> = (props) => {
	const classes = useStyles();
	const { lang, loggedOnUser } = useSelector((state: IAppState) => state.app);
	const { t } = Translation;

	const [column, setColumn] = React.useState<Table1Column>(props.column);
	const [points, setPoints] = React.useState<PointDTO[]>([]);

	useEffect(() => {
		let isMounted = true;

		if (isMounted) {
			if (points.length === 0) {
				let pointAPi = new PointAPI();

				pointAPi
					.GetPoints(loggedOnUser?.tenant!, '', 50)
					.then((resp) => {
						if (resp.status >= 200 && resp.status < 300) {
						}
						setPoints(resp.data.pointDTOs);
					})
					.catch((e) => {
						console.log(e.message);
					});
			}
		}
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<React.Fragment>
			<Dialog fullWidth open={props.open}>
				<DialogTitle>
					{t(TransText.columnDialog_table_1.title, null, lang, [])}{' '}
					{t(TransText.columnDialog_table_1.field, null, lang, [])}
					{':'}
					{column.field}
				</DialogTitle>
				<DialogContent dividers>
					<Box display="flex" flexDirection="row">
						<TextField
							className={classes.textField}
							InputProps={{
								classes: {
									input: classes.smallSize,
								},
							}}
							InputLabelProps={{
								shrink: true,
							}}
							margin="dense"
							size="small"
							label={t(TransText.app.name, null, lang, [])}
							value={column.headerName}
							onChange={(e) => {
								setColumn({ ...column, headerName: e.target.value });
							}}
						/>
						<TextField
							type="number"
							margin="dense"
							size="small"
							label={t(TransText.app.columnWidth, null, lang, [])}
							value={column.width !== undefined ? column.width : 120}
							onChange={(e) => {
								setColumn({ ...column, width: parseInt(e.target.value) });
							}}
							InputProps={{
								classes: {
									input: classes.smallSize,
								},
							}}
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					{/* <Button
						size="small"
						variant="contained"
						onClick={() => props.onDelete()}
					>
						{t(TransText.app.remove, null, lang, [])}
					</Button> */}
					<Button
						size="small"
						variant="contained"
						onClick={() => props.onCancel()}
					>
						{t(TransText.app.cancel, null, lang, [])}
					</Button>
					<Button
						disabled={
							column.headerName.length === 0 || column.field.length === 0
						}
						color="primary"
						size="small"
						variant="contained"
						onClick={() => {
							props.onUpdate(column);
						}}
					>
						{t(TransText.app.ok, null, lang, [])}
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
};
