import React, { CSSProperties } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { SerieSetting } from '../../../model/dashModel';

interface IProps {
	serie: SerieSetting;
	onEdit: (serie: SerieSetting) => any;
	showColor: boolean;
}

export const SerieItem = React.forwardRef((props: IProps, ref: any) => {
	const editWidget = () => {
		props.onEdit(props.serie);
	};

	const getButtonStyle = (): CSSProperties => {
		return { backgroundColor: props.serie.color };
	};

	return (
		<React.Fragment>
			<div ref={ref}>
				<Box display="flex" flexDirection="column">
					<Box display="flex" alignItems="start">
						<Box
							ml={4}
							mr={4}
							onTouchStart={(e) => {
								e.stopPropagation();
								editWidget();
							}}
							onClick={(e) => {
								e.stopPropagation();
								editWidget();
							}}
						>
							{true ? <FontAwesomeIcon icon={faEdit} /> : ''}
						</Box>

						<Typography variant="h6">
							<Box fontSize="16px">{props.serie.name}</Box>
						</Typography>
						{props.showColor ? (
							<Box ml={2} mr={2}>
								<Button
									disabled={true}
									style={getButtonStyle()}
									size="small"
									variant="contained"
								></Button>
							</Box>
						) : (
							''
						)}
					</Box>
					<Typography variant="h6">
						<Box ml={3} fontSize="14px">
							{props.serie == null || props.serie.pointDTO == null
								? ''
								: props.serie.pointDTO.pointName}
						</Box>
					</Typography>
				</Box>
			</div>
		</React.Fragment>
	);
});
