import axios from 'axios';
import { CustomerDTO, DashBoardDTO } from '../model/customerModel';
import {
	CustomerPcPointsWTO,
	CustomerPcUpdateReq,
	NanoConfig,
} from '../model/pointModel';
import { LoggedOnUser } from '../model/userModel';
import { store } from '../redux/store';

export class AdminAPI {
	baseUrl: string;
	loggedOnUser: LoggedOnUser | null;

	constructor() {
		const state = store.getState();
		this.baseUrl = state.app.baseUrl;
		this.loggedOnUser = state.app.loggedOnUser;
	}

	public GetCustomer = async (customerId: string) => {
		return await axios.get(
			this.baseUrl + 'admin/GetCustomer?customerId=' + customerId,
			{
				headers: { Authorization: `Bearer ${this.loggedOnUser?.accessToken}` },
			}
		);
	};

	public GetCustomers = async () => {
		return await axios.get(this.baseUrl + 'admin/GetCustomers', {
			headers: { Authorization: `Bearer ${this.loggedOnUser?.accessToken}` },
		});
	};

	public UpdateCustomer = async (customerDTO: CustomerDTO) => {
		return await axios.post(
			this.baseUrl + 'admin/UpdateCustomer',
			customerDTO,
			{
				headers: { Authorization: `Bearer ${this.loggedOnUser?.accessToken}` },
			}
		);
	};

	public GetCustomerDashBoards = async (customerId: string) => {
		return await axios.get(
			this.baseUrl + 'admin/GetCustomerDashBoardList?customerId=' + customerId,
			{
				headers: { Authorization: `Bearer ${this.loggedOnUser?.accessToken}` },
			}
		);
	};

	public GetCustomerDashBoard = async (
		customerId: string,
		dashBoardId: string
	) => {
		return await axios.get(
			this.baseUrl +
				'admin/GetCustomerDashBoard?customerId=' +
				customerId +
				'&dashBoardId=' +
				dashBoardId,
			{
				headers: { Authorization: `Bearer ${this.loggedOnUser?.accessToken}` },
			}
		);
	};

	public UpdateCustomerDashBoard = async (dashBoardDTO: DashBoardDTO) => {
		return await axios.post(
			this.baseUrl + 'admin/UpdateCustomerDashBoard',
			dashBoardDTO,
			{
				headers: { Authorization: `Bearer ${this.loggedOnUser?.accessToken}` },
			}
		);
	};

	public DeleteCustomerDashBoard = async (dashBoardDTO: DashBoardDTO) => {
		return await axios.post(
			this.baseUrl + 'admin/DeleteCustomerDashBoard',
			dashBoardDTO,
			{
				headers: { Authorization: `Bearer ${this.loggedOnUser?.accessToken}` },
			}
		);
	};

	public UploadArtifactFile = async (
		tenantId: string,
		artifactId: string,
		name: string,
		type: string,
		file: any,
		fileName: string
	) => {
		const formData = new FormData();
		formData.append('tenantId', tenantId);
		formData.append('artifactId', artifactId);
		formData.append('name', name);
		formData.append('type', type);
		formData.append('fileName', fileName);
		formData.append('formFile', file);
		return await axios.post(
			this.baseUrl + 'admin/UploadArtifactFile',
			formData,
			{
				headers: { Authorization: `Bearer ${this.loggedOnUser?.accessToken}` },
			}
		);
	};

	public GetPreviewForPointCsvFile = async (
		tenantId: string,
		pcId: string,
		file: any,
		fileName: string
	) => {
		const formData = new FormData();
		formData.append('tenantId', tenantId);
		formData.append('pcId', pcId);
		formData.append('fileName', fileName);
		formData.append('formFile', file);
		return await axios.post(
			this.baseUrl + 'admin/PreviewForPointCsvFile',
			formData,
			{
				headers: { Authorization: `Bearer ${this.loggedOnUser?.accessToken}` },
			}
		);
	};

	public GetCustomerArtifact = async (
		customerId: string,
		artifactId: string
	) => {
		return await axios.get(
			this.baseUrl +
				'admin/GetCustomerArtifact?customerId=' +
				customerId +
				'&artifactId=' +
				artifactId,
			{
				headers: { Authorization: `Bearer ${this.loggedOnUser?.accessToken}` },
			}
		);
	};

	public GetCustomerSiteNanoAndPc = async (customerId: string) => {
		return await axios.get(
			this.baseUrl + 'admin/GetCustomerSiteNanoAndPc?customerId=' + customerId,
			{
				headers: { Authorization: `Bearer ${this.loggedOnUser?.accessToken}` },
			}
		);
	};

	public GetCustomerNanoAndPc = async (customerId: string, nanoId: string) => {
		return await axios.get(
			this.baseUrl +
				'admin/GetCustomerNanoPc?customerId=' +
				customerId +
				'&nanoId=' +
				nanoId,
			{
				headers: { Authorization: `Bearer ${this.loggedOnUser?.accessToken}` },
			}
		);
	};

	public GetCustomerPcAndPoints = async (customerId: string, pcId: string) => {
		return await axios.get(
			this.baseUrl +
				'admin/GetCustomerPcPoint?customerId=' +
				customerId +
				'&pcId=' +
				pcId,
			{
				headers: { Authorization: `Bearer ${this.loggedOnUser?.accessToken}` },
			}
		);
	};

	public UpdateCustomerNano = async (nanoConfig: NanoConfig) => {
		return await axios.post(
			this.baseUrl + 'admin/UpdateCustomerNano',
			nanoConfig,
			{
				headers: { Authorization: `Bearer ${this.loggedOnUser?.accessToken}` },
			}
		);
	};

	public UpdateCustomerPc = async (
		customerPcUpdateReq: CustomerPcUpdateReq
	) => {
		return await axios.post(
			this.baseUrl + 'admin/UpdateCustomerPc',
			customerPcUpdateReq,
			{
				headers: { Authorization: `Bearer ${this.loggedOnUser?.accessToken}` },
			}
		);
	};

	public UpdateCustomerPcPoints = async (dto: CustomerPcPointsWTO) => {
		return await axios.post(
			this.baseUrl + 'admin/UpdateCustomerPcPoints',
			dto,
			{
				headers: { Authorization: `Bearer ${this.loggedOnUser?.accessToken}` },
			}
		);
	};
}
