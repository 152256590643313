import React, { Ref, useEffect, useImperativeHandle } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
	enPointState,
	enPointValueType,
	PointDTO,
	PointHistoryResponseDTO,
	RefObj,
	WidgetInfo,
} from '../../../../model/pointModel';
import { IAppState } from '../../../../redux/store';
import { PointAPI } from '../../../../services/pointAPI';
import { IWidgetProps } from '../../widgetTypes';

export const WidgetDefaultOptions = {
	text: 'Any text kan be set here {0} this is value from point',
	textColor: '#000000',
	textSize: '2',

	value: 0.0,
	levelColors: ['#ff1a1a', '#f5e942', '#8cff1a', '#1aff1a'],
	levelStartValues: [75, 85, 95, 98],
	varBold: true,
};

export const WidgetTextvar1 = React.forwardRef(
	(props: IWidgetProps, ref: Ref<RefObj>) => {
		const { loggedOnUser } = useSelector((state: IAppState) => state.app);

		// First render props.widgetInfo sets widgetINfo state
		const [widgetInfo, setWidgetInfo] = React.useState<WidgetInfo>(
			props.widgetInfo
		);

		const [widgetValue, setWidgetValue] = React.useState<number>(0);

		useImperativeHandle(ref, () => ({ CleanWidgetTypeConfigFromPvs }));

		// Called every chnage on props.widgetInfo
		useEffect(() => {
			let isMounted = true;
			let timer: any = null;
			let getHistoryDone = true;

			// Update widgetInfo state with props.widgetInfo, every time
			// we updated the config in the editWidget the props.widgetInfo will
			// change and we store the change to update the widget accordingly
			let wi = { ...widgetInfo };
			UpdateWidgetInfo(wi, props.widgetInfo);

			//wi = props.widgetInfo;

			// At first render ther is no properties set on
			// props.widgetInfo.widgetTypeConfig, we test for property textColor if undefined
			// then load the default widget specific config options
			if (props.widgetInfo.widgetTypeConfig === undefined) {
				wi.widgetTypeConfig = JSON.parse(JSON.stringify(WidgetDefaultOptions));
				props.widgetUpdateDefaultConfigUpdate(wi);
			}

			// Update widgetInfo state
			setWidgetInfo(wi);

			if (isMounted && !props.widgetInfo.refresh?.subscription) {
				getPointsHistory(props.widgetInfo.points[0]);

				if (timer == null) {
					timer = setInterval(
						() => {
							if (isMounted) {
								if (getHistoryDone) {
									getHistoryDone = false;
									getPointsHistory(props.widgetInfo.points[0])?.then(() => {
										getHistoryDone = true;
									});
								}
							}
						},
						props.widgetInfo.refresh === undefined
							? 30000
							: props.widgetInfo.refresh.refresh * 1000
					);
				}
			}

			return () => {
				clearInterval(timer);
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [props.widgetInfo]);

		const CleanWidgetTypeConfigFromPvs = (): any => {
			let copyJson = JSON.stringify(widgetInfo);
			let copyObj: WidgetInfo = JSON.parse(copyJson);

			return copyObj;
		};

		const getPointsHistory = (pointDTO: PointDTO) => {
			if (!pointDTO) return;

			let pointAPI: PointAPI = new PointAPI();
			return pointAPI
				.GetPointsHistory({
					customerId: loggedOnUser?.tenant == null ? '' : loggedOnUser.tenant,
					pointids: [pointDTO.pointID],
					fromDateTime: '',
					toDateTime: '',
					groupByIntervalUnit: '',
					groupByInterval: 0,
					maxRowsToReturn: 0,
					onlyReturnCount: false,
					latestValue: true,
				})
				.then((resp) => {
					if (resp.status >= 200 && resp.status < 300) {
						let pointHistoryResp: PointHistoryResponseDTO;

						pointHistoryResp = resp.data;

						if (
							pointHistoryResp.pointHistory &&
							pointHistoryResp.pointHistory.length > 0
						) {
							for (
								let c = 0;
								c < pointHistoryResp.pointHistory[0].points.length;
								c++
							) {
								let samp = pointHistoryResp.pointHistory[0].points[c];

								let value: number | null = null;
								if (
									samp.state !== enPointState.NULL &&
									samp.state === enPointState.OK
								) {
									if (pointDTO.valueType === enPointValueType.json_type) {
										let obj = JSON.parse(samp.value);

										if (
											props.widgetInfo.widgetTypeConfig.valuePathFunction !==
											undefined
										) {
											var theInstructions =
												props.widgetInfo.widgetTypeConfig.valuePathFunction ===
												undefined
													? ''
													: props.widgetInfo.widgetTypeConfig.valuePathFunction;

											//	"return obj[0].filter((x) => {if (x.name === 'KM1_OEE_CurrShift') return true;})[0].value;";

											// eslint-disable-next-line
											var F = new Function('obj', theInstructions);
											let res = F(obj);
											value = parseFloat(res.replace(',', '.'));
										}
									} else {
										value = parseFloat(samp.value.replace(',', '.'));
									}
								}
								setWidgetValue(value == null ? 0 : Math.round(value * 10) / 10);
							}
						}
					}
				})
				.catch((e) => {
					console.log(e);
				})
				.finally(() => {});
		};

		const UpdateWidgetInfo = (
			targetWidgetInfo: WidgetInfo,
			sourceWidgetInfo: WidgetInfo
		) => {
			targetWidgetInfo.x = sourceWidgetInfo.x;
			targetWidgetInfo.y = sourceWidgetInfo.y;
			targetWidgetInfo.h = sourceWidgetInfo.h;
			targetWidgetInfo.i = sourceWidgetInfo.i;
			targetWidgetInfo.w = sourceWidgetInfo.w;

			targetWidgetInfo.points = sourceWidgetInfo.points;
			targetWidgetInfo.refresh = sourceWidgetInfo.refresh;
			targetWidgetInfo.title = sourceWidgetInfo.title;
			targetWidgetInfo.type = sourceWidgetInfo.type;

			targetWidgetInfo.widgetRefIndex = sourceWidgetInfo.widgetRefIndex;
			targetWidgetInfo.widgetTypeConfig = sourceWidgetInfo.widgetTypeConfig;
		};

		const MergeTextAndVar = (text: string, value: number) => {
			if (text === undefined) return 'Nan';

			let index = text.indexOf('{0}');
			let preText = text.substr(0, index);
			let postText = text.substr(index + 3);

			let textColor: any = '#000000';
			if (widgetInfo.widgetTypeConfig !== undefined) {
				for (
					let c = 0;
					c < widgetInfo.widgetTypeConfig.levelStartValues.length;
					c++
				) {
					if (value >= widgetInfo.widgetTypeConfig.levelStartValues[c])
						textColor = widgetInfo.widgetTypeConfig.levelColors[c];
				}
			}

			return (
				<span>
					{preText}{' '}
					<span
						style={{
							fontWeight:
								widgetInfo.widgetTypeConfig === undefined ||
								widgetInfo.widgetTypeConfig.varBold === false
									? 'normal'
									: 'bold',
							color: textColor,
						}}
					>
						{value}
					</span>{' '}
					{postText}
				</span>
			);
		};

		return (
			<React.Fragment>
				{widgetInfo.widgetTypeConfig !== undefined ? (
					<div key={widgetInfo.i} id="textvar_1">
						<Box display="flex" justifyContent="center" m={2}>
							<Typography variant="h5">
								<Box fontSize="32px">
									{MergeTextAndVar(
										widgetInfo.widgetTypeConfig.text === undefined
											? ''
											: widgetInfo.widgetTypeConfig.text,
										widgetValue
									)}
								</Box>
							</Typography>
						</Box>
					</div>
				) : (
					''
				)}
			</React.Fragment>
		);
	}
);
