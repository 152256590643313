import {
	AppBar,
	CircularProgress,
	Box,
	Button,
	Checkbox,
	CssBaseline,
	Dialog,
	DialogContent,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	InputLabel,
	makeStyles,
	MenuItem,
	Paper,
	Select,
	TextField,
	Toolbar,
	Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../redux/store';
import TransText from '../../../../resource/transText';
import Translation from '../../../../services/translation';
import { OrangeButton } from '../../../../styles/buttons';
import {
	enPointState,
	enPointValueType,
	enTimeUnitType,
	PointDTO,
	PointHistoryResponseDTO,
	WidgetInfo,
} from '../../../../model/pointModel';
import { PointAPI } from '../../../../services/pointAPI';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
			width: '25ch',
		},
	},
	appBarDialog: {
		position: 'relative',
	},
	titleDialog: {
		marginLeft: theme.spacing(2),
		flexGrow: 1,
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		//width: '25ch',
	},
	smallSize: {
		fontSize: 13,
		fontWeight: 400,
	},
	paper: {
		width: '100%',
		height: '100%',

		backgroundColor: 'white',
	},
	margin: {
		margin: theme.spacing(3),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 90,
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative',
	},
	progress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}));

interface IProps {
	selectedWidgetInfo: WidgetInfo;
	open: boolean;
	remove: (id: string) => any;
	save: (widgetInfo: WidgetInfo) => any;
	cancel: () => any;
}

export const WidgetEditTextvar1: React.FC<IProps> = ({
	selectedWidgetInfo,
	open,
	save,
	remove,
	cancel,
}) => {
	const classes = useStyles();
	const { t } = Translation;
	const { lang, loggedOnUser } = useSelector((state: IAppState) => state.app);
	const [widgetInfo, setWidgetInfo] =
		React.useState<WidgetInfo>(selectedWidgetInfo);
	const [reloadSpinner, setReloadSpinner] = React.useState<boolean>();
	const [pointLoadSpinner, setPointLoadSpinner] = React.useState<boolean>();
	const [points, setPoints] = React.useState<PointDTO[]>([]);
	const [selectedPoint, setSelectedPoint] = React.useState<PointDTO>();
	useEffect(() => {
		let isMounted = true;

		if (selectedWidgetInfo.points.length > 0) {
			setSelectedPoint(selectedWidgetInfo.points[0]);
			getPointsHistory(selectedWidgetInfo.points[0]);
		}

		if (points.length === 0) {
			let pointAPi = new PointAPI();
			setPointLoadSpinner(true);
			pointAPi
				.GetPoints(loggedOnUser?.tenant!, '', 50)
				.then((resp) => {
					if (isMounted) {
						if (resp.status >= 200 && resp.status < 300) {
							setPoints(
								resp.data.pointDTOs.filter((x: any) => {
									return (
										x.valueType === enPointValueType.double_type ||
										x.valueType === enPointValueType.long_type ||
										x.valueType === enPointValueType.bool_type ||
										x.valueType === enPointValueType.json_type
									);
								})
							);
						}
					}
				})
				.catch((e) => {
					console.log(e.message);
				})
				.finally(() => {
					setPointLoadSpinner(false);
				});
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSaveWidget = (widgetInfo: WidgetInfo) => {
		save(widgetInfo);
	};

	const OnClickReloadSeries = () => {
		getPointsHistory(widgetInfo.points[0]);
	};

	const onSelectedPoint = (pointDTO: PointDTO) => {
		setSelectedPoint(pointDTO);

		getPointsHistory(pointDTO);
	};

	const getPointsHistory = (pointDTO: PointDTO) => {
		if (!pointDTO) return;

		setReloadSpinner(true);
		let pointAPI: PointAPI = new PointAPI();
		pointAPI
			.GetPointsHistory({
				customerId: loggedOnUser?.tenant == null ? '' : loggedOnUser.tenant,
				pointids: [pointDTO.pointID],
				fromDateTime: '',
				toDateTime: '',
				groupByIntervalUnit: '',
				groupByInterval: 0,
				maxRowsToReturn: 0,
				onlyReturnCount: false,
				latestValue: true,
			})
			.then((resp) => {
				if (resp.status >= 200 && resp.status < 300) {
					let pointHistoryResp: PointHistoryResponseDTO;

					let wi = { ...widgetInfo };

					pointHistoryResp = resp.data;

					if (
						pointHistoryResp.pointHistory &&
						pointHistoryResp.pointHistory.length > 0
					) {
						for (
							let c = 0;
							c < pointHistoryResp.pointHistory[0].points.length;
							c++
						) {
							let samp = pointHistoryResp.pointHistory[0].points[c];

							let value: number | null = null;

							if (
								samp.state !== enPointState.NULL &&
								samp.state === enPointState.OK
							) {
								if (pointDTO.valueType === enPointValueType.json_type) {
									let obj = JSON.parse(samp.value);

									if (wi.widgetTypeConfig.valuePathFunction !== undefined) {
										var theInstructions =
											wi.widgetTypeConfig.valuePathFunction === undefined
												? ''
												: wi.widgetTypeConfig.valuePathFunction;

										//	"return obj[0].filter((x) => {if (x.name === 'KM1_OEE_CurrShift') return true;})[0].value;";
										// eslint-disable-next-line
										var F = new Function('obj', theInstructions);
										let res = F(obj);
										value = parseFloat(res.replace(',', '.'));
									}
								} else {
									value = parseFloat(samp.value.replace(',', '.'));
								}
							}

							wi.widgetTypeConfig.value =
								Math.round(value === null ? 0 : value * 10) / 10;
							wi.points = [pointDTO];

							setWidgetInfo(wi);
						}
					}
				}
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				setReloadSpinner(false);
			});
	};

	const MergeTextAndVar = (text: string, value: number) => {
		if (text === undefined) return 'Nan';

		let index = text.indexOf('{0}');
		let preText = text.substr(0, index);
		let postText = text.substr(index + 3);

		let textColor: any = '#000000';
		if (widgetInfo.widgetTypeConfig !== undefined) {
			for (
				let c = 0;
				c < widgetInfo.widgetTypeConfig.levelStartValues.length;
				c++
			) {
				if (value >= widgetInfo.widgetTypeConfig.levelStartValues[c])
					textColor = widgetInfo.widgetTypeConfig.levelColors[c];
			}
		}

		return (
			<span>
				{preText}{' '}
				<span
					style={{
						fontWeight:
							widgetInfo.widgetTypeConfig === undefined ||
							widgetInfo.widgetTypeConfig.varBold === false
								? 'normal'
								: 'bold',
						color: textColor,
					}}
				>
					{value}
				</span>{' '}
				{postText}
			</span>
		);
	};

	return (
		<React.Fragment>
			<Dialog fullScreen open={open}>
				<AppBar className={classes.appBarDialog}>
					<Toolbar>
						<Typography variant="h6" className={classes.titleDialog}>
							{widgetInfo !== undefined ? widgetInfo.title : ''}
						</Typography>

						<Button
							autoFocus
							color="inherit"
							onClick={() => {
								remove(widgetInfo === undefined ? '' : widgetInfo.i);
							}}
						>
							Delete
						</Button>

						<Button
							autoFocus
							color="inherit"
							onClick={() => {
								onSaveWidget(widgetInfo);
							}}
						>
							Save
						</Button>
						<OrangeButton
							autoFocus
							color="inherit"
							onClick={() => {
								cancel();
							}}
						>
							{t(TransText.app.cancel, null, lang, [])}
						</OrangeButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<CssBaseline />
					<Grid container spacing={2}>
						{/* Widget */}
						<Grid item sm={8}>
							<Paper elevation={4}>
								<Box display="flex" flexDirection="column" m={4} p={4}>
									<Box display="flex" justifyContent="space-between">
										<Box>{''}</Box>

										<Box>
											<Typography variant="h6">
												<Box fontSize="16px">
													{widgetInfo !== undefined ? widgetInfo.title : ''}
												</Box>
											</Typography>
										</Box>

										<Box m={3}>{''}</Box>
									</Box>

									<div key={widgetInfo.i} id="textvar_1">
										<Box display="flex" justifyContent="center" m={2}>
											<Typography variant="h5">
												<Box fontSize="32px" className="font-bold">
													{MergeTextAndVar(
														widgetInfo.widgetTypeConfig.text,
														widgetInfo.widgetTypeConfig.value
													)}
												</Box>
											</Typography>
										</Box>
									</div>
								</Box>
							</Paper>
						</Grid>

						{/* Widget settings */}
						<Grid item sm={4}>
							<Paper elevation={4}>
								<Box m={4} p={4}>
									{/* Title */}
									<Box m={2} mb={2}>
										<TextField
											fullWidth
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											label={t(
												TransText.widgetEdit.widgetTitle,
												null,
												lang,
												[]
											)}
											value={widgetInfo !== undefined ? widgetInfo.title : ''}
											onChange={(e) => {
												setWidgetInfo({
													...widgetInfo,
													title: e.target.value,
												});
											}}
										/>
									</Box>

									{/* Level 1 */}
									<Box display="flex" flexWrap="wrap" m={2}>
										<Box m={2} mb={2}>
											<TextField
												className={classes.textField}
												InputProps={{
													classes: {
														input: classes.smallSize,
													},
												}}
												InputLabelProps={{
													shrink: true,
												}}
												label={t(
													TransText.widgetGauge2.levelColor,
													null,
													lang,
													['1']
												)}
												value={
													widgetInfo.widgetTypeConfig.levelColors !== undefined
														? widgetInfo.widgetTypeConfig.levelColors[0]
														: ''
												}
												onChange={(e) => {
													let wi = { ...widgetInfo };
													wi.widgetTypeConfig.levelColors[0] = e.target.value;

													setWidgetInfo(wi);
												}}
											/>
											<TextField
												className={classes.textField}
												InputProps={{
													classes: {
														input: classes.smallSize,
													},
												}}
												InputLabelProps={{
													shrink: true,
												}}
												type="number"
												disabled={widgetInfo.refresh?.subscription}
												label={t(
													TransText.widgetGauge2.levelStart,
													null,
													lang,
													['1']
												)}
												value={
													widgetInfo.widgetTypeConfig.levelStartValues !==
													undefined
														? widgetInfo.widgetTypeConfig.levelStartValues[0]
														: 0
												}
												onChange={(e) => {
													let wi = { ...widgetInfo };
													wi.widgetTypeConfig.levelStartValues[0] =
														Number.parseInt(e.target.value);

													setWidgetInfo(wi);
												}}
											/>
										</Box>
									</Box>
									{/* Level 2 */}
									<Box display="flex" flexWrap="wrap" m={2}>
										<Box m={2} mb={2}>
											<TextField
												className={classes.textField}
												InputProps={{
													classes: {
														input: classes.smallSize,
													},
												}}
												InputLabelProps={{
													shrink: true,
												}}
												label={t(
													TransText.widgetGauge2.levelColor,
													null,
													lang,
													['2']
												)}
												value={
													widgetInfo.widgetTypeConfig.levelColors !== undefined
														? widgetInfo.widgetTypeConfig.levelColors[1]
														: ''
												}
												onChange={(e) => {
													let wi = { ...widgetInfo };
													wi.widgetTypeConfig.levelColors[1] = e.target.value;

													setWidgetInfo(wi);
												}}
											/>
											<TextField
												className={classes.textField}
												InputProps={{
													classes: {
														input: classes.smallSize,
													},
												}}
												InputLabelProps={{
													shrink: true,
												}}
												type="number"
												disabled={widgetInfo.refresh?.subscription}
												label={t(
													TransText.widgetGauge2.levelStart,
													null,
													lang,
													['2']
												)}
												value={
													widgetInfo.widgetTypeConfig.levelStartValues !==
													undefined
														? widgetInfo.widgetTypeConfig.levelStartValues[1]
														: 0
												}
												onChange={(e) => {
													let wi = { ...widgetInfo };
													wi.widgetTypeConfig.levelStartValues[1] =
														Number.parseInt(e.target.value);

													setWidgetInfo(wi);
												}}
											/>
										</Box>
									</Box>
									{/* Level 3 */}
									<Box display="flex" flexWrap="wrap" m={2}>
										<Box m={2} mb={2}>
											<TextField
												className={classes.textField}
												InputProps={{
													classes: {
														input: classes.smallSize,
													},
												}}
												InputLabelProps={{
													shrink: true,
												}}
												label={t(
													TransText.widgetGauge2.levelColor,
													null,
													lang,
													['3']
												)}
												value={
													widgetInfo.widgetTypeConfig.levelColors !== undefined
														? widgetInfo.widgetTypeConfig.levelColors[2]
														: ''
												}
												onChange={(e) => {
													let wi = { ...widgetInfo };
													wi.widgetTypeConfig.levelColors[2] = e.target.value;

													setWidgetInfo(wi);
												}}
											/>
											<TextField
												className={classes.textField}
												InputProps={{
													classes: {
														input: classes.smallSize,
													},
												}}
												InputLabelProps={{
													shrink: true,
												}}
												type="number"
												disabled={widgetInfo.refresh?.subscription}
												label={t(
													TransText.widgetGauge2.levelStart,
													null,
													lang,
													['3']
												)}
												value={
													widgetInfo.widgetTypeConfig.levelStartValues !==
													undefined
														? widgetInfo.widgetTypeConfig.levelStartValues[2]
														: 0
												}
												onChange={(e) => {
													let wi = { ...widgetInfo };
													wi.widgetTypeConfig.levelStartValues[2] =
														Number.parseInt(e.target.value);

													setWidgetInfo(wi);
												}}
											/>
										</Box>
									</Box>
									{/* Level 4 */}
									<Box display="flex" flexWrap="wrap" m={2}>
										<Box m={2} mb={2}>
											<TextField
												className={classes.textField}
												InputProps={{
													classes: {
														input: classes.smallSize,
													},
												}}
												InputLabelProps={{
													shrink: true,
												}}
												label={t(
													TransText.widgetGauge2.levelColor,
													null,
													lang,
													['4']
												)}
												value={
													widgetInfo.widgetTypeConfig.levelColors !== undefined
														? widgetInfo.widgetTypeConfig.levelColors[3]
														: ''
												}
												onChange={(e) => {
													let wi = { ...widgetInfo };
													wi.widgetTypeConfig.levelColors[3] = e.target.value;

													setWidgetInfo(wi);
												}}
											/>
											<TextField
												className={classes.textField}
												InputProps={{
													classes: {
														input: classes.smallSize,
													},
												}}
												InputLabelProps={{
													shrink: true,
												}}
												type="number"
												disabled={widgetInfo.refresh?.subscription}
												label={t(
													TransText.widgetGauge2.levelStart,
													null,
													lang,
													['4']
												)}
												value={
													widgetInfo.widgetTypeConfig.levelStartValues !==
													undefined
														? widgetInfo.widgetTypeConfig.levelStartValues[3]
														: 0
												}
												onChange={(e) => {
													let wi = { ...widgetInfo };
													wi.widgetTypeConfig.levelStartValues[3] =
														Number.parseInt(e.target.value);

													setWidgetInfo(wi);
												}}
											/>
										</Box>
									</Box>

									{/* Static text */}
									<Box m={2} mb={2}>
										<TextField
											fullWidth
											multiline
											rows={4}
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											label={t(
												TransText.widgetEdit.widgetStaticText,
												null,
												lang,
												[]
											)}
											value={
												widgetInfo !== undefined
													? widgetInfo.widgetTypeConfig.text
													: ''
											}
											onChange={(e) => {
												let wi = { ...widgetInfo };
												wi.widgetTypeConfig.text = e.target.value;

												setWidgetInfo(wi);
											}}
										/>
									</Box>

									{/* Point */}
									<Box m={2} mt={4}>
										<div className={classes.wrapper}>
											<Autocomplete
												size="small"
												options={points}
												getOptionLabel={(option) =>
													option.pointName + ' , ' + option.pointDesc
												}
												fullWidth
												disabled={pointLoadSpinner}
												renderInput={(params) => (
													<TextField
														{...params}
														label={t(
															TransText.widgetEdit.selectPoint,
															null,
															lang,
															[]
														)}
														//variant="outlined"
													/>
												)}
												value={selectedPoint}
												onChange={(e, value) => {
													if (value !== null) onSelectedPoint(value);
												}}
											/>
											{pointLoadSpinner && (
												<CircularProgress
													size={24}
													className={classes.progress}
												/>
											)}
										</div>
									</Box>

									{/* Select JsonObject prop */}
									{selectedPoint?.valueType === enPointValueType.json_type ? (
										<Box
											// display="flex"
											// justifyContent="flex-start"
											// flexWrap="wrap"
											m={2}
											mt={2}
										>
											<TextField
												multiline
												rows={4}
												fullWidth
												className={classes.textField}
												InputProps={{
													classes: {
														input: classes.smallSize,
													},
												}}
												InputLabelProps={{
													shrink: true,
												}}
												// margin="dense"
												// size="small"
												label={t(
													TransText.widgetEdit.selectedPointJsonType,
													null,
													lang,
													[]
												)}
												value={
													widgetInfo.widgetTypeConfig.valuePathFunction !==
													undefined
														? widgetInfo.widgetTypeConfig.valuePathFunction
														: ''
												}
												onChange={(e) => {
													let wi = { ...widgetInfo };
													wi.widgetTypeConfig.valuePathFunction =
														e.target.value;

													setWidgetInfo(wi);
												}}
											/>
										</Box>
									) : (
										''
									)}

									{/* Time range Refresh */}
									<Box display="flex" flexWrap="wrap" m={2}>
										<FormGroup row>
											<FormControlLabel
												control={
													<Checkbox
														color="primary"
														checked={
															widgetInfo !== undefined
																? widgetInfo.refresh?.subscription
																: false
														}
														onChange={(e) => {
															let wi = { ...widgetInfo };
															wi.refresh!.subscription = e.target.checked;

															setWidgetInfo(wi);
														}}
													/>
												}
												label={t(
													TransText.widgetEdit.timeRangeSubscription,
													null,
													lang,
													[]
												)}
											/>
										</FormGroup>
									</Box>
									<Box display="flex" flexWrap="wrap" m={2}>
										<TextField
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											type="number"
											margin="dense"
											size="small"
											disabled={widgetInfo.refresh?.subscription}
											label={t(
												TransText.widgetEdit.timeRangeRefresh,
												null,
												lang,
												[]
											)}
											value={
												widgetInfo !== undefined
													? widgetInfo.refresh?.refresh
													: 0
											}
											onChange={(e) => {
												let wi = { ...widgetInfo };
												wi.refresh!.refresh = Number.parseInt(e.target.value);

												setWidgetInfo(wi);
											}}
										/>
										<Box ml={2}>
											<FormControl className={classes.formControl}>
												<InputLabel id="demo-simple-select-label">
													{t(
														TransText.widgetEdit.timeRangeRefreshUnit,
														null,
														lang,
														[]
													)}
												</InputLabel>

												<Select
													disabled={widgetInfo.refresh?.subscription}
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={
														widgetInfo !== undefined
															? widgetInfo.refresh?.refreshUnit.toString()
															: ''
													}
													onChange={(e) => {
														let wi = { ...widgetInfo };
														wi.refresh!.refreshUnit = e.target
															.value as keyof typeof enTimeUnitType;

														setWidgetInfo(wi);
													}}
												>
													<MenuItem value={'Second'}>sec</MenuItem>
													<MenuItem value={'Minute'}>min</MenuItem>
													<MenuItem value={'Hour'}>hour</MenuItem>
													<MenuItem value={'Day'}>day</MenuItem>
													<MenuItem value={'Week'}>week</MenuItem>
													<MenuItem value={'Month'}>month</MenuItem>
												</Select>
											</FormControl>
										</Box>
									</Box>
									<Box m={2}>
										<div className={classes.wrapper}>
											<Button
												onClick={OnClickReloadSeries}
												fullWidth
												variant="contained"
												color="primary"
												disabled={reloadSpinner}
											>
												{t(TransText.widgetEdit.loadAllSeries, null, lang, [])}
											</Button>
											{reloadSpinner && (
												<CircularProgress
													size={24}
													className={classes.progress}
												/>
											)}
										</div>
									</Box>
								</Box>
							</Paper>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
};
