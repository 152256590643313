export class TransText {
	static app = {
		appTitle: { enUS: 'Piot', svSE: 'Piot' },
		welcome: { enUS: 'Welcome', svSE: 'Välkommen' },
		login: { enUS: 'Login', svSE: 'Logga in' },
		ok: { enUS: 'Ok', svSE: 'Ok' },
		cancel: { enUS: 'Cancel', svSE: 'Avbryt' },
		yes: { enUS: 'Yes', svSE: 'Ja' },
		no: { enUS: 'No', svSE: 'Nej' },
		loggedIn: { enUS: 'Logged in', svSE: 'Inloggad' },
		loggedInWithCustomer: {
			enUS: 'Logged in on customer {0}',
			svSE: 'Inloggad mot kund {0}',
		},
		columnWidth: { enUS: 'Column width', svSE: 'Kolumnbredd' },
		loggedOut: { enUS: 'Logged off', svSE: 'UtLoggad' },
		save: { enUS: 'Save', svSE: 'Spara' },
		home: { enUS: 'Home', svSE: 'Hem' },
		test: { enUS: 'Test', svSE: 'Test' },
		edit: { enUS: 'Edit', svSE: 'Redigera' },
		state: { enUS: 'State', svSE: 'Status' },
		add: { enUS: 'Add', svSE: 'Lägg till' },
		remove: { enUS: 'Remove', svSE: 'Ta bort' },
		notFound: { enUS: '{0} is not found', svSE: '{0} finns inte' },
		name: { enUS: 'Name', svSE: 'Namn' },
		description: { enUS: 'Description', svSE: 'Beskrivning' },
		version: { enUS: 'Version', svSE: 'Version' },
		email: {
			enUS: 'Email',
			svSE: 'E-Post',
		},
		phone: {
			enUS: 'PhoneNr',
			svSE: 'TelNr',
		},
		height: { enUS: 'Height', svSE: 'Höjd' },
		width: { enUS: 'Width', svSE: 'Bredd' },
		settings: { enUS: 'Settings', svSE: 'Inställningar' },
		type: { enUS: 'Type', svSE: 'Typ' },
		active: { enUS: 'Active', svSE: 'Aktiv' },
		upUser: { enUS: 'User', svSE: 'Användare' },
		upTime: { enUS: 'Update time', svSE: 'Senast uppdaterad' },
		fontSize: { enUS: 'Font size', svSE: 'Font storlek' },
		fontWeight: { enUS: 'Font weight', svSE: 'Font vikt' },
	};

	static error = {
		general: {
			enUS: 'An errror has occured, its logged. Please try again later.',
			svSE: 'Ett fel har registrerats, vänligen försök senare',
		},
		failedLogin: {
			enUS: 'Useraccount or password is wrong',
			svSE: 'Användarekonto eller lösenord är fel',
		},
		network: {
			enUS: 'No network connection, Please try again later.',
			svSE: 'Ingen nätverksansluting, Vänligen försök senare',
		},
		NOTFOUND_CUSTOMER: {
			enUS: 'Did not found customer {0}',
			svSE: 'Hittade inte kund {0}',
		},
		UNAUTHORIZED: {
			enUS: 'Not authorized, login with an authorized account',
			svSE: 'Inte behörig, logga in med ett behörigt konto',
		},
		NOT_FOUND: {
			enUS: 'Did not find {0}',
			svSE: 'Hittade inte {0}',
		},
		NOT_SUPPORTED: {
			enUS: 'Not supported {0}',
			svSE: 'Inte understödd {0}',
		},
		NO_TENANT: {
			enUS: 'Missing customer {0}',
			svSE: 'Saknas kund {0}',
		},
		FILESIZE_MAXSIZE: {
			enUS: 'Filesize max {0}',
			svSE: 'Filstorlek max {0}',
		},
		SERVER_ERROR: {
			enUS: 'Server error, its logged, try again later',
			svSE: 'Server fel, det är loggat, försök igen senare',
		},
	};

	static navBarTop = {
		title: {
			enUS: 'Piot',
			svSE: 'Piot',
		},

		titleLong: {
			enUS: 'Piot IOT platform',
			svSE: 'Piot IOT plattform',
		},
		selectLanguage: {
			enUS: 'Select language',
			svSE: 'Välj språk',
		},
		logout: {
			enUS: 'Logout',
			svSE: 'Logga ut',
		},
		myProfile: {
			enUS: 'My profile',
			svSE: 'Min profil',
		},
	};

	static login = {
		account: {
			enUS: 'Account',
			svSE: 'Konto',
		},
		password: {
			enUS: 'Password',
			svSE: 'Lösenord',
		},
		headTitle: {
			enUS: 'Login',
			svSE: 'Inloggnig',
		},
		pageProtectedLogin: {
			enUS: 'Page {0} is protected, login',
			svSE: 'Sidan {0} är skyddad logga in',
		},
		forgottpassword: {
			enUS: 'Forgott password',
			svSE: 'Glömt lösenord',
		},
		selectCustomer: {
			enUS: 'Select customer',
			svSE: 'Välj kund',
		},
		selectCustomerTitle: {
			enUS: 'Select customer to work with',
			svSE: 'Välj kund att jobba med',
		},
	};

	static myProfile = {
		title: {
			enUS: 'My profile',
			svSE: 'Profil',
		},
		name: {
			enUS: 'Name',
			svSE: 'Namn',
		},
		roles: {
			enUS: 'Roles',
			svSE: 'Roller',
		},
	};

	static home = {
		headTitle: {
			enUS: 'Piot (Process IOT)',
			svSE: 'Piot (Process IOT)',
		},
		bodyTitle: {
			enUS: 'IOT, M2M and Dashboard.',
			svSE: 'IOT, M2M och Dashboard',
		},
		body: {
			enUS: 'Connect your process signals and information for secure access in the Piotcloud.',
			svSE: 'Anslut dinas processsignaler och information för säker access i Piotcloud.',
		},
	};

	static customerDetail = {
		headTitle: {
			enUS: 'Customer',
			svSE: 'Kund',
		},
		name: {
			enUS: 'Name',
			svSE: 'Namn',
		},
		address: {
			enUS: 'Address',
			svSE: 'Adress',
		},
		zipcode: {
			enUS: 'Zipcode',
			svSE: 'Postnr',
		},
		city: {
			enUS: 'City',
			svSE: 'Stad',
		},
		country: {
			enUS: 'Country',
			svSE: 'Land',
		},
		contactName: {
			enUS: 'Contact',
			svSE: 'Kontakt',
		},
		saveSuccessfully: {
			enUS: 'Saved customer',
			svSE: 'Kund sparad',
		},
		saveFailed: {
			enUS: 'Saving customer failed, error is registered',
			svSE: 'Fel vid sparande av kund, felet är registrerat',
		},
	};

	static customerList = {
		headTitle: {
			enUS: 'Customer list',
			svSE: 'Kund lista',
		},
		customer: {
			enUS: 'Customer',
			svSE: 'Kund',
		},
	};

	static nanosList = {
		headTitle: {
			enUS: 'Nanos',
			svSE: 'Nanos',
		},
		customer: {
			enUS: 'Customer',
			svSE: 'Kund',
		},
	};

	static nanoDetail = {
		headTitle: {
			enUS: 'Nano',
			svSE: 'Nano',
		},
		name: {
			enUS: 'Name',
			svSE: 'Namn',
		},
		saveSuccessfully: {
			enUS: 'Nano saved',
			svSE: 'Nano sparad',
		},
		saveFailed: {
			enUS: 'Saving nano failed, error is registered',
			svSE: 'Fel vid sparande av nano, felet är registrerat',
		},
	};

	static pcDetail = {
		headTitle: {
			enUS: 'Pc (Protocol channel)',
			svSE: 'Pc (Protokoll kanal)',
		},
		name: {
			enUS: 'Name',
			svSE: 'Namn',
		},
		saveSuccessfully: {
			enUS: 'Pc saved',
			svSE: 'Pc sparad',
		},
		saveFailed: {
			enUS: 'Saving pc failed, error is registered',
			svSE: 'Fel vid sparande av pc, felet är registrerat',
		},
		pcMode: {
			enUS: 'PcMode',
			svSE: 'PcMode',
		},
	};

	static dash = {
		cancelDashChanges: {
			enUS: 'Do you want to leave changes unsaved?',
			svSE: 'Vill vill du lämna ändringar osparade',
		},
		deleteDash: {
			enUS: 'Do you want to delete dashboard?',
			svSE: 'Vill radera dashboard?',
		},
		addNewDash: {
			enUS: 'New dash',
			svSE: 'Ny dash',
		},
		selectDash: {
			enUS: 'Select dash',
			svSE: 'Välöj dash',
		},
		addNewWidget: {
			enUS: 'New widget',
			svSE: 'Ny kontroll',
		},
		editDash: {
			enUS: 'Edit dash',
			svSE: 'Editera dash',
		},
		selectDashName: {
			enUS: 'Select dash name',
			svSE: 'Välj dash namn',
		},
	};

	static widgetEdit = {
		serieDuplicated: {
			enUS: 'New serie has same name or pointID as present serie',
			svSE: 'Nyt serie har samma namn eller pointId som befintlig serie',
		},
		widgetStaticText: {
			enUS: 'Static text, with {0} as var pos',
			svSE: 'Statisk text med {0} pos för var',
		},
		widgetTitle: {
			enUS: 'Title',
			svSE: 'Namn',
		},
		widgetUnit: {
			enUS: 'Unit',
			svSE: 'Enhet',
		},
		yAxisLabel: {
			enUS: 'Y axis label',
			svSE: 'Y axel text',
		},
		yAxisMin: {
			enUS: 'Y axis range min',
			svSE: 'Y axel område min',
		},
		yAxisMax: {
			enUS: 'Y axis range max',
			svSE: 'Y axel range max',
		},
		yAxisAutomaticMax: {
			enUS: 'Y axis auto max',
			svSE: 'Y axel auto max',
		},
		selectColor: {
			enUS: 'Select color',
			svSE: 'Välj färg',
		},
		selectPoint: {
			enUS: 'Select data point',
			svSE: 'Välj data punkt',
		},
		selectedPointJsonType: {
			enUS: 'Set the value path',
			svSE: 'Ange path till value',
		},
		series: {
			enUS: 'Data series',
			svSE: 'Dataserier',
		},
		removeSerieConfirmContent: {
			enUS: 'Do you want to remove serie {0}',
			svSE: 'Vill du tabort serie {0}',
		},
		removeSerieConfirmTitle: {
			enUS: 'Remove serie?',
			svSE: 'Tabort serie?',
		},
		timeRangeStart: {
			enUS: 'End of interval (0 is now, + is future)',
			svSE: 'End av intervall (är nu och + är i framtiden)',
		},
		timeRangeIntervall: {
			enUS: 'Length of interval',
			svSE: 'Längd av intervall',
		},
		timeRangeLastUnit: {
			enUS: 'Unit',
			svSE: 'Enhet',
		},
		timeRangeGroup: {
			enUS: 'Group',
			svSE: 'Gruppera',
		},
		timeRangeGroupUnit: {
			enUS: 'Unit',
			svSE: 'Enhet',
		},
		timeRangeRefresh: {
			enUS: 'Refresh rate',
			svSE: 'Uppdateringsintervall',
		},
		timeRangeRefreshUnit: {
			enUS: 'Unit',
			svSE: 'Enhet',
		},
		timeRangeSubscription: {
			enUS: 'Subscribe',
			svSE: 'Prenumeration',
		},
		loadAllSeries: {
			enUS: 'Reload all series',
			svSE: 'Ladda om alla serier',
		},
		selectImage: {
			enUS: 'Select image',
			svSE: 'Välj bild',
		},
		barStacked: {
			enUS: 'Stacked bar',
			svSE: 'Stackad kolumn',
		},
	};

	static widgetTextVar = {
		staticText: {
			enUS: 'Static text add {serieName}',
			svSE: 'Statisk text infoga {serieName}',
		},
	};

	static widgetGauge2 = {
		functionConvert: {
			enUS: 'Function to convert value',
			svSE: 'Funktion convertera värde',
		},
		functionParams: {
			enUS: 'Function parameter points',
			svSE: 'Funktion parameter punkter',
		},
		levelColor: {
			enUS: 'Level {0} color',
			svSE: 'Nivå {0} färg',
		},
		levelStart: {
			enUS: 'Level',
			svSE: 'Nivå',
		},
		rangeMin: {
			enUS: 'Range min',
			svSE: 'Område min',
		},
		rangeMax: {
			enUS: 'Range max',
			svSE: 'Område max',
		},
	};

	static columnDialog_table_1 = {
		title: {
			enUS: 'Column',
			svSE: 'Kolumn',
		},
		field: {
			enUS: 'Field',
			svSE: 'Fält',
		},
		column: {
			enUS: 'Title',
			svSE: 'Namn',
		},
	};

	static widgetImage1 = {
		fileUploaded: {
			enUS: 'File {0} uploaded',
			svSE: 'Filen {0} är uppladdad',
		},
	};

	static uploadPointsCsv = {
		headTitle: {
			enUS: 'Import a csv file with points',
			svSE: 'Importera en csv fil med points',
		},
		selectCsvFile: {
			enUS: 'Select csv file',
			svSE: 'Välj csv fil',
		},
		importPoints: {
			enUS: 'Import points',
			svSE: 'Importera points',
		},
		importState: {
			enUS: 'Import',
			svSE: 'Import',
		},
		importComment: {
			enUS: 'Comment',
			svSE: 'Comment',
		},
		errorInCSVFile: {
			enUS: 'Error in csv file',
			svSE: 'Fel i csv fil',
		},
	};

	static pcMsSql = {
		successSavedPoints: {
			enUS: 'Updated points',
			svSE: 'Uppdaterat points',
		},
		failedSavedPoints: {
			enUS: 'Update of points failed',
			svSE: 'Error vid uppdatering av points ',
		},
		successSavedPc: {
			enUS: 'Updated pc configuration',
			svSE: 'Uppdaterat pc configutration',
		},
		failedSavedPc: {
			enUS: 'Update of pc config failed',
			svSE: 'Error vid uppdatering av pc configuration ',
		},
	};

	static Point = {
		name: {
			enUS: 'Name',
			svSE: 'Namn',
		},
		Description: {
			enUS: 'Description',
			svSE: 'Beskrivning',
		},
		PointAdress: {
			enUS: 'Pointaddress',
			svSE: 'Pointadress',
		},
		DataType: {
			enUS: 'Datatype',
			svSE: 'Datatyp',
		},
		ScanRate: {
			enUS: 'Scanrate',
			svSE: 'Inläs intervall',
		},
		Deadband: {
			enUS: 'Deadband',
			svSE: 'Dödband',
		},
		PointCustomJson: {
			enUS: 'Custom config',
			svSE: 'Anpassad config',
		},

		Read: {
			enUS: 'Read enable',
			svSE: 'Läsa',
		},

		Write: {
			enUS: 'Write enable',
			svSE: 'Skriva',
		},

		Subscribed: {
			enUS: 'Subscribed',
			svSE: 'Prenumererad',
		},

		Call: {
			enUS: 'Call with param',
			svSE: 'Anropa med parameter',
		},

		Function: {
			enUS: 'Function',
			svSE: 'Funktion',
		},
	};

	static series = {
		addSerie: {
			enUS: 'Add new serie',
			svSE: 'Lägg till serie',
		},
		levelColor: {
			enUS: 'Level {0} color',
			svSE: 'Nivå {0} färg',
		},
		levelStart: {
			enUS: 'Level {0}',
			svSE: 'Nivå {0}',
		},
		graphicalShape: {
			enUS: 'Control shape',
			svSE: 'Kontrol form',
		},
		removeSerie: {
			enUS: 'Remove serie {0} ?',
			svSE: 'Ta bort serie {0} ?',
		},
	};

	static serieFunc = {
		serieSettingsTitle: {
			enUS: 'Serie settings',
			svSE: 'Serie inställning',
		},
		SelectParameter: {
			enUS: 'Script function parameter',
			svSE: 'Skriptfunktions parameter',
		},
		refreshParameterValues: {
			enUS: 'Refresh parameter point values from process',
			svSE: 'Refresha parameter punkters värden från process',
		},
		addParam: {
			enUS: 'Add point as parameter to scriptfunction',
			svSE: 'Lägg till punkt som parameter till skript funktionen',
		},
		testScript: {
			enUS: 'Test script',
			svSE: 'Testa skript',
		},
		duplicatedParamName: {
			enUS: 'Point name {0} already exists',
			svSE: 'Punk namn {0} finns redan',
		},
		invalidPointName: {
			enUS: 'Invalid point name, use only A-Z, a-z, 0-9, or _ in Name and not only numbers',
			svSE: 'Ogiltigt point name, använd bara A-Z, a-z. 0-9 eller _ i Namn och inte bara siffror',
		},
		functionExpression: {
			enUS: 'Script function: Ex: return(value * 2)',
			svSE: 'Skriptfunktion: Ex: return(value * 2)',
		},
		functionExpressionError: {
			enUS: 'Script error: {0}',
			svSE: 'Skript fel {0}',
		},
	};
}

export default TransText;
