import {
	AppBar,
	Box,
	Button,
	CircularProgress,
	CssBaseline,
	Dialog,
	DialogContent,
	Grid,
	makeStyles,
	Paper,
	TextField,
	Toolbar,
	Typography,
} from '@material-ui/core';
import React, { CSSProperties, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../redux/store';
import TransText from '../../../../resource/transText';
import Translation from '../../../../services/translation';
import { OrangeButton } from '../../../../styles/buttons';
import { ColumnItemTable1 } from './columnItemTable';
import {
	enPointState,
	enPointValueType,
	PointDataDTO,
	PointDTO,
	PointHistoryResponseDTO,
	WidgetInfo,
} from '../../../../model/pointModel';
import { ColumnDialogTable1 } from './columnDialogTable1';
import { PointAPI } from '../../../../services/pointAPI';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Table1Column } from '../../../../model/dashModel';
import moment from 'moment';
import { IWidgetEditProps } from '../../widgetTypes';

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			margin: theme.spacing(1),
			width: '25ch',
		},
	},
	appBarDialog: {
		position: 'relative',
	},
	titleDialog: {
		marginLeft: theme.spacing(2),
		flexGrow: 1,
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '25ch',
	},
	smallSize: {
		fontSize: 13,
		fontWeight: 400,
	},
	paper: {
		width: '100%',
		height: '100%',

		backgroundColor: 'white',
	},
	margin: {
		margin: theme.spacing(3),
	},
	wrapper: {
		position: 'relative',
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}));

export const WidgetEditTable1: React.FC<IWidgetEditProps> = ({
	selectedWidgetInfo,
	open,
	save,
	remove,
	cancel,
}) => {
	const classes = useStyles();
	const { t } = Translation;
	const { lang, loggedOnUser } = useSelector((state: IAppState) => state.app);
	const [widgetInfo, setWidgetInfo] =
		React.useState<WidgetInfo>(selectedWidgetInfo);

	const [editColumn, setEditColumn] = React.useState<Table1Column>();

	const [points, setPoints] = React.useState<PointDTO[]>([]);
	const [selectedPoint, setSelectedPoint] = React.useState<PointDTO>();

	const [spinner, setSpinner] = React.useState<boolean>();

	useEffect(() => {
		let isMounted = true;

		if (isMounted) {
			if (selectedWidgetInfo.points.length > 0) {
				setSelectedPoint(selectedWidgetInfo.points[0]);
				getPointsHistory(selectedWidgetInfo.points[0], false);
			}

			// Fill select points list
			if (points.length === 0) {
				let pointAPi = new PointAPI();
				pointAPi
					.GetPoints(loggedOnUser?.tenant!, '', 50)
					.then((resp) => {
						if (resp.status >= 200 && resp.status < 300) {
						}
						setPoints(
							resp.data.pointDTOs.filter((x: any) => {
								return x.valueType === enPointValueType.json_type;
							})
						);
					})
					.catch((e) => {
						console.log(e.message);
					});
			}
		}
		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const saveWidget = () => {
		save(widgetInfo);
	};

	const onEditColumn = (column: Table1Column) => {
		setEditColumn(column);
	};

	const onCancelEditColumn = () => {
		setEditColumn(undefined);
	};

	const onSaveEditColumn = (column: Table1Column) => {
		let wi = { ...widgetInfo };

		let colIndex = wi.widgetTypeConfig.columns.findIndex(
			(x: any) => x.field === column.field
		);

		let wicCol = [...wi.widgetTypeConfig.columns];
		let newcol = {
			...wicCol[colIndex],
			headerName: column.headerName,
			width: column.width,
		};
		wicCol[colIndex] = newcol;
		wi.widgetTypeConfig.columns = wicCol;

		setWidgetInfo(wi);
		setEditColumn(undefined);
	};

	const onSelectedPoint = (pointDTO: PointDTO) => {
		setSelectedPoint(pointDTO);

		getPointsHistory(pointDTO, true);
	};

	const getPointsHistory = (pointDTO: PointDTO, rebuild: boolean) => {
		if (!pointDTO) return;

		setSpinner(true);

		let pointAPI: PointAPI = new PointAPI();
		pointAPI
			.GetPointsHistory({
				customerId: loggedOnUser?.tenant == null ? '' : loggedOnUser.tenant,
				pointids: [pointDTO.pointID],
				fromDateTime: moment().subtract(30, 'day').toISOString(),
				toDateTime: moment().toISOString(),
				groupByIntervalUnit: '',
				groupByInterval: 0,
				maxRowsToReturn: 3600,
				onlyReturnCount: false,
				latestValue: false,
			})
			.then((resp) => {
				if (resp.status >= 200 && resp.status < 300) {
					let pointHistoryResp: PointHistoryResponseDTO;
					pointHistoryResp = resp.data;

					console.log(
						'Got response !! nr of PVS ' +
							pointHistoryResp.pointHistory[0].points.length
					);

					if (
						pointHistoryResp !== null &&
						pointHistoryResp.pointHistory !== null &&
						pointHistoryResp.pointHistory.length > 0 &&
						pointHistoryResp.pointHistory[0].points.length > 0
					) {
						let generateId: boolean = false;

						if (
							widgetInfo.widgetTypeConfig.columns === undefined ||
							widgetInfo.widgetTypeConfig.columns === [] ||
							rebuild
						) {
							console.log('Rebuild columns !! ');

							let wi = { ...widgetInfo };

							wi.widgetTypeConfig.columns = [];

							// Filter out any faulted states
							let points: PointDataDTO[] =
								pointHistoryResp.pointHistory[0].points.filter(
									(x: PointDataDTO) => {
										return x.state === enPointState.OK;
									}
								);

							console.log('Valid points ' + points.length);

							if (points.length > 0) {
								let rowData = JSON.parse(points[0].value);

								rowData.forEach((column: any) => {
									wi.widgetTypeConfig.columns = [
										...wi.widgetTypeConfig.columns,
										{
											field: column.name,
											headerName: column.name,
											width: 150,
										},
									];
								});

								if (
									wi.widgetTypeConfig.columns.find(
										(x: any) => x.field.toLowerCase() === 'id'
									) === undefined
								) {
									generateId = true;
									wi.widgetTypeConfig.columns = [
										...wi.widgetTypeConfig.columns,
										{
											field: 'id',
											headerName: 'id',
											width: 150,
											hide: true,
										},
									];
								}
							}

							// Get the column data
							let tableData: any[] = [];

							let id: number = 0;
							points.forEach((point) => {
								let rowData = JSON.parse(point.value);
								let tableRow: any = {};

								if (generateId) {
									tableRow['id'] = id;
									id += 1;
								}

								rowData.forEach((col: any) => {
									tableRow[col.name] = col.value;
								});

								tableData.push(tableRow);
							});

							wi.widgetTypeConfig.rows = tableData;
							wi.points = [pointDTO];

							console.log('Set wi');
							setWidgetInfo(wi);
						}
					}

					if (
						widgetInfo.widgetTypeConfig.columns !== undefined &&
						widgetInfo.widgetTypeConfig.columns.length > 0
					) {
						console.log('Set sortMOdel');

						// setSortModel([
						// 	{
						// 		field: 'id',
						// 		sort: 'desc',
						// 	},
						// ]);

						console.log(' Set sortMOdel Done');
					}
				} else {
					// Error
				}
			})
			.catch((e) => {
				console.log(e);
			})
			.finally(() => {
				setSpinner(false);
			});
	};

	const getDivStyle = (): CSSProperties => {
		return {
			width: '100%',
			height:
				widgetInfo.widgetTypeConfig.height === undefined
					? '120px'
					: widgetInfo.widgetTypeConfig.height + 'px',
		};
	};

	return (
		<React.Fragment>
			<Dialog fullScreen open={open}>
				<AppBar className={classes.appBarDialog}>
					<Toolbar>
						<Typography variant="h6" className={classes.titleDialog}>
							{widgetInfo !== undefined ? widgetInfo.title : ''}
						</Typography>

						<Button
							autoFocus
							color="inherit"
							onClick={() => {
								remove(widgetInfo === undefined ? '' : widgetInfo.i);
							}}
						>
							Delete
						</Button>

						<Button
							autoFocus
							color="inherit"
							onClick={() => {
								saveWidget();
							}}
						>
							Save
						</Button>
						<OrangeButton
							autoFocus
							color="inherit"
							onClick={() => {
								cancel();
							}}
						>
							Cancel
						</OrangeButton>
					</Toolbar>
				</AppBar>
				<DialogContent>
					<CssBaseline />
					<Grid container spacing={2}>
						{/* Widget */}
						<Grid item sm={8}>
							<Paper elevation={4}>
								<Box display="flex" flexDirection="column" m={4} p={4}>
									<Box display="flex" justifyContent="space-between">
										<Box>{''}</Box>

										<Box>
											<Typography variant="h6">
												<Box fontSize="16px">
													{widgetInfo !== undefined ? widgetInfo.title : ''}
												</Box>
											</Typography>
										</Box>

										<Box m={3}>{''}</Box>
									</Box>

									<Box m={2}>
										<div style={getDivStyle()}>
											<DataGridPro
												hideFooter={true}
												rows={
													widgetInfo.widgetTypeConfig.rows === undefined
														? []
														: widgetInfo.widgetTypeConfig.rows
												}
												columns={
													widgetInfo.widgetTypeConfig.columns === undefined
														? []
														: widgetInfo.widgetTypeConfig.columns
												}
												density="compact"
												disableMultipleSelection={true}
												// sortModel={[
												// 	{
												// 		field: 'id',
												// 		sort: 'desc',
												// 	},
												// ]}
											/>
										</div>
									</Box>
								</Box>
							</Paper>
						</Grid>

						{/* Widget settings */}
						<Grid item sm={4}>
							<Paper elevation={4}>
								<Box m={4} p={4}>
									{/* Title */}
									<Box
										display="flex"
										justifyContent="flex-start"
										flexWrap="wrap"
										m={2}
										mb={2}
									>
										<TextField
											className={classes.textField}
											InputProps={{
												classes: {
													input: classes.smallSize,
												},
											}}
											InputLabelProps={{
												shrink: true,
											}}
											margin="dense"
											size="small"
											label={t(
												TransText.widgetEdit.widgetTitle,
												null,
												lang,
												[]
											)}
											value={widgetInfo !== undefined ? widgetInfo.title : ''}
											onChange={(e) => {
												setWidgetInfo({
													...widgetInfo,
													title: e.target.value,
												});
											}}
										/>
									</Box>

									{/* Height */}
									<Box
										display="flex"
										justifyContent="flex-start"
										flexWrap="wrap"
										m={2}
										mb={2}
									>
										<TextField
											type="number"
											margin="dense"
											size="small"
											label={t(TransText.app.height, null, lang, [])}
											value={
												widgetInfo.widgetTypeConfig.height !== undefined
													? widgetInfo.widgetTypeConfig.height
													: 120
											}
											onChange={(e) => {
												let wi = { ...widgetInfo };
												if (parseInt(e.target.value) > 1400)
													wi.widgetTypeConfig.height = '1400';
												else wi.widgetTypeConfig.height = e.target.value;
												setWidgetInfo(wi);
											}}
										/>
									</Box>

									{/* Point */}
									<Box m={2} mt={4}>
										<div className={classes.wrapper}>
											<Autocomplete
												size="small"
												options={points}
												getOptionLabel={(option) =>
													option.pointName + ' , ' + option.pointDesc
												}
												fullWidth
												disabled={spinner}
												renderInput={(params) => (
													<TextField
														{...params}
														label={t(
															TransText.widgetEdit.selectPoint,
															null,
															lang,
															[]
														)}
														//variant="outlined"
													/>
												)}
												value={selectedPoint}
												onChange={(e, value) => {
													if (value !== null) onSelectedPoint(value!);
												}}
											/>
											{spinner && (
												<CircularProgress
													size={24}
													className={classes.buttonProgress}
												/>
											)}
										</div>
									</Box>

									{/* Columns */}
									<Box flexDirection="column" display="flex" m={2} mb={2}>
										<Box>
											{widgetInfo.widgetTypeConfig.columns !== undefined
												? widgetInfo.widgetTypeConfig.columns.map(
														(column: Table1Column) => (
															<ColumnItemTable1
																key={column.headerName}
																column={column}
																onEdit={onEditColumn}
															/>
														)
												  )
												: ''}
										</Box>
									</Box>
								</Box>
							</Paper>
						</Grid>
					</Grid>
					{editColumn != null ? (
						<ColumnDialogTable1
							open={editColumn != null ? true : false}
							column={editColumn!}
							onCancel={onCancelEditColumn}
							onUpdate={onSaveEditColumn}
						/>
					) : (
						''
					)}
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
};
