import axios from 'axios';
import { NanoRequestDTO, PointHistoryRequestDTO } from '../model/pointModel';
import { LoggedOnUser } from '../model/userModel';
import { store } from '../redux/store';

export class PointAPI {
	baseUrl: string;
	loggedOnUser: LoggedOnUser | null;

	constructor() {
		const state = store.getState();
		this.baseUrl = state.app.baseUrl;
		this.loggedOnUser = state.app.loggedOnUser;
	}

	public GetPoints = async (
		customerId: string,
		search: string,
		maxRows: number
	) => {
		return await axios.get(
			this.baseUrl +
				'point/GetPoints?customerId=' +
				customerId +
				'&search=' +
				search +
				'&maxRows=' +
				maxRows,
			{
				headers: { Authorization: `Bearer ${this.loggedOnUser?.accessToken}` },
			}
		);
	};

	public GetPointsHistory = async (
		pointHistoryRequestDTO: PointHistoryRequestDTO
	) => {
		return await axios.post(
			this.baseUrl + 'point/GetPointsHistory',
			pointHistoryRequestDTO,
			{
				headers: { Authorization: `Bearer ${this.loggedOnUser?.accessToken}` },
			}
		);
	};

	public NanoRequest = async (pcRequestDTO: NanoRequestDTO) => {
		return await axios.post(this.baseUrl + 'point/NanoRequest', pcRequestDTO, {
			headers: { Authorization: `Bearer ${this.loggedOnUser?.accessToken}` },
		});
	};
}
