import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Table1Column } from '../../../../model/dashModel';

interface IProps {
	column: Table1Column;

	onEdit: (column: Table1Column) => any;
}

export const ColumnItemTable1: React.FC<IProps> = (props) => {
	const editWidget = () => {
		props.onEdit(props.column);
	};

	return (
		<React.Fragment>
			<Box display="flex" flexDirection="column">
				<Box display="flex" alignItems="center">
					<Box
						m={3}
						onTouchStart={(e) => {
							e.stopPropagation();
							editWidget();
						}}
						onClick={(e) => {
							e.stopPropagation();
							editWidget();
						}}
					>
						{true ? <FontAwesomeIcon icon={faEdit} /> : ''}
					</Box>

					<Typography variant="h6">
						<Box fontSize="16px">{props.column.headerName}</Box>
					</Typography>
				</Box>
				<Typography variant="h6">
					<Box ml={3} fontSize="14px">
						{props.column == null || props.column.field == null
							? ''
							: props.column.field}
					</Box>
				</Typography>
			</Box>
		</React.Fragment>
	);
};
