import React, { useEffect } from 'react';
import 'moment/locale/se';
import 'moment/locale/en-gb';
import {
	Box,
	Button,
	CssBaseline,
	makeStyles,
	Paper,
	TextField,
	Typography,
	Grid,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { IAppState } from '../redux/store';
import { NavBar } from '../components/navBar';
import Translation from '../services/translation';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import TransText from '../resource/transText';

const useStyles = makeStyles((theme) => ({
	layoutLanguage: {
		flexGrow: 1,
		margin: 20,
	},
	title: {
		margin: 20,
	},
	wrapper: {
		position: 'relative',
	},
	inputLable: {
		minWidth: '200px',
		fontSize: '0.9em',
	},
	inputBox: {
		height: 10,
		padding: 8,
		fontSize: '1em',
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	disableDiv: {
		opacity: 0.5,
		pointerEvents: 'none',
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: '25ch',
	},
	smallSize: {
		fontSize: 13,
		fontWeight: 400,
	},
}));

export const MyProfile = (props: any) => {
	const { lang, loggedOnUser } = useSelector((state: IAppState) => state.app);
	const classes = useStyles();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	const [spinner] = React.useState(false);
	const { t } = Translation;

	useEffect(() => {
		if (props.location.state && props.location.state.redirect) {
			enqueueSnackbar(
				t(TransText.login.pageProtectedLogin, null, lang, [
					props.location.state.redirect,
				]),
				{
					variant: 'warning',
				}
			);
		}

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props]);

	const handleOk = () => {
		history.push('/');
	};

	return (
		<React.Fragment>
			<CssBaseline />
			<NavBar />

			<Grid container justify="center">
				<Grid item xs={12} sm={6} lg={4}>
					<Box p={4}>
						<Paper>
							<Box display="flex" justifyContent="center" mt={2} mb={2}>
								<Typography variant="h5" noWrap>
									{Translation.t(TransText.myProfile.title, null, lang, [])}
								</Typography>
							</Box>

							<Box display="flex" p={4}>
								<TextField
									className={classes.textField}
									style={{ width: '25ch' }}
									InputProps={{
										classes: {
											input: classes.smallSize,
										},
										readOnly: true,
									}}
									label={t(TransText.login.account, null, lang, [])}
									margin="dense"
									variant="outlined"
									value={loggedOnUser?.user.email}
								/>
								<TextField
									className={classes.textField}
									style={{ width: '20ch' }}
									InputProps={{
										classes: {
											input: classes.smallSize,
										},
										readOnly: true,
									}}
									label={t(TransText.myProfile.roles, null, lang, [])}
									margin="dense"
									variant="outlined"
									value={loggedOnUser?.roles.join()}
								/>
							</Box>
							<Box display="flex" justifyContent="flex-end" p={4}>
								{/* <Box mr={2}>
									<RedButton
										variant="contained"
										color="primary"
										disabled={spinner}
										onClick={handleCancel}
									>
										{Translation.t(TransText.app.cancel, lang, [])}
									</RedButton>
								</Box> */}
								<div className={classes.wrapper}>
									<Button
										variant="contained"
										color="primary"
										disabled={spinner}
										onClick={handleOk}
									>
										{Translation.t(TransText.app.ok, null, lang, [])}
									</Button>
									{spinner && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</div>
							</Box>
						</Paper>
					</Box>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};
