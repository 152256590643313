import {
	Box,
	CssBaseline,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	makeStyles,
	Menu,
	MenuItem,
	Paper,
	Popover,
	Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import '../../node_modules/react-grid-layout/css/styles.css';
import Highcharts from 'highcharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCogs,
	faEdit,
	faFolderOpen,
	faHome,
	faPlus,
	faInfoCircle,
	faSave,
	faTrashAlt,
	faWindowClose,
} from '@fortawesome/free-solid-svg-icons';
import Translation from '../services/translation';
import TransText from '../resource/transText';
import { IAppState } from '../redux/store';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { enTimeUnitType, RefObj, WidgetInfo } from '../model/pointModel';
import { Widget } from '../components/dashboard/widget';
import { WidgetEdit } from '../components/dashboard/widgetEdit';
import {
	DashBoardDTO,
	DashBoardResponse,
	DeleteDashBoardResponse,
	UpdateDashBoardResponse,
} from '../model/customerModel';

import { AdminAPI } from '../services/adminAPI';
import { ComfirmDialog } from '../components/confirmDialog';
import { useSnackbar } from 'notistack';
import { GetStringDialog } from '../components/getStringDialog';
import { CreateGuid } from '../services/misc';
const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	layout: {
		backgroundColor: '#e0e0e0',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaper: {
		width: drawerWidth,
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(28) + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(28) + 1,
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(1),
	},
	appBarDialog: {
		position: 'relative',
	},
	titleDialog: {
		marginLeft: theme.spacing(2),
		flexGrow: 1,
	},
	wrapper: {
		position: 'relative',
	},
	progress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	widgetNormalStyle: {
		width: '100%',
		height: '100%',

		backgroundColor: 'white',
		border: '',
	},
	widgetErrorStyle: {
		width: '100%',
		height: '100%',

		backgroundColor: 'white',
		border: '4px solid red',
	},
}));

const ResponsiveGridLayout = WidthProvider(Responsive);

export const Dash: React.FC = () => {
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const { lang, loggedOnUser } = useSelector((state: IAppState) => state.app);
	const classes = useStyles();
	const { t } = Translation;
	const [open, setOpen] = React.useState(false);
	const [edit, setEdit] = React.useState<boolean>(false);
	const [addWidgetAnchorEl, setAddWidgetAnchorEl] =
		React.useState<null | HTMLElement>(null);

	const [selectDashMenuAncorEl, setSelectDashMenuAncorEl] =
		React.useState<null | HTMLElement>(null);

	const [selectedWidget, setSelectedWidget] = React.useState<WidgetInfo>();

	const [widgets, setWidgets] = React.useState<WidgetInfo[]>([]);

	const [widgetsOrg, setWidgetsOrg] = React.useState<string>('');

	const [layout, setLayout] = React.useState<any>({
		lg: [],
		md: [],
		sm: [],
	});

	const [layoutOrg, setLayoutOrg] = React.useState<string>('');

	const [getDashBoardsSpinner, setGetDashBoardsSpinner] =
		React.useState<boolean>(false);

	const [cancelDashChanges, setCancelDashChanges] =
		React.useState<boolean>(false);

	const widgetRefs = React.useRef<RefObj[]>([]);

	const [dashBoardOrg, setDashBoardOrg] = React.useState<string>();

	const [dashBoard, setDashBoard] = React.useState<DashBoardDTO>();

	const [dashBoards, setDashBoards] = React.useState<DashBoardDTO[]>([]);

	const [dashSettingsDlg, setDashSettingsDlg] = React.useState<boolean>(false);

	const [selectDashNameDlg, setSelectDashNameDlg] =
		React.useState<boolean>(false);

	const [deleteDashConfirmDlg, setDeleteDashConfirmDlg] =
		React.useState<boolean>(false);

	const [widgetErrorEl, setWidgetErrorEl] = React.useState(null);

	const openWidgetErrorPop = Boolean(widgetErrorEl);
	const openWidgetErrorId = openWidgetErrorPop
		? 'widgetError-popover'
		: undefined;

	useEffect(() => {
		onResize(null);
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [layout, widgets]);

	const getDashBoardList = () => {
		setGetDashBoardsSpinner(true);

		let API: AdminAPI = new AdminAPI();
		API.GetCustomerDashBoards(
			loggedOnUser?.tenant == null ? '' : loggedOnUser.tenant
		)
			.then((resp) => {
				if (resp.status >= 200 && resp.status < 300) {
					let dashBoardResponse: DashBoardResponse;
					dashBoardResponse = resp.data;

					if (
						dashBoardResponse.result === '' &&
						dashBoardResponse.dashBoardDTOs.length > 0
					) {
						setDashBoards(dashBoardResponse.dashBoardDTOs);
					}
				}
			})
			.catch((e) => {
        console.log("error" + e);
      })
			.finally(() => {
				setGetDashBoardsSpinner(false);
			});
	};

	const getDashBoard = (dashBoardId: string) => {
		setGetDashBoardsSpinner(true);

		let API: AdminAPI = new AdminAPI();
		API.GetCustomerDashBoard(
			loggedOnUser?.tenant == null ? '' : loggedOnUser.tenant,
			dashBoardId
		)
			.then((resp) => {
				if (resp.status >= 200 && resp.status < 300) {
					let dashBoardResponse: DashBoardResponse;
					dashBoardResponse = resp.data;

					if (
						dashBoardResponse.result === '' &&
						dashBoardResponse.dashBoardDTOs.length > 0
					) {
						activateSelectedDash(dashBoardResponse.dashBoardDTOs[0]);
					}
				}
			})
			.catch((e) => {
        console.log("Failed");

      })
			.finally(() => {
				setGetDashBoardsSpinner(false);
			});
	};
	const updateDashBoard = (
		dashName: string,
		cleanWidgetInfos: WidgetInfo[]
	) => {
		let dashBoardDTO: DashBoardDTO;

		if (dashBoard === undefined) {
			dashBoardDTO = {
				customerId: loggedOnUser?.tenant == null ? '' : loggedOnUser.tenant,
				layoutJson: JSON.stringify(layout),
				widgetJson: JSON.stringify(cleanWidgetInfos),
				name: dashName,
				result: '',
				id: '',
				hideNav: false,
				hiteTitle: false,
			};
		} else {
			dashBoardDTO = dashBoard;
			dashBoardDTO.name = dashName;
			dashBoardDTO.layoutJson = JSON.stringify(layout);
			dashBoardDTO.widgetJson = JSON.stringify(cleanWidgetInfos);
		}

		let API: AdminAPI = new AdminAPI();
		API.UpdateCustomerDashBoard(dashBoardDTO)
			.then((resp) => {
				if (resp.status >= 200 && resp.status < 300) {
					let updateDashBoardResponse: UpdateDashBoardResponse;
					updateDashBoardResponse = resp.data;

					if (updateDashBoardResponse.result !== '') {
						enqueueSnackbar(
							t(TransText.error, updateDashBoardResponse.result, lang, []),
							{
								variant: 'error',
							}
						);
					} else {
						if (
							updateDashBoardResponse.result === undefined ||
							updateDashBoardResponse.result === ''
						) {
							dashBoardDTO.id = updateDashBoardResponse.id;

							setDashBoard(dashBoardDTO);
						}
					}
				}
			})
			.catch(() => {})
			.finally(() => {});
	};
	const deleteDashBoard = () => {
		let API: AdminAPI = new AdminAPI();
		API.DeleteCustomerDashBoard(dashBoard!)
			.then((resp) => {
				if (resp.status >= 200 && resp.status < 300) {
					let response: DeleteDashBoardResponse;
					response = resp.data;

					if (response.result !== '') {
						enqueueSnackbar(t(TransText.error, response.result, lang, []), {
							variant: 'error',
						});
					} else {
						if (response.result === '') {
							setDashBoard(undefined);
							setLayout({});
							setWidgets([]);
						}
					}
				}
			})
			.catch(() => {})
			.finally(() => {});
	};
	const activateSelectedDash = (dashBoard: DashBoardDTO) => {
		let wi = JSON.parse(dashBoard.widgetJson);
		if (wi.length > 0) if (wi[0] == null) wi = [];

		let c = 0;
		let w = [...widgets];
		w = [];
		wi.map((x: WidgetInfo) => {
			x.widgetRefIndex = c;
			c++;

			x.error = undefined;
			w.push(x);
			return x;
		});

		setWidgets(wi);
		setWidgetsOrg(dashBoard.widgetJson);

		setLayout(JSON.parse(dashBoard.layoutJson));
		setLayoutOrg(dashBoard.layoutJson);

		setDashBoardOrg(JSON.stringify(dashBoard));
		setDashBoard(dashBoard);

		setSelectDashMenuAncorEl(null);
	};
	const onNewDash = () => {
		setDashBoard({
			...dashBoard,
			name: 'New dash',
			id: '',
			customerId: loggedOnUser === null ? '' : loggedOnUser.tenant,
			widgetJson: '',
			layoutJson: '',
			result: '',
			hideNav: false,
			hiteTitle: false,
		});

		setWidgetsOrg('');
		setLayoutOrg('');
		setWidgets([]);
		setLayout({ lg: [], md: [] });

		setEdit(true);
	};
	const editSave = () => {
		if (edit) {
			if (dashBoard?.name.toLowerCase() === 'new dash') {
				setSelectDashNameDlg(true);
			} else storeDashBoard(dashBoard === undefined ? '' : dashBoard.name);
		} else {
			setEdit(true);
		}
	};
	const storeDashBoard = (dashName: string) => {
		let cleanWidgetInfos: WidgetInfo[] = [];

		widgets.map((w: WidgetInfo) => {
			if (
				cleanWidgetInfos.find((x) => {
					return x.i === w.i;
				})
			) {
				console.log('Duplicate widget ' + w.title + ' not saved');
			} else
				cleanWidgetInfos.push(
					widgetRefs.current[w.widgetRefIndex].CleanWidgetTypeConfigFromPvs()
				);
			return w;
		});

		updateDashBoard(dashName, cleanWidgetInfos);

		setWidgets(cleanWidgetInfos);

		setEdit(false);
	};
	const onCancelDash = () => {
		if (
			widgetsOrg !== JSON.stringify(widgets) ||
			layoutOrg !== JSON.stringify(layout) ||
			dashBoardOrg !== JSON.stringify(dashBoard)
		)
			setCancelDashChanges(true);
		else {
			setEdit(false);
		}
	};
	const onCancelCancelDash = () => {
		setCancelDashChanges(false);
	};
	const onConfirmCancelDash = () => {
		setWidgets(JSON.parse(widgetsOrg));
		setLayout(JSON.parse(layoutOrg));

		setCancelDashChanges(false);
		setEdit(false);
	};
	const onCancelSelectDashName = () => {
		setSelectDashNameDlg(false);
	};
	const onConfirmSelectDashName = (dashName: string) => {
		setDashBoard({ ...dashBoard!, name: dashName });
		setSelectDashNameDlg(false);
		storeDashBoard(dashName);
	};
	const onDeleteDashboard = () => {
		setDeleteDashConfirmDlg(true);
	};
	const onCancelDashDelete = () => {
		setDeleteDashConfirmDlg(false);
	};
	const onConfirmDashDelete = () => {
		deleteDashBoard();

		setDeleteDashConfirmDlg(false);
		setEdit(false);
	};
	const onDashSettings = () => {
		setDashSettingsDlg(true);
	};
	const onConfirmDashSetting = (dashName: string) => {
		setDashBoard({ ...dashBoard!, name: dashName });

		setDashSettingsDlg(false);
	};
	const onCancelDashSetting = () => {
		setDashSettingsDlg(false);
	};
	const handleSelectDash = (event: any) => {
		getDashBoardList();
		setSelectDashMenuAncorEl(event.target);
	};
	const handleDrawerCloseOpen = () => {
		setOpen(!open);
	};
	const onResize = (e: any) => {
		for (var i = 0; i < Highcharts.charts.length; i++) {
			Highcharts.charts[i]?.reflow();
		}
	};
	const onLayoutChange = (currentLayout: Layout[], allLayouts: Layouts) => {
		setLayout(allLayouts);
	};
	const addWidget = (type: string) => {
		let newWidgets = widgets.filter((w) => {
			if (w.title.startsWith('New widget')) return true;
			else return false;
		});
		let lpNr = 0;
		newWidgets.forEach((newW) => {
			let wlpnr = Number.parseInt(newW.title.replace('New widget ', ''));
			if (wlpnr > lpNr) lpNr = wlpnr;
		});
		lpNr += 1;

		setWidgets([
			...widgets,
			{
				i: CreateGuid(),
				x: 0,
				y: 0,
				w: 20,
				h: 12,
				type: type,
				title: 'New widget ' + lpNr,
				points: [],
				refresh: {
					subscription: false,
					start: 0,
					startUnit: enTimeUnitType[
						enTimeUnitType.Hour
					] as keyof typeof enTimeUnitType,
					last: 1,
					lastUnit: enTimeUnitType[
						enTimeUnitType.Hour
					] as keyof typeof enTimeUnitType,
					group: 10,
					groupUnit: enTimeUnitType[
						enTimeUnitType.Second
					] as keyof typeof enTimeUnitType,
					refresh: 30,
					refreshUnit: enTimeUnitType[
						enTimeUnitType.Second
					] as keyof typeof enTimeUnitType,
				},
				widgetTypeConfig: undefined,
				widgetRefIndex: widgets.length,
				error: undefined,
			},
		]);

		setAddWidgetAnchorEl(null);
	};
	const removeWidget = (i: string) => {
		setWidgets(widgets.filter((item) => item.i !== i));
		setSelectedWidget(undefined);
	};
	const editWidget = (i: string) => {
		let w = widgets.find((x) => x.i === i);

		setSelectedWidget(JSON.parse(JSON.stringify(w)));
	};
	const saveWidget = (widgetInfo: WidgetInfo) => {
		let arrIndex = widgets.findIndex((x) => x.i === widgetInfo.i);
		let wi = [...widgets];
		wi[arrIndex] = widgetInfo;

		setWidgets(wi);

		setSelectedWidget(undefined);
	};

	const handleSelectWidgetToAdd = (event: any) => {
		setAddWidgetAnchorEl(event.currentTarget);
	};
	const handleWidgetUpdateDefaultConfig = (widgetInfo: WidgetInfo) => {
		saveWidget(widgetInfo);
	};

	const handleWidgetError = (widgetInfo: WidgetInfo) => {
		let arrIndex = widgets.findIndex((x) => x.i === widgetInfo.i);
		let wi = [...widgets];
		if (wi[arrIndex].error !== widgetInfo.error) {
			wi[arrIndex].error = widgetInfo.error;
			setWidgets(wi);

			setSelectedWidget(undefined);
		}
	};

	const getWidgetStateColor = (widgetInfo: any): any => {
		if (widgetInfo.error !== undefined) return classes.widgetErrorStyle;
		else return classes.widgetNormalStyle;
	};

	const openWidgetError = (event: any) => {
		setWidgetErrorEl(event.currentTarget);
	};

	const closeWidgetError = () => {
		setWidgetErrorEl(null);
	};


  const isEditAvailable = () =>{
    return (loggedOnUser?.roles.includes('SUPER') ||
					loggedOnUser?.roles.includes('SUPPORT') ||
					loggedOnUser?.roles.includes('CUST_ADMIN'))
  }


	return (
		<React.Fragment>
			<div className={classes.root}>
				<CssBaseline />
				<Drawer
					variant="permanent"
					className={clsx(classes.drawer, {
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					})}
					classes={{
						paper: clsx({
							[classes.drawerOpen]: open,
							[classes.drawerClose]: !open,
						}),
					}}
				>
					<div className={classes.toolbar}>
						<IconButton onClick={handleDrawerCloseOpen}>
							{!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
						</IconButton>
					</div>

					<Divider />

					<List>
						<ListItem button key="Home" onClick={() => history.push('./')}>
							<ListItemIcon>
								<FontAwesomeIcon icon={faHome} />
							</ListItemIcon>
							<ListItemText primary={t(TransText.app.home, null, lang, [])} />
						</ListItem>
						{!edit ? (
							<div>
                { isEditAvailable()?(
								<ListItem button key="NewDash" onClick={onNewDash}>
									<ListItemIcon>
										<FontAwesomeIcon icon={faPlus} />
									</ListItemIcon>
									<ListItemText
										primary={t(TransText.dash.addNewDash, null, lang, [])}
									/>
								</ListItem>
                ):''}

								<ListItem
									button
									key="SelectDash"
									disabled={getDashBoardsSpinner}
									aria-controls="simple-menu"
									aria-haspopup="true"
									onClick={(e) => {
										handleSelectDash(e);
									}}
								>
									<ListItemIcon>
										<FontAwesomeIcon icon={faFolderOpen} />
									</ListItemIcon>
									<ListItemText
										primary={t(TransText.dash.selectDash, null, lang, [])}
									/>
								</ListItem>

								<Menu
									id="simple-menu"
									anchorEl={selectDashMenuAncorEl}
									keepMounted
									open={Boolean(selectDashMenuAncorEl)}
									onClose={() => {
										setSelectDashMenuAncorEl(null);
									}}
								>
									{dashBoards.map((dash, key) => {
										return (
											<MenuItem
												key={key}
												onClick={() => {
													getDashBoard(dash.id);
												}}
											>
												{dash.name}
											</MenuItem>
										);
									})}
								</Menu>
							</div>
						) : (
							''
						)}
						{(dashBoard !== undefined && isEditAvailable() ) ? (
							<ListItem button key="EditDash" onClick={editSave}>
								<ListItemIcon>
									{!edit ? (
										<FontAwesomeIcon icon={faEdit} />
									) : (
										<FontAwesomeIcon icon={faSave} />
									)}
								</ListItemIcon>
								{!edit ? (
									<ListItemText
										primary={t(TransText.dash.editDash, null, lang, [])}
									/>
								) : (
									<ListItemText
										primary={t(TransText.app.save, null, lang, [])}
									/>
								)}
							</ListItem>
						) : (
							''
						)}

						{edit ? (
							<div>
								<ListItem button key="Settings" onClick={onDashSettings}>
									<ListItemIcon>
										<FontAwesomeIcon icon={faCogs} />
									</ListItemIcon>
									<ListItemText
										primary={t(TransText.app.settings, null, lang, [])}
									/>
								</ListItem>
								<ListItem button key="Delete" onClick={onDeleteDashboard}>
									<ListItemIcon>
										<FontAwesomeIcon icon={faTrashAlt} />
									</ListItemIcon>
									<ListItemText
										primary={t(TransText.app.remove, null, lang, [])}
									/>
								</ListItem>
								<ListItem button key="Cancel" onClick={onCancelDash}>
									<ListItemIcon>
										<FontAwesomeIcon icon={faWindowClose} />
									</ListItemIcon>
									<ListItemText
										primary={t(TransText.app.cancel, null, lang, [])}
									/>
								</ListItem>
							</div>
						) : (
							''
						)}
						{edit ? (
							<div>
								<ListItem
									aria-controls="simple-menu"
									aria-haspopup="true"
									onClick={handleSelectWidgetToAdd}
								>
									<ListItemIcon>
										<FontAwesomeIcon icon={faPlus} />
									</ListItemIcon>
									<ListItemText
										primary={t(TransText.dash.addNewWidget, null, lang, [])}
									/>
								</ListItem>
								<Menu
									id="simple-menu"
									anchorEl={addWidgetAnchorEl}
									keepMounted
									open={Boolean(addWidgetAnchorEl)}
									onClose={() => {
										setAddWidgetAnchorEl(null);
									}}
								>
									<MenuItem
										onClick={() => {
											addWidget('line_1');
										}}
									>
										Line
									</MenuItem>
									<MenuItem
										onClick={() => {
											addWidget('bar_1');
										}}
									>
										Bar
									</MenuItem>
									<MenuItem
										onClick={() => {
											addWidget('image_1');
										}}
									>
										Image
									</MenuItem>
									<MenuItem
										onClick={() => {
											addWidget('gauge_2');
										}}
									>
										Arc gauge
									</MenuItem>
									<MenuItem
										onClick={() => {
											addWidget('table_1');
										}}
									>
										Table
									</MenuItem>
									<MenuItem
										onClick={() => {
											addWidget('textvar_1');
										}}
									>
										TextVar
									</MenuItem>
									<MenuItem
										onClick={() => {
											addWidget('textvar_2');
										}}
									>
										TextVar_2
									</MenuItem>
								</Menu>
							</div>
						) : (
							''
						)}
					</List>
				</Drawer>

				<main className={classes.content}>
					<Box display="flex" justifyContent="center">
						<Typography variant="h6">
							{loggedOnUser?.tenantName} {' ' + dashBoard?.name}
						</Typography>
					</Box>
					<ResponsiveGridLayout
						isDraggable={edit === true} // Readonly
						isResizable={edit === true} // Readonly
						layouts={layout}
						breakpoints={{ lg: 1200, md: 900, sm: 600, xs: 480 }}
						cols={{ lg: 120, md: 30, sm: 30, xs: 10 }}
						rowHeight={10}
						resizeHandles={['se', 'e', 's']}
						onLayoutChange={onLayoutChange}
					>
						{widgets.map((widgetInfo) => {
							return (
								<div
									key={widgetInfo.i}
									data-grid={widgetInfo}
									className={classes.layout}
								>
									<Paper
										elevation={4}
										className={getWidgetStateColor(widgetInfo)}
									>
										<Box
											display="flex"
											flexDirection="column"
											style={{ height: '100%', width: '100%' }}
										>
											<Box display="flex" justifyContent="space-between">
												<Box
													aria-describedby={openWidgetErrorId}
													m={3}
													onTouchStart={(e) => {
														e.stopPropagation();
														openWidgetError(e);
													}}
													onClick={(e) => {
														e.stopPropagation();
														openWidgetError(e);
													}}
												>
													{widgetInfo.error !== undefined ? (
														<FontAwesomeIcon
															style={{ color: 'red' }}
															icon={faInfoCircle}
														/>
													) : (
														''
													)}
												</Box>
												<Popover
													id={openWidgetErrorId}
													open={openWidgetErrorPop}
													anchorEl={widgetErrorEl}
													onClose={closeWidgetError}
													anchorOrigin={{
														vertical: 'bottom',
														horizontal: 'left',
													}}
												>
													<Typography variant="body2">
														<Box p={2} maxWidth={'400px'}>
															{widgetInfo.error}
														</Box>
													</Typography>
												</Popover>

												<Box>
													<Typography variant="h6">
														<Box fontSize="16px">{widgetInfo.title}</Box>
													</Typography>
												</Box>

												<Box
													m={3}
													onTouchStart={(e) => {
														e.stopPropagation();
														editWidget(widgetInfo.i);
													}}
													onClick={(e) => {
														e.stopPropagation();
														editWidget(widgetInfo.i);
													}}
												>
													{edit ? <FontAwesomeIcon icon={faEdit} /> : ''}
												</Box>
											</Box>

											<Widget
												widgetUpdateDefaultConfigUpdate={
													handleWidgetUpdateDefaultConfig
												}
												widgetError={handleWidgetError}
												widgetInfo={widgetInfo}
												ref={(ref: any) => {
													if (ref != null)
														widgetRefs.current[widgetInfo.widgetRefIndex] = ref;
												}}
											/>
										</Box>
									</Paper>
								</div>
							);
						})}
					</ResponsiveGridLayout>
					{selectedWidget !== undefined ? (
						<WidgetEdit
							open={selectedWidget !== undefined ? true : false}
							selectedWidgetInfo={selectedWidget!}
							remove={removeWidget}
							cancel={() => {
								setSelectedWidget(undefined);
							}}
							save={(widgetInfo) => {
								saveWidget(widgetInfo);
							}}
						/>
					) : (
						''
					)}
				</main>

				<ComfirmDialog
					showCancelButton={true}
					open={cancelDashChanges}
					titleText={t(TransText.dash.cancelDashChanges, null, lang, [])}
					contentText={''}
					cancelButtonText={t(TransText.app.no, null, lang, [])}
					confirmButtonText={t(TransText.app.yes, null, lang, [])}
					onCancel={onCancelCancelDash}
					onConfirm={onConfirmCancelDash}
				/>

				<ComfirmDialog
					showCancelButton={true}
					open={deleteDashConfirmDlg}
					titleText={t(TransText.dash.deleteDash, null, lang, [])}
					contentText={dashBoard === undefined ? '' : 'Dash: ' + dashBoard.name}
					cancelButtonText={t(TransText.app.no, null, lang, [])}
					confirmButtonText={t(TransText.app.yes, null, lang, [])}
					onCancel={onCancelDashDelete}
					onConfirm={onConfirmDashDelete}
				/>

				<GetStringDialog
					open={selectDashNameDlg}
					titleText={t(TransText.dash.selectDashName, null, lang, [])}
					contentText={dashBoard === undefined ? '' : dashBoard.name}
					cancelButtonText={t(TransText.app.no, null, lang, [])}
					confirmButtonText={t(TransText.app.ok, null, lang, [])}
					onCancel={onCancelSelectDashName}
					onConfirm={onConfirmSelectDashName}
				/>

				<GetStringDialog
					open={dashSettingsDlg}
					titleText={t(TransText.dash.selectDashName, null, lang, [])}
					contentText={dashBoard === undefined ? '' : dashBoard.name}
					cancelButtonText={t(TransText.app.no, null, lang, [])}
					confirmButtonText={t(TransText.app.ok, null, lang, [])}
					onCancel={onCancelDashSetting}
					onConfirm={onConfirmDashSetting}
				/>
			</div>
		</React.Fragment>
	);
};
