import React from 'react';
import { ColorResult, CirclePicker } from 'react-color';

export interface ColorPickerParam {
	colorId: any;
	color: string;
}

interface ColorPickerProps {
	param: ColorPickerParam;
	onSelectedColor: (param: ColorPickerParam) => any;
}

export const ColorPicker = (props: ColorPickerProps) => {
	return (
		<React.Fragment>
			<CirclePicker
				color={props.param.color}
				onChange={(color) => {
					props.onSelectedColor({
						colorId: props.param.colorId,
						color: color.hex,
					});
				}}
				colors={[
					'#000000', // Black
					'#999999', // Grey

					'#9F0500', // Dark red
					'#FF0000', // Red

					'#f57c00', // Orange
					'#ffb74d', // Light Orange

					'#194d33', // Green
					'#00FF00', // Light Green

					'#D3D405', // Dark yellow
					'#FDFF0D', // Yellow

					'#0062b1', // Dark blue
					'#0000ff', // Blue

					'#653294', // Purple
					'#aea1ff', // Light Purple

					'#ab149e', // Pink
					'#fda1ff', // Light pink
				]}
			/>
		</React.Fragment>
	);
};
