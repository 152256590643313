import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { IAppState } from '../redux/store';

export const ProtectedRoute = ({ component, authorization, ...rest }: any) => {
	const { loggedOnUser } = useSelector((state: IAppState) => state.app);

	var authorizedUser: boolean = false;

	if (
		loggedOnUser &&
		(loggedOnUser.roles.filter((role) => {
			if (authorization.find((auth: string) => auth === role) !== undefined)
				return true;
			return false;
		}).length > 0 ||
			authorization.length === 0)
	)
		authorizedUser = true;

	const routeComponent = (props: any) =>
		authorizedUser ? (
			React.createElement(component, props)
		) : (
			<Redirect
				to={{ pathname: '/login', state: { redirect: rest.location.pathname } }}
			/>
		);

	return <Route {...rest} render={routeComponent} />;
};
