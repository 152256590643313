import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { blue, green } from '@material-ui/core/colors';

import { compose } from 'redux';
import { connect } from 'react-redux';
import './app.css';

import { Login } from './pages/loginPage';
import Home from './pages/homePage';
import { Dash } from './pages/dash';
import { PublicDash } from './pages/publicDash';
import { MyProfile } from './pages/myProfile';
import { Customer } from './pages/customer';
import { Customers } from './pages/customers';
import { Nano } from './pages/nano';
import { Nanos } from './pages/nanos';
import { Pc } from './pages/pc';
import { ProtectedRoute } from './components/protectedRoute';
import { Dispatch } from 'react';
import {
	SetLanguage,
	SetBaseUrl,
	SetBuildNr,
	IAction,
	SetLoggedOnUser,
} from './redux/actions';
import { IAppState } from './redux/store';
import 'typeface-roboto';
import { SnackbarProvider } from 'notistack';
import { EnumConstant, EnumRoleTypes, LoggedOnUser } from './model/userModel';
import { LicenseInfo } from '@mui/x-data-grid-pro';
let theme = createTheme({
	palette: {
		background: {
			default: '#FFFFFF',
		},
		primary: {
			main: blue[500],
		},
		warning: {
			main: green[600],
		},
	},
	spacing: 2,
	overrides: {
		// Style sheet name ⚛️
		MuiDialogTitle: {
			root: {
				backgroundColor: blue[500],
				color: 'white',
			},
		},
	},
});

theme = responsiveFontSizes(theme);

interface IState {
	lang: string;
}

interface IProps {
	lang: string;
	buildNr: string;
	baseUrl: string;

	setLanguage(lang: string): void;
	setBuildNr(buildNr: string): void;
	setBaseUrl(url: string): void;
	setLoggedOnUser(loggedOnUser: LoggedOnUser): void;
}

class App extends Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		const { setBuildNr, setBaseUrl, setLoggedOnUser } = this.props;
		setBuildNr(props.buildNr);

		LicenseInfo.setLicenseKey(
			'41a7b0792907bdc5fc0ea84b1964cc28T1JERVI6MjE1NjQsRVhQSVJZPTE2NDUzNjQwOTg3NDEsS0VZVkVSU0lPTj0x'
		);

		if (
			process.env.REACT_APP_BASEURL === undefined ||
			process.env.REACT_APP_BASEURL.length === 0
		)
			setBaseUrl(window.location.origin + '/api/');
		else setBaseUrl(process.env.REACT_APP_BASEURL);

		let json = sessionStorage.getItem(EnumConstant.LOGGEDONUSER);
		if (json && json.length !== 0) {
			setLoggedOnUser(JSON.parse(json));
		}
	}

	render() {
		return (
			<ThemeProvider theme={theme}>
				<SnackbarProvider maxSnack={4}>
					<Router>
						<Switch>
							<Route path="/login" render={(props) => <Login {...props} />} />

							<ProtectedRoute
								path="/dash"
								component={Dash}
								authorization={[
									EnumRoleTypes.SUPER,
									EnumRoleTypes.SUPPORT,
									EnumRoleTypes.CUST_ADMIN,
									EnumRoleTypes.CUST_VIEW,
									EnumRoleTypes.CUST_CONTRIB,
								]}
							/>

							<ProtectedRoute
								path="/customers"
								component={Customers}
								authorization={[EnumRoleTypes.SUPER, EnumRoleTypes.SUPPORT]}
							/>

							<ProtectedRoute
								path="/customer/:tenantId?"
								component={Customer}
								authorization={[
									EnumRoleTypes.SUPER,
									EnumRoleTypes.SUPPORT,
									EnumRoleTypes.CUST_ADMIN,
								]}
							/>

							<ProtectedRoute
								path="/nanos"
								component={Nanos}
								authorization={[
									EnumRoleTypes.SUPER,
									EnumRoleTypes.SUPPORT,
									EnumRoleTypes.CUST_ADMIN,
									EnumRoleTypes.CUST_CONTRIB,
								]}
							/>

							<ProtectedRoute
								path="/nano/:nanoId"
								component={Nano}
								authorization={[
									EnumRoleTypes.SUPER,
									EnumRoleTypes.SUPPORT,
									EnumRoleTypes.CUST_ADMIN,
									EnumRoleTypes.CUST_CONTRIB,
								]}
							/>

							<ProtectedRoute
								path="/pc/:pcType/:pcId/:nanoId"
								component={Pc}
								authorization={[
									EnumRoleTypes.SUPER,
									EnumRoleTypes.SUPPORT,
									EnumRoleTypes.CUST_ADMIN,
									EnumRoleTypes.CUST_CONTRIB,
								]}
							/>

							<ProtectedRoute
								path="/myProfile"
								component={MyProfile}
								authorization={[
									EnumRoleTypes.SUPER,
									EnumRoleTypes.SUPPORT,
									EnumRoleTypes.CUST_ADMIN,
									EnumRoleTypes.CUST_VIEW,
									EnumRoleTypes.CUST_CONTRIB,
								]}
							/>

							{/* <ProtectedRoute
								path="/DashTestWidgets"
								component={DashTestWidgets}
								authorization={[EnumRoleTypes.SUPER]}
							/> */}

							{/* <ProtectedRoute
								path="/componenttest"
								component={ComponentTest}
								authorization={[EnumRoleTypes.SUPER]}
							/> */}

							<Route
								path="/publicDash/:apiKey/:dashBoardId"
								component={PublicDash}
							/>

							<Route path="/" component={Home} />
						</Switch>
					</Router>
				</SnackbarProvider>
			</ThemeProvider>
		);
	}
}

const mapStateToProps = (state: IAppState) => {
	return {
		lang: state.app.lang,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<IAction>) => {
	return {
		setLanguage: (selectedLanguage: string) => {
			dispatch(SetLanguage(selectedLanguage));
		},
		setBuildNr: (buildnr: string) => {
			dispatch(SetBuildNr(buildnr));
		},
		setBaseUrl: (url: string) => {
			dispatch(SetBaseUrl(url));
		},
		setLoggedOnUser: (loggedOnUser: LoggedOnUser) => {
			dispatch(SetLoggedOnUser(loggedOnUser));
		},
	};
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(App);
