import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { ControlSerie } from './controlTypes';

interface IProps {
	controlSerie: ControlSerie;

	onEdit: (controlSerie: ControlSerie) => any;
}

export const SerieItemControl = React.forwardRef((props: IProps, ref: any) => {
	const editWidget = () => {
		props.onEdit(props.controlSerie);
	};

	return (
		<React.Fragment>
			<div ref={ref}>
				<Box display="flex" flexDirection="column">
					<Box display="flex" alignItems="center">
						<Box
							m={3}
							onTouchStart={(e) => {
								e.stopPropagation();
								editWidget();
							}}
							onClick={(e) => {
								e.stopPropagation();
								editWidget();
							}}
						>
							{true ? <FontAwesomeIcon icon={faEdit} /> : ''}
						</Box>

						<Typography variant="h6">
							<Box fontSize="16px">{props.controlSerie.name}</Box>
						</Typography>
					</Box>
					<Typography variant="h6">
						<Box ml={3} fontSize="14px">
							{props.controlSerie == null || props.controlSerie.pointDTO == null
								? ''
								: props.controlSerie.pointDTO.pointName}
						</Box>
					</Typography>
				</Box>
			</div>
		</React.Fragment>
	);
});
