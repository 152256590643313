import {
	Box,
	Button,
	CircularProgress,
	createStyles,
	CssBaseline,
	Fab,
	IconButton,
	makeStyles,
	Paper,
	TextField,
	Theme,
	Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from '../redux/store';
import Translation from '../services/translation';
import TransText from '../resource/transText';

import { NavBar } from '../components/navBar';
import { AdminAPI } from '../services/adminAPI';
import { useHistory, useParams } from 'react-router';
import RouteLeavingGuard from '../components/routeLeavingGuard';
import ArrowBackwardIcon from '@material-ui/icons/ArrowBack';

import { useSnackbar } from 'notistack';
import { enNanoStateCodes, NanoConfig } from '../model/pointModel';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faDownload,
	faPen,
	faPlus,
	faSync,
	faUpload,
} from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexFlow: 'column',
			height: '100vh',
		},
		paper: {
			flex: '1 1 auto',
			margin: '12px',
		},
		textField: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			width: '25ch',
		},
		smallSize: {
			fontSize: 13,
			fontWeight: 400,
		},
		wrapperRel: {
			position: 'relative',
		},
		buttonProgress: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
		},
	})
);

export const Nano: React.FC = (props: any) => {
	const { lang, loggedOnUser } = useSelector((state: IAppState) => state.app);
	const { t } = Translation;
	const classes = useStyles();
	const history = useHistory();
	const params: any = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const [entity, setEntity] = React.useState<NanoConfig>({
		id: '',
		name: '',
		description: '',
		nanoVersion: '',
		active: false,
		nanoConfigJson: '',
		siteID: '',
		siteName: '',
		pcConfigs: [],
		nanoAppConfigs: [],
		nanoState: enNanoStateCodes.NANO_UN_INITIALIZED,
	});
	const [orgEntity, setOrgEntity] = React.useState<NanoConfig>({
		id: '',
		name: '',
		description: '',
		nanoVersion: '',
		active: false,
		nanoConfigJson: '',
		siteID: '',
		siteName: '',
		pcConfigs: [],
		nanoAppConfigs: [],
		nanoState: enNanoStateCodes.NANO_UN_INITIALIZED,
	});
	const [dirty, setDirty] = React.useState<boolean>(false);
	const [progress, setProgress] = React.useState<boolean>(false);

	const adminAPI = new AdminAPI();

	const columns: any[] = [
		{
			field: '',
			headerName: '',
			sortable: false,
			width: 30,
			disableClickEventBubbling: true,
			renderCell: (params: any) => {
				const onClick = () => {
					let convPcType: string = params.row.pcType;
					convPcType = convPcType.replaceAll('.', '_');

					history.push({
						pathname:
							'/pc/' + convPcType + '/' + params.id + '/' + params.row.nanoId,
					});
				};
				return (
					<IconButton size="small" color="primary" onClick={onClick}>
						<FontAwesomeIcon icon={faPen} />
					</IconButton>
				);
			},
		},
		{
			field: 'Id',
			headerName: 'Id',
			width: 50,
			hide: true,
		},
		{
			field: 'pcType',
			headerName: t(TransText.app.type, null, lang, []),
			width: 180,
		},
		{
			field: 'name',
			headerName: t(TransText.app.name, null, lang, []),
			width: 180,
		},
		{
			field: 'active',
			headerName: t(TransText.app.active, null, lang, []),
			width: 120,
		},
		{
			field: 'description',
			headerName: t(TransText.app.description, null, lang, []),
			width: 180,
		},
	];

	useEffect(() => {
		const handleUnload = (e: any) => {
			if (dirty) {
				e.preventDefault();
				e.returnValue = 'Stop';
			}
		};

		window.onbeforeunload = handleUnload;

		return () => {
			window.onbeforeunload = null;
		};
	}, [dirty]);

	useEffect(() => {
		loadEntity();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let isDirty = false;
		for (let key in orgEntity) {
			if (orgEntity.hasOwnProperty(key)) {
				if (key !== 'pcConfigs' && key !== 'nanoAppConfigs') {
					if (entity[key] !== orgEntity[key]) {
						isDirty = true;
						break;
					}
				}
			}
		}

		setDirty(isDirty);

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entity, orgEntity]);

	const onSave = () => {
		setProgress(true);
		adminAPI
			.UpdateCustomerNano(entity)
			.then(() => {
				setDirty(false);
				enqueueSnackbar(
					t(TransText.nanoDetail.saveSuccessfully, null, lang, []),
					{
						variant: 'success',
					}
				);
			})
			.catch(() => {
				enqueueSnackbar(t(TransText.nanoDetail.saveFailed, null, lang, []), {
					variant: 'error',
				});
			})
			.finally(() => {
				setProgress(false);
			});
	};

	const loadEntity = () => {
		if (params.nanoId && loggedOnUser !== null) {
			setProgress(true);
			return adminAPI
				.GetCustomerNanoAndPc(loggedOnUser.tenant, params.nanoId)
				.then((response) => {
					if (response.status >= 200 && response.status < 300) {
						setEntity(response.data.nanoConfig);
						setOrgEntity(response.data.nanoConfig);
						setDirty(false);
						console.log('Reset dirty');
					} else {
						enqueueSnackbar(
							t(TransText.error, response.data.result + '_CUSTOMER', lang, []),
							{
								variant: 'error',
							}
						);
					}
				})
				.catch((ex) => {
					enqueueSnackbar(ex.message, {
						variant: 'error',
					});
				})
				.finally(() => {
					setProgress(false);
				});
		}
	};

	const onCancel = () => {
		setEntity(orgEntity);
		setDirty(false);
	};

	const GoBackClick = () => {
		history.push('/nanos');
	};

	// const IsUserInRole = (inRole: EnumRoleTypes) => {
	// 	return loggedOnUser !== null && loggedOnUser.roles.includes(inRole);
	// };

	const HandleReload = () => {
		loadEntity();
	};

	// const renderState = (nano: NanoConfig) => {
	// 	if (
	// 		nano.nanoState === enNanoStateCodes.NANO_UN_INITIALIZED ||
	// 		nano.nanoState === enNanoStateCodes.NANO_FAILED ||
	// 		nano.nanoState === enNanoStateCodes.NANO_NOT_ACTIVE
	// 	) {
	// 		return <FontAwesomeIcon style={{ color: 'red' }} icon={faStopCircle} />;
	// 	} else if (
	// 		nano.nanoState === enNanoStateCodes.NANO_CONFIGURED ||
	// 		nano.nanoState === enNanoStateCodes.NANO_BOOTING_PC ||
	// 		nano.nanoState === enNanoStateCodes.NANO_OPEN_SERVERREQUEST
	// 	) {
	// 		return (
	// 			<FontAwesomeIcon style={{ color: 'pink' }} icon={faExclamationCircle} />
	// 		);
	// 	} else {
	// 		return (
	// 			<FontAwesomeIcon style={{ color: 'green' }} icon={faCheckCircle} />
	// 		);
	// 	}
	// };

	const HandleAddPc = () => {};
	return (
		<React.Fragment>
			<RouteLeavingGuard
				when={dirty}
				navigate={(path) => history.push(path)}
				shouldBlockNavigation={(location) => {
					return dirty;
				}}
			/>
			<CssBaseline />

			<div className={classes.root}>
				<NavBar />
				<Paper className={classes.paper}>
					{/* Title */}
					<Box display="flex" justifyContent="space-between" m={2}>
						<Box m={2} display="flex" flexDirection="row">
							<Fab
								size="small"
								color="primary"
								aria-label="add"
								onClick={GoBackClick}
								disabled={dirty}
							>
								<ArrowBackwardIcon />
							</Fab>

							<Box ml={2}>
								<div className={classes.wrapperRel}>
									<Fab
										size="small"
										color="secondary"
										aria-label="add"
										disabled={progress}
										onClick={HandleReload}
									>
										<FontAwesomeIcon icon={faSync} />
									</Fab>
									{progress && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</div>
							</Box>
						</Box>

						<Box>
							<Typography variant="h5" noWrap>
								{t(TransText.nanoDetail.headTitle, null, lang, [])}
							</Typography>
						</Box>
						<Box width="80px"></Box>
					</Box>
					{/* Settings */}
					<Box p={4}>
						<Paper elevation={4} style={{ maxWidth: '900px' }}>
							<Box m={4} p={4}>
								{/* Name, Desc, Version row */}
								<Box
									display="flex"
									justifyContent="flex-start"
									flexWrap="wrap"
									m={2}
								>
									<TextField
										className={classes.textField}
										InputProps={{
											classes: {
												input: classes.smallSize,
											},
										}}
										InputLabelProps={{
											shrink: true,
										}}
										margin="dense"
										size="small"
										label={t(TransText.nanoDetail.name, null, lang, [])}
										value={entity?.name}
										onChange={(e) => {
											setEntity({
												...entity,
												name: e.target.value,
											});
										}}
									/>
									<TextField
										className={classes.textField}
										InputProps={{
											classes: {
												input: classes.smallSize,
											},
										}}
										InputLabelProps={{
											shrink: true,
										}}
										multiline
										maxRows={4}
										margin="dense"
										size="small"
										label={t(TransText.app.description, null, lang, [])}
										value={entity?.description}
										onChange={(e) => {
											setEntity({
												...entity,
												description: e.target.value,
											});
										}}
									/>
									<TextField
										className={classes.textField}
										InputProps={{
											classes: {
												input: classes.smallSize,
											},
										}}
										InputLabelProps={{
											shrink: true,
										}}
										margin="dense"
										size="small"
										label={t(TransText.app.version, null, lang, [])}
										value={entity?.nanoVersion}
										onChange={(e) => {
											setEntity({
												...entity,
												nanoVersion: e.target.value,
											});
										}}
									/>
								</Box>
								{/* ... */}
								<Box
									display="flex"
									justifyContent="flex-start"
									flexWrap="wrap"
									m={2}
								></Box>
								{/* Command */}
								<Box
									display="flex"
									flexDirection="row"
									justifyContent="space-between"
									m={2}
								>
									<Button
										variant="contained"
										size="small"
										disabled={!dirty}
										onClick={onCancel}
									>
										{Translation.t(TransText.app.cancel, null, lang, [])}
									</Button>

									<div className={classes.wrapperRel}>
										<Button
											variant="contained"
											size="small"
											color="primary"
											disabled={!dirty || progress}
											type="submit"
											onClick={onSave}
										>
											{Translation.t(TransText.app.save, null, lang, [])}
										</Button>
										{progress && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</div>
								</Box>
							</Box>
						</Paper>
					</Box>

					{/* Point controls */}
					<Box display="Flex" flexDirection="row" m={4}>
						<Fab
							size="small"
							color="primary"
							aria-label="add"
							onClick={() => {}}
						>
							<FontAwesomeIcon icon={faPlus} />
						</Fab>
						<Box ml={2}>
							<Fab
								size="small"
								color="primary"
								aria-label="upload"
								onClick={() => {}}
							>
								<FontAwesomeIcon icon={faUpload} />
							</Fab>
						</Box>
						<Box ml={2}>
							<Fab
								size="small"
								color="primary"
								aria-label="download"
								onClick={() => {}}
							>
								<FontAwesomeIcon icon={faDownload} />
							</Fab>
						</Box>
					</Box>
					{/* Grid */}
					<Box
						m={4}
						style={{
							height: `calc(100% - (270px))`,
							//minHeight: '300px',
							overflow: 'auto',
						}}
					>
						<DataGridPro
							rows={entity.pcConfigs}
							columns={columns}
							density="compact"
							disableMultipleSelection={true}
						/>
					</Box>
				</Paper>
			</div>
		</React.Fragment>
	);
};
