import {
	AppBar,
	Box,
	Button,
	Dialog,
	DialogContent,
	IconButton,
	makeStyles,
	Paper,
	Toolbar,
	Typography,
} from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from '../redux/store';
import TransText from '../resource/transText';
import Translation from '../services/translation';
import { OrangeButton } from '../styles/buttons';

import { AdminAPI } from '../services/adminAPI';
import { PointConfig } from '../model/pointModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { ComfirmDialog } from './confirmDialog';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexFlow: 'column',
		height: '100vh',
	},
	appBarDialog: {
		position: 'relative',
	},
	titleDialog: {
		marginLeft: theme.spacing(2),
		flexGrow: 1,
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	smallSize: {
		fontSize: 13,
		fontWeight: 400,
	},
	paper: {
		//height: '100vh',
	},
	margin: {
		margin: theme.spacing(3),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 90,
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative',
	},
	progress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}));

interface IProps {
	pcId: string;
	open: boolean;
	cancel: () => any;
	processUploadedPoints: (previewPoints: PointConfig[]) => any;
	showImportPoints: PointConfig[];
	importPoints: (previewPoints: PointConfig[]) => any;
}

export const UploadPointsCsv: React.FC<IProps> = ({
	pcId,
	open,
	cancel,
	processUploadedPoints,
	showImportPoints,
	importPoints,
}) => {
	const classes = useStyles();
	const history = useHistory();
	const { t } = Translation;
	const { lang, loggedOnUser } = useSelector((state: IAppState) => state.app);

	const [showConfim, setShowConfirm] = React.useState<boolean>(false);
	const [confirmBodyText, setConfirmBodyText] = React.useState<string>('');

	const columns: any[] = [
		{
			field: '',
			headerName: '',
			sortable: false,
			width: 30,
			disableClickEventBubbling: true,
			renderCell: (params: any) => {
				const onClick = () => {
					let convPcType: string = params.row.pcType;
					convPcType = convPcType.replaceAll('.', '_');

					history.push({
						pathname:
							'/pc/' + convPcType + '/' + params.id + '/' + params.row.nanoId,
					});
				};
				return (
					<IconButton size="small" color="primary" onClick={onClick}>
						<FontAwesomeIcon icon={faPen} />
					</IconButton>
				);
			},
		},
		{
			field: 'Id',
			headerName: 'Id',
			width: 50,
			hide: true,
		},
		{
			field: 'importState',
			headerName: t(TransText.uploadPointsCsv.importState, null, lang, []),
			width: 180,
		},
		{
			field: 'importComment',
			headerName: t(TransText.uploadPointsCsv.importComment, null, lang, []),
			width: 180,
		},
		{
			field: 'name',
			headerName: t(TransText.Point.name, null, lang, []),
			width: 180,
		},
		{
			field: 'description',
			headerName: t(TransText.Point.Description, null, lang, []),
			width: 180,
		},
		{
			field: 'pointAdress',
			headerName: t(TransText.Point.PointAdress, null, lang, []),
			width: 180,
		},
		{
			field: 'valueType',
			headerName: t(TransText.Point.DataType, null, lang, []),
			width: 180,
		},
		{
			field: 'scanRateMS',
			headerName: t(TransText.Point.ScanRate, null, lang, []),
			width: 180,
		},
		{
			field: 'deadBand',
			headerName: t(TransText.Point.Deadband, null, lang, []),
			width: 120,
		},
		{
			field: 'pointConfigCustomJson',
			headerName: t(TransText.Point.PointCustomJson, null, lang, []),
			width: 180,
		},

		{
			field: 'read',
			headerName: t(TransText.Point.Read, null, lang, []),
			width: 180,
		},
		{
			field: 'write',
			headerName: t(TransText.Point.Write, null, lang, []),
			width: 180,
		},
		{
			field: 'subscribed',
			headerName: t(TransText.Point.Subscribed, null, lang, []),
			width: 180,
		},
		{
			field: 'function',
			headerName: t(TransText.Point.Function, null, lang, []),
			width: 180,
		},
	];

	const HandleSelectedFile = (fileList: FileList) => {
		GetPointsPreview(fileList[0], fileList[0].name).then((resp: any) => {
			if (resp.status >= 200 && resp.status < 300) {
				if (resp.data.result === '')
					processUploadedPoints(resp.data.pointConfigs);
				else {
					processUploadedPoints([]);
					setConfirmBodyText(resp.data.result);
					setShowConfirm(true);
				}
			}
		});
	};

	const GetPointsPreview = (file: any, fileName: string) => {
		let adminAPI: AdminAPI = new AdminAPI();
		return adminAPI.GetPreviewForPointCsvFile(
			loggedOnUser?.tenant ? loggedOnUser?.tenant : '',
			pcId,
			file,
			fileName
		);
	};

	return (
		<React.Fragment>
			<Dialog fullScreen open={open}>
				<AppBar className={classes.appBarDialog}>
					<Toolbar>
						<Typography variant="h6" className={classes.titleDialog}>
							{t(TransText.uploadPointsCsv.headTitle, null, lang, [])}
						</Typography>

						<Box ml={2}>
							<Button variant="contained" component="label" color="secondary">
								{t(TransText.uploadPointsCsv.selectCsvFile, null, lang, [])}
								<input
									type="file"
									hidden
									onChange={(e) => {
										if (e.target.files !== null)
											HandleSelectedFile(e.target.files);
										e.target.value = '';
									}}
									accept=".csv"
								/>
							</Button>
						</Box>
						<Box ml={2}>
							<Button
								variant="contained"
								component="label"
								color="secondary"
								disabled={showImportPoints.length === 0}
								onClick={() => {
									importPoints(showImportPoints);
								}}
							>
								{t(TransText.uploadPointsCsv.importPoints, null, lang, [])}
							</Button>
						</Box>
						<Box ml={4}>
							<OrangeButton
								autoFocus
								color="inherit"
								onClick={() => {
									cancel();
								}}
							>
								{t(TransText.app.cancel, null, lang, [])}
							</OrangeButton>
						</Box>
					</Toolbar>
				</AppBar>

				<DialogContent>
					{/* DataGrid preview and action */}
					<Box m={4}>
						<Paper className={classes.paper}>
							{/* Grid */}
							<Box p={4} style={{ height: 600, width: '100%' }}>
								<DataGridPro
									rows={showImportPoints}
									columns={columns}
									density="compact"
									disableMultipleSelection={true}
								/>
							</Box>
						</Paper>
					</Box>
				</DialogContent>
			</Dialog>
			<ComfirmDialog
				onCancel={function () {}}
				showCancelButton={false}
				onConfirm={function () {
					setShowConfirm(false);
				}}
				open={showConfim}
				titleText={t(TransText.uploadPointsCsv.errorInCSVFile, null, lang, [])}
				contentText={confirmBodyText}
				cancelButtonText={t(TransText.app.cancel, null, lang, [])}
				confirmButtonText={t(TransText.app.ok, null, lang, [])}
			/>
		</React.Fragment>
	);
};
