import React from 'react';
import { useDrag } from 'react-dnd';
import {
	DragItemTypes,
	IValueControlProps,
	ValueControlTypes,
} from './controlTypes';

export const ValueControl: React.FC<IValueControlProps> = ({
	serie,
	selected,
	value,
	controlSelected,
}) => {
	const [{ isDragging }, drag] = useDrag(() => ({
		type: DragItemTypes.VALUE_CONTROL,
		item: serie,
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	}));

	const GetValueControlText = () => {
		let bck = '#ffffffff';
		let border = '';
		if (selected) {
			bck = 'yellow';
			border = '2px dashed #000000';
		}

		let fsize = '12px';
		let round = 3;
		let defaultValue = '24.23';

		let fcolor: any = '#000000';
		if (serie !== undefined) {
			for (let c = 0; c < serie.colorLevels.length; c++) {
				if (value >= serie.colorLevels[c].level)
					fcolor = serie.colorLevels[c].color;
			}
		}

		return (
			<div
				ref={drag}
				onMouseDown={() => {
					controlSelected(serie.id);
				}}
				style={{
					visibility: isDragging ? 'hidden' : 'visible',
					border: selected ? border : '',
					backgroundColor: selected ? 'yellow' : bck,
					color: fcolor,
					borderRadius: round,

					position: 'absolute',

					top: serie.top,
					left: serie.left,
					width: '40px',
					height: '16px',

					fontSize: fsize,
					fontWeight: 'bolder',

					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				{value === undefined ? defaultValue : value}
			</div>
		);
	};

	const GetValueControlRectangle = () => {
		let bck = 'blue';
		let border = '';
		if (selected) {
			bck = 'yellow';
			border = '2px dashed #000000';
		}

		let fsize = '12px';

		let round = 3;

		let color: any = '#000000';
		if (serie !== undefined) {
			for (let c = 0; c < serie.colorLevels.length; c++) {
				if (value >= serie.colorLevels[c].level)
					color = serie.colorLevels[c].color;
			}
		}

		return (
			<div
				ref={drag}
				onMouseDown={() => {
					controlSelected(serie.id);
				}}
				style={{
					visibility: isDragging ? 'hidden' : 'visible',
					border: selected ? border : '',
					backgroundColor: selected ? 'yellow' : color,
					color: bck,
					borderRadius: round,

					position: 'absolute',

					top: serie.top,
					left: serie.left,
					width: '12px',
					height: '12px',

					fontSize: fsize,
					fontWeight: 'bolder',

					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			></div>
		);
	};
	const GetValueControl = () => {
		if (serie.type === ValueControlTypes.Text) return GetValueControlText();
		else return GetValueControlRectangle();
	};

	return <React.Fragment>{GetValueControl()}</React.Fragment>;
};
