import {
	Box,
	Button,
	CircularProgress,
	createStyles,
	CssBaseline,
	Fab,
	FormControl,
	IconButton,
	InputLabel,
	makeStyles,
	MenuItem,
	Paper,
	Select,
	TextField,
	Theme,
	Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../redux/store';
import Translation from '../../../services/translation';
import TransText from '../../../resource/transText';

import { NavBar } from '../../../components/navBar';

import { AdminAPI } from '../../../services/adminAPI';
import { useHistory, useParams } from 'react-router';
import RouteLeavingGuard from '../../../components/routeLeavingGuard';
import ArrowBackwardIcon from '@material-ui/icons/ArrowBack';

import { useSnackbar } from 'notistack';
import {
	CustomerPcPointsWTO,
	CustomerPcUpdateReq,
	enPcStateCodes,
	PcConfig,
} from '../../../model/pointModel';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faDownload,
	faPen,
	faPlus,
	faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { PointConfig } from '../../../model/pointModel';
import { UploadPointsCsv } from '../../../components/uploadPointsCsv';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexFlow: 'column',
			height: '100vh',
		},
		paper: {
			flex: '1 1 auto',
			margin: '12px',
		},
		textField: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			width: '25ch',
		},
		smallSize: {
			fontSize: 13,
			fontWeight: 400,
		},
		wrapperRel: {
			position: 'relative',
		},
		buttonProgress: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
		},
		formControl: {
			margin: theme.spacing(1),
			minWidth: 90,
		},
	})
);

enum enPcMode {
	CallPoint_SelectPointWithTimeRangeAsGenericType = 0,
	Import_PointMappedToTableAsJsonType = 1,
	Import_PointsMappedToColumnAsGenericType = 2,
	Import_PointMappedToTableRowsAsJsonType = 3,
}

export const PcOpcda: React.FC = (props) => {
	const { lang, loggedOnUser } = useSelector((state: IAppState) => state.app);
	const { t } = Translation;
	const classes = useStyles();
	const history = useHistory();
	const params: any = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const [entity, setEntity] = React.useState<PcConfig>({
		id: '',
		name: '',
		description: '',
		pcType: '',
		pcTypeVersion: '',
		active: false,
		pcConfigCustomJson: '',
		runMode: '',
		serviceAccount: '',
		servicePassword: '',
		serviceDomain: '',
		nanoId: '',
		nanoName: '',
		pointconfigs: [],
		pcState: enPcStateCodes.PC_UN_INITIALIZED,
	});
	const [orgEntity, setOrgEntity] = React.useState<PcConfig>({
		id: '',
		name: '',
		description: '',
		pcType: '',
		pcTypeVersion: '',
		active: false,
		pcConfigCustomJson: '',
		runMode: '',
		serviceAccount: '',
		servicePassword: '',
		serviceDomain: '',
		nanoId: '',
		nanoName: '',
		pointconfigs: [],
		pcState: enPcStateCodes.PC_UN_INITIALIZED,
	});
	const [dirty, setDirty] = React.useState<boolean>(false);
	const [progress, setProgress] = React.useState<boolean>(false);
	const [openUploadPoints, setOpenUploadPoints] =
		React.useState<boolean>(false);
	const adminAPI = new AdminAPI();
	const [importPoints, setImportPoints] = React.useState<PointConfig[]>([]);

	const columns: any[] = [
		{
			field: '',
			headerName: '',
			sortable: false,
			width: 30,
			disableClickEventBubbling: true,
			renderCell: (params: any) => {
				const onClick = () => {
					let convPcType: string = params.row.pcType;
					convPcType = convPcType.replaceAll('.', '_');

					history.push({
						pathname:
							'/pc/' + convPcType + '/' + params.id + '/' + params.row.nanoId,
					});
				};
				return (
					<IconButton size="small" color="primary" onClick={onClick}>
						<FontAwesomeIcon icon={faPen} />
					</IconButton>
				);
			},
		},
		{
			field: 'name',
			headerName: 'Id',
			width: 50,
			hide: true,
		},
		{
			field: 'name',
			headerName: t(TransText.Point.name, null, lang, []),
			width: 180,
		},
		{
			field: 'description',
			headerName: t(TransText.Point.Description, null, lang, []),
			width: 180,
		},
		{
			field: 'pointAdress',
			headerName: t(TransText.Point.PointAdress, null, lang, []),
			width: 180,
		},
		{
			field: 'valueType',
			headerName: t(TransText.Point.DataType, null, lang, []),
			width: 180,
		},
		{
			field: 'scanRateMS',
			headerName: t(TransText.Point.ScanRate, null, lang, []),
			width: 180,
		},
		{
			field: 'deadBand',
			headerName: t(TransText.Point.Deadband, null, lang, []),
			width: 120,
		},
		{
			field: 'pointConfigCustomJson',
			headerName: t(TransText.Point.PointCustomJson, null, lang, []),
			width: 180,
		},

		{
			field: 'read',
			headerName: t(TransText.Point.Read, null, lang, []),
			width: 180,
		},
		{
			field: 'write',
			headerName: t(TransText.Point.Write, null, lang, []),
			width: 180,
		},
		{
			field: 'subscribed',
			headerName: t(TransText.Point.Subscribed, null, lang, []),
			width: 180,
		},
		{
			field: 'function',
			headerName: t(TransText.Point.Function, null, lang, []),
			width: 180,
		},
		{
			field: 'upUser',
			headerName: t(TransText.app.upUser, null, lang, []),
			width: 180,
		},
		{
			field: 'upTime',
			headerName: t(TransText.app.upTime, null, lang, []),
			width: 180,
			valueFormatter: (params: any) => {
				// first converts to JS Date, then to locale option through date-fns
				return moment(params.value).format('YYYY-MM-DD HH:mm:ss');
			},
		},
	];

	useEffect(() => {
		const handleUnload = (e: any) => {
			if (dirty) {
				e.preventDefault();
				e.returnValue = 'Stop';
			}
		};

		window.onbeforeunload = handleUnload;

		return () => {
			window.onbeforeunload = null;
		};
	}, [dirty]);

	useEffect(() => {
		let isMounted: boolean = true;

		if (params.pcId && loggedOnUser !== null) {
			setProgress(true);
			adminAPI
				.GetCustomerPcAndPoints(loggedOnUser.tenant, params.pcId)
				.then((response) => {
					if (isMounted) {
						if (response.status >= 200 && response.status < 300) {
							setEntity(response.data.pcConfig);
							setOrgEntity(response.data.pcConfig);
							setDirty(false);
							console.log('Reset dirty');
						} else {
							enqueueSnackbar(
								t(
									TransText.error,
									response.data.result + '_CUSTOMER',
									lang,
									[]
								),
								{
									variant: 'error',
								}
							);
						}
					}
				})
				.catch((ex) => {
					if (isMounted) {
						enqueueSnackbar(ex.message, {
							variant: 'error',
						});
					}
				})
				.finally(() => {
					if (isMounted) {
						setProgress(false);
					}
				});
		}

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let isDirty = false;
		for (let key in orgEntity) {
			if (orgEntity.hasOwnProperty(key)) {
				if (key !== 'pcConfigs' && key !== 'nanoAppConfigs') {
					if (entity[key] !== orgEntity[key]) {
						isDirty = true;
						break;
					}
				}
			}
		}

		setDirty(isDirty);

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entity, orgEntity]);

	const SavePcConfig = () => {
		setProgress(true);

		// Strip of ant points, only update Pc
		let pc = { ...entity };
		pc.pointconfigs = [];

		let dto: CustomerPcUpdateReq = {
			CustomerId:
				loggedOnUser?.tenant !== undefined ? loggedOnUser?.tenant : '',
			PcId: pc.id,
			pcConfig: pc,
		};

		adminAPI
			.UpdateCustomerPc(dto)
			.then(() => {
				setDirty(false);
				enqueueSnackbar(t(TransText.pcMsSql.successSavedPc, null, lang, []), {
					variant: 'success',
				});
			})
			.catch(() => {
				enqueueSnackbar(t(TransText.pcMsSql.failedSavedPc, null, lang, []), {
					variant: 'error',
				});
			})
			.finally(() => {
				setProgress(false);
			});
	};

	const SavePointConfigs = (importPoints: PointConfig[]) => {
		setProgress(true);

		let dto: CustomerPcPointsWTO = {
			customerId:
				loggedOnUser?.tenant !== undefined ? loggedOnUser?.tenant : '',
			pcId: entity.id,
			pointConfigs: [...importPoints],
		};

		adminAPI
			.UpdateCustomerPcPoints(dto)
			.then(() => {
				enqueueSnackbar(
					t(TransText.pcMsSql.successSavedPoints, null, lang, []),
					{
						variant: 'success',
					}
				);
			})
			.catch((e) => {
				enqueueSnackbar(
					t(TransText.pcMsSql.failedSavedPoints, null, lang, []),
					{
						variant: 'error',
					}
				);
			})
			.finally(() => {
				setProgress(false);
			});
	};

	const onCancel = () => {
		setEntity(orgEntity);
		setDirty(false);
	};

	const GoBackClick = () => {
		history.push('/nano/' + entity.nanoId);
	};

	const HandleAddPoint = () => {};

	// Process the uploadedCSV points
	const ProcessPoints = (previewPoints: PointConfig[]) => {
		previewPoints.map((x: PointConfig) => {
			let pcConfigCustom = JSON.parse(entity.pcConfigCustomJson);
			if (pcConfigCustom !== undefined) {
				if (
					pcConfigCustom.PcMode !==
					enPcMode.CallPoint_SelectPointWithTimeRangeAsGenericType
				) {
					x.subscribed = true;
					x.read = true;
					x.write = false;
					x.function = false;
					x.call = false;
				}
			}
			return pcConfigCustom;
		});

		setImportPoints(previewPoints);
	};

	const ImportPoints = (previewPoints: PointConfig[]) => {
		SavePointConfigs(previewPoints);

		setOpenUploadPoints(false);
	};

	return (
		<React.Fragment>
			<RouteLeavingGuard
				when={dirty}
				navigate={(path) => history.push(path)}
				shouldBlockNavigation={(location) => {
					return dirty;
				}}
			/>
			<CssBaseline />
			<div className={classes.root}>
				<NavBar />

				<Paper className={classes.paper}>
					{/* Title */}
					<Box display="flex" justifyContent="space-between" m={2}>
						<Box m={2}>
							<Fab
								size="small"
								color="primary"
								aria-label="add"
								onClick={GoBackClick}
								disabled={dirty}
							>
								<ArrowBackwardIcon />
							</Fab>
						</Box>

						<Box>
							<Typography variant="h5" noWrap>
								{'OPCDA '} {t(TransText.pcDetail.headTitle, null, lang, [])}
							</Typography>
						</Box>
						<Box width="40px"></Box>
					</Box>
					{/* Settings */}
					<Box p={4}>
						<Paper elevation={4} style={{ maxWidth: '900px' }}>
							<Box m={4} p={4}>
								{/* Name, Desc, Version row */}
								<Box
									display="flex"
									justifyContent="flex-start"
									flexWrap="wrap"
									m={2}
								>
									<FormControl className={classes.formControl}>
										<InputLabel id="demo-simple-select-label">
											{t(
												TransText.widgetEdit.timeRangeLastUnit,
												null,
												lang,
												[]
											)}
										</InputLabel>

										<Select
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											value={''}
											onChange={(e) => {}}
										>
											<MenuItem
												value={
													'CallPoint_SelectPointWithTimeRangeAsGenericType'
												}
											>
												CallPoint_SelectPointWithTimeRangeAsGenericType
											</MenuItem>
											<MenuItem value={'Import_PointMappedToTableAsJsonType'}>
												Import_PointMappedToTableAsJsonType
											</MenuItem>
											<MenuItem value={'Hour'}>hour</MenuItem>
											<MenuItem value={'Day'}>day</MenuItem>
											<MenuItem value={'Week'}>week</MenuItem>
											<MenuItem value={'Month'}>month</MenuItem>
										</Select>
									</FormControl>
									<TextField
										className={classes.textField}
										InputProps={{
											classes: {
												input: classes.smallSize,
											},
										}}
										InputLabelProps={{
											shrink: true,
										}}
										margin="dense"
										size="small"
										label={t(TransText.pcDetail.name, null, lang, [])}
										value={entity?.name}
										onChange={(e) => {
											setEntity({
												...entity,
												name: e.target.value,
											});
										}}
									/>
									<TextField
										className={classes.textField}
										InputProps={{
											classes: {
												input: classes.smallSize,
											},
										}}
										InputLabelProps={{
											shrink: true,
										}}
										multiline
										maxRows={4}
										margin="dense"
										size="small"
										label={t(TransText.app.description, null, lang, [])}
										value={entity?.description}
										onChange={(e) => {
											setEntity({
												...entity,
												description: e.target.value,
											});
										}}
									/>
									<TextField
										className={classes.textField}
										InputProps={{
											classes: {
												input: classes.smallSize,
											},
										}}
										InputLabelProps={{
											shrink: true,
										}}
										margin="dense"
										size="small"
										label={t(TransText.app.version, null, lang, [])}
										value={entity?.pcTypeVersion}
										onChange={(e) => {
											setEntity({
												...entity,
												pcTypeVersion: e.target.value,
											});
										}}
									/>
								</Box>
								{/* ... */}
								<Box
									display="flex"
									justifyContent="flex-start"
									flexWrap="wrap"
									m={2}
								></Box>
								{/* Command */}
								<Box
									display="flex"
									flexDirection="row"
									justifyContent="space-between"
									m={2}
								>
									<Button
										variant="contained"
										size="small"
										disabled={!dirty}
										onClick={onCancel}
									>
										{Translation.t(TransText.app.cancel, null, lang, [])}
									</Button>

									<div className={classes.wrapperRel}>
										<Button
											variant="contained"
											size="small"
											color="primary"
											disabled={!dirty || progress}
											type="submit"
											onClick={SavePcConfig}
										>
											{Translation.t(TransText.app.save, null, lang, [])}
										</Button>
										{progress && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</div>
								</Box>
							</Box>
						</Paper>
					</Box>
					{/* Point controls */}
					<Box display="Flex" flexDirection="row" m={4}>
						<Fab
							size="small"
							color="primary"
							aria-label="add"
							onClick={HandleAddPoint}
						>
							<FontAwesomeIcon icon={faPlus} />
						</Fab>
						<Box ml={2}>
							<Fab
								size="small"
								color="primary"
								aria-label="upload"
								onClick={() => {
									setImportPoints([]);
									setOpenUploadPoints(true);
								}}
							>
								<FontAwesomeIcon icon={faUpload} />
							</Fab>
						</Box>
						<Box ml={2}>
							<Fab
								size="small"
								color="primary"
								aria-label="download"
								onClick={() => {}}
							>
								<FontAwesomeIcon icon={faDownload} />
							</Fab>
						</Box>
					</Box>

					{/* Grid */}
					<Box
						m={4}
						style={{
							height: `calc(100% - (270px))`,
							minHeight: '300px',
							overflow: 'auto',
						}}
					>
						<DataGridPro
							rows={entity.pointconfigs}
							columns={columns}
							density="compact"
							disableMultipleSelection={true}
							//autoHeight={true}
						/>
					</Box>
				</Paper>
			</div>
			<UploadPointsCsv
				pcId={entity.id}
				open={openUploadPoints}
				processUploadedPoints={ProcessPoints}
				showImportPoints={importPoints}
				importPoints={ImportPoints}
				cancel={() => {
					setOpenUploadPoints(false);
				}}
			/>
		</React.Fragment>
	);
};
