import React from 'react';
import { useParams } from 'react-router';
import { PcMssql } from './Pc/mssql/pcMssql';
import { PcOpcda } from './Pc/opcda/pcOpcda';
import { PcExcel } from './Pc/excel/pcExcel';
import { NotFound } from './Pc/notFound';
export const Pc: React.FC = (props: any) => {
	const params: any = useParams();
	const components: any = {
		piot_nano_pc_mssql: PcMssql,
		piot_nano_win_pc_opcda: PcOpcda,
		piot_nano_pc_excel: PcExcel,
	};

	const BootComponent = components[params.pcType];

	return BootComponent === undefined ? (
		<NotFound pcType={params.pcType} nanoId={params.nanoId} />
	) : (
		<BootComponent />
	);
};
