import React from 'react';
import { WidgetInfo } from '../../model/pointModel';
import { WidgetEditLine1 } from './widgets/widgetLine1/widgetEditLine1';
import { WidgetEditBar1 } from './widgets/widgetBar1/widgetEditBar1';
// import { WidgetEditTable1 } from './widgetTable1/widgetEditTable1';
import { WidgetEditGauge2 } from './widgets/widgetGauge2/widgetEditGauge2';
import { WidgetEditTextvar1 } from './widgets/widgetTextVar1/widgetEditTextvar1';
import { WidgetEditTextVar2 } from './widgets/widgetTextVar2/widgetEditTextVar2';
import { WidgetEditImage1 } from './widgets/widgetImage1/widgetEditImage1';
import { WidgetEditTable1 } from './widgets/widgetTable1/widgetEditTable1';

interface IProps {
	selectedWidgetInfo: WidgetInfo;
	open: boolean;
	remove: (id: string) => any;
	save: (widgetInfo: WidgetInfo) => any;
	cancel: () => any;
}

export const WidgetEdit: React.FC<IProps> = (props: IProps) => {
	const components: any = {
		line_1: WidgetEditLine1,
		bar_1: WidgetEditBar1,
		table_1: WidgetEditTable1,
		gauge_2: WidgetEditGauge2,
		textvar_1: WidgetEditTextvar1,
		textvar_2: WidgetEditTextVar2,
		image_1: WidgetEditImage1,
	};

	const BootComponent = components[props.selectedWidgetInfo.type];
	return (
		<BootComponent
			key={props.selectedWidgetInfo.i}
			selectedWidgetInfo={props.selectedWidgetInfo}
			open={props.open}
			cancel={props.cancel}
			save={props.save}
			remove={props.remove}
		/>
	);
};
