import {
	Box,
	CircularProgress,
	createStyles,
	CssBaseline,
	Fab,
	IconButton,
	makeStyles,
	Paper,
	Theme,
	Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from '../redux/store';
import Translation from '../services/translation';
import TransText from '../resource/transText';

import { NavBar } from '../components/navBar';
import { CustomerDTO } from '../model/customerModel';
import { AdminAPI } from '../services/adminAPI';
import { useHistory } from 'react-router';
import { DataGridPro } from '@mui/x-data-grid-pro';

import { useSnackbar } from 'notistack';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlus, faSync } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexFlow: 'column',
			height: '100vh',
		},
		paper: {
			flex: '1 1 auto',
			//height: 'calc(100% - 80px)', //height of toolbar if you know it beforehand
		},
		wrapperRel: {
			position: 'relative',
		},
		buttonProgress: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			marginTop: -12,
			marginLeft: -12,
		},
	})
);

export const Customers: React.FC = () => {
	const { lang } = useSelector((state: IAppState) => state.app);
	const { t } = Translation;
	const classes = useStyles();
	const [customers, setCustomers] = React.useState<CustomerDTO[]>([]);
	const [progress, setProgress] = React.useState<boolean>(false);
	const [reloadToggle, setReloadToogle] = React.useState<boolean>(false);
	const history = useHistory();
	const adminAPI = new AdminAPI();
	const { enqueueSnackbar } = useSnackbar();

	const columns: any[] = [
		{
			field: '',
			headerName: t(TransText.app.edit, null, lang, []),
			sortable: false,
			width: 80,
			disableClickEventBubbling: true,
			renderCell: (params: any) => {
				const onClick = () => {
					var id = params.id;

					history.push({
						pathname: '/customer',
						state: {
							// location state
							customerId: id,
						},
					});
				};
				return (
					<IconButton size="small" color="primary" onClick={onClick}>
						<FontAwesomeIcon icon={faPen} />
					</IconButton>
				);
			},
		},
		{
			field: 'id',
			headerName: 'Id',
			width: 50,
			hide: true,
		},
		{
			field: 'name',
			headerName: t(TransText.customerList.customer, null, lang, []),
			width: 180,
		},
	];

	useEffect(() => {
		let isMounted = true;
		setProgress(true);
		adminAPI
			.GetCustomers()
			.then((response) => {
				if (isMounted) {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.result === null || response.data.result === '') {
							setCustomers(response.data.customerDTOs);
						} else {
							enqueueSnackbar(
								t(
									TransText.error,
									response.data.result + '_CUSTOMER',
									lang,
									[]
								),
								{
									variant: 'error',
								}
							);
						}
					}
				}
			})
			.catch((ex) => {
				if (isMounted) {
					enqueueSnackbar(ex.message, {
						variant: 'error',
					});
				}
			})
			.finally(() => {
				if (isMounted) {
					setProgress(false);
				}
			});

		return () => {
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadToggle]);

	return (
		<React.Fragment>
			<CssBaseline />
			<Box m={8}>
				<div className={classes.root}>
					<NavBar />

					<Paper className={classes.paper}>
						<Box display="flex" justifyContent="space-between" m={4}>
							<Box m={2} display="flex" flexDirection="row">
								<Fab size="small" color="primary" aria-label="add">
									<FontAwesomeIcon icon={faPlus} />
								</Fab>
								<Box ml={2}>
									<div className={classes.wrapperRel}>
										<Fab
											size="small"
											color="secondary"
											aria-label="add"
											disabled={progress}
											onClick={() => {
												setReloadToogle(!reloadToggle);
											}}
										>
											<FontAwesomeIcon icon={faSync} />
										</Fab>
										{progress && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</div>
								</Box>
							</Box>

							<Box>
								<Typography variant="h5" noWrap>
									{t(TransText.customerList.headTitle, null, lang, [])}
								</Typography>
							</Box>
							<Box width="80px"></Box>
						</Box>

						<Box
							p={4}
							style={{ height: `calc(100% - 70px)`, overflow: 'auto' }}
						>
							<DataGridPro
								rows={customers}
								columns={columns}
								density="compact"
								disableMultipleSelection={true}
							/>
						</Box>
					</Paper>
				</div>
			</Box>
		</React.Fragment>
	);
};
