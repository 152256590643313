import { LoggedOnUser } from '../model/userModel';
import { AppEvents } from './events';

export interface IAction {
	type: AppEvents;
	payload: any;
}

const makeAction = <T extends AppEvents, p>(type: T) => (payload: p) => {
	return {
		type,
		payload,
	};
};

export const SetLanguage = makeAction<AppEvents.SET_LANGUAGE, string>(
	AppEvents.SET_LANGUAGE
);
export const SetBuildNr = makeAction<AppEvents.SET_BUILDNR, string>(
	AppEvents.SET_BUILDNR
);
export const SetBaseUrl = makeAction<AppEvents.SET_BASEURL, string | undefined>(
	AppEvents.SET_BASEURL
);

export const SetLoggedOnUser = makeAction<
	AppEvents.SET_LOGGEDONUSER,
	LoggedOnUser | null
>(AppEvents.SET_LOGGEDONUSER);
