import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import boost from 'highcharts/modules/boost';
import moment from 'moment';
import React, { Ref, useEffect, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { SerieSetting } from '../../../../model/dashModel';
import {
	enPointState,
	PointDTO,
	PointHistoryDTO,
	PointHistoryResponseDTO,
	RefObj,
	WidgetInfo,
} from '../../../../model/pointModel';
import { IAppState } from '../../../../redux/store';
import { PointAPI } from '../../../../services/pointAPI';
import { INameValueHash } from '../../common/types';
import { IWidgetProps } from '../../widgetTypes';

export const WidgetDefaultOptions = {
	chart: {
		type: 'spline',
	},
	title: {
		text: null,
	},
	time: {
		useUTC: false,
	},
	xAxis: {
		type: 'datetime',
		dateTimeLabelFormats: {
			month: '%e. %b',
			year: '%b',
		},
		title: {
			text: null,
		},
	},
	yAxis: {
		title: {
			text: 'Unit',
		},
		min: 0,
		max: null,
	},
	colors: [],
	series: [],
};
// Serie definition
// 		name:
// 		data:
// 		lineWidth:
// 		marker:
// 		boostThreshold:
// 		turboThreshold:
//    valueCalcExpresion: '',
//    valueCalcParamPoints: [],
//

export const WidgetLine1 = React.forwardRef(
	(props: IWidgetProps, ref: Ref<RefObj>) => {
		const { loggedOnUser } = useSelector((state: IAppState) => state.app);

		const [widgetInfo, setWidgetInfo] = React.useState<WidgetInfo>();

		boost(Highcharts);

		useEffect(() => {
			let isMounted = true;
			let timer: any = null;
			let getHistoryDone = true;

			// At first render the widget updates widgetInfo.widgetTypeConfig
			// with default configuration, so we get a first unconfigured render
			if (props.widgetInfo.widgetTypeConfig === undefined) {
				let wi = { ...props.widgetInfo };
				wi.widgetTypeConfig = JSON.parse(JSON.stringify(WidgetDefaultOptions));
				props.widgetUpdateDefaultConfigUpdate(wi);
				return;
			}

			// Read all points data direct on props.widgets update and start a
			// refresh timer
			if (isMounted && !props.widgetInfo.refresh?.subscription) {
				getPointsHistory();

				if (timer == null) {
					timer = setInterval(
						() => {
							if (isMounted) {
								if (getHistoryDone) {
									getHistoryDone = false;
									getPointsHistory()?.then(() => {
										getHistoryDone = true;
									});
								}
							}
						},
						props.widgetInfo.refresh === undefined
							? 30000
							: props.widgetInfo.refresh.refresh * 1000
					);
				}
			}

			return () => {
				clearInterval(timer);
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [props.widgetInfo]);

		// Links the function CleanWidgetTypeConfigFromPvs to the parent
		useImperativeHandle(ref, () => ({ CleanWidgetTypeConfigFromPvs }));

		// Called by the dash when saving the widgets info
		// This fuinction removes any pointdata from the structure so
		// it will not end up in the database.
		const CleanWidgetTypeConfigFromPvs = (): any => {
			let wi = { ...props.widgetInfo };

			if (
				wi.widgetTypeConfig.series === undefined ||
				wi.widgetTypeConfig.series.length === 0
			)
				return wi;

			wi.widgetTypeConfig.series.map((x: any) => {
				x.data = [];
				return x;
			});

			return wi;
		};

		const getPointsHistory = () => {
			let pointDTOs: PointDTO[] = [...props.widgetInfo.points];

			for (var c = 0; c < pointDTOs.length; c++) {
				var serie = props.widgetInfo.widgetTypeConfig.series[c];
				if (
					serie !== undefined &&
					serie.valueCalcParamPoints !== undefined &&
					serie.valueCalcParamPoints.length > 0
				) {
					serie.valueCalcParamPoints.map((serie: SerieSetting) => {
						pointDTOs.push(serie.pointDTO!);
					});
				}
			}

			// // add any calcParameterPoints
			// if (
			// 	props.widgetInfo.widgetTypeConfig.valueCalcParamPoints !== undefined &&
			// 	props.widgetInfo.widgetTypeConfig.valueCalcParamPoints.length > 0
			// ) {
			// 	props.widgetInfo.widgetTypeConfig.valueCalcParamPoints.map(
			// 		(serie: SerieSetting) => {
			// 			pointDTOs.push(serie.pointDTO!);
			// 		}
			// 	);
			// }

			let pointAPI: PointAPI = new PointAPI();

			let pointIds: string[] = [];
			for (let pointC = 0; pointC < pointDTOs.length; pointC++)
				pointIds.push(pointDTOs[pointC].pointID);

			return pointAPI
				.GetPointsHistory({
					customerId: loggedOnUser?.tenant == null ? '' : loggedOnUser.tenant,
					pointids: pointIds,
					fromDateTime: getFromDateTime(),
					toDateTime: getToDateTime(),

					groupByIntervalUnit:
						props.widgetInfo!.refresh === undefined
							? 'second'
							: (props.widgetInfo.refresh
									.groupUnit as moment.unitOfTime.DurationConstructor),
					groupByInterval:
						props.widgetInfo!.refresh === undefined
							? 1
							: props.widgetInfo!.refresh.group,
					maxRowsToReturn: 100000,
					onlyReturnCount: false,
					latestValue: false,
				})
				.then((resp) => {
					if (resp.status >= 200 && resp.status < 300) {
						let pointHistoryResp: PointHistoryResponseDTO;
						pointHistoryResp = resp.data;

						let wi = { ...props.widgetInfo };

						// Loop every widget serie
						for (
							let cSerie = 0;
							cSerie < wi.widgetTypeConfig.series.length;
							cSerie++
						) {
							// Get the points for the serie
							let serie = wi.widgetTypeConfig.series[cSerie];
							if (serie === undefined) continue;

							serie.data = [];

							// check if we have a calcExpression
							if (
								serie.valueCalcExpresion !== undefined &&
								serie.valueCalcExpresion.length > 0
							) {
								let paramStr = 'value,params';
								let parameterHash: INameValueHash = {};

								let calcExpressionFunction: any = undefined;
								try {
									calcExpressionFunction = new Function(
										paramStr,
										serie.valueCalcExpresion
									);
								} catch (e) {
									return;
								}

								let pHist: PointHistoryDTO | undefined =
									pointHistoryResp.pointHistory.find((x) => {
										return x.pointId === serie.pointDTO.pointID;
									});

								for (let cPoint = 0; cPoint < pHist!.points.length; cPoint++) {
									// Get serie samp
									let samp = pHist!.points[cPoint];

									// Get parameters samp
									serie.valueCalcParamPoints.map((paramPoint: SerieSetting) => {
										let paramPVS = pointHistoryResp.pointHistory.find(
											(x: PointHistoryDTO) => {
												return x.pointId === paramPoint.pointDTO?.pointID;
											}
										);

										if (
											paramPVS !== undefined &&
											paramPVS.points[cPoint].state === enPointState.OK
										) {
											paramPoint.value = parseFloat(
												paramPVS.points[cPoint].value.replace(',', '.')
											);
										} else paramPoint.value = null;
									});

									// Set values to the parameterHash
									for (let c = 0; c < serie.valueCalcParamPoints.length; c++) {
										parameterHash[serie.valueCalcParamPoints[c].name] =
											serie.valueCalcParamPoints[c].value;
									}

									try {
										let utc = moment(samp.time);
										let t = utc.add(utc.utcOffset(), 'minutes').toDate();

										let value: number | null = null;
										if (
											samp.state === enPointState.OK &&
											serie.valueCalcParamPoints.find((x: SerieSetting) => {
												return x.value === null;
											}) === undefined
										) {
											value = calcExpressionFunction!(
												parseFloat(samp.value.replace(',', '.')),
												parameterHash
											);
										} else {
											value = null;
										}

										serie.data.push({
											x: t,
											y: value,
										});
									} catch (e) {
										break;
									}
								}
							} else {
								// Raw serie
								let pHist: PointHistoryDTO | undefined =
									pointHistoryResp.pointHistory.find((x) => {
										return x.pointId === serie.pointDTO.pointID;
									});

								for (let cPoint = 0; cPoint < pHist!.points.length; cPoint++) {
									// parse all PVS for point
									let samp = pHist!.points[cPoint];

									let utc = moment(samp.time);
									let t = utc.add(utc.utcOffset(), 'minutes').toDate();

									let value: number | null = null;
									if (samp.state !== enPointState.NULL)
										value = parseFloat(samp.value);

									serie.data.push({
										x: t,
										y: value,
									});
								}
							}
						}
						// OK
						wi.error = undefined;
						props.widgetError(wi);

						setWidgetInfo(wi);
					}
				})
				.catch((e) => {
					let wi = { ...props.widgetInfo };
					wi.error = 'Cant load data from server';
					props.widgetError(wi);
				})
				.finally(() => {});
		};

		const getToDateTime = (): string => {
			if (
				props.widgetInfo.refresh?.start === 0 ||
				props.widgetInfo.refresh?.startUnit === undefined
			)
				return moment().toISOString();
			else {
				if (props.widgetInfo.refresh?.startUnit === 'Second') {
					return moment()
						.add(props.widgetInfo.refresh?.start, 'seconds')
						.toISOString();
				} else if (props.widgetInfo.refresh?.startUnit === 'Minute') {
					return moment()
						.add(props.widgetInfo.refresh?.start, 'minutes')
						.toISOString();
				} else if (props.widgetInfo.refresh?.startUnit === 'Hour') {
					return moment()
						.add(props.widgetInfo.refresh?.start, 'hours')
						.toISOString();
				} else if (props.widgetInfo.refresh?.startUnit === 'Day') {
					return moment()
						.add(props.widgetInfo.refresh?.start, 'days')
						.toISOString();
				} else if (props.widgetInfo.refresh?.startUnit === 'Week') {
					return moment()
						.add(props.widgetInfo.refresh?.start, 'weeks')
						.toISOString();
				} else if (props.widgetInfo.refresh?.startUnit === 'Month') {
					return moment()
						.add(props.widgetInfo.refresh?.start, 'months')
						.toISOString();
				}
				return 'NA';
			}
		};

		const getFromDateTime = (): string => {
			let toDateTime = moment();
			if (
				props.widgetInfo.refresh?.start === 0 ||
				props.widgetInfo.refresh?.startUnit === undefined
			)
				toDateTime = moment();
			else {
				toDateTime = moment(getToDateTime());
			}
			if (props.widgetInfo.refresh?.lastUnit === 'Second') {
				return toDateTime
					.add(-props.widgetInfo.refresh?.last, 'seconds')
					.toISOString();
			} else if (props.widgetInfo.refresh?.lastUnit === 'Minute') {
				return toDateTime
					.add(-props.widgetInfo.refresh?.last, 'minutes')
					.toISOString();
			} else if (props.widgetInfo.refresh?.lastUnit === 'Hour') {
				return toDateTime
					.add(-props.widgetInfo.refresh?.last, 'hours')
					.toISOString();
			} else if (props.widgetInfo.refresh?.lastUnit === 'Day') {
				return toDateTime
					.add(-props.widgetInfo.refresh?.last, 'days')
					.toISOString();
			} else if (props.widgetInfo.refresh?.lastUnit === 'Week') {
				return toDateTime
					.add(-props.widgetInfo.refresh?.last, 'weeks')
					.toISOString();
			} else if (props.widgetInfo.refresh?.lastUnit === 'Month') {
				return toDateTime
					.add(-props.widgetInfo.refresh?.last, 'months')
					.toISOString();
			}
			return 'NA';
		};

		return (
			<React.Fragment>
				{widgetInfo ? (
					<HighchartsReact
						containerProps={{
							style: { height: '100%', width: '100%' },
						}}
						key={props.widgetInfo.i}
						highcharts={Highcharts}
						options={widgetInfo!.widgetTypeConfig}
					/>
				) : (
					''
				)}
			</React.Fragment>
		);
	}
);
